import React from 'react';
import logoRAB from './img/marcarab-tr.png';
import logoUFMG from './img/ufmgLogo.png';

function LogoParceria() {
    return(        
        <div className="container">
            
                <div className="row d-flex" >     

                    <div className="col mt-3">
                        <center>
                            <img src={logoRAB} className="logoImgRAB" alt="Logo da Rede Artesanato Brasil"/> 
                            <img src={logoUFMG} className="logoImgUFMG" alt="Logo da UFMG"/>

                        </center>
                    </div>
                </div>
            
        </div>
        );
    
}
export default LogoParceria;