export const SOLICITAR_DOCUMENTOS = 'SOLICITAR_DOCUMENTOS'
export const SOLICITAR_APROVACAO_DOCUMENTO = 'SOLICITAR_APROVACAO_DOCUMENTO'
export const VISUALIZAR_DOCUMENTOS = 'VISUALIZAR_DOCUMENTOS'
export const VISUALIZAR_DOCUMENTOS_EQUIPE = 'VISUALIZAR_DOCUMENTOS_EQUIPE'
export const VISUALIZAR_DOCUMENTOS_TODAS_EQUIPES = 'VISUALIZAR_DOCUMENTOS_TODAS_EQUIPES'
export const SOLICITAR_CANCELAMENTO_DOCUMENTOS = 'SOLICITAR_CANCELAMENTO_DOCUMENTOS'
export const SOLICITAR_DOCUMENTOS_TODAS_EQUIPES = 'SOLICITAR_DOCUMENTOS_TODAS_EQUIPES'
export const ARQUIVAR_DOCUMENTOS = 'ARQUIVAR_DOCUMENTOS'
export const ARQUIVAR_DOCUMENTOS_EQUIPE = 'ARQUIVAR_DOCUMENTOS_EQUIPE'
export const ARQUIVAR_DOCUMENTOS_TODAS_EQUIPES = 'ARQUIVAR_DOCUMENTOS_TODAS_EQUIPES'
export const CRIAR_DOCUMENTOS = 'CRIAR_DOCUMENTOS'
export const CRIAR_DOCUMENTOS_SOLICITADOS = 'CRIAR_DOCUMENTOS_SOLICITADOS'
export const CRIAR_DOCUMENTOS_REJEITADOS = 'CRIAR_DOCUMENTOS_REJEITADOS'
export const REJEITAR_SOLICITACAO_DOCUMENTOS = 'REJEITAR_SOLICITACAO_DOCUMENTOS'
export const EDITAR_DOCUMENTOS_MINUTA = 'EDITAR_DOCUMENTOS_MINUTA'
export const EDITAR_DOCUMENTOS = 'EDITAR_DOCUMENTOS'
export const APROVAR_DOCUMENTOS = 'APROVAR_DOCUMENTOS'
export const DEFINIR_DATA_ASSINATURA_DOCUMENTO = 'DEFINIR_DATA_ASSINATURA_DOCUMENTO'
export const EXPORTAR_DOCUMENTOS_PDF = 'EXPORTAR_DOCUMENTOS_PDF'
export const EXPORTAR_DOCUMENTOS_WORD = 'EXPORTAR_DOCUMENTOS_WORD'
export const VISUALIZAR_MINHA_CONTA = 'VISUALIZAR_MINHA_CONTA'
export const VISUALIZAR_USUARIOS_PAINEL = 'VISUALIZAR_USUARIOS_PAINEL'
export const EDITAR_USUARIOS_PAINEL = 'EDITAR_USUARIOS_PAINEL'
export const ARQUIVAR_USUARIOS_PAINEL = 'ARQUIVAR_USUARIOS_PAINEL'
export const ARQUIVAR_USUARIOS_LOTE_PAINEL = 'ARQUIVAR_USUARIOS_LOTE_PAINEL'
export const EXCLUIR_USUARIOS_PAINEL = 'EXCLUIR_USUARIOS_PAINEL'
export const CRIAR_USUARIOS_PAINEL = 'CRIAR_USUARIOS_PAINEL'
export const CRIAR_USUARIOS_LOTE_PAINEL = 'CRIAR_USUARIOS_LOTE_PAINEL'

export const VISUALIZAR_USUARIOS = 'VISUALIZAR_USUARIOS'
export const CRIAR_USUARIOS = 'CRIAR_USUARIOS'
export const EDITAR_USUARIOS = 'EDITAR_USUARIOS'
export const ARQUIVAR_USUARIOS = 'ARQUIVAR_USUARIOS'
export const EXCLUIR_USUARIOS = 'EXCLUIR_USUARIOS'

export const VISUALIZAR_USUARIOS_AREA_PUBLICA = 'VISUALIZAR_USUARIOS_AREA_PUBLICA'
export const VISUALIZAR_FUNCOES_EQUIPES = 'VISUALIZAR_FUNCOES_EQUIPES'
export const VISUALIZAR_FUNCOES_TODAS_EQUIPES = 'VISUALIZAR_FUNCOES_TODAS_EQUIPES'
export const CRIAR_FUNCOES_EQUIPES = 'CRIAR_FUNCOES_EQUIPES'
export const CRIAR_FUNCOES_TODAS_EQUIPES = 'CRIAR_FUNCOES_TODAS_EQUIPES'
export const EXCLUIR_FUNCOES_EQUIPES = 'EXCLUIR_FUNCOES_EQUIPES'
export const VISUALIZAR_USUARIOS_FUNCOES_EQUIPES = 'VISUALIZAR_USUARIOS_FUNCOES_EQUIPES'
export const VISUALIZAR_USUARIOS_FUNCOES_TODAS_EQUIPES = 'VISUALIZAR_USUARIOS_FUNCOES_TODAS_EQUIPES'
export const CRIAR_USUARIOS_FUNCOES_EQUIPES = 'CRIAR_USUARIOS_FUNCOES_EQUIPES'
export const CRIAR_USUARIOS_FUNCOES_TODAS_EQUIPES = 'CRIAR_USUARIOS_FUNCOES_TODAS_EQUIPES'
export const EDITAR_USUARIOS_FUNCOES_EQUIPES = 'EDITAR_USUARIOS_FUNCOES_EQUIPES'
export const EXCLUIR_USUARIOS_FUNCOES_EQUIPES = 'EXCLUIR_USUARIOS_FUNCOES_EQUIPES'
export const OCULTAR_EXIBIR_USUARIOS_AREA_PUBLICA = 'OCULTAR_EXIBIR_USUARIOS_AREA_PUBLICA'
export const VISUALIZAR_EQUIPES = 'VISUALIZAR_EQUIPES'
export const CRIAR_EQUIPES = 'CRIAR_EQUIPES'
export const EXCLUIR_EQUIPES = 'EXCLUIR_EQUIPES'
export const EDITAR_EQUIPES = 'EDITAR_EQUIPES'
export const EXPORTAR_PROJETO_PF = 'EXPORTAR_PROJETO_PF'
export const EXPORTA_PROJETO_PJ = 'EXPORTAR_PROJETO_PJ'
export const ENTREVISTADOR = 'ENTREVISTAR_PESSOAS'
export const ENVIAR_EMAIL_CONTATO = 'ENVIAR_EMAIL_CONTATO'
export const CANCELAR_DOCUMENTO = 'CANCELAR_DOCUMENTO'
export const GERENCIAR_TERMO_PARTICIPACAO = 'GERENCIAR_TERMO_PARTICIPACAO'
export const GERENCIAR_PERMISSOES = 'GERENCIAR_PERMISSOES'
export const GERAR_DECLARACAO_PARTICIPACAO_PROJETO = 'GERAR_DECLARACAO_PARTICIPACAO_PROJETO'