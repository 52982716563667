import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import ModalEnviarEmail from "../../../Modal/ModalEnviarEmail";
import { GlobalContext } from "../../../../Context/GlobalContext/context";
import * as permissions from "../../../../Context/GlobalContext/permissions";
import { apiURL } from "../../../../Services/env";
import {api, apiWithToken} from "../../../../Services/Api";
import ReactTooltip from "react-tooltip";
import MaskedInput from "../../../InputMask";


export class TablePessoas extends Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.referencia = React.createRef();

        this.state = {
            usuarios: [],
            papeis: [],
            equipes: [],
            page: 0,
            offset: 0,
            limit: 10,
            pageCount: 0,
            pesquisa: '',
            type: '',
            status: '',
            usuario: '',

            view_pessoa: {},
            view_pessoas_inst: [],
            user: null,
            id: null,
            nome: null,
            email: null,
            cpf: null,
            biografia: null,
            salvar_usuario: false,
            pessoa_usuario: false,
            modalShow: null,
            checkAllUsers: false,
            equipes_pessoa: [],
            listUsersChecked: [],
            checkAllUsersEl: [],
            arquivoNovo: null,
            countBio: 0,
            isMobile: false
        }
    }

    componentDidMount() {
        if (!this.context.getPermission(permissions.VISUALIZAR_USUARIOS)) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        this.getDados();
        this.setState({ isMobile: window.screen.width <= 400 })
    }

    getDados = () => {
        this.context.startLoading();

        const body = new FormData();
        body.append('limit', this.state.limit);
        body.append('offset', this.state.offset);

        if (this.state.pesquisa !== "") {
            body.append('pesquisa', this.state.pesquisa);
        }
        if (this.state.type !== "" && this.state.type !== "Todos") {
            body.append('condicao', this.state.type);
        }
        if (this.state.usuario !== "" && this.state.usuario !== "Todos") {
            body.append('usuario', this.state.usuario);
        }
        if (this.state.status !== "") {
            body.append('situacao', this.state.status);
        }

        const requestOptions = {
            method: 'POST',
            body
        }

        api(apiURL + 'gerirAcesso/manterUsuarios', requestOptions)
            .then(async res => await res.json())
            .then(res => {

                const {data, total} = res.resultado;

                this.setState({
                    usuarios: data,
                    pageCount: total,
                })
            })
            .finally(() => {
                this.context.stopLoading()
            })

    }

    limparFiltro = async () => {
        document.getElementById('pesquisar').value = '';
        await this.setState({
            limit: 10,
            offset: 0,
            page: 0,
            pesquisa: ''
        })
        this.getDados(10, 0);
    }

    limpartForm = () => {
        this.setState({
            id: null,
            nome: null,
            cpf: null,
            cnpj: null,
            email: null,
            biografia: null,
            data_nascimento: null,
            rg: null,
            orgao_rg: null,
            expedicao_rg: null,
            nacionalidade: null,
            profissao: null,
            salvar_usuario: false,
            pessoa_usuario: false,
            arquivoNovo: null,
            countBio: 0
        })
        document.getElementById('nome_salvar').value = '';
        document.getElementById('email_salvar').value = '';
        document.getElementById('cpf_salvar').value = '';
        document.getElementById('biografia_salvar').value = '';
        document.getElementById('data_nascimento').value = '';
        document.getElementById('rg').value = '';
        document.getElementById('orgao_rg').value = '';
        document.getElementById('expedicao_rg').value = '';
        document.getElementById('nacionalidade').value = '';
        document.getElementById('profissao').value = '';
        document.getElementById('salvar_usuario').checked = false;
        this.limparFiltro()
    }

    limpartFormAux = () => {
        this.setState({
            id: null,
            nome: null,
            cpf: null,
            cnpj: null,
            email: null,
            biografia: null,
            data_nascimento: null,
            rg: null,
            orgao_rg: null,
            expedicao_rg: null,
            nacionalidade: null,
            profissao: null,
            salvar_usuario: false,
            pessoa_usuario: false,
            arquivoNovo: null,
            countBio: 0
        })
        document.getElementById('nome_salvar').value = '';
        document.getElementById('email_salvar').value = '';
        document.getElementById('cpf_salvar').value = '';
        document.getElementById('biografia_salvar').value = '';
        document.getElementById('data_nascimento').value = '';
        document.getElementById('rg').value = '';
        document.getElementById('orgao_rg').value = '';
        document.getElementById('expedicao_rg').value = '';
        document.getElementById('nacionalidade').value = '';
        document.getElementById('profissao').value = '';
        document.getElementById('salvar_usuario').checked = false;
    }

    salvarUsuario = () => {

        if (this.context.getPermission(permissions.CRIAR_USUARIOS)) {
            const nome = this.state.nome ?? '';
            const email = this.state.email ?? '';
            const cpf = this.state.cpf ?? '';
            const salvar_usuario = this.state.salvar_usuario ?? '';

            if (nome !== '' && nome !== null && email !== '' && email !== null && cpf !== '' && cpf !== null){

                this.setState({
                    limit: 10,
                    offset: 0,
                    page: 0
                })

                this.context.startLoading();
                document.getElementById('btnCloseModal').click();

                const body = new FormData();
                body.append('nome', nome);
                body.append('email', email);
                body.append('data_nascimento', this.state.data_nascimento ?? '');
                body.append('cpf', cpf);
                body.append('rg', this.state.rg ?? '');
                body.append('orgao_rg', this.state.orgao_rg ?? '');
                body.append('expedicao_rg', this.state.expedicao_rg ?? '');
                body.append('biografia', this.state.biografia ?? '');
                body.append('nacionalidade', this.state.nacionalidade ?? '');
                body.append('profissao', this.state.profissao ?? '');
                body.append('salvar_usuario', salvar_usuario === '' ? '2' : salvar_usuario ? '1' : '2'); // 1 - Sim; 2 - Não

                const requestOptions = {
                    method: 'POST',
                    body
                }

                api(apiURL + 'gerirAcesso/manterUsuarios/cadastrar', requestOptions)
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.limpartForm();
                        this.context.stopLoading();
                        this.getDados();
                    })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    removerUsuario = (id_pessoa, type) => {
        let op = false;


        if (this.context.getPermission(permissions.EXCLUIR_USUARIOS)) {
            op = window.confirm("Confirme a exlusão do registro: ");
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }


        if (op){

            this.setState({
                limit: 10,
                offset: 0
            })
            this.context.startLoading();

            const body = new FormData();
            body.append('type', type)

            const requestOptions = {
                method: 'POST',
                body
            }

            api(apiURL + 'gerirAcesso/manterUsuarios/deletar/' + id_pessoa, requestOptions)
                .then(async res => await res.json())
                .then(res => alert(res.message))
                .finally(() => {
                    this.context.stopLoading();
                    this.getDados();
                })
        }

    }

    arquivarUsuario = (id_pessoa, type) => {
        let op = false;
        if (this.context.getPermission(permissions.ARQUIVAR_USUARIOS)) {
            op = window.confirm("Confirme o arquivamento do registro: ");
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }


        if (op){

            this.setState({
                limit: 10,
                offset: 0
            })
            this.context.startLoading();

            const body = new FormData();
            body.append('type', type)

            const requestOptions = {
                method: 'POST',
                body
            }

            api(apiURL + 'gerirAcesso/manterUsuarios/arquivar/' + id_pessoa, requestOptions)
                .then(async res => await res.json())
                .then(res => alert(res.message))
                .finally(() => {
                    this.context.stopLoading();
                    this.getDados();
                })
        }

    }

    desarquivarUsuario = (id_pessoa, type) => {
        let op = false;

        if (this.context.getPermission(permissions.ARQUIVAR_USUARIOS)) {
            op = window.confirm("Confirme a ativação do registro: ");
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }

        if (op){

            this.setState({
                limit: 10,
                offset: 0
            })
            this.context.startLoading();

            const body = new FormData();
            body.append('type', type)

            const requestOptions = {
                method: 'POST',
                body
            }

            api(apiURL + 'gerirAcesso/manterUsuarios/desarquivar/' + id_pessoa, requestOptions)
                .then(async res => await res.json())
                .then(res => alert(res.message))
                .finally(() => {
                    this.context.stopLoading();
                    this.getDados();
                })
        }

    }

    editarUsuario = (pessoa) => {

        if (this.context.getPermission(permissions.EDITAR_USUARIOS)) {
            this.setState({
                id: pessoa.ID,
                nome: pessoa.NOME ?? '',
                cpf: pessoa.CPF ?? '',
                cnpj: pessoa.CNPJ ?? '',
                email: pessoa.EMAIL ?? '',
                data_nascimento: pessoa.DATA_NASCIMENTO ?? '',
                rg: pessoa.RG ?? '',
                orgao_rg: pessoa.ORGAO_RG ?? '',
                expedicao_rg: pessoa.EXPEDICAO_RG ?? '',
                biografia: pessoa.BIOGRAFIA ?? '',
                profissao: pessoa.PROFISSAO ?? '',
                nacionalidade: pessoa.NACIONALIDADE ?? '',
                countBio: pessoa.BIOGRAFIA ? pessoa.BIOGRAFIA.length : 0,
                pessoa_usuario: pessoa.usuario !== null && pessoa.usuario !== undefined,
                view_pessoa: Object.assign({}, pessoa)
            });
            document.getElementById('btnLaunchModalUpdate').click();
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    atualizarUsuario = () => {
        if (this.context.getPermission(permissions.EDITAR_USUARIOS)) {
            const id = this.state.id ?? '';
            const nome = this.state.nome ?? '';
            const email = this.state.email ?? '';
            const cpf = this.state.cpf ?? '';
            const salvar_usuario = this.state.salvar_usuario ?? '';

            if (id !== '' && id !== null && nome !== '' && nome !== null && email !== '' && email !== null && cpf !== '' && cpf !== null){

                this.context.startLoading();
                document.getElementById('btnCloseModalUpdate').click();

                const body = new FormData();
                body.append('nome', nome);
                body.append('email', email);
                body.append('cpf', cpf);
                body.append('data_nascimento', this.state.data_nascimento ?? '');

                body.append('rg', this.state.rg ?? '');
                body.append('orgao_rg', this.state.orgao_rg ?? '');
                body.append('expedicao_rg', this.state.expedicao_rg ?? '');
                body.append('profissao', this.state.profissao ?? '');
                body.append('nacionalidade', this.state.nacionalidade ?? '');

                body.append('biografia', this.state.biografia);
                body.append('salvar_usuario', salvar_usuario === '' ? '2' : salvar_usuario ? '1' : '2'); // 1 - Sim; 2 - Não

                const requestOptions = {
                    method: 'POST',
                    body
                }

                api(apiURL + 'gerirAcesso/manterUsuarios/atualizar/' + id, requestOptions)
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.limpartForm();
                        this.context.stopLoading();
                        this.getDados();
                    })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleBiografia = (e) => {
        this.setState(s => ({ ...s, countBio: e.target.value.length, biografia: e.target.value }) )
    }

    switchCargo = (posicao) => {
        switch (posicao) {
            case 'RESPONSAVEL':
                return 'Responsável';
            case 'DIRIGENTE':
                return 'Dirigente';
            case 'INTERLOCUTOR':
                return 'Interlocutor';
            default:
                return 'Responsável';
        }
    }

    switchPrincipaisDesafios = pd => {
        switch (pd) {
            case 'legislacao':
                return 'Legislação (atualização das leis)';
            case 'comercializacao':
                return 'Comercialização';
            case 'producao':
                return 'Produção';
            case 'resgistro_saberes':
                return 'Registro de saberes / práticas e técnicas';
            default:
                return '';
        }
    }

    switchFormaAtuacao = fa => {
        switch (fa) {
            case 'artesao':
                return 'Artesão / Artesã';
            case 'instrutoria_tecnicas_artesanais':
                return 'Instrutoria e Técnicas Artesanais';
            case 'consultoria_design':
                return 'Consultoria Design';
            case 'consultoria_gestao':
                return 'Consultoria Gestão';
            case 'comercio_materia_prima':
                return 'Comércio de Matéria-prima';
            case 'comercio_insumos':
                return 'Coméricio Insumos';
            case 'comercio_artesanato':
                return 'Comércio Artesanato';
            case 'outros':
                return 'Outros';
            default:
                return '';
        }
    }

    switchSexo = s => {
        switch (s) {
            case 'masculino':
                return 'Masculino';
            case 'feminino':
                return 'Feminino';
            case 'homem_trans':
                return 'Homem trans';
            case 'mulher_trans':
                return 'Mulher trans';
            default:
                return '';
        }
    }

    switchPublicosAlvo = s => {
        switch (s) {
            case 'destinado_a_artesao':
                return 'Artesões';
            case 'destinados_a_grupo_artesao':
                return 'Grupos de Artesãos';
            case 'destinados_a_associacoes':
                return 'Associações';
            case 'destinados_a_gestores':
                return 'Gestores';
            case 'destinados_a_fornecedores':
                return 'Fornecedores';
            case 'destinados_a_outros':
                return 'Outros';
            default:
                return '';
        }
    }

    switchAreasAtendimento = s => {
        switch (s) {
            case 'nenhuma':
                return 'Nenhuma';
            case 'area_capacitacao':
                return 'Capacitação';
            case 'area_acesso_mercado':
                return 'Acesso a mercado';
            case 'area_estudos_pesquisas':
                return 'Estudos e pesquisas';
            case 'area_acesso_a_credito':
                return 'Acesso a crédito';
            case 'area_inovacao_e_tecnologia':
                return 'Inovação e Tecnologia';
            case 'area_infraestrutura':
                return 'Infraestrutura';
            case 'area_outros':
                return 'Outros';
            default:
                return '';
        }
    }

    handleCpf = (e) => {
        let value = e.target.value.replaceAll('.', '')
            .replaceAll('_', '')
            .replaceAll('-', '')
        this.setState({
            cpf: value !== '' ? value : null
        });
    }

    handleCnpj = (e) => {
        let value = e.target.value.replaceAll('.', '')
            .replaceAll('_', '')
            .replaceAll('-', '')
            .replaceAll('/', '')
        this.setState({
            cnpj: value !== '' ? value : null
        });
    }

    getDadosRelacoes = async (pessoa_id) => {
        const body = new FormData();
        body.append('pessoa_id', pessoa_id);
        const requestOptions = {
            method: 'POST',
            body
        }
        let res = await api(apiURL + 'gerirAcesso/manterUsuarios/relacoes', requestOptions);
        return await res.json();
    }

    paginate = async (event) => {

        await this.setState({offset: event.selected + 1, page: event.selected, checkAllUsers: false });
        this.getDados();
    }

    handleUsuario = (event) => {
        this.setState({
            usuario: event.target.value
        })
    }

    handleCondicao = (event) => {
        this.setState({
            type: event.target.value
        })
    }

    aumentarLimite = () => {
        const valor = document.getElementById('aumentar_limite').value
        this.setState({ limit: valor, offset: 0, page: 1 });
        this.getDados()
    }

    handlePesquisa = (e) => {
        e.preventDefault();
        this.setState({ limit: 10, offset: 0, page: 0 })
        this.getDados()
    }

    handleMostrarOcutarArquivados = (event) => {
        this.setState({
                status: event.target.checked ? 'INATIVO' : 'ATIVO'
            }
        );
    }

    handleModalCreateShow = () => {
        if (this.context.getPermission(permissions.CRIAR_USUARIOS)) {
            document.getElementById('modalCreateUsuario').click();
            ReactTooltip.rebuild();
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }

    }

    gerarDeclaracao = (cpfDeclarante, isUsuario) => {

        if(isUsuario === 'NÃO'){
            alert('Esta pessoa não é um usuário da área administrativa!');
        }
        else{
            if (!this.context.getPermission(permissions.GERAR_DECLARACAO_PARTICIPACAO_PROJETO)) {
                alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!');
            }
            else{

                let cpfSolicitante = localStorage.getItem("projartCod");
                let token = localStorage.getItem("projartToken");

                this.context.startLoading();

                this.emitirDeclaracao(cpfSolicitante, cpfDeclarante, token).then(response => {
                    return response.blob();
                })
                    .then(blob => {
                        const url = URL.createObjectURL(blob,{type: 'application/pdf;charset=UTF-8'});
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Declaração de participação.pdf');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(erra => {console.log(erra);}).finally(() => this.context.stopLoading());

            }
        }
    }

    async emitirDeclaracao(cpfSolicitante, cpfDeclarante, token){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: token,
                cpfSolicitante: cpfSolicitante,
                cpfDeclaracao: cpfDeclarante
            })
        };

        const response = await apiWithToken(apiURL+"emitirDeclaracao", requestOptions);
        return response;

    }

    visualizarDadosUsuario = (pessoa) => {

        if (this.context.getPermission(permissions.VISUALIZAR_USUARIOS)) {
            this.context.startLoading();

            this.getDadosRelacoes(pessoa['ID'])
                .then(res => {

                    let vinculo_endereco_instituicao = pessoa['VINCULO_ENDERECO_INSTITUICAO'];
                    vinculo_endereco_instituicao = vinculo_endereco_instituicao !== null ? JSON.parse(vinculo_endereco_instituicao) : {};

                    let atividade_pagamento_dados = pessoa['ATIVIDADE_PAGAMENTO_DADOS'];
                    atividade_pagamento_dados = atividade_pagamento_dados !== null && atividade_pagamento_dados !== undefined ? JSON.parse(atividade_pagamento_dados) : {};

                    this.setState({
                        nome: pessoa['NOME'] ?? '',
                        email: pessoa['EMAIL'] ?? '',
                        cpf: pessoa['CPF'] ?? '',
                        cnpj: pessoa['CNPJ'] ?? '',
                        rg: pessoa['RG'] ?? '',
                        data_nascimento: pessoa['DATA_NASCIMENTO'] ?? '',
                        orgao_rg: pessoa['ORGAO_RG'] ?? '',
                        expedicao_rg: pessoa['EXPEDICAO_RG'] ?? '',
                        profissao: pessoa['PROFISSAO'] ?? '',
                        nacionalidade: pessoa['NACIONALIDADE'] ?? '',
                        biografia: pessoa['BIOGRAFIA'] ?? '',

                        link_pdf_cpf: pessoa['PDF_CPF'],
                        link_pdf_rg: pessoa['PDF_RG'],
                        link_pdf_lattes: pessoa['PDF_LATTES'],
                        link_pdf_conta_bancaria: pessoa['PDF_CONTA_BANCARIA'],

                        vinculo_nome_instituicao: pessoa['VINCULO_NOME_INSTITUICAO'],
                        vinculo_endereco_cep_instituicao: vinculo_endereco_instituicao.cep,
                        vinculo_endereco_uf_instituicao: vinculo_endereco_instituicao.uf,
                        vinculo_endereco_cidade_instituicao: vinculo_endereco_instituicao.cidade,
                        vinculo_endereco_bairro_instituicao: vinculo_endereco_instituicao.bairro,
                        vinculo_endereco_rua_instituicao: vinculo_endereco_instituicao.rua,
                        vinculo_endereco_numero_instituicao: vinculo_endereco_instituicao.numero,
                        vinculo_endereco_complemento_instituicao: vinculo_endereco_instituicao.complemento,
                        vinculo_endereco_campus_instituicao: vinculo_endereco_instituicao.campus,
                        vinculo_categoria: pessoa['VINCULO_CATEGORIA'],
                        vinculo_cargo: pessoa['VINCULO_CARGO'],
                        vinculo_matricula_siape: pessoa['VINCULO_MATRICULA_SIAPE'],

                        atividade_data_inicio: pessoa['ATIVIDADE_DATA_INICIO'],
                        atividade_data_fim: pessoa['ATIVIDADE_DATA_FIM'],
                        atividade_carga_horaria: pessoa['ATIVIDADE_CARGA_HORARIA'],

                        atividade_pagamento_tipo: pessoa['ATIVIDADE_PAGAMENTO_TIPO'],
                        atividade_pagamento_dados_banco: atividade_pagamento_dados.banco,
                        atividade_pagamento_dados_agencia: atividade_pagamento_dados.agencia,
                        atividade_pagamento_dados_conta: atividade_pagamento_dados.conta,

                        endereco: pessoa['ENDERECO'] ?? '',
                        endereco_cep: pessoa['ENDERECO_CEP'] ?? '',
                        endereco_uf: pessoa['ENDERECO_UF'] ?? '',
                        endereco_cidade: pessoa['ENDERECO_CIDADE'] ?? '',
                        endereco_estado: pessoa['ENDERECO_ESTADO'] ?? '',
                        endereco_bairro: pessoa['ENDERECO_BAIRRO'] ?? '',
                        endereco_rua: pessoa['ENDERECO_RUA'] ?? '',
                        endereco_numero: pessoa['ENDERECO_NUMERO'] ?? '',
                        endereco_complemento: pessoa['COMPLEMENTO'] ?? '',

                        ddd_celular: pessoa['DDD_CELULAR'] ?? '',
                        celular: pessoa['CELULAR'] ?? '',

                        ddd_whatsapp: pessoa['DDD_WHATSAPP'] ?? '',
                        whatsapp: pessoa['WHATSAPP'] ?? '',

                        ddd_telefone: pessoa['DDD_TELEFONE'] ?? '',
                        ramal_telefone: pessoa['RAMAL_TELEFONE'] ?? '',
                        telefone: pessoa['TELEFONE'] ?? '',

                        twitter: pessoa['TWITTER'] ?? '',
                        facebook: pessoa['FACEBOOK'] ?? '',
                        instagram: pessoa['INSTAGRAM'] ?? '',
                        linkedin: pessoa['LINKEDIN'] ?? '',
                        lattes: pessoa['LINK_LATTES'] ?? '',
                        termo: pessoa['TERMO'] ?? '',

                        countBio: (pessoa['BIOGRAFIA'] ?? '').length,

                        view_pessoa: Object.assign({}, pessoa),
                        equipes_pessoa: res.equipes,
                        view_pessoas_inst: res.pessoas,
                        nomeEmpresa: res.empresa
                    });
                })
                .finally(() => {
                    this.context.stopLoading()
                    document.getElementById('btnLaunchModalView').click();
                })


        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    render() {
        return (
        <><div className="row my-4">
                <div className="col">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Filtrar Pessoas
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group mb-3">
                                                    <label htmlFor='pesquisar'>Pesquisa:</label>
                                                    <input className="form-control" id="pesquisar" type='search' placeholder="Pesquisar (Nome, CPF, CNPJ, E-mail)" onChange={e => this.setState({ pesquisa: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-3">
                                                    <label htmlFor='type'>Condição:</label>
                                                    <select className="form-control" id="type" onChange={this.handleCondicao}>
                                                        <option>Todos</option>
                                                        <option>Contratante</option>
                                                        <option>Colaborador Externo</option>
                                                        <option>Colaborador Interno</option>
                                                        <option>Bolsista</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-3">
                                                    <label htmlFor='usuario'>Usuário:</label>
                                                    <select className="form-control" id="usuario" onChange={this.handleUsuario}>
                                                        <option>Todos</option>
                                                        <option value='SIM'>Com usuário</option>
                                                        <option value='NAO'>Sem usuário</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-check mb-3">
                                                    <input
                                                        type='checkbox'
                                                        className="form-check-input"
                                                        id='checkArquivado'
                                                        onChange={this.handleMostrarOcutarArquivados}
                                                        
                                                        checked={this.state.status === 'INATIVO'} />
                                                    <label className='form-check-label' htmlFor='checkArquivado'>Mostrar pessoas arquivadas</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12" style={{ textAlign: 'right' }}>
                                                <button type="button" className="btn btn-secondary" onClick={this.limparFiltro}>Limpar</button>
                                                <button type="submit" className="btn btn-primary mx-2" onClick={this.handlePesquisa}
                                                >Pesquisar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><div className="row mt-1 justify-content-between" style={{ alignItems: 'center' }}>
                    <div className="col-sm-6 col-md-2 col-lg-2">
                        <div className="form-group my-4">
                            <label htmlFor="aumentar_limite">Itens na página</label>
                            <select className="form-control" id="aumentar_limite" onChange={() => this.aumentarLimite()}
                                style={{ width: '50px' }}>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group my-4" style={{ textAlign: 'right' }}>
                            <button
                                className="btn btn-primary btn-block mb-2"
                                type="button"
                                style={{ marginRight: '15px' }}
                                onClick={this.handleModalCreateShow}
                            >
                                Adicionar Pessoa
                            </button>
                            <button
                                className="d-none"
                                id="modalCreateUsuario"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#modalCreate"
                                style={{ marginRight: '15px' }}
                            >
                            </button>
                            <button
                                id="btnLaunchModalUpdate"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#modalUpdate"
                                className="d-none"
                            ></button>
                        </div>
                    </div>
                </div>

        {
            this.state.usuarios.length > 0 &&
            <div className="row my-1">
                <div className="col-lg-12">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col" className="text-left">ID</th>
                                <th scope="col">NOME</th>
                                <th scope="col">E-MAIL</th>
                                <th scope="col">USUÀRIO</th>
                                <th scope="col">CPF</th>
                                <th scope="col">CNPJ</th>
                                <th scope="col">DATA CRIAÇÂO</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.usuarios.map((user, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{user.ID}</td>
                                        <td>{user.NOME.substring(0, 40) + "..."}</td>
                                        <td>
                                            <a href="#/"
                                            onClick={() => this.setState({modalShow: user.ID})}>{user.EMAIL.substring(0, 30) + "..."}</a>
                                            <ModalEnviarEmail
                                                show={this.state.modalShow === user.ID}
                                                handleClose={() => this.setState({modalShow: null})}
                                                user={user}
                                            />
                                        </td>
                                        <td>{user.USUARIO}</td>
                                        <td>{user.CPF2 !== "" ? user.CPF2 : '-'}</td>
                                        <td>{user.CNPJ2 !== "" ? user.CNPJ2 : '-'}</td>
                                        <td>{user.MOVIMENTACAO}</td>
                                        {(user.SITUACAO_REGISTRO === 'ATIVO') &&
                                            <td>
                                                <button
                                                    className="btn p-0 p-1"
                                                    onClick={() => this.visualizarDadosUsuario(user)}
                                                    data-tip="Visualizar dados da pessoa"
                                                    data-place="bottom"
                                                    data-effect="solid"
                                                >
                                                    <i className="fas fa-eye text-primary"></i>
                                                </button>
                                                <button
                                                    className="btn p-0 p-1"
                                                    onClick={() => this.gerarDeclaracao(user.CPF, user.USUARIO)}
                                                    data-tip="Gerar declaração de participação no projeto"
                                                    data-place="bottom"
                                                    data-effect="solid"
                                                >
                                                    <i className="fas fa-file-alt text-secondary"></i>
                                                </button>
                                                <button
                                                    className="btn p-0 p-1 btn-link"
                                                    onClick={() => this.arquivarUsuario(user.ID)}
                                                    data-tip="Arquivar pessoa"
                                                    data-place="bottom"
                                                    data-effect="solid"
                                                >
                                                    <i className="fas fa-archive text-warning"></i>
                                                </button>
                                                <button
                                                    className="btn p-0 p-1"
                                                    onClick={() => this.removerUsuario(user.ID)}
                                                    data-tip="Remover pessoa"
                                                    data-place="bottom"
                                                    data-effect="solid"
                                                >
                                                    <i className="fas fa-trash text-danger"></i>
                                                </button>
                                            </td>
                                        }
                                        { (user.SITUACAO_REGISTRO === 'INATIVO') &&
                                        <td>
                                            <button
                                                className="btn p-0 p-1 btn-link" 
                                                onClick={() => this.desarquivarUsuario(user.ID)} 
                                                data-tip="Reativar Pessoa"
                                                data-place="bottom"
                                                data-effect="solid"
                                            >
                                                <i className="fas fa-archive text-success"></i>
                                            </button>
                                        </td>}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}

                    marginPagesDisplayed={this.state.isMobile ? 1 : 5}
                    pageRangeDisplayed={this.state.isMobile ? 1 : 2}
                    onPageChange={this.paginate}

                    forcePage={this.state.page}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>

                <div className="modal fade" id="modalCreate" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalLabel">Adicionar Nova Pessoa</h5>
                                <button
                                    type="button"
                                    id="btnCloseModal"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.limpartFormAux}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form className="container">
                                    <div className='my-4'>
                                        <p className="alert alert-warning">
                                            <strong className='text-danger me-2'>*</strong>
                                            <span>Campos obrigatórios</span>
                                        </p>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <label className="required" htmlFor="nome_salvar">Nome:</label>
                                            <input
                                                className="form-control"
                                                id="nome_salvar"
                                                onChange={e => this.setState({ nome: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <label className="required" htmlFor="email_salvar">E-mail:</label>
                                            <input
                                                type='email'
                                                className="form-control"
                                                id="email_salvar"
                                                onChange={e => this.setState({ email: e.target.value })}
                                            />
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                            <div className='form-group my-2'>
                                                <label className="required" htmlFor="cpf_salvar">CPF:</label>
                                                <MaskedInput
                                                    mask="999.999.999-99"
                                                    className="form-control"
                                                    id="cpf_salvar"
                                                    onChange={this.handleCpf}
                                                    value={this.state.cpf ?? ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                            <div className='form-group my-2'>
                                                <label htmlFor="data_nascimento">Data nascimento:</label>
                                                <input
                                                    id='data_nascimento'
                                                    type="date"
                                                    className="form-control"
                                                    defaultValue={this.state.data_nascimento}
                                                    onChange={e => this.setState({ data_nascimento: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="rg">RG:</label>
                                                <input
                                                    id='rg'
                                                    type="rg"
                                                    className="form-control"
                                                    defaultValue={this.state.rg}
                                                    onChange={e => this.setState({ rg: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="orgao_rg">Orgão RG:</label>
                                                <input
                                                    id='orgao_rg'
                                                    type="orgao_rg"
                                                    className="form-control"
                                                    onChange={e => this.setState({ orgao_rg: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="expedicao_rg">Expedição RG:</label>
                                                <input
                                                    id='expedicao_rg'
                                                    type="date"
                                                    className="form-control"
                                                    onChange={e => this.setState({ expedicao_rg: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="nacionalidade">Nacionalidade:</label>
                                                <input
                                                    id='nacionalidade'
                                                    type="nacionalidade"
                                                    className="form-control"
                                                    defaultValue={this.state.nacionalidade}
                                                    onChange={e => this.setState({ nacionalidade: e.target.value })}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className='form-group my-2'>
                                                <label htmlFor="profissao">Profissão:</label>
                                                <input
                                                    id='profissao'
                                                    type="profissao"
                                                    className="form-control"
                                                    onChange={e => this.setState({ profissao: e.target.value })}

                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <div className='form-group my-2'>
                                                <label htmlFor="biografia_salvar">Biografia:</label>
                                                <textarea
                                                    rows={5}
                                                    className="form-control"
                                                    id="biografia_salvar"
                                                    onChange={e => this.handleBiografia(e)}
                                                    maxLength={1200}
                                                ></textarea>
                                                <small style={{float: 'right'}}>{this.state.countBio}/1200</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <div className="form-group form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="salvar_usuario"
                                                    onChange={e => this.setState({ salvar_usuario: e.target.checked })}
                                                />
                                                <label htmlFor="salvar_usuario" className="form-check-label" style={{marginTop: '-3px'}}> Conceder acesso de usuário
                                                    <i
                                                        className="far fa-question-circle p-2"
                                                        data-tip='<p>Marque esta opção caso deseje que <br />
                                                            a pessoa tenha acesso aos próprios dados<br />
                                                            dentro do sistema e futuramente, <br />
                                                            você possa conceder outras funções a ele.</p>'
                                                        data-place="right"
                                                        data-html={true}
                                                        data-class="z-index-2"
                                                        data-effect="solid"
                                                    ></i>
                                                </label>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <div className="form-group">
                                                { this.state.salvar_usuario &&
                                                    <div className="alert alert-warning">
                                                        OBS: Ao selecionar essa opção, você opta por salvar um usuário default da pessoa para acesso ao sistema administrativo do PAB. O acesso poderá ser realizado usando o CPF tanto no login, quanto na senha.
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.limpartFormAux}
                                    disabled={this.context.loading}
                                >
                                    Fechar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.salvarUsuario}
                                    disabled={
                                        !this.state.nome ||
                                        !this.state.email ||
                                        (!this.state.cpf || (this.state.cpf && this.state.cpf.length < 11)) ||
                                        (!!this.state.biografia && this.state.biografia.length > 1200) ||
                                        this.context.loading
                                    }
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalUpdate" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalLabel">Atualizar dados da Pessoa</h5>
                                <button
                                    type="button"
                                    id="btnCloseModalUpdate"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.limpartFormAux}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form className="container">
                                    <div className='my-4'>
                                        <p className="alert alert-warning">
                                            <strong className='text-danger me-2'>*</strong>
                                            <span>Campos obrigatórios</span>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <label className="required" htmlFor="nome_salvar">Nome:</label>
                                            <input
                                                className="form-control"
                                                id="nome_salvar"
                                                value={this.state.nome ?? ''}
                                                onChange={e => this.setState({ nome: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <label className="required" htmlFor="email_salvar">E-mail:</label>
                                            <input
                                                type='email'
                                                className="form-control"
                                                id="email_salvar"
                                                value={this.state.email ?? ''}
                                                onChange={e => this.setState({ email: e.target.value })}
                                            />
                                        </div>
                                        {
                                            this.state.cpf !== '' &&
                                            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                <div className='form-group my-2'>
                                                    <label className="required"
                                                           htmlFor="cpf_salvar">CPF:</label>
                                                    <MaskedInput
                                                        mask="999.999.999-99"
                                                        className="form-control"
                                                        id="cpf_salvar"
                                                        onChange={this.handleCpf}
                                                        value={this.state.cpf ?? ''}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.cnpj !== '' &&
                                            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                <div className='form-group my-2'>
                                                    <label className="required"
                                                           htmlFor="cnpj_salvar">CNPJ:</label>
                                                    <MaskedInput
                                                        mask="99.999.999/9999-99"
                                                        className="form-control"
                                                        id="cnpj_salvar"
                                                        onChange={this.handleCnpj}
                                                        value={this.state.cnpj ?? ''}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                            <div className='form-group my-2'>
                                                <label htmlFor="data_nascimento">Data nascimento:</label>
                                                <input
                                                    id='data_nascimento'
                                                    type="date"
                                                    className="form-control"
                                                    defaultValue={this.state.data_nascimento}
                                                    onChange={e => this.setState({ data_nascimento: e.target.value })}
                                                    value={this.state.data_nascimento ?? ''}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="rg">RG:</label>
                                                <input
                                                    id='rg'
                                                    type="rg"
                                                    className="form-control"
                                                    defaultValue={this.state.rg}
                                                    onChange={e => this.setState({ rg: e.target.value })}
                                                    value={this.state.rg ?? ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="orgao_rg">Orgão RG:</label>
                                                <input
                                                    id='orgao_rg'
                                                    type="orgao_rg"
                                                    className="form-control"
                                                    onChange={e => this.setState({ orgao_rg: e.target.value })}
                                                    value={this.state.orgao_rg ?? ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="expedicao_rg">Expedição RG:</label>
                                                <input
                                                    id='expedicao_rg'
                                                    type="date"
                                                    className="form-control"
                                                    onChange={e => this.setState({ expedicao_rg: e.target.value })}
                                                    value={this.state.expedicao_rg ?? ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group my-2'>
                                                <label htmlFor="nacionalidade">Nacionalidade:</label>
                                                <input
                                                    id='nacionalidade'
                                                    type="nacionalidade"
                                                    className="form-control"
                                                    defaultValue={this.state.nacionalidade}
                                                    onChange={e => this.setState({ nacionalidade: e.target.value })}
                                                    value={this.state.nacionalidade ?? ''}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className='form-group my-2'>
                                                <label htmlFor="profissao">Profissão:</label>
                                                <input
                                                    id='profissao'
                                                    type="profissao"
                                                    className="form-control"
                                                    onChange={e => this.setState({ profissao: e.target.value })}
                                                    value={this.state.profissao ?? ''}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <div className="form-group my-2">
                                                <label htmlFor="biografia_salvar">Biografia:</label>
                                                <textarea
                                                    rows={5}
                                                    className="form-control"
                                                    id="biografia_salvar"
                                                    value={this.state.biografia ?? ''}
                                                    onChange={e => this.handleBiografia(e)}
                                                    maxLength={1200}
                                                ></textarea>
                                                <small style={{float: 'right'}}>{this.state.countBio}/1200</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <div className="form-group form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="salvar_usuario"
                                                    checked={this.state.salvar_usuario}
                                                    onChange={e => this.setState({ salvar_usuario: e.target.checked })}
                                                />
                                                <label htmlFor="salvar_usuario"
                                                       className="form-check-label" style={{marginTop: '-3px'}}
                                                >
                                                    {this.state.pessoa_usuario ? ' Atualizar acesso de usuario' : ' Conceder acesso ao usuario'}
                                                    <i
                                                        className="far fa-question-circle p-2"
                                                        data-tip='<p>Marque esta opção caso deseje que <br />
                                                            a pessoa tenha acesso aos próprios dados<br />
                                                            dentro do sistema e futuramente, <br />
                                                            você possa conceder outras funções a ele.</p>'
                                                        data-place="right"
                                                        data-html={true}
                                                        data-class="z-index-2"
                                                        data-effect="solid"
                                                    ></i></label>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                            <div className="form-group">
                                                { this.state.salvar_usuario &&
                                                    <div className="alert alert-warning">
                                                        {this.state.pessoa_usuario ?
                                                            'OBS: Ao selecionar essa opção, você opta por atualizar o usuário da pessoa, criando assim uma nova senha para acesso do sistema administrativo PAB. O acesso poderá ser realizado usando o CPF tanto no login, quanto na senha.'
                                                            :
                                                            'OBS: Ao selecionar essa opção, você opta por salvar um usuário default da pessoa para acesso ao sistema administrativo do PAB. O acesso poderá ser realizado usando o CPF tanto no login, quanto na senha.'
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.limpartFormAux}
                                    disabled={this.context.loading}
                                >
                                    Fechar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.atualizarUsuario}
                                    disabled={
                                        !this.state.nome ||
                                        !this.state.email ||
                                        ((this.state.view_pessoa.CPF && !this.state.cpf) || (this.state.cpf && this.state.cpf.length < 11)) ||
                                        ((this.state.view_pessoa.CNPJ && !this.state.cnpj) || (this.state.cnpj && this.state.cnpj.length < 14)) ||
                                        (!!this.state.biografia && this.state.biografia.length > 1200) ||
                                        this.context.loading
                                    }
                                >
                                    Atualizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <button className="d-none" id='btnLaunchModalView' data-bs-toggle="modal" data-bs-target="#modalView"></button>
                <div className="modal fade" id="modalView" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalLabel">{this.state.view_pessoa.CNPJ ? 'Dados da empresa ' : 'Dados da pessoa '}<strong className='text-muted'>{(this.state.view_pessoa.NOME || "").toUpperCase()}</strong></h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            {
                                // this.state.view_pessoa.CPF !== null &&
                                true &&
                                <div className="modal-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                                <strong className='text-uppercase'>Dados</strong>
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="relacoes-tab" data-bs-toggle="tab" href="#relacoes" role="tab" aria-controls="relacoes" aria-selected="false">
                                                <strong className='text-uppercase' style={{ marginLeft: '5px' }}>Relações</strong>
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="equipes-tab" data-bs-toggle="tab" href="#equipes" role="tab" aria-controls="equipes" aria-selected="false">
                                                <strong className='text-uppercase' style={{ marginLeft: '5px' }}>Equipes</strong>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <form className='container mt-5' autoComplete='none'>

                                                <div className="row">

                                                    <div className='col-md-12'>
                                                        <fieldset>
                                                            <legend>Dados pessoais</legend>
                                                            <div className='row'>
                                                                <div className="col-md-12">
                                                                    <div className='form-group my-2'>
                                                                        <label htmlFor="nome">Nome:</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.nome ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className='form-group my-2'>
                                                                        <label
                                                                            htmlFor="email">E-mail:</label>
                                                                        <input
                                                                            id='email'
                                                                            className="form-control"
                                                                            defaultValue={this.state.email ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {this.state.cpf &&
                                                                    <div className="col-md-6">
                                                                        <div
                                                                            className='form-group my-2'>
                                                                            <label
                                                                                htmlFor="cpf">CPF:</label>
                                                                            <input
                                                                                id="cpf"
                                                                                className="form-control"
                                                                                defaultValue={this.state.view_pessoa.CPF2}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {!this.state.cpf && this.state.cnpj &&
                                                                    <div className="col-md-6">
                                                                        <div
                                                                            className='form-group my-2'>
                                                                            <label
                                                                                htmlFor="cpf">CNPJ:</label>
                                                                            <input
                                                                                id="cpf"
                                                                                className="form-control"
                                                                                defaultValue={this.state.view_pessoa.CNPJ2}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-md-6">
                                                                    <div className='form-group my-2'>
                                                                        <label htmlFor="data_nascimento">Data
                                                                            nascimento:</label>
                                                                        <input
                                                                            id="data_nascimento"
                                                                            type={'date'}
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.data_nascimento ?? ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className='form-group my-2'>
                                                                        <label htmlFor="rg">RG:</label>
                                                                        <input
                                                                            id='rg'
                                                                            className="form-control"
                                                                            defaultValue={this.state.rg}
                                                                            disabled
                                                                            value={this.state.rg ?? ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className='form-group my-2'>
                                                                        <label htmlFor="orgao_rg">Orgão
                                                                            RG:</label>
                                                                        <input
                                                                            id='orgao_rg'
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.orgao_rg ?? ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className='form-group my-2'>
                                                                        <label htmlFor="expedicao_rg">Data
                                                                            emissão RG:</label>
                                                                        <input
                                                                            id='expedicao_rg'
                                                                            type="date"
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.expedicao_rg ?? ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className='form-group my-2'>
                                                                        <label
                                                                            htmlFor="nacionalidade">Nacionalidade:</label>
                                                                        <input
                                                                            id='nacionalidade'
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.nacionalidade ?? ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className='form-group my-2'>
                                                                        <label
                                                                            htmlFor="profissao">Profissão:</label>
                                                                        <input
                                                                            id='profissao'
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.profissao ?? ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="nome_salvar">Gênero:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        value={this.state.view_pessoa.GENERO ?
                                                                            this.switchSexo(this.state.view_pessoa.GENERO) :
                                                                            this.state.view_pessoa.GENERO_OUTRO ? this.state.view_pessoa.GENERO_OUTRO : ''}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="nome_salvar">Faixa etária:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        value={this.state.view_pessoa.FAIXA_ETARIA2 ?? ''}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                    <label
                                                                        htmlFor="biografia">Biografia:</label>
                                                                    <textarea
                                                                        rows={5}
                                                                        className="form-control"
                                                                        id="biografia"
                                                                        value={this.state.biografia ?? ''}
                                                                        maxLength={1200}
                                                                        disabled
                                                                    ></textarea>
                                                                    <small
                                                                        style={{float: 'right'}}>{this.state.countBio}/1200</small>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <fieldset>
                                                            <legend>Setor artesanal</legend>
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="nome_salvar">Atua no setor artesanal desde:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        value={this.state.view_pessoa.SETOR_ARTESANAL_DESDE ?? ''}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3 align-self-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input me-2"
                                                                        checked={this.state.view_pessoa.EXCLUSIVO_SETOR_ARTESANAL === '1'}
                                                                        disabled
                                                                    />
                                                                    <label className="form-check-label" htmlFor="nome_salvar">Atua exclusivamente no setor artesanal?</label>
                                                                </div>

                                                                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="email_salvar">Forma de atuação no setor artesanal:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.switchFormaAtuacao(this.state.view_pessoa.FORMA_ATUACAO_SETOR_ARTESANAL)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="email_salvar">Forma de atuação no setor artesanal(outros):</label>
                                                                    <input
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.view_pessoa.FORMA_ATUACAO_SETOR_ARTESANAL_OUTROS ?? ''}
                                                                    />
                                                                </div>

                                                                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="nome_salvar">Principais desafios:</label>
                                                                    <ul style={{marginTop: '5px'}}>
                                                                        {
                                                                            this.state.view_pessoa.PRINCIPAIS_DESAFIOS && JSON.parse(this.state.view_pessoa.PRINCIPAIS_DESAFIOS).map((pd, pdi) => <li key={pdi}>{this.switchPrincipaisDesafios(pd)}</li>)
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="email_salvar">Principais desafios (Justifique):</label>
                                                                    <input
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.view_pessoa.PRINCIPAIS_DESAFIOS_JUSTIFIQUE ?? ''}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <fieldset>
                                                            <legend>SICAB</legend>
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor="nome_salvar">Número inscrição SICAB:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        value={this.state.view_pessoa.NUMERO_SICAB ?? ''}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3 align-self-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input me-2"
                                                                        checked={this.state.view_pessoa.INTERESSE_SICAB === '1'}
                                                                        disabled
                                                                    />
                                                                    <label className="form-check-label" htmlFor="nome_salvar">Tem interesse em se cadastrar no SICAB?</label>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <fieldset>
                                                            <legend>Endereço</legend>
                                                            <div className='row'>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='endereco_cep'>CEP:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        value={this.state.endereco_cep ?? ''}
                                                                        autoComplete="none"
                                                                        disabled
                                                                    />

                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                    <label
                                                                        htmlFor='endereco_cidade'>Cidade:</label>
                                                                    <input
                                                                        id='endereco_cidade'
                                                                        className="form-control"
                                                                        value={this.state.endereco_cidade ?? ''}
                                                                        autoComplete="none"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                    <label htmlFor='endereco_uf'>UF:</label>
                                                                    <input
                                                                        id='endereco_uf'
                                                                        className="form-control"
                                                                        value={this.state.endereco_uf ?? ''}
                                                                        maxLength={2}
                                                                        autoComplete="none"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                    <label
                                                                        htmlFor='endereco_bairro'>Bairro:</label>
                                                                    <input
                                                                        id='endereco_bairro'
                                                                        className="form-control"
                                                                        value={this.state.endereco_bairro ?? ''}
                                                                        autoComplete="none"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                    <label htmlFor='endereco_rua'>Rua:</label>
                                                                    <input
                                                                        id='endereco_rua'
                                                                        className="form-control"
                                                                        value={this.state.endereco_rua ?? ''}
                                                                        autoComplete="none"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-10 col-lg-10 mb-3">
                                                                    <label
                                                                        htmlFor='endereco_complemento'>Complemento:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        value={this.state.endereco_complemento ?? ''}
                                                                        autoComplete="none"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                    <label
                                                                        htmlFor='endereco_numero'>Número:</label>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={this.state.endereco_numero ?? ''}
                                                                        autoComplete="none"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>

                                                        </fieldset>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <fieldset>
                                                            <legend>Contato</legend>
                                                            <div className='row'>
                                                                <p className="my-2">Celular:</p>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='ddd_celular'>DDD:</label>
                                                                    <input
                                                                        id='ddd_celular'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.ddd_celular ?? ''}
                                                                        maxLength={2}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='celular'>Número:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.celular ?? ''}
                                                                        maxLength={9}
                                                                    />
                                                                </div>
                                                                <p className="my-2">Telefone:</p>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='ddd_telefone'>DDD:</label>
                                                                    <input
                                                                        id='ddd_telefone'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.ddd_telefone ?? ''}
                                                                        maxLength={2}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label
                                                                        htmlFor='ramal_telefone'>Ramal:</label>
                                                                    <input
                                                                        id='ramal_telefone'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.ramal_telefone ?? ''}
                                                                        maxLength={4}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label
                                                                        htmlFor='telefone_numero'>Número:</label>
                                                                    <input
                                                                        id='telefone_numero'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.telefone ?? ''}
                                                                        maxLength={10}
                                                                    />
                                                                </div>
                                                                <p className="my-2">Whatsapp:</p>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='ddd_whatsapp'>DDD:</label>
                                                                    <input
                                                                        id='ddd_whatsapp'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.ddd_whatsapp ?? ''}
                                                                        maxLength={2}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='whatsapp'>Número:</label>
                                                                    <input
                                                                        id='whatsapp'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.whatsapp ?? ''}
                                                                        maxLength={9}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <fieldset>
                                                            <legend>Redes sociais:</legend>
                                                            <div className='row'>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='facebook'>Facebook:</label>
                                                                    <input
                                                                        id='facebook'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.facebook ?? ''}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label
                                                                        htmlFor='instagram'>Instagram:</label>
                                                                    <input
                                                                        id='instagram'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.instagram ?? ''}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <label htmlFor='linkedin'>Linkedin:</label>
                                                                    <input
                                                                        id='linkedin'
                                                                        className="form-control"
                                                                        disabled
                                                                        value={this.state.linkedin ?? ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <fieldset>
                                                            <legend>Arquivos</legend>
                                                            <div className='row justify-content-center'>
                                                                <div className="col-md-2 text-center">
                                                                    <p>CPF</p>
                                                                    <i className="fas fa-file-pdf fa-3x"
                                                                       style={{color: '#cb3532'}}></i><br/>
                                                                    <a href={this.state.link_pdf_cpf}
                                                                       download target='_blank'
                                                                       rel='noreferrer'>
                                                                        <i className='fas fa-download'></i> download
                                                                    </a>
                                                                </div>
                                                                <div className="col-md-2 text-center">
                                                                    <p>RG</p>
                                                                    <i className="fas fa-file-pdf fa-3x"
                                                                       style={{color: '#cb3532'}}></i><br/>
                                                                    <a href={this.state.link_pdf_rg}
                                                                       download target='_blank'
                                                                       rel='noreferrer'>
                                                                        <i className='fas fa-download'></i> download
                                                                    </a>
                                                                </div>
                                                                <div className="col-md-2 text-center">
                                                                    <p>Conta Bancária</p>
                                                                    <i className="fas fa-file-pdf fa-3x"
                                                                       style={{color: '#cb3532'}}></i><br/>
                                                                    <a href={this.state.link_pdf_conta_bancaria}
                                                                       download target='_blank'
                                                                       rel='noreferrer'>
                                                                        <i className='fas fa-download'></i> download
                                                                    </a>
                                                                </div>
                                                                <div className="col-md-2 text-center">
                                                                    <p>Currículo Lattes</p>
                                                                    <i className="fas fa-file-pdf fa-3x"
                                                                       style={{color: '#cb3532'}}></i><br/>
                                                                    <a href={this.state.link_pdf_lattes}
                                                                       download target='_blank'
                                                                       rel='noreferrer'>
                                                                        <i className='fas fa-download'></i> download
                                                                    </a>
                                                                </div>
                                                                <div className="col-md-3 text-center">
                                                                    <p>Comprovante de mátrícula:</p>
                                                                    <i className="fas fa-file-pdf fa-3x"
                                                                       style={{color: '#cb3532'}}></i><br/>
                                                                    <a href={this.state.link_pdf_lattes}
                                                                       download target='_blank'
                                                                       rel='noreferrer'>
                                                                        <i className='fas fa-download'></i> download
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <fieldset>
                                                            <legend>Vínculo institucional</legend>
                                                            <div className='row mt-4'>
                                                                <div className="col-md-12">
                                                                    <fieldset>
                                                                        <legend
                                                                            style={{fontSize: "11pt"}}>Dados
                                                                            da instituição
                                                                        </legend>
                                                                        <div className='row'>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="vinculo_nome_instituicao">Nome
                                                                                        da
                                                                                        instituição:</label>
                                                                                    <input
                                                                                        id="vinculo_nome_instituicao"
                                                                                        type="text"
                                                                                        className='form-control'
                                                                                        value={this.state.vinculo_nome_instituicao ?? ''}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_cep_instituicao'>CEP:</label>
                                                                                <input
                                                                                    required
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_cep_instituicao ?? ''}
                                                                                    disabled
                                                                                />

                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_cidade_instituicao'>Cidade:</label>
                                                                                <input
                                                                                    id='vinculo_endereco_cidade_instituicao'
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_cidade_instituicao ?? ''}
                                                                                    autoComplete="none"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_uf_instituicao'>UF:</label>
                                                                                <input
                                                                                    id='vinculo_endereco_uf_instituicao'
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_uf_instituicao ?? ''}
                                                                                    maxLength={2}
                                                                                    autoComplete="none"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_bairro_instituicao'>Bairro:</label>
                                                                                <input
                                                                                    id='vinculo_endereco_bairro_instituicao'
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_bairro_instituicao ?? ''}
                                                                                    autoComplete="none"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_rua_instituicao'>Rua:</label>
                                                                                <input
                                                                                    id='vinculo_endereco_rua_instituicao'
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_rua_instituicao ?? ''}
                                                                                    autoComplete="none"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-10 col-lg-10 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_complemento_instituicao'>Complemento:</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_complemento_instituicao ?? ''}
                                                                                    autoComplete="none"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_numero_instituicao'>Número:</label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_numero_instituicao ?? ''}
                                                                                    autoComplete="none"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                                <label
                                                                                    htmlFor='vinculo_endereco_campus_instituicao'>Campus:</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    value={this.state.vinculo_endereco_campus_instituicao ?? ''}
                                                                                    autoComplete="none"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <fieldset>
                                                                        <legend
                                                                            style={{fontSize: "11pt"}}>Dados
                                                                            do vinculado
                                                                        </legend>
                                                                        <div className='row'>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="vinculo_categoria">Categoria:</label>
                                                                                    <input
                                                                                        className='form-control'
                                                                                        value={this.state.vinculo_categoria ?? ''}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="vinculo_cargo">Cargo:</label>
                                                                                    <input
                                                                                        id="vinculo_cargo"
                                                                                        type="text"
                                                                                        className='form-control'
                                                                                        disabled
                                                                                        value={this.state.vinculo_cargo ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.vinculo_categoria === 'Servidor Docente' &&
                                                                                <div className="col-md-12">
                                                                                    <div
                                                                                        className='form-group my-2'>
                                                                                        <label
                                                                                            className='form-label'
                                                                                            htmlFor="vinculo_matricula_siape">Matrícula
                                                                                            SIAPE:</label>
                                                                                        <input
                                                                                            id="vinculo_matricula_siape"
                                                                                            type="text"
                                                                                            className='form-control'
                                                                                            disabled
                                                                                            value={this.state.vinculo_matricula_siape ?? ''}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            <div className="col-md-12 ">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        htmlFor='lattes'>Lattes(link):</label>
                                                                                    <input
                                                                                        id='lattes'
                                                                                        className="form-control"
                                                                                        value={this.state.lattes ?? ''}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <fieldset>
                                                            <legend>Atividade de extensão</legend>
                                                            <div className='row mt-4'>
                                                                <div className='col-md-12'>
                                                                    <fieldset>
                                                                        <legend
                                                                            style={{fontSize: "11pt"}}>Dados
                                                                            das atividades
                                                                        </legend>
                                                                        <div className='row'>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="atividade_data_inicio">Data
                                                                                        de início das
                                                                                        atividades no
                                                                                        projeto:</label>
                                                                                    <input
                                                                                        id="atividade_data_inicio"
                                                                                        type="date"
                                                                                        className='form-control'
                                                                                        disabled
                                                                                        value={this.state.atividade_data_inicio ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="atividade_data_fim">Data
                                                                                        de fim das
                                                                                        atividades no
                                                                                        projeto:</label>
                                                                                    <input
                                                                                        id="atividade_data_fim"
                                                                                        type="date"
                                                                                        className='form-control'
                                                                                        disabled
                                                                                        value={this.state.atividade_data_fim ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="atividade_carga_horaria">Carga
                                                                                        horária
                                                                                        mensal:</label>
                                                                                    <input
                                                                                        id="atividade_carga_horaria"
                                                                                        type="number"
                                                                                        className='form-control'
                                                                                        disabled
                                                                                        value={this.state.atividade_carga_horaria ?? ''}
                                                                                        min={0}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>

                                                                <div className='col-md-12'>
                                                                    <fieldset>
                                                                        <legend
                                                                            style={{fontSize: "11pt"}}>Dados
                                                                            dos pagamentos
                                                                        </legend>
                                                                        <div className='row'>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="atividade_pagamento_dados_banco">Banco:</label>
                                                                                    <input
                                                                                        id="atividade_pagamento_dados_banco"
                                                                                        className='form-control'
                                                                                        disabled
                                                                                        value={this.state.atividade_pagamento_dados_banco ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="atividade_pagamento_tipo">Tipo
                                                                                        de conta:</label>
                                                                                    <input
                                                                                        id="atividade_pagamento_tipo"
                                                                                        className='form-control'
                                                                                        value={this.state.atividade_pagamento_tipo ?? ''}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="atividade_pagamento_dados_agencia">Agência:</label>
                                                                                    <input
                                                                                        id="atividade_pagamento_dados_agencia"
                                                                                        type="number"
                                                                                        className='form-control'
                                                                                        disabled
                                                                                        value={this.state.atividade_pagamento_dados_agencia ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className='form-group my-2'>
                                                                                    <label
                                                                                        className='form-label'
                                                                                        htmlFor="atividade_pagamento_dados_conta">Conta:</label>
                                                                                    <input
                                                                                        id="atividade_pagamento_dados_conta"
                                                                                        type="number"
                                                                                        className='form-control'
                                                                                        disabled
                                                                                        value={this.state.atividade_pagamento_dados_conta ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                </div>

                                            </form>
                                        </div>
                                        <div className="tab-pane fade" id="relacoes" role="tabpanel" aria-labelledby="relacoes-tab">
                                            {
                                                this.state.view_pessoas_inst.length === 0 &&
                                                <div className='mt-2'>
                                                    <p className='alert alert-warning'>Nenhum registro encontrado!</p>
                                                </div>
                                            }
                                            {
                                                this.state.view_pessoas_inst.length > 0 &&
                                                <div className='table-responsive'>
                                                    <table className='table table-stripped'>
                                                        <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>PESSOA</th>
                                                            <th>EMPRESA</th>
                                                            <th>CPF</th>
                                                            <th>EMAIL</th>
                                                            <th>INÍCIO RELAÇÃO</th>
                                                            <th>FIM RELAÇÃO</th>
                                                            <th>POSIÇÃO</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {
                                                            this.state.view_pessoas_inst.map((p, i) => (
                                                                <tr key={i}>
                                                                    <td>{p.ID}</td>
                                                                    <td>{p.NOME}</td>
                                                                    <td>{this.state.nomeEmpresa}</td>
                                                                    <td>{p.CPF2}</td>
                                                                    <td>{p.EMAIL}</td>
                                                                    <td>{p.INICIO_RELACAO_PESSOA_EMPRESA ?? '-'}</td>
                                                                    <td>{p.FIM_RELACAO_PESSOA_EMPRESA ?? '-'}</td>
                                                                    <td>{p.POSICAO}</td>
                                                                </tr>
                                                            ))
                                                        }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                        <div className="tab-pane fade" id="equipes" role="tabpanel" aria-labelledby="equipes-tab">
                                            {
                                                this.state.equipes_pessoa.length === 0 &&
                                                <div className='mt-2'>
                                                    <p className='alert alert-warning'>Nenhum registro encontrado!</p>
                                                </div>
                                            }
                                            {
                                                this.state.equipes_pessoa.length > 0 &&
                                                <div className='table-responsive'>
                                                    <table className='table table-stripped'>
                                                        <thead>
                                                        <tr>
                                                            <th>Equipe</th>
                                                            <th>Função</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {
                                                            this.state.equipes_pessoa.map((e, i) => (
                                                                <tr key={i}>
                                                                    <td style={i > 0 ? {borderBottom: 'none'} : {}}>{e.NOME}</td>
                                                                    <td style={i > 0 ? {borderBottom: 'none'} : {}}>
                                                                        {
                                                                            e.papeis.map((p, j) => (
                                                                                <div key={j}>
                                                                                    {j > 0 && <hr/>}
                                                                                    <strong>{p.NOME}</strong><br/>
                                                                                    <strong>Atuação: </strong><span>{p.ATUACAO ?? '-'}</span><br/>
                                                                                    <strong>Condição: </strong><span>{p.CONDICAO ?? '-'}</span><br/>
                                                                                    <strong>Visualização
                                                                                        Área
                                                                                        Pública: </strong><span>{p.AREA_PUBLICA === 'S' ? 'Habilitado' : 'Desabilitado'}</span><br/><br/>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                // this.state.view_pessoa.CNPJ !== null &&
                                false &&
                                <div className="modal-body">
                                    <div className="accordion" id="accordionExamplePJ">

                                        <div className="accordion-item active">
                                            <h2 className="accordion-header" id="headingTwoPJInst">
                                                <button style={{ fontSize: '20px'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoPJInst" aria-expanded="false" aria-controls="collapseTwoPJInst">
                                                    Instituição
                                                </button>
                                            </h2>
                                            <div id="collapseTwoPJInst" className="accordion-collapse collapse show" aria-labelledby="headingTwoPJInst" data-bs-parent="#accordionExamplePJ">
                                                <div className="accordion-body">
                                                    <div className="row my-3">
                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend style={{ fontSize: '16px'}}>Dados</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Nome:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.NOME ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">CNPJ:</label>
                                                                        <MaskedInput
                                                                            mask="99.999.999/9999-99"
                                                                            className="form-control"
                                                                            disabled
                                                                            readOnly
                                                                            value={this.state.view_pessoa.CNPJ ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                        <label htmlFor="email_salvar">E-mail:</label>
                                                                        <input
                                                                            type='email'
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.view_pessoa.EMAIL ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                        <label>Website</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.WEBSITE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>


                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Serviços/atividades para o segmento do artesanato</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Áreas de atendimento:</label>
                                                                        <ul className="mt-2">
                                                                            {
                                                                                this.state.view_pessoa.AREAS_ATENDIMENTO &&
                                                                                JSON.parse(this.state.view_pessoa.AREAS_ATENDIMENTO).map((aa, aai) => <li key={aai}>{this.switchAreasAtendimento(aa)}</li>)
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Públicos alvo:</label>
                                                                        <ul className="mt-2">
                                                                            {
                                                                                this.state.view_pessoa.PUBLICOS_ALVO &&
                                                                                JSON.parse(this.state.view_pessoa.PUBLICOS_ALVO).map((pa, pai) => <li key={pai}>{this.switchPublicosAlvo(pa)}</li>)
                                                                            }
                                                                        </ul>
                                                                    </div>

                                                                    {this.state.view_pessoa.OUTROS_AREAS_ATENDIMENTO &&
                                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                            <label htmlFor="nome_salvar">Áreas de atendimento (outros):</label>
                                                                            <input
                                                                                className="form-control"
                                                                                value={this.state.view_pessoa.OUTROS_AREAS_ATENDIMENTO ?? ''}
                                                                                disabled
                                                                            />
                                                                        </div>}

                                                                    {this.state.view_pessoa.OUTROS_PUBLICOS_ALVO &&
                                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                            <label htmlFor="nome_salvar">Públicos alvo (outros)</label>
                                                                            <input
                                                                                className="form-control"
                                                                                value={this.state.view_pessoa.OUTROS_PUBLICOS_ALVO ?? ''}
                                                                                disabled
                                                                            />
                                                                        </div>}
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Endereço</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>CEP</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.ENDERECO_CEP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Cidade</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.ENDERECO_CIDADE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>UF</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.UF ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Bairro</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.ENDERECO_BAIRRO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Rua</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.ENDERECO_RUA ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-3">
                                                                        <label>Complemento</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.COMPLEMENTO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.ENDERECO_NUMERO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Contato</legend>
                                                                <div className="row">
                                                                    <p className="my-2">Telefone:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.DDD_TELEFONE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoa.TELEFONE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwoPJDir">
                                                <button style={{ fontSize: '20px'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoPJDir" aria-expanded="false" aria-controls="collapseTwoPJDir">
                                                    Dirigente
                                                </button>
                                            </h2>
                                            <div id="collapseTwoPJDir" className="accordion-collapse collapse" aria-labelledby="headingTwoPJDir" data-bs-parent="#accordionExamplePJ">
                                                <div className="accordion-body">
                                                    <div className="row my-3">
                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend style={{ fontSize: '16px'}}>Dados</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Nome:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.NOME ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">CPF:</label>
                                                                        <MaskedInput
                                                                            mask="999.999.999"
                                                                            className="form-control"
                                                                            disabled
                                                                            readOnly
                                                                            value={this.state.view_pessoas_inst[0]?.CPF ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                        <label htmlFor="email_salvar">E-mail:</label>
                                                                        <input
                                                                            type='email'
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.view_pessoas_inst[0]?.EMAIL ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="email_salvar">Gênero:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.switchSexo(this.state.view_pessoas_inst[0]?.GENERO) ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="email_salvar">Cargo:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.view_pessoas_inst[0]?.CARGO ?? ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend style={{ fontSize: '16px'}}>Período gestão</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Início</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.INICIO_RELACAO_PESSOA_EMPRESA ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Fim</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.TERMINO_RELACAO_PESSOA_EMPRESA ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend style={{ fontSize: '16px'}}>Contato</legend>
                                                                <div className="row">
                                                                    <p className="my-2">Celular:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.DDD_CELULAR ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.CELULAR ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <p className="my-2">Telefone:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.DDD_TELEFONE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Ramal</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.RAMAL_TELEFONE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.TELEFONE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <p className="my-2">Whatsapp:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.DDD_WHATSAPP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[0]?.WHATSAPP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwoPJResp">
                                                <button style={{ fontSize: '20px'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoPJResp" aria-expanded="false" aria-controls="collapseTwoPJResp">
                                                    Responsável
                                                </button>
                                            </h2>
                                            <div id="collapseTwoPJResp" className="accordion-collapse collapse" aria-labelledby="headingTwoPJResp" data-bs-parent="#accordionExamplePJ">
                                                <div className="accordion-body">
                                                    <div className="row my-3">
                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Dados</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Nome:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.NOME ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">CPF:</label>
                                                                        <MaskedInput
                                                                            mask="999.999.999-99"
                                                                            className="form-control"
                                                                            disabled
                                                                            readOnly
                                                                            value={this.state.view_pessoas_inst[1]?.CPF ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                        <label htmlFor="email_salvar">E-mail:</label>
                                                                        <input
                                                                            type='email'
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.view_pessoas_inst[1]?.EMAIL ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Gênero:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.GENERO ?
                                                                                this.switchSexo(this.state.view_pessoas_inst[1]?.GENERO) :
                                                                                this.state.view_pessoas_inst[1]?.GENERO_OUTRO ? this.state.view_pessoas_inst[1]?.GENERO_OUTRO : ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Setor artesanal</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Atua no setor artesanal desde:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.SETOR_ARTESANAL_DESDE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3 align-self-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input me-2"
                                                                            checked={this.state.view_pessoas_inst[1]?.EXCLUSIVO_SETOR_ARTESANAL === '1'}
                                                                            disabled
                                                                        />
                                                                        <label className="form-check-label" htmlFor="nome_salvar">Atua exclusivamente no setor artesanal?</label>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Endereço</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>CEP</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_CEP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Cidade</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_CIDADE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>UF</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.UF ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Bairro</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_BAIRRO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Rua</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_RUA ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-3">
                                                                        <label>Complemento</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.COMPLEMENTO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_NUMERO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Contato</legend>
                                                                <div className="row">
                                                                    <p className="my-2">Celular:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.DDD_CELULAR ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.CELULAR ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <p className="my-2">Whatsapp:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.DDD_WHATSAPP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.WHATSAPP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Redes sociais</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Twitter</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.TWITTER ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Facebook</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.FACEBOOK ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Instagram</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.INSTAGRAM ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Linkedin</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.LINKEDIN ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwoPJInter">
                                                <button style={{ fontSize: '20px'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoPJInter" aria-expanded="false" aria-controls="collapseTwoPJInter">
                                                    Interlocutor
                                                </button>
                                            </h2>
                                            <div id="collapseTwoPJInter" className="accordion-collapse collapse" aria-labelledby="headingTwoPJInter" data-bs-parent="#accordionExamplePJ">
                                                <div className="accordion-body">
                                                    <div className="row my-3">
                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Dados</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Nome:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[2]?.NOME ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">CPF:</label>
                                                                        <MaskedInput
                                                                            mask="999.999.999-99"
                                                                            className="form-control"
                                                                            disabled
                                                                            readOnly
                                                                            value={this.state.view_pessoas_inst[2]?.CPF ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                        <label htmlFor="email_salvar">E-mail:</label>
                                                                        <input
                                                                            type='email'
                                                                            className="form-control"
                                                                            disabled
                                                                            value={this.state.view_pessoas_inst[2]?.EMAIL ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Gênero:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[2]?.GENERO ?
                                                                                this.switchSexo(this.state.view_pessoas_inst[2]?.GENERO) :
                                                                                this.state.view_pessoas_inst[2]?.GENERO_OUTRO ? this.state.view_pessoas_inst[1]?.GENERO_OUTRO : ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Cargo:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[2]?.CARGO}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Setor artesanal</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label htmlFor="nome_salvar">Atua no setor artesanal desde:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.SETOR_ARTESANAL_DESDE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Forma de atuação no setor artesanal:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.FORMA_ATUACAO_SETOR_ARTESANAL ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    {this.state.view_pessoas_inst[1]?.FORMA_ATUACAO_SETOR_ARTESANAL_OUTROS &&
                                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3 align-self-center">
                                                                            <label>Forma de atuação no setor artesanal (outros)</label>
                                                                            <input
                                                                                className="form-check-input"
                                                                                value={this.state.view_pessoas_inst[1]?.FORMA_ATUACAO_SETOR_ARTESANAL_OUTROS ?? ''}
                                                                                disabled
                                                                            />
                                                                        </div>}
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Endereço</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>CEP</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_CEP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Cidade</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_CIDADE ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>UF</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.UF ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Bairro</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_BAIRRO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label>Rua</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_RUA ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-3">
                                                                        <label>Complemento</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.COMPLEMENTO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.ENDERECO_NUMERO ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Contato</legend>
                                                                <div className="row">
                                                                    <p className="my-2">Celular:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.DDD_CELULAR ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.CELULAR ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <p className="my-2">Whatsapp:</p>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label>DDD</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.DDD_WHATSAPP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label>Número</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.WHATSAPP ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <fieldset>
                                                                <legend>Redes sociais</legend>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Twitter</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.TWITTER ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Facebook</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.FACEBOOK ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Instagram</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.INSTAGRAM ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                                                                        <label>Linkedin</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.view_pessoas_inst[1]?.LINKEDIN ?? ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                            <div className="modal-footer">
                                <button className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        }

        {
            this.state.usuarios.length === 0 &&
            <div className='alert alert-warning'>
                Nenhum registro encontrado!
            </div>
        }
        </>
        )
    }
}