import { Component } from "react";
import { apiWithToken } from "../../Services/Api";
import { apiURL } from "../../Services/env";
import { GlobalContext } from "./context";

export class GlobalContextProvider extends Component {

    state = {
        loading: false,
    }

    startLoading = () => {
        this.setState((prevState) => ({ loading: true }))
    }

    stopLoading = () => {
        this.setState((prevState) => ({ loading: false }))
    }

    limparStorage = () => {
        localStorage.removeItem('projartFoto');
        localStorage.removeItem('projartToken');
        localStorage.removeItem('projartUsuario');
        localStorage.removeItem('projartPapeis');
        localStorage.removeItem('projartCod');
        localStorage.removeItem('projartPreviegio');
        localStorage.removeItem('projartPermissoesUsuario');
        localStorage.removeItem('redirect');
    }

    logout = () => {

        const requestOptions = {
            method: 'POST'
        };

        this.toggleMenu();
        this.startLoading();

        apiWithToken(apiURL + 'logout', requestOptions)
            .finally(() => {
                this.limparStorage();
                window.location.href = '/';
                this.stopLoading();
            })

    }

    getPermission = (permission) => {
        let permissionUser = JSON.parse(localStorage.getItem('projartPermissoesUsuario'));
        return this.isSuperUser() || permissionUser.includes(permission);
    }

    getPermissions = () => JSON.parse(localStorage.getItem('projartPermissoesUsuario'));
    

    isSuperUser = () => {
        return localStorage.getItem('projartPreviegio') === 'SIM'
    }

    toggleMenu = () => {
        const sidebar = document.getElementById('sidebar');
        if (window.screen.width < 400) {
            sidebar.classList.toggle('active');
        }
    }

    sylePlaceholderSelect = () => {
        return {
            placeholder: () => (
                {
                    color: '#212529', 
                    gridArea: '1/1/2/3', 
                    marginLeft: '2px', 
                    marginRight: '2px', 
                    boxSizing: 'border-box'
                }
            )
        }
    }

    render() {
        const { children } = this.props
        const { loading } = this.state
        const { startLoading, stopLoading, limparStorage, logout, getPermission, getPermissions, isSuperUser, sylePlaceholderSelect } = this

        return (
            <GlobalContext.Provider
                value={{
                    loading,
                    limparStorage,
                    startLoading,
                    stopLoading,
                    getPermission,
                    getPermissions,
                    logout,
                    isSuperUser,
                    sylePlaceholderSelect
                }}
            >
                {children}
            </GlobalContext.Provider>
        )
    }
}