import React from 'react';
import { GlobalContext } from '../../Context/GlobalContext/context';
import * as permissions from '../../Context/GlobalContext/permissions';
import { apiURL } from '../../Services/env';
import { apiWithToken } from "../../Services/Api";

class ModalEnviarEmailAvulso extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.limparDados = this.limparDados.bind(this);
        this.enviarEmailReprovacao = this.enviarEmailReprovacao.bind(this);
        this.enviarEmail = this.enviarEmail.bind(this);
        this.handleChangeAssunto = this.handleChangeAssunto.bind(this);
        this.handleChangeMensagem = this.handleChangeMensagem.bind(this);
        this.atualizarStatusDocumento = this.atualizarStatusDocumento.bind(this);
        this.handleSituacaoDocumento = props.handleSituacaoDocumento;
        this.ativarBotoes = this.ativarBotoes.bind(this);
        this.enviarEmailRejeitarSolicitacao = this.enviarEmailRejeitarSolicitacao.bind(this);

        this.state = {
            assunto: '',
            mensagem: '',
            nome: props.nome,
            email: props.email,
            id: props.id,
            cpf: localStorage.getItem("projartCod"),
            origem: props.origem,
            liberado: false
        };

    }

    ativarBotoes(origem) {
        if (this.state.liberado) {
            
            if (origem === 'contato') {
                document.getElementById("enviarEmailReprovacao").style.display = 'none';
                document.getElementById("enviarEmailRejeitarSolicitacao").style.display = 'none';
                document.getElementById("enviarEmailContato").style.display = 'flex';
            }
            else {
                if (origem === 'botao') {
                    document.getElementById("enviarEmailReprovacao").style.display = 'flex';
                    document.getElementById("enviarEmailContato").style.display = 'none';
                    document.getElementById("enviarEmailRejeitarSolicitacao").style.display = 'none';
                }
                else {
                    document.getElementById("enviarEmailReprovacao").style.display = 'none';
                    document.getElementById("enviarEmailContato").style.display = 'none';
                    document.getElementById("enviarEmailRejeitarSolicitacao").style.display = 'flex';
                }
            }
        }
    }

    componentDidMount() {
        
        if (!this.context.getPermission(permissions.ARQUIVAR_DOCUMENTOS) &&
            !this.context.getPermission(permissions.ARQUIVAR_DOCUMENTOS_EQUIPE) &&
            !this.context.getPermission(permissions.ARQUIVAR_DOCUMENTOS_TODAS_EQUIPES) &&
            !this.context.getPermission(permissions.REJEITAR_SOLICITACAO_DOCUMENTOS) &&
            !this.context.getPermission(permissions.SOLICITAR_CANCELAMENTO_DOCUMENTOS) &&
            !this.context.getPermission(permissions.CANCELAR_DOCUMENTO) &&
            !this.context.getPermission(permissions.ENVIAR_EMAIL_CONTATO)
        ) {
            this.setState({ liberado: false })
        }
        else {
            this.setState({ liberado: true })
        }
        this.ativarBotoes(this.state.origem);
    }

    componentDidUpdate(prevProps) {
        // Uso típico, (não esqueça de comparar as props):
        if (this.props.nome !== prevProps.nome) {
            this.setState({ nome: this.props.nome });
        }

        if (this.props.email !== prevProps.email) {
            this.setState({ email: this.props.email });
        }

        if (this.props.origem !== prevProps.origem) {
            this.setState({ origem: this.props.origem });
            this.ativarBotoes(this.props.origem);
        }

    }

    limparDados() {
        this.setState({
            assunto: '',
            mensagem: ''
        });

        document.getElementById('assunto').value = '';
        document.getElementById('mensagem').value = '';

    }

    async atualizarStatusDocumento(status) {

        let formData = new FormData();
        formData.append('cpf', this.state.cpf);
        formData.append('status', status);
        formData.append('assunto', this.state.assunto);
        formData.append('mensagem', this.state.mensagem);

        if (this.state.origem === 'botaoSolicitanteRejeitarSolicitacao') {
            formData.append('destinatario', 'secretaria');
        }
        else {
            formData.append('destinatario', 'solicitante');
            if (status === 'REJEITADO') {
                formData.append('rejeitadoPor', localStorage.getItem('projartCod'));
            }
        }

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        const response = await apiWithToken(apiURL + "documento/atualizarStatus/" + this.state.id, requestOptions);
        const resp = await response.json();

        return resp;
    }

    enviarEmailRejeitarSolicitacao(e) {

        if (this.state.assunto === null || this.state.assunto === undefined || this.state.assunto === '' ||
            this.state.mensagem === null || this.state.mensagem === undefined || this.state.mensagem === '') {
            alert('Preencha o assunto e a mensagem para poder enviar o e-mail!');
        }
        else {

            document.getElementById('closeModalBtn').click();
            this.context.startLoading();
            this.atualizarStatusDocumento('REJEITADO').then(data => {

                //alert('E-mail enviado com sucesso! \nSolicitação rejeitada!');

                // document.getElementById("botaoArquivarDocumento").style.display = 'none';

                this.handleSituacaoDocumento('REJEITADO');

            })
                .catch()
                .finally(() => this.context.stopLoading());

            this.limparDados();

        }
    }

    enviarEmailReprovacao(e) {

        if (this.state.assunto === null || this.state.assunto === undefined || this.state.assunto === '' ||
            this.state.mensagem === null || this.state.mensagem === undefined || this.state.mensagem === '') {
            alert('Preencha o assunto e a mensagem para poder enviar o e-mail!');
        }
        else {

            document.getElementById('closeModalBtn').click();

            this.context.startLoading();

            this.atualizarStatusDocumento('MINUTA').then(data => {

                alert('E-mail enviado com sucesso! \nDocumento reprovado!');

                // document.getElementById("botaoArquivarDocumento").style.display = 'none';
                // document.getElementById("botaoAprovarDocumento").style.display = 'none';

                this.handleSituacaoDocumento('MINUTA');

            })
                .catch()
                .finally(() => this.context.stopLoading());

            this.limparDados();

        }
    }

    async enviarEmailContato() {

        let formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('assunto', this.state.assunto);
        formData.append('mensagem', this.state.mensagem);

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        const response = await apiWithToken(apiURL + "documento/enviarEmail", requestOptions);
        const resp = await response.json();

        return resp;
    }

    enviarEmail(e) {

        if (this.context.getPermission(permissions.ENVIAR_EMAIL_CONTATO)){
            if (this.state.assunto === null || this.state.assunto === undefined || this.state.assunto === '' ||
                this.state.mensagem === null || this.state.mensagem === undefined || this.state.mensagem === '') {
                alert('Preencha o assunto e a mensagem para poder enviar o e-mail!');
            }
            else {

                document.getElementById('closeModalBtn').click();

                this.context.startLoading();

                this.enviarEmailContato().then(data => {

                    alert(data['mensagem']);

                })
                .catch()
                .finally(() => this.context.stopLoading());

                this.limparDados();

            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o suporte técnico!')
        }
    }

    handleChangeAssunto(e) {
        this.setState({ assunto: e.target.value });
    }

    handleChangeMensagem(e) {
        this.setState({ mensagem: e.target.value });
    }

    render() {

        const { loading } = this.context;

        return (
            <div className="modal fade" id="modalEnviarEmail" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <button id='closeModalBtn' type="button" className="close d-none" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        { this.state.liberado && <div className="modal-body" style={{ backgroundColor: '#f2f2f2', borderRadius: '4px' }}>
                            <div className="container-fluid">
                                <div className="row my-2" >
                                    <div style={{ color: '#707070' }} className="col-xs-12 col-sm-12 col-md-12 col-12">
                                        Enviar e-mail para:
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-12">
                                        {this.state.nome}
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-12">
                                        {this.state.email}
                                    </div>
                                </div>
                                <div className="row mt-3" >
                                    <div className="col-xs-12 col-sm-12 col-md-12">
                                        <label htmlFor="assunto">Assunto</label>
                                        <input type="text" id="assunto" className="form-control" onChange={this.handleChangeAssunto} required />
                                    </div>
                                </div>
                                <div className="row my-3" >
                                    <div className="col-xs-12 col-sm-12 col-md-12">
                                        <label htmlFor="mensagem">Mensagem</label>
                                        <div>
                                            <textarea className="form-control" id="mensagem" onChange={this.handleChangeMensagem} rows="7">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={this.limparDados} disabled={loading}>CANCELAR</button>
                                <button id="enviarEmailRejeitarSolicitacao" type="button" className="btn btn-primary" onClick={this.enviarEmailRejeitarSolicitacao} data-bs-dismiss="modal" disabled={loading}>REJEITAR</button>
                                <button id="enviarEmailReprovacao" type="button" className="btn btn-primary" onClick={this.enviarEmailReprovacao} data-bs-dismiss="modal" disabled={loading}>REPROVAR</button>
                                <button id="enviarEmailContato" type="button" className="btn btn-primary" onClick={this.enviarEmail} data-bs-dismiss="modal" style={{ display: 'none' }} disabled={loading}>ENVIAR</button>
                            </div>
                        </div>}

                        {!this.state.liberado && 
                            <div className="modal-body" style={{ backgroundColor: '#f2f2f2', borderRadius: '4px' }}>
                                <p className='alert alert-warning'>
                                    Você não tem permissão para acessar essa área!
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>

        );
    }
}
export default ModalEnviarEmailAvulso;