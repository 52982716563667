import React from 'react';
import LogoParceria from '../../Components/LogoParceria';
import { Link, Redirect } from 'react-router-dom';
import MaskedInput from '../../Components/InputMask';
import { apiURL } from '../../Services/env';
import background from "../../Resources/img/fundoLogin.png";

import '../../css/style.css';
import { GlobalContext } from '../../Context/GlobalContext/context';

class Login extends React.Component {

    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.validarEmail = this.validarEmail.bind(this);
        this.verificarEmail = this.verificarEmail.bind(this);
        this.limparEmail = this.limparEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCPF = this.handleChangeCPF.bind(this);
        this.handleChangeCPFMascarado = this.handleChangeCPFMascarado.bind(this);
        this.handleChangeSenha = this.handleChangeSenha.bind(this);
        this.armazenarCredencial = this.armazenarCredencial.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.enviarEmail = this.enviarEmail.bind(this);
        this.soNumeros = this.soNumeros.bind(this);

        var url = props.location.search;
        url = url.substring(1, url.length);

        const token = url.split('token=')[1];

        this.state = {
            nome: '',
            cpf: '',
            email: '',
            passwd: '',
            token: '',
            papeis: [],
            redirecionar: false,
            foto: '',
            token2: token
        };

    }

    componentDidMount() {

        if (!!localStorage.getItem('projartCod')) {
            this.props.history.push("/painel");
        }
        else{

            this.setState({
                cpf: (localStorage.getItem('projartUser') !== null || localStorage.getItem('projartUser') !== undefined) ? localStorage.getItem('projartUser') : '',
                pass: (localStorage.getItem('projartCred') !== null || localStorage.getItem('projartCred') !== undefined) ? localStorage.getItem('projartCred') : '',
            });
        }
    }

    componentWillUnmount() {

    }

    armazenarCredencial() {

        localStorage.setItem('projartUser', this.state.cpf);
        localStorage.setItem('projartCred', this.state.passwd);
    }

    limparEmail() {
        this.setState({ email: '' });
        document.getElementById("email").value = "";
    }

    async handleSubmit(e) {

        const { startLoading, stopLoading } = this.context;

        e.preventDefault();

        if (
            (this.state.cpf === null || this.state.cpf === undefined || this.state.cpf === '') ||
            (this.state.passwd === null || this.state.passwd === undefined || this.state.passwd === '')
        ) {
            alert("Gentileza informar CPF e Senha para efetuar o login no sistema!");
        }
        else {

            startLoading();

            let erro = document.getElementById('erro');

            try {

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        cpf: this.state.cpf,
                        passwd: this.state.passwd
                    })
                };

                const response = await fetch(apiURL + "autenticacao", requestOptions);
                const data = await response.json();

                if (!data["status"]) {
                    erro.innerText = data["message"];
                    erro.style.visibility = 'visible';
                    this.limparSenha()
                }
                else {
                    this.setState({ token: data["token"] });
                    this.setState({ nome: data["primeiroNome"] });
                    this.setState({ papeis: data["papeis"] });

                    let permissoesUsuario = JSON.stringify(this.state.papeis);

                    localStorage.setItem('projartToken', this.state.token);
                    localStorage.setItem('projartUsuario', this.state.nome);
                    localStorage.setItem('projartPreviegio', data['previlegio']);
                    localStorage.setItem('projartPapeis', this.state.papeis);
                    localStorage.setItem('projartPermissoesUsuario', permissoesUsuario);
                    localStorage.setItem('projartCod', this.state.cpf);
                    localStorage.setItem('projartFoto', data["foto"]);

                    this.setState({ redirecionar: true });
                    stopLoading();

                }
            }
            catch (error) {
                erro.innerText = "Aconteceu um erro inesperado, consulte o suporte técnico!";
                erro.style.visibility = 'visible';
                this.limparSenha()
            }

            stopLoading()
        }


    }

    limparSenha(){
        document.getElementById('passwd').value = ''
        this.setState({ passwd: '' })
    }

    verificarEmail() {

        if (!this.state.email.includes('@')) {
            return false;
        }

        let partesEmail = this.state.email.split('@');

        if (partesEmail.length !== 2) {
            return false;
        }

        if (partesEmail[0].length <= 0) {
            return false;
        }

        if (!partesEmail[1].includes('.')) {
            return false;
        }

        return true;
    }

    validarEmail(e) {

        e.preventDefault();

        let spinner = document.getElementById("spinnerModal");
        spinner.style.display = 'block';

        if (
            (this.state.email === null || this.state.email === undefined || this.state.email === '')
            || this.verificarEmail() === false
        ) {
            alert("Gentileza informar um e-mail válido!");
        }
        else {
            this.enviarEmail().then(mensagem => alert(mensagem));
        }

        spinner.style.display = 'none';

    }

    async enviarEmail() {

        document.getElementById('closeRecuperarSenhaModal').click();

        const { startLoading, stopLoading } = this.context;

        try {

            startLoading();
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.state.email
                })
            };

            const response = await fetch(apiURL + "recuperarSenha", requestOptions);
            const data = await response.json();
            stopLoading();
            return data["mensagem"];

        }
        catch (error) {
            stopLoading();
            return "Não foi possível enviar o e-mail de recuperação de senha!";
        }

    }

    soNumeros(str) {
        return str.replace(/[^0-9]/g, '');
    }

    handleChangeCPFMascarado(e) {
        this.setState({ cpf: this.soNumeros(e.target.value) });
    }

    handleChangeCPF(e) {

        let cpf = document.getElementById("cpf");
        let aux = cpf.value;

        if (e.keyCode === 69 || e.keyCode === 106 || e.keyCode === 107 || e.keyCode === 108
            || e.keyCode === 109 || e.keyCode === 110 || e.keyCode === 111 || e.keyCode === 61
            || e.keyCode === 58 || e.keyCode === 170 || e.keyCode === 171 || e.keyCode === 173
            || e.keyCode === 191 || e.keyCode === 187 || e.keyCode === 189) {

            cpf.value = aux.substring(0, (aux.length - 1));
        }
        else {

            this.setState({ cpf: e.target.value });

        }

    }


    handleChangeEmail(e) {
        this.setState({ email: e.target.value });
    }

    handleChangeSenha(e) {
        this.setState({ passwd: e.target.value });
    }

    render() {

        const { loading } = this.context;
        let redirect = localStorage.getItem('redirect')
        if (this.state.redirecionar === true && redirect !== null && redirect !== undefined) {
            localStorage.removeItem('redirect');
            return (<Redirect to={redirect}></Redirect>);
        }
        else if (this.state.redirecionar === true) {
            return (<Redirect to="/painel"></Redirect>);
        }
        else {
            return (
                <div className="containerLogin" style={{background: `transparent url(${background})`}}>
                    <LogoParceria></LogoParceria>
                    <div className="formularioLogin">
                        <div className="alert alert-danger erroLogin" role="alert" id="erro"></div>
                        <form onSubmit={this.handleSubmit} autoComplete="false">
                            <div className="mb-3">
                                <label htmlFor="cpf" className="form-label  primeiro">CPF:</label>
                                <input type="number" className="form-control semSeta" id="cpf" onKeyUp={this.handleChangeCPF} style={{ display: 'none' }} />
                                <MaskedInput mask="999.999.999-99" className="form-control" id="cpfMascarado" autoComplete="none" onChange={this.handleChangeCPFMascarado}></MaskedInput>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="passwd" className="form-label">SENHA:</label>
                                <input type="password" className="form-control" id="passwd" onChange={this.handleChangeSenha} />
                            </div>
                            <div className="row mb-3 d-flex align-items-center">
                                <div className="col form-check my-3">
                                    <input type="checkbox" className="form-check-input" id="checkLembrar" />
                                    <label onClick={this.armazenarCredencial} className="form-check-label" htmlFor="checkLembrar">Lembrar-me</label>
                                </div>
                                <div className="col my-3 d-flex align-items-center">
                                    <button type="submit" className="botaoLogin" disabled={loading}>Entrar</button>
                                </div>
                            </div>
                        </form>
                        <div className="esqueceu">
                            <Link to="/" data-bs-toggle="modal" data-bs-target="#recuperarSenha" aria-expanded="true">Esqueceu a senha?</Link>
                        </div>
                    </div>

                    <div className="modal fade" id="recuperarSenha" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Redifinir senha</h5>
                                    <button type="button" id='closeRecuperarSenhaModal' className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.limparEmail}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container">
                                        <div className="row my-3">
                                            <div className="col">
                                                Para que você possa recuperar o acesso ao sistema devido a perda da senha, será enviado um e-mail de recuperação de senha para o e-mail cadastrado no perfil da pessoas.
                                                <br /><br />
                                                Por gentileza forneça o e-mail vinculado à conta do seu cadastro no sistema.
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col">
                                                <label htmlFor="email" className="form-label primeiro">E-mail:</label>
                                                <input type="email" placeholder='Ex: contato@dominio.com' className="form-control email" id="email" onChange={this.handleChangeEmail} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <span style={{ display: 'none' }} id="spinnerModal" className="spinner-border spinner-border-sm mx-3 text-primary" role="status" aria-hidden="true"> </span>
                                    <button type="button" onClick={this.validarEmail} className="btn btn-primary">Enviar e-mail de recuperação de senha</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            );

        }
    }

}
export default Login;