import { Component } from "react";
import LogoParceriaInterna from "../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../Components/Menu/Menu";
import Titulo from "../../../Components/Titulo";
import { GlobalContext } from "../../../Context/GlobalContext/context";
import { apiURL } from "../../../Services/env";
import { apiWithToken } from "../../../Services/Api";

import "./index.css";
import {ButtonBack} from "../../../Components/ButtonBack";

export default class TermoParticipacaoUsuario extends Component {

    static contextType = GlobalContext;

    constructor(props){
        super(props);
        var url = props.location.search;
        url = url.substring(1, url.length);

        const token = url.split('token=')[1];
        this.state = {
            token,
            status: false
        };
    }

    componentDidMount(){
        if (this.state.token === undefined || this.state.token === null) {
            alert('Acesso negado!');
            window.location = "/";
        }
        else {
            this.getDados();
        }
    }

    getDados = () => {
        
        this.context.startLoading();

        const body = new FormData();
        body.append('token_participacao', this.state.token);
        const requestOptions = {
            method: 'POST',
            body
        };
        apiWithToken(apiURL + "participacao/checarLinkAssinatura", requestOptions)
            .then(async res => {
                const json = await res.json();
                let pessoa = json.pessoa
                if (json.status !== true) {
                    alert(json.mensagem)
                    window.location = "/";
                }
                else if (
                    !pessoa.ENDERECO_NUMERO ||
                    !pessoa.ENDERECO_BAIRRO ||
                    !pessoa.ENDERECO_CIDADE ||
                    !pessoa.ENDERECO_UF ||
                    !pessoa.ENDERECO_CEP ||
                    !pessoa.CPF ||
                    !pessoa.RG ||
                    !pessoa.ORGAO_RG
                ){
                    alert("É necessário finalizar o preenchimento dos dados do \"Minha Conta\"");
                    window.location = "/painel/minhaConta?view=1";
                }
                else {
                    this.setState({
                        status: true,
                        nome: pessoa.NOME,
                        cpf: pessoa.CPF2,
                        data: json.data
                    })
                }
            })
            .finally(() => {
                this.context.stopLoading();
            })
    
    }

    assinarTermo = (e) => {

        const op = window.confirm("Confirmar:");

        if (op) {
            
            this.context.startLoading();
      
            const body = new FormData();
            body.append('token_acesso', this.state.token)
            const requestOptions = {
                method: 'POST',
                body
            };

            apiWithToken(apiURL + "participacao/assinar", requestOptions)
                .then(async res => await res.json())
                .then(res => {
                    alert(res['mensagem']);
                })
                .catch(err => console.log(err))
                .finally(() => {
                    this.context.stopLoading();
                    document.getElementById('assinarTermo').style.display = "none"
                    window.location.href = "/painel/minhaConta?tab=4"
                })
        }
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu></Menu>
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container">
                                    <ButtonBack
                                        to="/painel/minhaConta?tab=4"
                                    />
                                    <div className="row my-4" >
                                        <div className='col-md-12'>
                                            {
                                                this.state.status &&
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <Titulo name="Termo de consentimento livre e esclarecido (TCLE)"
                                                                className="text-center text-uppercase my-4"/>
                                                        <p className="text-center">
                                                            (Em atendimento à Resolução 466/12 do Conselho <br/>Nacional
                                                            de Saúde/Ministério da Saúde)<br/>
                                                        </p>
                                                        <h2 className="text-center text-uppercase">Participação em
                                                            pesquisa</h2>
                                                    </div>
                                                    <div className="col-md-12 mt-5">
                                                        <p style={{textAlign: 'justify'}}>
                                                            Você está sendo convidado(a) a participar da pesquisa:
                                                            "PROPOSTA DE ESTRUTURAÇÃO DO SISTEMA DE GESTÃO DO ARTESANATO
                                                            BRASILEIRO:
                                                            DIAGNÓSTICO E PLANEJAMENTO ESTRATÉGICO", cujo objetivo é
                                                            realizar o projeto Estruturação do Sistema de Gestão do
                                                            Artesanato Brasileiro:
                                                            Diagnóstico e Planejamento Estratégico que apresente um
                                                            levantamento atualizado dos problemas e necessidades que
                                                            atingem o setor artesanal,
                                                            com identificação das possibilidades de aperfeiçoamento da
                                                            política do PAB e das ações destinadas ao desenvolvimento do
                                                            setor artesanal,
                                                            com vistas à melhoria dos processos, dos resultados e da
                                                            gestão.
                                                        </p>
                                                        <p style={{textAlign: 'justify'}}>
                                                            Para isso, você participará da metodologia GRUPOS DE
                                                            DISCUSSÃO e/ou ENTREVISTA, por via remota, com duração
                                                            aproximada de 2 horas.
                                                        </p>
                                                        <p>
                                                            Seu aceite a este convite e sua participação na pesquisa são
                                                            de caráter estritamente voluntário, estando você livre para
                                                            retirar seu consentimento a qualquer
                                                            instante a partir de agora, sem que haja qualquer prejuízo
                                                            na relação com o pesquisador e com a instituição.
                                                        </p>
                                                        <br/>
                                                        <br/>
                                                        <p>
                                                            Assumimos os seguintes compromissos:
                                                        </p>
                                                        <div>
                                                            <ul>
                                                                <li>
                                                                    não ocasionar gastos financeiros a você ou quaisquer
                                                                    outros participantes;
                                                                </li>
                                                                <li>
                                                                    os possíveis desconfortos provenientes dela serão
                                                                    minimizados pelos pesquisadores através de medidas
                                                                    como:
                                                                    respeito à sua opinião e conceitos;
                                                                </li>
                                                                <li>
                                                                    dar-lhe a faculdade de responder tão somente a
                                                                    questões que se dispuser;
                                                                </li>
                                                                <li>
                                                                    uso de pseudônimos para garantir seu anonimato;
                                                                </li>
                                                                <li>
                                                                    armazenamento do material gerado com segurança;
                                                                </li>
                                                                <li>
                                                                    acesso ao material bruto restrito aos agentes do
                                                                    Projeto;
                                                                </li>
                                                                <li>
                                                                    o material gerado será usado, estritamente, para
                                                                    fins acadêmicos, relatórios e artigos.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <p>
                                                            O estudo trará possíveis benefícios para todos os
                                                            participantes, pois ao participar da pesquisa,
                                                            as instituições de vínculo dos participantes estarão
                                                            contribuindo para gerar dados sobre o ecossistema do
                                                            artesanato brasileiro,
                                                            potencializando o desenvolvimento do setor.
                                                        </p>
                                                        <p>
                                                            Desse modo, solicitamos sua autorização para sua
                                                            participação no GRUPO DE DISCUSSÃO e/ou na ENTREVISTA
                                                            vídeo-gravado,
                                                            que será utilizado exclusivamente para fins
                                                            acadêmico-científicos. Os dados da pesquisa serão
                                                            armazenados em arquivos digitais,
                                                            sob a guarda e a responsabilidade dos pesquisadores, por um
                                                            período de cinco anos após o término da pesquisa, quando
                                                            serão inutilizados.
                                                            Em caso de dúvidas de caráter ético acerca da pesquisa, o
                                                            Comitê de Ética em Pesquisa da UFMG poderá ser contatado.
                                                        </p>
                                                        <p>
                                                            Caso autorize, você terá acesso a uma cópia deste Termo de
                                                            Consentimento Livre Esclarecido, no qual constam os contatos
                                                            dos responsáveis,
                                                            podendo sanar suas dúvidas sobre o projeto e/ou sua
                                                            participação a qualquer momento. Em caso de danos
                                                            provenientes da pesquisa,
                                                            você poderá buscar indenização.
                                                        </p>
                                                    </div>
                                                    <div className="col-md-12 bg-checkbox-content">
                                                        <h2 className="text-center">
                                                            Consentimento
                                                        </h2>
                                                        <div className="form-check mt-4">
                                                            <input type='checkbox' className="form-check-input" id="ck1"
                                                                   onChange={e => this.setState({ck1: e.target.checked})}/>
                                                            <label htmlFor="ck1" className="form-check-label">
                                                                Li todo o conteúdo acima e fui devidamente informado(a)
                                                                sobre todos os aspectos e motivaçoes desta pesquisa, pude
                                                                esclarecer minhas
                                                                dúvidas e sei que, a qualquer momento, poderei solicitar
                                                                novas informações e modificar minha decisão sobre a
                                                                participação se assim o desejar.
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type='checkbox' className="form-check-input" id="ck2"
                                                                   onChange={e => this.setState({ck2: e.target.checked})}/>
                                                            <label htmlFor="ck2" className="form-check-label">
                                                                Concordo com a minha participação nesta pesquisa.
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type='checkbox' className="form-check-input" id="ck3"
                                                                   onChange={e => this.setState({ck3: e.target.checked})}/>
                                                            <label htmlFor="ck3" className="form-check-label">
                                                                Concordo com o uso de gravação em áudio/vídeo durante o
                                                                projeto, sem minha identificação.
                                                            </label>
                                                        </div>
                                                        <div className="form-group text-end">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={this.assinarTermo}
                                                                id="assinarTermo"
                                                                disabled={!this.state.ck1 || !this.state.ck2 || !this.state.ck3}
                                                            >
                                                                Aceitar termo
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
