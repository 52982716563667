import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

class DocumentoRecebido extends React.Component {

    constructor(props){
        super(props);

        this.handleChangeArquivoNovo = props.handleChangeArquivoNovo;
        this.handleChangeLinkNovo = props.handleChangeLinkNovo;
        this.handleNumeroDocumentoExterno = props.handleNumeroDocumentoExterno;
        this.handleRemetente = props.handleRemetente;

        this.state = {
            tabLink : props.tabLink,
            tabArquivo : props.tabArquivo,
            mostrar : props.mostrar
        }
    }

    componentDidMount(){         
    }

    componentWillUnmount(){
    }

    render(props){
        return(    
            <div id="informeDocumento"style={{display:this.state.mostrar}}>
                <div className="row my-3">  
                    <div className="col-xs-12 col-sm-12 col">
                        <Tabs id="abaEscolhaDocumento">
                            <TabList>
                                <Tab><span className="d-flex">{this.state.tabLink} <span className="mx-1" id="novoLinkOpcional">(opcional)</span></span></Tab>
                                <Tab><span className="d-flex">{this.state.tabArquivo} <span className="mx-1" id="novoArquivoOpcional">(opcional)</span></span></Tab>
                            </TabList>
                            <TabPanel >
                                <label htmlFor="linkNovo">Insira o link:</label>
                                <input type="text" className="form-control" id="linkNovo" onChange={this.handleChangeLinkNovo}/>
                            </TabPanel>
                            <TabPanel >
                                <label htmlFor="arquivoNovo">Selecione o arquivo:</label>
                                <input type="file" accept="application/pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" className="form-control" id="arquivoNovo" name="arquivoNovo" onChange={this.handleChangeArquivoNovo}/>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
                <div className="row" id="camposDocumentoRecebido">
                    <div className = "col-xs-12 col-sm-12 col-md-6">
                        <label htmlFor="numeroDocumentoExterno">Número do documento externo (opcional)</label>
                        <input type="text" className="form-control" id="numeroDocumentoExterno" onChange={this.handleNumeroDocumentoExterno}/>
                    </div>
                    <div className = "col-xs-12 col-sm-12 col-md-6">
                        <label htmlFor="remetente">Remetente (opcional)</label>
                        <input className="form-control" id="remetente" onChange={this.handleRemetente}/>
                    </div>                    
                </div>
            </div>
            );

        }
}
export default DocumentoRecebido;