import React from 'react';
import { apiURL } from '../../Services/env';
import MaskedInput from '../InputMask';
import Validacao from '../Validacao';

class ModalAdicionarEntrevistador extends React.Component{
    
    constructor(props){
            super(props);

            this.limparDados = this.limparDados.bind(this);
            this.cadastrarEntrevistador = this.cadastrarEntrevistador.bind(this);
            this.handleChangeNome = this.handleChangeNome.bind(this);
            this.handleChangeEmail = this.handleChangeEmail.bind(this);
            this.handleChangeCpf = this.handleChangeCpf.bind(this);
            this.soNumeros = this.soNumeros.bind(this);
            this.handleChangeEntrevistador = props.handleChangeEntrevistador
        
            this.state = {                
                nome: '',
                email: '',
                cpf: '',
                equipe: '',
                idEntrevistador: props.idEntrevistador
            };
    
    }

       limparDados(e){
        this.setState({                
            nome: '',
            email: '',
            cpf: ''
        });

        document.getElementById('nome').value = '';
        document.getElementById('email').value = '';
        document.getElementById('cpf').value = '';
    }

    async persistirEntrevistador(){
        
        //console.log(document.getElementById("emissor").value);

        let formData = new FormData();
        formData.append('cpf', this.state.cpf);
        formData.append('nome',  this.state.nome);
        formData.append('email',  this.state.email);
        formData.append('cod_papel',  'ENTREVISTADOR');
        formData.append('cod_equipe', document.getElementById("emissor").value);
        
        const requestOptions = {
            method: 'POST',
            body: formData
        }
        const response = await fetch(apiURL+"pessoa/cadastrar", requestOptions);
        const resp = await response.json();

        return resp;
    }

    cadastrarEntrevistador(e){

        if( this.state.nome === null || this.state.nome === undefined || this.state.nome === '' ||
            this.state.email === null || this.state.email === undefined || this.state.email === '' ||
            this.state.cpf === null || this.state.cpf === undefined || this.state.cpf === ''){
            alert('Preencha todos os campos para poder cadastrar o entrevistador!')
        }
        else{            
            if(Validacao(this.state.email) === false){
                alert("E-mail do entrevistador inválido! \nAcerte-o para conseguir incluir o entrevistador!");
            }
            else{
                this.persistirEntrevistador().then(data => {
                        
                    if(data["status"] === false){
                        alert("Falha ao cadastrar o entrevistador!");
                    }
                    else{
        
                        this.setState({idEntrevistador: data["pessoa"]["ID"]});
                        this.handleChangeEntrevistador(data["pessoa"]["ID"]);

                        alert("Entrevistador adicionado com sucesso!");
                    }
                }).catch();

                this.limparDados();
            }
        }
    }

    handleChangeNome(e){
        this.setState({nome: e.target.value});
    }

    handleChangeEmail(e){
        this.setState({email: e.target.value});
    }

    soNumeros(str){
        return str.replace(/[^0-9]/g, '');
    }

    handleChangeCpf(e){        
        this.setState({cpf: this.soNumeros(e.target.value)});
    }

    render(){
        return (     
            <div className="modal fade" id="modalEntrevistador" aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel1">Inclusão de entrevistador</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="container-fluid">
                        
                        <div className="row mt-2" >
                            <div className = "col-xs-12 col-sm-12 col-md-2">
                                <label htmlFor="nome">Nome</label>
                            </div>
                            <div className = "col-xs-12 col-sm-12 col-md-8" >
                                <input type="text" id="nome"className="form-control" onChange={this.handleChangeNome} required/>
                            </div>
                        </div>
                        <div className="row mt-2" >
                            <div  className = "col-xs-12 col-sm-12 col-md-2">
                                <label htmlFor="email">E-mail</label>
                            </div>
                            <div  className = "col-xs-12 col-sm-12 col-md-8">
                                <input type="text" className="form-control" id="email" onChange={this.handleChangeEmail} required/>    
                            </div>
                        </div>
                        <div className="row mt-2 mb-3" >
                            <div  className = "col-xs-12 col-sm-12 col-md-2">
                                <label htmlFor="cpf">CPF</label>
                            </div>
                            <div  className = "col-xs-12 col-sm-12 col-md-8">
                            <MaskedInput required mask="999.999.999-99" className="form-control" id="cpf" onChange={this.handleChangeCpf} value={this.state.cpf}></MaskedInput>
                            </div>
                        </div>
                    </div>
                <div className="modal-footer">´
                <button type="button" className="btn btn-secondary"  data-bs-dismiss="modal" onClick={this.limparDados}>Cancelar</button>
                  <button type="button" className="btn btn-primary" onClick={this.cadastrarEntrevistador} data-bs-dismiss="modal">Cadastrar</button>
                </div>
              </div>
            </div>
          </div>
        </div>                           

        );
    }
}
export default ModalAdicionarEntrevistador;