import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Titulo from '../../../Components/Titulo';
import LogoParceriaInterna from '../../../Components/LogoParceria/LogoParceriaInterna';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../../css/style.css';
import { apiURL } from '../../../Services/env';
import { api } from "../../../Services/Api";
import { GlobalContext } from '../../../Context/GlobalContext/context';
import * as permissions from '../../../Context/GlobalContext/permissions';

class ExportarPessoaJuridica extends React.Component {

    static contextType = GlobalContext;

	constructor(props){
        super(props);

        this.referencia = React.createRef();

        this.handleSubmitExportar = this.handleSubmitExportar.bind(this);
        this.handleSelecionarTodos = this.handleSelecionarTodos.bind(this);
        this.handleCheckEmpresaNome = this.handleCheckEmpresaNome.bind(this);
        this.handleCheckEmpresaWebsite = this.handleCheckEmpresaWebsite.bind(this);
        this.handleCheckEmpresaCidade = this.handleCheckEmpresaCidade.bind(this);
        this.handleCheckEmpresaEstado = this.handleCheckEmpresaEstado.bind(this);
        this.handleCheckEmpresaTempoAtuacao = this.handleCheckEmpresaTempoAtuacao.bind(this);
        this.handleCheckEmpresaAtuaSomenteSetorArtesanal = this.handleCheckEmpresaAtuaSomenteSetorArtesanal.bind(this);
        this.handleCheckEmpresaAreaAtendimentoNenhum = this.handleCheckEmpresaAreaAtendimentoNenhum.bind(this);
        this.handleCheckEmpresaAreaAtendimentoCapacitacao = this.handleCheckEmpresaAreaAtendimentoCapacitacao.bind(this);
        this.handleCheckEmpresaAreaAtendimentoAcessoAMercado = this.handleCheckEmpresaAreaAtendimentoAcessoAMercado.bind(this);
        this.handleCheckEmpresaAreaAtendimentoEstudosEPesquisas = this.handleCheckEmpresaAreaAtendimentoEstudosEPesquisas.bind(this);
        this.handleCheckEmpresaAreaAtendimentoAcessoACredito = this.handleCheckEmpresaAreaAtendimentoAcessoACredito.bind(this);
        this.handleCheckEmpresaAreaAtendimentoInovacaoETecnologia = this.handleCheckEmpresaAreaAtendimentoInovacaoETecnologia.bind(this);
        this.handleCheckEmpresaAreaAtendimentoInfraestrutura = this.handleCheckEmpresaAreaAtendimentoInfraestrutura.bind(this);
        this.handleCheckEmpresaAreaAtendimentoOutros = this.handleCheckEmpresaAreaAtendimentoOutros.bind(this);
        this.handleCheckEmpresaAreaAtendimentoOutrosDetalhes = this.handleCheckEmpresaAreaAtendimentoOutrosDetalhes.bind(this);
        this.handleCheckEmpresaAreaAtendimento = this.handleCheckEmpresaAreaAtendimento.bind(this);
        this.handleCheckEmpresaPublicoAlvo = this.handleCheckEmpresaPublicoAlvo.bind(this);
        this.handleCheckEmpresaDestinadoAArtesao = this.handleCheckEmpresaDestinadoAArtesao.bind(this);
        this.handleCheckEmpresaDestinadoAAssociacoes = this.handleCheckEmpresaDestinadoAAssociacoes.bind(this);
        this.handleCheckEmpresaDestinadoAGestores = this.handleCheckEmpresaDestinadoAGestores.bind(this);
        this.handleCheckEmpresaDestinadoAFornecedores = this.handleCheckEmpresaDestinadoAFornecedores.bind(this);
        this.handleCheckEmpresaDestinadoAOutros = this.handleCheckEmpresaDestinadoAOutros.bind(this);
        this.handleCheckEmpresaDestinadoAOutrosDetalhes = this.handleCheckEmpresaDestinadoAOutrosDetalhes.bind(this);
        this.handleCheckDirigenteGenero = this.handleCheckDirigenteGenero.bind(this);
        this.handleCheckDirigenteCargo = this.handleCheckDirigenteCargo.bind(this);
        this.handleCheckDirigentePeriodo = this.handleCheckDirigentePeriodo.bind(this);
        this.handleCheckResponsavelGenero = this.handleCheckResponsavelGenero.bind(this);
        this.handleCheckResponsavelCidade = this.handleCheckResponsavelCidade.bind(this);
        this.handleCheckResponsavelEstado = this.handleCheckResponsavelEstado.bind(this);
        this.handleCheckResponsavelLinkedIn = this.handleCheckResponsavelLinkedIn.bind(this);
        this.handleCheckResponsavelFacebook = this.handleCheckResponsavelFacebook.bind(this);
        this.handleCheckResponsavelInstagram = this.handleCheckResponsavelInstagram.bind(this);
        this.handleCheckResponsavelTwitter = this.handleCheckResponsavelTwitter.bind(this);
        this.handleCheckInterlocutorGenero = this.handleCheckInterlocutorGenero.bind(this);
        this.handleCheckInterlocutorCargo = this.handleCheckInterlocutorCargo.bind(this);
        this.handleCheckInterlocutorTempoAtuacao = this.handleCheckInterlocutorTempoAtuacao.bind(this);
        this.handleCheckInterlocutorFormaAtuacao = this.handleCheckInterlocutorFormaAtuacao.bind(this);
        this.handleCheckInterlocutorLinkedIn = this.handleCheckInterlocutorLinkedIn.bind(this);
        this.handleCheckInterlocutorFacebook = this.handleCheckInterlocutorFacebook.bind(this);
        this.handleCheckInterlocutorInstagram = this.handleCheckInterlocutorInstagram.bind(this);
        this.handleCheckInterlocutorTwitter = this.handleCheckInterlocutorTwitter.bind(this);
        this.handleCheckCruzamentoDirigenteIgualResponsavel = this.handleCheckCruzamentoDirigenteIgualResponsavel.bind(this);
        this.handleCheckCruzamentoDirigenteIgualInterlocutor = this.handleCheckCruzamentoDirigenteIgualInterlocutor.bind(this);
        this.handleCheckCruzamentoResponsavelgualInterlocutor = this.handleCheckCruzamentoResponsavelgualInterlocutor.bind(this);				

        this.state = {
            token: localStorage.getItem("projartToken"),
            cpf: localStorage.getItem("projartCod"),
            nome: localStorage.getItem('projartUsuario'),
            checkEmpresaNome: false,
            checkEmpresaWebsite: false,
            checkEmpresaCidade: false,
            checkEmpresaEstado: false,
            checkEmpresaTempoAtuacao: false,
            checkEmpresaAtuaSomenteSetorArtesanal: false,
            checkEmpresaAreaAtendimentoNenhum: false,
            checkEmpresaAreaAtendimentoCapacitacao: false,
            checkEmpresaAreaAtendimentoAcessoAMercado: false,
            checkEmpresaAreaAtendimentoEstudosEPesquisas: false,
            checkEmpresaAreaAtendimentoAcessoACredito: false,
            checkEmpresaAreaAtendimentoInovacaoETecnologia: false,
            checkEmpresaAreaAtendimentoInfraestrutura: false,
            checkEmpresaAreaAtendimentoOutros: false,
            checkEmpresaAreaAtendimentoOutrosDetalhes: false,
            checkEmpresaAreaAtendimento: false,
            checkEmpresaPublicoAlvo: false,
            checkEmpresaDestinadoAArtesao: false,
            checkEmpresaDestinadoAAssociacoes: false,
            checkEmpresaDestinadoAGestores: false,
            checkEmpresaDestinadoAFornecedores: false,
            checkEmpresaDestinadoAOutros: false,
            checkEmpresaDestinadoAOutrosDetalhes: false,
            checkDirigenteGenero: false,
            checkDirigenteCargo: false,
            checkDirigentePeriodo: false,
            checkResponsavelGenero: false,
            checkResponsavelCidade: false,
            checkResponsavelEstado: false,
            checkResponsavelLinkedIn: false,
            checkResponsavelFacebook: false,
            checkResponsavelInstagram: false,
            checkResponsavelTwitter: false,
            checkInterlocutorGenero: false,
            checkInterlocutorCargo: false,
            checkInterlocutorTempoAtuacao: false,
            checkInterlocutorFormaAtuacao: false,
            checkInterlocutorLinkedIn: false,
            checkInterlocutorFacebook: false,
            checkInterlocutorInstagram: false,
            checkInterlocutorTwitter: false,
            checkCruzamentoDirigenteIgualResponsavel: false,
            checkCruzamentoDirigenteIgualInterlocutor: false,
            checkCruzamentoResponsavelgualInterlocutor: false
        };
             
    }

    componentDidMount(){
        if (!this.context.getPermission(permissions.EXPORTA_PROJETO_PJ)) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
    }
        
    handleSelecionarTodos(e){
        
        let valor = false;
        if(document.getElementById('checkTodos').checked === true){
            valor = true;
        }

        if(document.getElementById('checkEmpresaNome') !== null) document.getElementById('checkEmpresaNome').checked = valor;
        if(document.getElementById('checkEmpresaWebsite')!== null) document.getElementById('checkEmpresaWebsite').checked = valor;
        if(document.getElementById('checkEmpresaCidade')!== null) document.getElementById('checkEmpresaCidade').checked = valor;
        if(document.getElementById('checkEmpresaEstado')!== null) document.getElementById('checkEmpresaEstado').checked = valor;
        if(document.getElementById('checkEmpresaTempoAtuacao')!== null) document.getElementById('checkEmpresaTempoAtuacao').checked = valor;
        if(document.getElementById('checkEmpresaAtuaSomenteSetorArtesanal')!== null) document.getElementById('checkEmpresaAtuaSomenteSetorArtesanal').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimento')!== null) document.getElementById('checkEmpresaAreaAtendimento').checked = valor;
        if(document.getElementById('checkEmpresaPublicoAlvo')!== null) document.getElementById('checkEmpresaPublicoAlvo').checked = valor;
        /*
        if(document.getElementById('checkEmpresaAreaAtendimentoNenhum')!== null) document.getElementById('checkEmpresaAreaAtendimentoNenhum').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoCapacitacao')!== null) document.getElementById('checkEmpresaAreaAtendimentoCapacitacao').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoAcessoAMercado')!== null) document.getElementById('checkEmpresaAreaAtendimentoAcessoAMercado').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoEstudosEPesquisas')!== null) document.getElementById('checkEmpresaAreaAtendimentoEstudosEPesquisas').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoAcessoACredito')!== null) document.getElementById('checkEmpresaAreaAtendimentoAcessoACredito').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoInovacaoETecnologia')!== null) document.getElementById('checkEmpresaAreaAtendimentoInovacaoETecnologia').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoInfraestrutura')!== null) document.getElementById('checkEmpresaAreaAtendimentoInfraestrutura').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoOutros')!== null) document.getElementById('checkEmpresaAreaAtendimentoOutros').checked = valor;
        if(document.getElementById('checkEmpresaAreaAtendimentoOutrosDetalhes')!== null) document.getElementById('checkEmpresaAreaAtendimentoOutrosDetalhes').checked = valor;
        if(document.getElementById('checkEmpresaDestinadoAArtesao')!== null) document.getElementById('checkEmpresaDestinadoAArtesao').checked = valor;
        if(document.getElementById('checkEmpresaDestinadoAAssociacoes')!== null) document.getElementById('checkEmpresaDestinadoAAssociacoes').checked = valor;
        if(document.getElementById('checkEmpresaDestinadoAGestores')!== null) document.getElementById('checkEmpresaDestinadoAGestores').checked = valor;
        if(document.getElementById('checkEmpresaDestinadoAFornecedores')!== null) document.getElementById('checkEmpresaDestinadoAFornecedores').checked = valor;
        if(document.getElementById('checkEmpresaDestinadoAOutros')!== null) document.getElementById('checkEmpresaDestinadoAOutros').checked = valor;
        if(document.getElementById('checkEmpresaDestinadoAOutrosDetalhes')!== null) document.getElementById('checkEmpresaDestinadoAOutrosDetalhes').checked = valor;
        */
        if(document.getElementById('checkDirigenteGenero')!== null) document.getElementById('checkDirigenteGenero').checked = valor;
        if(document.getElementById('checkDirigenteCargo')!== null) document.getElementById('checkDirigenteCargo').checked = valor;
        if(document.getElementById('checkDirigentePeriodo')!== null) document.getElementById('checkDirigentePeriodo').checked = valor;
        if(document.getElementById('checkResponsavelGenero')!== null) document.getElementById('checkResponsavelGenero').checked = valor;
        if(document.getElementById('checkResponsavelCidade')!== null) document.getElementById('checkResponsavelCidade').checked = valor;
        if(document.getElementById('checkResponsavelEstado')!== null) document.getElementById('checkResponsavelEstado').checked = valor;
        if(document.getElementById('checkResponsavelLinkedIn')!== null) document.getElementById('checkResponsavelLinkedIn').checked = valor;
        if(document.getElementById('checkResponsavelFacebook')!== null) document.getElementById('checkResponsavelFacebook').checked = valor;
        if(document.getElementById('checkResponsavelInstagram')!== null) document.getElementById('checkResponsavelInstagram').checked = valor;
        if(document.getElementById('checkResponsavelTwitter')!== null) document.getElementById('checkResponsavelTwitter').checked = valor;
        if(document.getElementById('checkInterlocutorGenero')!== null) document.getElementById('checkInterlocutorGenero').checked = valor;
        if(document.getElementById('checkInterlocutorCargo')!== null) document.getElementById('checkInterlocutorCargo').checked = valor;
        if(document.getElementById('checkInterlocutorTempoAtuacao')!== null) document.getElementById('checkInterlocutorTempoAtuacao').checked = valor;
        if(document.getElementById('checkInterlocutorFormaAtuacao')!== null) document.getElementById('checkInterlocutorFormaAtuacao').checked = valor;
        if(document.getElementById('checkInterlocutorLinkedIn')!== null) document.getElementById('checkInterlocutorLinkedIn').checked = valor;
        if(document.getElementById('checkInterlocutorFacebook')!== null) document.getElementById('checkInterlocutorFacebook').checked = valor;
        if(document.getElementById('checkInterlocutorInstagram')!== null) document.getElementById('checkInterlocutorInstagram').checked = valor;
        if(document.getElementById('checkInterlocutorTwitter')!== null) document.getElementById('checkInterlocutorTwitter').checked = valor;
        if(document.getElementById('checkCruzamentoDirigenteIgualResponsavel')!== null) document.getElementById('checkCruzamentoDirigenteIgualResponsavel').checked = valor;
        if(document.getElementById('checkCruzamentoDirigenteIgualInterlocutor')!== null) document.getElementById('checkCruzamentoDirigenteIgualInterlocutor').checked = valor;
        if(document.getElementById('checkCruzamentoResponsavelgualInterlocutor')!== null) document.getElementById('checkCruzamentoResponsavelgualInterlocutor').checked = valor;
        
        this.setState({
            checkEmpresaNome: valor,
            checkEmpresaWebsite: valor,
            checkEmpresaCidade: valor,
            checkEmpresaEstado: valor,
            checkEmpresaTempoAtuacao: valor,
            checkEmpresaAtuaSomenteSetorArtesanal: valor,
            checkEmpresaAreaAtendimento: valor,
            checkEmpresaPublicoAlvo: valor,
            checkEmpresaAreaAtendimentoNenhum: valor,
            checkEmpresaAreaAtendimentoCapacitacao: valor,
            checkEmpresaAreaAtendimentoAcessoAMercado: valor,
            checkEmpresaAreaAtendimentoEstudosEPesquisas: valor,
            checkEmpresaAreaAtendimentoAcessoACredito: valor,
            checkEmpresaAreaAtendimentoInovacaoETecnologia: valor,
            checkEmpresaAreaAtendimentoInfraestrutura: valor,
            checkEmpresaAreaAtendimentoOutros: valor,
            checkEmpresaAreaAtendimentoOutrosDetalhes: valor,
            checkEmpresaDestinadoAArtesao: valor,
            checkEmpresaDestinadoAAssociacoes: valor,
            checkEmpresaDestinadoAGestores: valor,
            checkEmpresaDestinadoAFornecedores: valor,
            checkEmpresaDestinadoAOutros: valor,
            checkEmpresaDestinadoAOutrosDetalhes: valor,
            checkDirigenteGenero: valor,
            checkDirigenteCargo: valor,
            checkDirigentePeriodo: valor,
            checkResponsavelGenero: valor,
            checkResponsavelCidade: valor,
            checkResponsavelEstado: valor,
            checkResponsavelLinkedIn: valor,
            checkResponsavelFacebook: valor,
            checkResponsavelInstagram: valor,
            checkResponsavelTwitter: valor,
            checkInterlocutorGenero: valor,
            checkInterlocutorCargo: valor,
            checkInterlocutorTempoAtuacao: valor,
            checkInterlocutorFormaAtuacao: valor,
            checkInterlocutorLinkedIn: valor,
            checkInterlocutorFacebook: valor,
            checkInterlocutorInstagram: valor,
            checkInterlocutorTwitter: valor,
            checkCruzamentoDirigenteIgualResponsavel: valor,
            checkCruzamentoDirigenteIgualInterlocutor: valor,
            checkCruzamentoResponsavelgualInterlocutor: valor
        });
    
    }

    handleCheckEmpresaNome(e){
        this.setState({checkEmpresaNome: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaWebsite(e){
        this.setState({checkEmpresaWebsite: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }

    handleCheckEmpresaCidade(e){
        this.setState({checkEmpresaCidade: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }

    handleCheckEmpresaEstado(e){
        this.setState({checkEmpresaEstado: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaTempoAtuacao(e){
        this.setState({checkEmpresaTempoAtuacao: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
     
    handleCheckEmpresaAtuaSomenteSetorArtesanal(e){
        this.setState({checkEmpresaAtuaSomenteSetorArtesanal: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }

    handleCheckEmpresaPublicoAlvo(e){
        this.setState({checkEmpresaPublicoAlvo: e.target.checked});
         
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }

    handleCheckEmpresaAreaAtendimento(e){
        this.setState({checkEmpresaAreaAtendimento: e.target.checked});
         
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }

    handleCheckEmpresaAreaAtendimentoNenhum(e){
        this.setState({checkEmpresaAreaAtendimentoNenhum: e.target.checked});
         
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoCapacitacao(e){
        this.setState({checkEmpresaAreaAtendimentoCapacitacao: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoAcessoAMercado(e){
        this.setState({checkEmpresaAreaAtendimentoAcessoAMercado: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoEstudosEPesquisas(e){
        this.setState({checkEmpresaAreaAtendimentoEstudosEPesquisas: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoAcessoACredito(e){
        this.setState({checkEmpresaAreaAtendimentoAcessoACredito: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoInovacaoETecnologia(e){
        this.setState({checkEmpresaAreaAtendimentoInovacaoETecnologia: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoInfraestrutura(e){
        this.setState({checkEmpresaAreaAtendimentoInfraestrutura: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoOutros(e){
        this.setState({checkEmpresaAreaAtendimentoOutros: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaAreaAtendimentoOutrosDetalhes(e){
        this.setState({checkEmpresaAreaAtendimentoOutrosDetalhes: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaDestinadoAArtesao(e){
        this.setState({checkEmpresaDestinadoAArtesao: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaDestinadoAAssociacoes(e){
        this.setState({checkEmpresaDestinadoAAssociacoes: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaDestinadoAGestores(e){
        this.setState({checkEmpresaDestinadoAGestores: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaDestinadoAFornecedores(e){
        this.setState({checkEmpresaDestinadoAFornecedores: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaDestinadoAOutros(e){
        this.setState({checkEmpresaDestinadoAOutros: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckEmpresaDestinadoAOutrosDetalhes(e){
        this.setState({checkEmpresaDestinadoAOutrosDetalhes: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckDirigenteGenero(e){
        this.setState({checkDirigenteGenero: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckDirigenteCargo(e){
        this.setState({checkDirigenteCargo: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckDirigentePeriodo(e){
        this.setState({checkDirigentePeriodo: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckResponsavelGenero(e){
        this.setState({checkResponsavelGenero: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckResponsavelCidade(e){
        this.setState({checkResponsavelCidade: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckResponsavelEstado(e){
        this.setState({checkResponsavelEstado: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckResponsavelLinkedIn(e){
        this.setState({checkResponsavelLinkedIn: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckResponsavelFacebook(e){
        this.setState({checkResponsavelFacebook: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckResponsavelInstagram(e){
        this.setState({checkResponsavelInstagram: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckResponsavelTwitter(e){
        this.setState({checkResponsavelTwitter: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorGenero(e){
        this.setState({checkInterlocutorGenero: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorCargo(e){
        this.setState({checkInterlocutorCargo: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorTempoAtuacao(e){
        this.setState({checkInterlocutorTempoAtuacao: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorFormaAtuacao(e){
        this.setState({checkInterlocutorFormaAtuacao: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorLinkedIn(e){
        this.setState({checkInterlocutorLinkedIn: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorFacebook(e){
        this.setState({checkInterlocutorFacebook: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorInstagram(e){
        this.setState({checkInterlocutorInstagram: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckInterlocutorTwitter(e){
        this.setState({checkInterlocutorTwitter: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }
    
    handleCheckCruzamentoDirigenteIgualResponsavel(e){
        this.setState({checkCruzamentoDirigenteIgualResponsavel: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }

    handleCheckCruzamentoDirigenteIgualInterlocutor(e){
        this.setState({checkCruzamentoDirigenteIgualInterlocutor: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }

    handleCheckCruzamentoResponsavelgualInterlocutor(e){
        this.setState({checkCruzamentoResponsavelgualInterlocutor: e.target.checked});
        
        if(e.target.checked === false){
            document.getElementById('checkTodos').checked = false;
        }
    }				

    async extrairPessoaJuridica(){

        let spinner = document.getElementById("spinner");
        spinner.style.display = 'block';

        let formData = new FormData();
        formData.append('token', this.state.token);

        if(this.state.checkEmpresaNome === true){
            formData.append('nome_empresa','s');
        }

        if(this.state.checkEmpresaWebsite === true){
            formData.append('website_empresa','s');
        }

        if(this.state.checkEmpresaCidade === true){
            formData.append('cidade_empresa','s');
        }

        if(this.state.checkEmpresaEstado === true){
            formData.append('uf_empresa','s');
        }

        if(this.state.checkEmpresaTempoAtuacao === true){
            formData.append('setor_artesanal_desde','s');
        }

        if(this.state.checkEmpresaAtuaSomenteSetorArtesanal === true){
            formData.append('exclusivo_setor_artesanal','s');
        }

        if(this.state.checkEmpresaAreaAtendimento === true){
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaPublicoAlvo === true){
            formData.append('publicos_alvo','s');
        }
/*
        if(this.state.checkEmpresaAreaAtendimentoNenhum === true){
            formData.append('areas_atendimento','s');
            formData.append('area_atendimento_nenhum','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoCapacitacao === true){
            formData.append('area_atendimento_capacitacao','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoAcessoAMercado === true){
            formData.append('area_atendimento_acesso_a_mercado','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoEstudosEPesquisas === true){
            formData.append('area_atendimento_estudos_e_pesquisas','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoAcessoACredito === true){
            formData.append('area_atendimento_acesso_a_credito','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoInovacaoETecnologia === true){
            formData.append('area_atendimento_inovacao_e_tecnologia','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoInfraestrutura === true){
            formData.append('area_atendimento_infraestrutura','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoOutros === true){
            formData.append('area_atendimento_outros','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaAreaAtendimentoOutrosDetalhes === true){
            formData.append('area_atendimento_outros_detalhe','s');
            formData.append('areas_atendimento','s');
        }

        if(this.state.checkEmpresaDestinadoAArtesao === true){
            formData.append('publicos_alvo','s');
            formData.append('publico_alvo_artesao','s');
        }

        if(this.state.checkEmpresaDestinadoAAssociacoes === true){
            formData.append('publicos_alvo','s');
            formData.append('publico_alvo_associacoes','s');
        }

        if(this.state.checkEmpresaDestinadoAGestores === true){
            formData.append('publico_alvo_gestores','s');
            formData.append('publicos_alvo','s');
        }

        if(this.state.checkEmpresaDestinadoAFornecedores === true){
            formData.append('publico_alvo_fornecedores','s');
            formData.append('publicos_alvo','s');
        }

        if(this.state.checkEmpresaDestinadoAOutros === true){
            formData.append('publico_alvo_outros','s');
            formData.append('publicos_alvo','s');
        }

        if(this.state.checkEmpresaDestinadoAOutrosDetalhes === true){
            formData.append('publico_alvo_outros_detalhe','s');
            formData.append('publicos_alvo','s');
        }
*/
        if(this.state.checkDirigenteGenero === true){
            formData.append('genero_dirigente','s');
        }

        if(this.state.checkDirigenteCargo === true){
            formData.append('cargo_dirigente','s');
        }

        if(this.state.checkDirigentePeriodo === true){
            formData.append('periodo_gestao_dirigente','s');
        }

        if(this.state.checkResponsavelGenero === true){
            formData.append('genero_responsavel','s');
        }

        if(this.state.checkResponsavelCidade === true){
            formData.append('cidade_responsavel','s');
        }

        if(this.state.checkResponsavelEstado === true){
            formData.append('uf_responsavel','s');
        }

        if(this.state.checkResponsavelLinkedIn === true){
            formData.append('linkedin_responsavel','s');
        }

        if(this.state.checkResponsavelFacebook === true){
            formData.append('facebook_responsavel','s');
        }

        if(this.state.checkResponsavelInstagram === true){
            formData.append('instagram_responsavel','s');
        }

        if(this.state.checkResponsavelTwitter === true){
            formData.append('twitter_responsavel','s');
        }

        if(this.state.checkInterlocutorGenero === true){
            formData.append('genero_interlocutor','s');
        }

        if(this.state.checkInterlocutorCargo === true){
            formData.append('cargo_interlocutor','s');
        }

        if(this.state.checkInterlocutorTempoAtuacao === true){
            formData.append('atua_desde_interlocutor','s');
        }

        if(this.state.checkInterlocutorFormaAtuacao === true){
            formData.append('forma_atuacao_interlocutor','s');
        }

        if(this.state.checkInterlocutorLinkedIn === true){
            formData.append('linkedin_interlocutor','s');
        }

        if(this.state.checkInterlocutorFacebook === true){
            formData.append('facebook_interlocutor','s');
        }

        if(this.state.checkInterlocutorInstagram === true){
            formData.append('instagram_interlocutor','s');
        }

        if(this.state.checkInterlocutorTwitter === true){
            formData.append('twitter_interlocutor','s');
        }

        if(this.state.checkCruzamentoDirigenteIgualResponsavel === true){
            formData.append('dirigente_e_responsavel','s');
        }

        if(this.state.checkCruzamentoDirigenteIgualInterlocutor === true){
            formData.append('dirigente_e_interlocutor','s');
        }

        if(this.state.checkCruzamentoResponsavelgualInterlocutor === true){
            formData.append('responsavel_e_interlocutor','s');
        }

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        const response = await api(apiURL+"pessoa/exportar/PessoaJuridica", requestOptions);
        return response;

    }

    handleSubmitExportar(e){

        e.preventDefault();
        
        if (this.context.getPermission(permissions.EXPORTA_PROJETO_PJ)) {

            this.context.startLoading();   
            
            document.getElementById("erro").style.display = 'none';
            document.getElementById("erro").innerHTML = '';
            document.getElementById("sucesso").style.display = 'none';
            document.getElementById("sucesso").innerHTML = '';
        
        
            this.extrairPessoaJuridica()
            .then(response => { 
                //return response.text();
                return response.blob();       
            })
            .then(blob => {     
    //            const url = URL.createObjectURL(new Blob([data],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'}));
                const url = URL.createObjectURL(blob,{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exportacao_pessoa_juridica.xlsx');
                document.body.appendChild(link);
                link.click();       
                document.body.removeChild(link);
            })
            .catch(erra => {console.log(erra);})
            .finally(() => this.context.stopLoading())   
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

	render(){
	    return (		
			
            <div className="container-xl-fluid">         
                <Menu idMenuOpen = "btnPesquisa"></Menu>             
                <div className="page-content fundoAreaTrabalho" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid" >
                        <div className="row" >
                            <div className="col p-2" style={{backgroundColor: '#F2F3F7'}}>
                                <div className="container-fluid">  
                                    <br/>
                                    <Titulo name="Exportar dados da pessoa jurídica" className=".mt-3"/>        
                                    <br/>                               
                                    <div className="row my-1">    
                                        <div className = "col">                                            
                                            <form className="container" encType="multipart/form-data">
                                                <div className="alert alert-danger" role="alert" id="erro"  style={{display:'none'}}>
                                                </div>
                                                <div className="alert alert-success" role="alert" id="sucesso" style={{display:'none'}}>
                                                </div>
                                                <div className="row my-3">
                                                    <div className = "col-xs-12 col-sm-12 col-md-12">
                                                        Selecione os campos que deseja exportar para a planilha:<br/>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className = "col d-flex flex-row-reverse  align-items-center">               
                                                        <label htmlFor="checkTodos"> &nbsp; Selecionar todos os campos </label>
                                                        <input type="checkbox" id='checkTodos' onChange={this.handleSelecionarTodos}/>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row my-3" id="linhaAbas">  
                                                    <div className="col-xs-12 col-sm-12 col">
                                                        <Tabs id="abaEscolhaDocumento">
                                                            <TabList>
                                                                <Tab><span className="d-flex">EMPRESA</span></Tab>
                                                                <Tab><span className="d-flex">DIRIGENTE</span></Tab>
                                                                <Tab><span className="d-flex">RESPONSÁVEL</span></Tab>
                                                                <Tab><span className="d-flex">INTERLOCUTOR</span></Tab>
                                                                <Tab><span className="d-flex">CRUZAMENTOS</span></Tab>
                                                            </TabList>
                                                            <TabPanel id="empresa">
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>IDENTIFICAÇÃO / LOCALIZAÇÃO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaNome' checked={this.state.checkEmpresaNome} onChange={this.handleCheckEmpresaNome}/>
                                                                        <label htmlFor="checkEmpresaNome"> &nbsp; Nome</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaWebsite' checked={this.state.checkEmpresaWebsite} onChange={this.handleCheckEmpresaWebsite}/>
                                                                        <label htmlFor="checkEmpresaWebsite"> &nbsp; Website</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaCidade'  checked={this.state.checkEmpresaCidade} onChange={this.handleCheckEmpresaCidade}/>
                                                                        <label htmlFor="checkEmpresaCidade"> &nbsp; Cidade</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaEstado' checked={this.state.checkEmpresaEstado} onChange={this.handleCheckEmpresaEstado}/>
                                                                        <label htmlFor="checkEmpresaEstado"> &nbsp; Estado</label>
                                                                    </div>                                                   
                                                                </div>
                                                                <hr/>
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>ARTESANATO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaTempoAtuacao' checked={this.state.checkEmpresaTempoAtuacao} onChange={this.handleCheckEmpresaTempoAtuacao}/>
                                                                        <label htmlFor="checkEmpresaTempoAtuacao"> &nbsp; Tempo de atuação</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAtuaSomenteSetorArtesanal' checked={this.state.checkEmpresaAtuaSomenteSetorArtesanal} onChange={this.handleCheckEmpresaAtuaSomenteSetorArtesanal}/>
                                                                        <label htmlFor="checkEmpresaAtuaSomenteSetorArtesanal"> &nbsp; Atua somente no setor</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimento' checked={this.state.checkEmpresaAreaAtendimento} onChange={this.handleCheckEmpresaAreaAtendimento}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimento"> &nbsp; Área de atendimento</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkEmpresaPublicoAlvo' checked={this.state.checkEmpresaPublicoAlvo} onChange={this.handleCheckEmpresaPublicoAlvo}/>
                                                                        <label htmlFor="checkEmpresaPublicoAlvo"> &nbsp; Destinado a</label>
                                                                    </div>
                                                                </div>                 
                                                                <hr style={{display:'none'}}/>                                           
                                                                <div className="row mt-3" style={{display:'none'}}>
                                                                    <div className="col">
                                                                        <label><b>ÁREA DE ATENDIMENTO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3" style={{display:'none'}}>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoNenhum' checked={this.state.checkEmpresaAreaAtendimentoNenhum} onChange={this.handleCheckEmpresaAreaAtendimentoNenhum}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoNenhum"> &nbsp; Nenhum</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoCapacitacao' checked={this.state.checkEmpresaAreaAtendimentoCapacitacao} onChange={this.handleCheckEmpresaAreaAtendimentoCapacitacao}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoCapacitacao"> &nbsp; Capacitação</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoAcessoAMercado' checked={this.state.checkEmpresaAreaAtendimentoAcessoAMercado} onChange={this.handleCheckEmpresaAreaAtendimentoAcessoAMercado}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoAcessoAMercado"> &nbsp; Acesso a mercado</label>
                                                                    </div>                                                                    
                                                                </div>                                                            
                                                                <div className="row my-3" style={{display:'none'}}>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoEstudosEPesquisas' checked={this.state.checkEmpresaAreaAtendimentoEstudosEPesquisas} onChange={this.handleCheckEmpresaAreaAtendimentoEstudosEPesquisas}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoEstudosEPesquisas"> &nbsp; Estudos e pesquisas</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoAcessoACredito' checked={this.state.checkEmpresaAreaAtendimentoAcessoACredito} onChange={this.handleCheckEmpresaAreaAtendimentoAcessoACredito}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoAcessoACredito"> &nbsp; Acesso a credito</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoInovacaoETecnologia' checked={this.state.checkEmpresaAreaAtendimentoInovacaoETecnologia} onChange={this.handleCheckEmpresaAreaAtendimentoInovacaoETecnologia}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoInovacaoETecnologia"> &nbsp; Inovacao e tecnologia</label>
                                                                    </div>                                                                    
                                                                </div>                                                            
                                                                <div className="row my-3" style={{display:'none'}}>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoInfraestrutura' checked={this.state.checkEmpresaAreaAtendimentoInfraestrutura} onChange={this.handleCheckEmpresaAreaAtendimentoInfraestrutura}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoInfraestrutura"> &nbsp; Infraestrutura</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoOutros' checked={this.state.checkEmpresaAreaAtendimentoOutros} onChange={this.handleCheckEmpresaAreaAtendimentoOutros}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoOutros"> &nbsp; Outros</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaAreaAtendimentoOutrosDetalhes' checked={this.state.checkEmpresaAreaAtendimentoOutrosDetalhes} onChange={this.handleCheckEmpresaAreaAtendimentoOutrosDetalhes}/>
                                                                        <label htmlFor="checkEmpresaAreaAtendimentoOutrosDetalhes"> &nbsp; Outros - Detalhes</label>
                                                                    </div>                                                                    
                                                                </div>
                                                                <hr  style={{display:'none'}}/>                                           
                                                                <div className="row mt-3" style={{display:'none'}} >
                                                                    <div className="col">
                                                                        <label><b>DESTINADO A</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3" style={{display:'none'}}>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaDestinadoAArtesao' checked={this.state.checkEmpresaDestinadoAArtesao} onChange={this.handleCheckEmpresaDestinadoAArtesao}/>
                                                                        <label htmlFor="checkEmpresaDestinadoAArtesao"> &nbsp; Artesãos</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaDestinadoAAssociacoes' checked={this.state.checkEmpresaDestinadoAAssociacoes} onChange={this.handleCheckEmpresaDestinadoAAssociacoes}/>
                                                                        <label htmlFor="checkEmpresaDestinadoAAssociacoes"> &nbsp; Associações</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaDestinadoAGestores'  checked={this.state.checkEmpresaDestinadoAGestores} onChange={this.handleCheckEmpresaDestinadoAGestores}/>
                                                                        <label htmlFor="checkEmpresaDestinadoAGestores"> &nbsp; Gestores</label>
                                                                    </div>                                                                    
                                                                </div>                                                            
                                                                <div className="row my-3" style={{display:'none'}}>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaDestinadoAFornecedores' checked={this.state.checkEmpresaDestinadoAFornecedores} onChange={this.handleCheckEmpresaDestinadoAFornecedores}/>
                                                                        <label htmlFor="checkEmpresaDestinadoAFornecedores"> &nbsp; Fornecedores</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaDestinadoAOutros' checked={this.state.checkEmpresaDestinadoAOutros} onChange={this.handleCheckEmpresaDestinadoAOutros}/>
                                                                        <label htmlFor="checkEmpresaDestinadoAOutros"> &nbsp; Outros</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkEmpresaDestinadoAOutrosDetalhes' checked={this.state.checkEmpresaDestinadoAOutrosDetalhes} onChange={this.handleCheckEmpresaDestinadoAOutrosDetalhes}/>
                                                                        <label htmlFor="checkEmpresaDestinadoAOutrosDetalhes"> &nbsp; Outros - Detalhes</label>
                                                                    </div>                                                                    
                                                                </div> 
                                                            </TabPanel>
                                                            <TabPanel id="dirigente">
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>IDENTIFICAÇÃO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-12 col-md-12">                                                        
                                                                        <input type="checkbox" id='checkDirigenteGenero' checked={this.state.checkDirigenteGenero} onChange={this.handleCheckDirigenteGenero}/>
                                                                        <label htmlFor="checkDirigenteGenero"> &nbsp; Gênero</label>
                                                                    </div>                                                   
                                                                </div>
                                                                <hr/>
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>GESTÃO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-6 col-md-6">                                                        
                                                                        <input type="checkbox" id='checkDirigenteCargo' checked={this.state.checkDirigenteCargo} onChange={this.handleCheckDirigenteCargo}/>
                                                                        <label htmlFor="checkDirigenteCargo"> &nbsp; Cargo / Função</label>
                                                                    </div>              
                                                                    <div className = "col-xs-12 col-sm-6 col-md-6">                                                        
                                                                        <input type="checkbox" id='checkDirigentePeriodo' checked={this.state.checkDirigentePeriodo} onChange={this.handleCheckDirigentePeriodo}/>
                                                                        <label htmlFor="checkDirigentePeriodo"> &nbsp; Período de gestão</label>
                                                                    </div>                                                   
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel id="responsavel">
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>IDENTIFICAÇÃO / LOCALIZAÇÃO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkResponsavelGenero' checked={this.state.checkResponsavelGenero} onChange={this.handleCheckResponsavelGenero}/>
                                                                        <label htmlFor="checkResponsavelGenero"> &nbsp; Gênero</label>
                                                                    </div> 
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkResponsavelCidade' checked={this.state.checkResponsavelCidade} onChange={this.handleCheckResponsavelCidade}/>
                                                                        <label htmlFor="checkResponsavelCidade"> &nbsp; Cidade</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkResponsavelEstado' checked={this.state.checkResponsavelEstado} onChange={this.handleCheckResponsavelEstado}/>
                                                                        <label htmlFor="checkResponsavelEstado"> &nbsp; Estado</label>
                                                                    </div>                                                   
                                                                </div>
                                                                <hr/>
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>REDES SOCIAIS</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkResponsavelLinkedIn' checked={this.state.checkResponsavelLinkedIn} onChange={this.handleCheckResponsavelLinkedIn}/>
                                                                        <label htmlFor="checkResponsavelLinkedIn"> &nbsp; Possui LinkedIn</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkResponsavelFacebook' checked={this.state.checkResponsavelFacebook} onChange={this.handleCheckResponsavelFacebook}/>
                                                                        <label htmlFor="checkResponsavelFacebook"> &nbsp; Possui Facebook</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkResponsavelInstagram' checked={this.state.checkResponsavelInstagram} onChange={this.handleCheckResponsavelInstagram}/>
                                                                        <label htmlFor="checkResponsavelInstagram"> &nbsp; Possui Instagram</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkResponsavelTwitter' checked={this.state.checkResponsavelTwitter}  onChange={this.handleCheckResponsavelTwitter}/>
                                                                        <label htmlFor="checkResponsavelTwitter"> &nbsp; Possui Twitter</label>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel id="interlocutor">
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>IDENTIFICAÇÃO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-12 col-md-12">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorGenero' checked={this.state.checkInterlocutorGenero} onChange={this.handleCheckInterlocutorGenero}/>
                                                                        <label htmlFor="checkInterlocutorGenero"> &nbsp; Gênero</label>
                                                                    </div>
                                                                </div>
                                                                <hr/>
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>GESTÃO</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorCargo' checked={this.state.checkInterlocutorCargo} onChange={this.handleCheckInterlocutorCargo}/>
                                                                        <label htmlFor="checkInterlocutorCargo"> &nbsp; Cargo / Função</label>
                                                                    </div>              
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorTempoAtuacao' checked={this.state.checkInterlocutorTempoAtuacao} onChange={this.handleCheckInterlocutorTempoAtuacao}/>
                                                                        <label htmlFor="checkInterlocutorTempoAtuacao"> &nbsp; Tempo de atuação</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorFormaAtuacao' checked={this.state.checkInterlocutorFormaAtuacao} onChange={this.handleCheckInterlocutorFormaAtuacao}/>
                                                                        <label htmlFor="checkInterlocutorFormaAtuacao"> &nbsp; Forma de atuação</label>
                                                                    </div>                                                   
                                                                </div>
                                                                <hr/>
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>REDES SOCIAIS</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorLinkedIn' checked={this.state.checkInterlocutorLinkedIn} onChange={this.handleCheckInterlocutorLinkedIn}/>
                                                                        <label htmlFor="checkInterlocutorLinkedIn"> &nbsp; Possui LinkedIn</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorFacebook' checked={this.state.checkInterlocutorFacebook} onChange={this.handleCheckInterlocutorFacebook}/>
                                                                        <label htmlFor="checkInterlocutorFacebook"> &nbsp; Possui Facebook</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorInstagram' checked={this.state.checkInterlocutorInstagram} onChange={this.handleCheckInterlocutorInstagram}/>
                                                                        <label htmlFor="checkInterlocutorInstagram"> &nbsp; Possui Instagram</label>
                                                                    </div>
                                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                                        <input type="checkbox" id='checkInterlocutorTwitter' checked={this.state.checkInterlocutorTwitter}  onChange={this.handleCheckInterlocutorTwitter}/>
                                                                        <label htmlFor="checkInterlocutorTwitter"> &nbsp; Possui Twitter</label>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel id="cruzamento">
                                                                <div className="row mt-3" >
                                                                    <div className="col">
                                                                        <label><b>QUESTIONAMENTOS</b> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-12 col-md-12">                                                        
                                                                        <input type="checkbox" id='checkCruzamentoDirigenteIgualResponsavel' checked={this.state.checkCruzamentoDirigenteIgualResponsavel} onChange={this.handleCheckCruzamentoDirigenteIgualResponsavel}/>
                                                                        <label htmlFor="checkCruzamentoDirigenteIgualResponsavel"> &nbsp; Dirigente também é responsável pelo atendimento?</label>
                                                                    </div>
                                                                </div> 
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-12 col-md-12">                                                        
                                                                        <input type="checkbox" id='checkCruzamentoDirigenteIgualInterlocutor' checked={this.state.checkCruzamentoDirigenteIgualInterlocutor} onChange={this.handleCheckCruzamentoDirigenteIgualInterlocutor}/>
                                                                        <label htmlFor="checkCruzamentoDirigenteIgualInterlocutor"> &nbsp; Dirigente também é interlocutor?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className = "col-xs-12 col-sm-12 col-md-12">                                                        
                                                                        <input type="checkbox" id='checkCruzamentoResponsavelgualInterlocutor' checked={this.state.checkCruzamentoResponsavelgualInterlocutor} onChange={this.handleCheckCruzamentoResponsavelgualInterlocutor}/>
                                                                        <label htmlFor="checkCruzamentoResponsavelgualInterlocutor"> &nbsp; Responsável também é interlocutor?</label>
                                                                    </div>
                                                                </div> 
                                                            </TabPanel>
                                                        </Tabs>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row my-3">
                                                    <div className="col d-flex flex-row-reverse  align-items-center">
                                                        <button type="submit" className="btn btn-primary my-3"
                                                            onClick={this.handleSubmitExportar} id="botaoExportar">
                                                                EXPORTAR
                                                        </button>
                                                    </div>
                                                </div>                                                                                                
                                            </form>
                                        </div>
                                    </div>  
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>  
            </div>              
        );
    }


}

export default ExportarPessoaJuridica;