import React from 'react';
import ComboEstados from "../ComboEstados";
import MaskedInput from '../InputMask';

class ModalDocumentoEdit extends React.Component{
    
    constructor(props){
            super(props);

            this.limparDataTramitacao = this.limparDataTramitacao.bind(this);
            this.handleChangeNome = this.handleChangeNome.bind(this);
            this.handleChangeEmail = this.handleChangeEmail.bind(this);
            this.handleChangeCargo = this.handleChangeCargo.bind(this);
            this.handleChangeInstituicao = this.handleChangeInstituicao.bind(this);
            this.handleChangeRua = this.handleChangeRua.bind(this);
            this.handleChangeNumero = this.handleChangeNumero.bind(this);
            this.handleChangeComplemento = this.handleChangeComplemento.bind(this);
            this.handleChangeBairro = this.handleChangeBairro.bind(this);
            this.handleChangeCidade = this.handleChangeCidade.bind(this);            
            this.handleChangeEstado = this.handleChangeEstado.bind(this);            
            this.handleChangeCep = this.handleChangeCep.bind(this);
            this.soNumeros = this.soNumeros.bind(this);
                    
            this.state = {   
                    id: props.tramitacao.id, 
                    nomeDestinatario: props.tramitacao.nomeDestinatario,
                    email: props.tramitacao.email,
                    cargo: props.tramitacao.cargo,
                    instituicao: props.tramitacao.instituicao,
                    rua: props.tramitacao.rua,
                    numero: props.tramitacao.numero,
                    complemento: props.tramitacao.complemento,
                    bairro: props.tramitacao.bairro,
                    cidade: props.tramitacao.cidade,
                    estado: props.tramitacao.estado,
                    cep: props.tramitacao.cep,
                    data: props.tramitacao.data,
                    responsavel: props.tramitacao.responsavel
            };
    
    }
    
    handleChangeNome(e){
        this.setState({nomeDestinatario: e.target.value});
    }

    handleChangeEmail(e){
        this.setState({email: e.target.value});
    }

    handleChangeCargo(e){
        this.setState({cargo: e.target.value});
    }

    handleChangeInstituicao(e){
        this.setState({instituicao: e.target.value});
    }

    handleChangeRua(e){
        this.setState({rua: e.target.value});
    }

    handleChangeNumero(e){
        this.setState({numero: e.target.value});
    }

    handleChangeComplemento(e){
        this.setState({complemento: e.target.value});
    }

    handleChangeBairro(e){
        this.setState({bairro: e.target.value});
    }

    handleChangeCidade(e){
        this.setState({cidade: e.target.value});
    }

    handleChangeEstado(e){
        this.setState({estado: e.target.value});
    }
    
    soNumeros(str){
        return str.replace(/[^0-9]/g, '');
    }

    handleChangeCep(e){        
        this.setState({cep: this.soNumeros(e.target.value)});
    }


    limparDataTramitacao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({data: ''});
            document.getElementById("dataTramitacao").value = '';
        }
    }


    render(){
        return (     
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                            <label htmlFor="dataTramitacao">Data envio:</label>
                                            <input type="date" className="form-control" id="dataTramitacao" onKeyUp={this.limparDataTramitacao} />
                                            <input type="hidden" className="form-control" id="idTramitacao"/>
                                            <input type="hidden" className="form-control" id="responsavelTramitacao"/>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className = "col-xs-12 col-sm-12 col-md-7">                                        
                                            <label htmlFor="nome">Destinatário:</label>
                                            <input type="text" className="form-control" id="nomeDestinatario" onChange={this.handleChangeNome}/>
                                        </div>
                                        <div className = "col-xs-12 col-sm-12 col-md-5">
                                            <label htmlFor="email">E-mail</label>
                                            <input type="text" className="form-control" id="emailDestinatario" onChange={this.handleChangeEmail}/>
                                        </div>                                    
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-xs-12 col-sm-12 col-md-5">
                                            <label htmlFor="cargo">Cargo</label>
                                            <input type="text" className="form-control" id="cargo" onChange={this.handleChangeCargo}/>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-7">
                                            <label htmlFor="instituicao">Instituição</label>
                                            <input type="text" className="form-control" id="instituicao" onChange={this.handleChangeInstituicao}/>
                                        </div>                                    
                                    </div>                                
                                    <div className="row my-3">
                                        <div className = "col-xs-12 col-sm-12 col-md-6">
                                            <label htmlFor="rua">Rua</label>
                                            <input type="text" className="form-control" id="rua" onChange={this.handleChangeRua}/>
                                        </div>
                                        <div className = "col-xs-12 col-sm-12 col-md-2">
                                            <label htmlFor="numero">Número</label>
                                            <input type="text" className="form-control" id="numero" onChange={this.handleChangeNumero}/>
                                        </div>
                                        <div className = "col-xs-12 col-sm-12 col-md-4">
                                            <label htmlFor="complemento">Complemento</label>
                                            <input type="text" className="form-control" id="complemento" onChange={this.handleChangeComplemento}/>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <label htmlFor="bairro">Bairro</label>
                                            <input type="text" className="form-control" id="bairro" onChange={this.handleChangeBairro}/>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <label htmlFor="cidade">Cidade</label>
                                            <input type="text" className="form-control" id="cidade" onChange={this.handleChangeCidade}/>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <ComboEstados id="estadoDadosEnvio" onChange={this.handleChangeEstado}></ComboEstados>
                                        <div className="col-xs-12 col-sm-12 col-md-4">
                                            <label htmlFor="cep">CEP</label>
                                            <MaskedInput mask="99.999-999" className="form-control" id="cep" onChange={this.handleChangeCep}></MaskedInput>
                                        </div>
                                    </div>
                                </div>
                            
        );
    }
}
export default ModalDocumentoEdit;