import { Component } from "react";
import { GlobalContext } from "../../Context/GlobalContext/context";
import "./style.css";

export class SpinnerContainer extends Component {

    static contextType = GlobalContext;

    render(){

        const { loading } = this.context;
        
        return (
            <>
                {!!loading && <div className="container-spinner">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
            </>
        )
    }
}