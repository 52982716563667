function Titulo(props) {

    const { tooltip } = props;

    return (
        <div className={props.className}>
            <h4>
                {props.name}
                {
                    tooltip &&
                        <i 
                            className="far fa-question-circle p-2" 
                            data-tip={ tooltip.texto } 
                            data-place={tooltip.direcao} 
                            data-html={true}
                            data-effect="solid"
                        ></i>
                }
                <span>{props.tituloDocumento}</span>
            </h4>
        </div>
    );
}
export default Titulo;