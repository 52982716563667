import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Titulo from '../../../Components/Titulo';
import LogoParceriaInterna from '../../../Components/LogoParceria/LogoParceriaInterna';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ModalDocumentoEdit from '../../../Components/Modal/ModalDocumentoEdit';
import DataUtil from '../../../Components/Data';
import '../../../css/style.css';
import { apiURL } from '../../../Services/env';
import { api } from "../../../Services/Api";
import MaskedInput from '../../../Components/InputMask';
import ComboEstados from '../../../Components/ComboEstados';
import ModalAdicionarEntrevistador from '../../../Components/Modal/ModalAdicionarEntrevistador';
import { Link }  from 'react-router-dom';
import ModalEnviarEmailAvulso from '../../../Components/Modal/ModalEnviarEmailAvulso';
import ValidacaoEmail, { validarHora, parseJwt, validarURLDoc } from '../../../Components/Validacao';
import ListaParticipantes from '../../../Components/Lista/ListaParticipantes';
import { GlobalContext } from '../../../Context/GlobalContext/context';
import * as permissions from "../../../Context/GlobalContext/permissions";

class DocumentoEdit extends React.Component {

    static contextType = GlobalContext;

	constructor(props){
        super(props);

        this.iniciarModalAnexo = this.iniciarModalAnexo.bind(this);
        this.atualizarAnexo = this.atualizarAnexo.bind(this);
        this.atualizarDocumento = this.atualizarDocumento.bind(this);
        this.montarTela = this.montarTela.bind(this);
        this.limparDataAssinatura = this.limparDataAssinatura.bind(this);
        this.limparDataAprovacao = this.limparDataAprovacao.bind(this);
        this.limparDataReuniao = this.limparDataReuniao.bind(this);
        this.handleNumeroDocumentoExterno = this.handleNumeroDocumentoExterno.bind(this);
        this.handleRemetente = this.handleRemetente.bind(this);
        this.atualizarTramitacao = this.atualizarTramitacao.bind(this);
        this.atualizarTela = this.atualizarTela.bind(this);
        this.atualizarTelaDocumentoSolicitado = this.atualizarTelaDocumentoSolicitado.bind(this);
        this.atualizarTelaAposCriacao = this.atualizarTelaAposCriacao.bind(this);
        this.atualizarTelaAposAtualizacao = this.atualizarTelaAposAtualizacao.bind(this);
        this.handleSubmitCriar = this.handleSubmitCriar.bind(this);
        this.handleSubmitAtualizar = this.handleSubmitAtualizar.bind(this);
        this.handleAtualizar = this.handleAtualizar.bind(this);
        this.handleChangeTemplate = this.handleChangeTemplate.bind(this);
        this.handleChangeEmissor = this.handleChangeEmissor.bind(this);
        this.handleChangeTitulo = this.handleChangeTitulo.bind(this);
        this.handleChangeLinkNovo = this.handleChangeLinkNovo.bind(this);
        this.handleChangeArquivoNovo = this.handleChangeArquivoNovo.bind(this);
        this.handleChangeObservacao = this.handleChangeObservacao.bind(this);
        this.salvarTramitacao = this.salvarTramitacao.bind(this);
        this.recuperarDocumento = this.recuperarDocumento.bind(this);
        this.montarDocumento = this.montarDocumento.bind(this);
        this.montarDocumentoSolicitado = this.montarDocumentoSolicitado.bind(this);
        this.salvarAnexo = this.salvarAnexo.bind(this);
        this.handleChangeDataAprovacao = this.handleChangeDataAprovacao.bind(this);
        this.handleChangeDataAssinatura = this.handleChangeDataAssinatura.bind(this);
        this.handleChangeDataReuniao = this.handleChangeDataReuniao.bind(this);
        this.handleChangeDataEntrevista = this.handleChangeDataEntrevista.bind(this);
        this.handleOnChangeIdAnexo = this.handleOnChangeIdAnexo.bind(this);
        this.handleOnChangeLinkAnexo = this.handleOnChangeLinkAnexo.bind(this);
        this.handleOnChangeDescricaoAnexo = this.handleOnChangeDescricaoAnexo.bind(this);
        this.handleOnChangeArquivoAnexo = this.handleOnChangeArquivoAnexo.bind(this);
        this.limparCamposAnexo = this.limparCamposAnexo.bind(this);
        this.inicializarCombos = this.inicializarCombos.bind(this);
        this.limparTemplate = this.limparTemplate.bind(this);
        this.validaData = this.validaData.bind(this);
        this.handleSubmitArquivar = this.handleSubmitArquivar.bind(this);
        this.handleSubmitRejeitarDocumento = this.handleSubmitRejeitarDocumento.bind(this);
        this.handleChangeHoraInicio = this.handleChangeHoraInicio.bind(this);
        this.handleChangeHoraTermino = this.handleChangeHoraTermino.bind(this);
        this.handleChangeHoraInicioEntrevista = this.handleChangeHoraInicioEntrevista.bind(this);
        this.handleChangeHoraTerminoEntrevista = this.handleChangeHoraTerminoEntrevista.bind(this);
        this.handleOnChangeCidadeEntrevista = this.handleOnChangeCidadeEntrevista.bind(this);
        this.handleOnChangeUFEntrevista = this.handleOnChangeUFEntrevista.bind(this);
        this.handleChangeCpfEntrevistador = this.handleChangeCpfEntrevistador.bind(this);
        this.handleChangeCpfEntrevistado = this.handleChangeCpfEntrevistado.bind(this);
        this.limparDataEntrevista = this.limparDataEntrevista.bind(this);
        this.handleChangeNomeEntrevistado = this.handleChangeNomeEntrevistado.bind(this);
        this.handleChangeEmailEntrevistado = this.handleChangeEmailEntrevistado.bind(this);
        this.handleChangeTipoInstituicaoEntrevistado = this.handleChangeTipoInstituicaoEntrevistado.bind(this);
        this.handleChangeInstituicaoEntrevistado = this.handleChangeInstituicaoEntrevistado.bind(this);
        this.handleChangeEntrevistador = this.handleChangeEntrevistador.bind(this);
        this.handleChangeIdEntrevistador = this.handleChangeIdEntrevistador.bind(this);
        this.handleChangeEmailEntrevistador = this.handleChangeEmailEntrevistador.bind(this);
        this.carregarDadosEquipe = this.carregarDadosEquipe.bind(this);
        this.preencherCamposDadosEntrevista = this.preencherCamposDadosEntrevista.bind(this);
        this.bloquearCamposEntrevista = this.bloquearCamposEntrevista.bind(this);
        this.handleBotaoVoltar = this.handleBotaoVoltar.bind(this);
        this.validarCamposEntrevista = this.validarCamposEntrevista.bind(this);
        this.handleSolicitarAprovacao = this.handleSolicitarAprovacao.bind(this);
        this.handleAprovarDocumento = this.handleAprovarDocumento.bind(this);
        this.atualizarStatusDocumento = this.atualizarStatusDocumento.bind(this);
        this.handleSituacaoDocumento = this.handleSituacaoDocumento.bind(this);
        this.bloquearCamposTela = this.bloquearCamposTela.bind(this);
        this.validarCamposReuniao = this.validarCamposReuniao.bind(this);
        this.handleChangeNomeResponsavel = this.handleChangeNomeResponsavel.bind(this);
        this.handleChangeEmailResponsavel = this.handleChangeEmailResponsavel.bind(this);
        this.removerParticipante = this.removerParticipante.bind(this);
        this.adicionarParticipante = this.adicionarParticipante.bind(this);
        this.estruturarParticipante = this.estruturarParticipante.bind(this);

        this._isMounted = false;
        this.referencia = React.createRef();
        this.mensagemValidacao = '';
        
        this.state = {
            id: (props.location.state !==null && props.location.state!== undefined && props.location.state.hasOwnProperty('id') === true) ? props.location.state.id : 0,
            origem: (props.location.state !==null && props.location.state!== undefined && props.location.state.hasOwnProperty('origem') === true) ? props.location.state.origem : 'pesquisar',
            token: localStorage.getItem("projartToken"),
            cpf: localStorage.getItem("projartCod"),
            papeis: localStorage.getItem('projartPapeis'),
            documento:'',
            codigoTemplate: 0,
            template: '',
            emissor: '',
            templates: '',
            emissores: '',
            movimentacao:'',
            nome: localStorage.getItem('projartUsuario'),
            titulo: '',
            tituloAux: '',
            situacaoDocumento: 'MINUTA',
            numeroDocumento: '',
            numeroDocumentoExterno: '',
            remetente: '',
            dataCriacao: '',
            dataAprovacao:'',
            dataAssinatura:'',
            dataReuniao:'',
            codigoDocumento: '',
            linkDocumento: '',
            idGoogle:'',
            linkNovo: '',
            arquivoNovo: '',
            observacao: '',
            tramitacao: {
                id:'', 
                nomeDestinatario:'',
                email:'',
                cargo:'',
                instituicao:'',
                rua:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                cep:'',
                data:'',
                responsavel: ''
            },
            anexos:[],
            anexosRemove: [],
            anexosAdd: [],
            anexo: {
                id: '',
                link: '',
                arquivo: '',
                descricao: ''
            },
            linkAnexo:'',
            arquivoAnexo:'',
            idAnexo:'',
            descricaoAnexo:'',
            tituloPagina: '',
            fileList: [],
            pastaId: '',
            file: {
                name: '',
                size: '',
                type: '',
                conteudo:''
            },
            fileAnexo: {
                name: '',
                size: '',
                type: '',
                conteudo:''
            },
            arquivoInformado: '',
            hoje:'',
            atualizando:false,
            nomeSolicitante:'',
            emailSolicitante:'',
            horaInicio: '',
            horaTermino: '',
            cidadeEntrevista: '',
            ufEntrevista: '',
            dataEntrevista: '',
            horaInicioEntrevista: '',
            horaTerminoEntrevista: '',
            nomeCoordenador:'',
            emailCoordenador:'',
            idEntrevistador: '',
            nomeEntrevistador: '',
            emailEntrevistador:'',
            cpfEntrevistador:'',
            idEntrevistadorExtra: '',
            nomeEntrevistadorExtra: '',
            emailEntrevistadorExtra:'',
            cpfEntrevistadorExtra:'',
            nomeEntrevistado: '',
            emailEntrevistado:'',
            cpfEntrevistado:'',
            instituicaoEntrevistado:'',
            tipoInstituicaoEntrevistado:'',
            entrevistadores:[],
            entrevistadoresExtra:[],
            origemEmail: 'botao',
            nomeResponsavel: '',
            emailResponsavel: '',
            participantes:[],
            removerParticipantes: [],
            adicionarParticipantes: [],
            editarObservacao:false,
            countObs: 0,
            isSolicitante: false
        };

             
    }

    DataAtual(){
        
        let today = new Date().toISOString().split('T')[0];
        document.getElementById("dataAssinatura")?.setAttribute('max', today);
        document.getElementById("dataAprovacao")?.setAttribute('max', today);
        
    }

    removerParticipante(participante){
        
        if(this.state.id === 0){

            let participantesAdd = [];
            this.state.adicionarParticipantes.forEach(
                participanteAdd => {
                    //console.log(anexoAdd);
                    if(participanteAdd.id !== participante.id){
                        participantesAdd.push(participanteAdd);                                    
                    }
                }
            );
            this.setState({adicionarParticipantes: participantesAdd});
        }
        else{
            let participantesRemover = this.state.removerParticipantes.splice(0);
            participantesRemover.push(participante);
            this.setState({removerParticipantes: participantesRemover});
        }
    }

    adicionarParticipante(participante){
        
        let participantesAdicionar = this.state.adicionarParticipantes.splice(0);
        participantesAdicionar.push(participante);
        this.setState({adicionarParticipantes: participantesAdicionar});
                
    }

    limparCamposTramitacao(){

        let nomeDestinatario = document.getElementById('nomeDestinatario');
        nomeDestinatario.value = '';

        let email = document.getElementById('emailDestinatario');
        email.value = '';

        let cargo = document.getElementById('cargo');
        cargo.value = '';

        let instituicao = document.getElementById('instituicao');
        instituicao.value = '';

        let rua = document.getElementById('rua');
        rua.value = '';

        let numero = document.getElementById('numero');
        numero.value = '';

        let complemento = document.getElementById('complemento');
        complemento.value = '';

        let bairro = document.getElementById('bairro');
        bairro.value = '';

        let cidade = document.getElementById('cidade');
        cidade.value = '';

        let estado = document.getElementById('estadoDadosEnvio');
        estado.value = '--';

        let cep = document.getElementById('cep');
        cep.value = '';

        let dataTramitacao = document.getElementById('dataTramitacao');
        dataTramitacao.value = '';

        let responsavelTramitacao = document.getElementById('responsavelTramitacao');
        responsavelTramitacao.value = '';

    }

    async iniciarDocumento(){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token
            })
        };
        
        const response = await api(apiURL+"documentoSuporte", requestOptions);
        return await response.json();
            
    }

    estruturarTramitacao(tramite){
        //console.log(tramite);
        if(tramite !== undefined){
            let tramitacaoAux = {
                id: tramite["COD_TRAMITACAO_DOCUMENTO"], 
                nomeDestinatario: tramite["NOME_DEST"],
                email: tramite["EMAIL_DEST"],
                cargo: tramite["CARGO_DEST"],
                instituicao: tramite["INSTITUICAO_DEST"],
                rua: tramite["RUA"],
                numero: tramite["NUMERO"],
                complemento: tramite["COMPLEMENTO"],
                bairro: tramite["BAIRRO"],
                cidade: tramite["CIDADE"],
                estado: tramite["ESTADO"],
                cep: tramite["CEP"],
                data: tramite["DATA_ENVIO"],
                responsavel: tramite["RESPONSAVEL_ALTERACAO"]
            };

            this.setState({tramitacao: tramitacaoAux});
        }
    }

    estruturarParticipante(participantesRetornados){
        
        participantesRetornados.forEach((participante) =>{
            
                let participanteAux = {
                    id: participante["ID"], 
                    nome: participante["NOME"],                    
                    email: participante["EMAIL"]
                };     
        
                let participantesAux = this.state.participantes.slice(0);
                participantesAux.push(participanteAux);
                this.setState({participantes: participantesAux});        
            });


    }

    estruturarAnexo(anexosRetornados){

        anexosRetornados.forEach((anexo) =>{

                let anexoAux = {
                    id: anexo["COD_ANEXO_DOCUMENTO"], 
                    link: anexo["LINK"],
                    arquivo: {
                        name: anexo["NOME"],                    
                        size: '',
                        type: '',                    
                        conteudo: ''
                    },
                    descricao: anexo["DESCRICAO"]
                };     
                
                let anexosAux = this.state.anexos.slice(0);
                anexosAux.push(anexoAux);
                this.setState({anexos: anexosAux});        
            });


    }

    async recuperarDocumento(){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token,
                id: this.state.id
            })
        };

        const response = await api(apiURL+"documento/recuperar", requestOptions);
        return await response.json();
              
    }

    inicializarCombos(data){
        
        if(this._isMounted){
            if(data["mensagem"] !== 'sucesso'){
                alert(data["mensagem"]);
            }
            else{
                this.setState({templates: data["templates"]}); 
                this.setState({emissores: data["emissores"]});
                
                const selectTemplates = document.getElementById("template");
                let option = new Option('Selecione', '0');                                        
                selectTemplates.options[0] = option;
                let contador = 1;            
                this.state.templates.forEach(temp => {                   
                     
                    option = new Option(temp['nome'], temp['cod_template_documento']);                                        
                    selectTemplates.options[contador] = option;
                    contador++;

                });
                
                if(this.state.id === 0){
                    selectTemplates.remove(5);
                }

                const selectEmissores = document.getElementById("emissor");
                option = new Option('Selecione...', '0');
                selectEmissores.options[0] = option;
                contador = 1;
                this.state.emissores.forEach(temp => {
                    option = new Option(temp['sigla'], temp['cod_equipe']);
                    selectEmissores.options[contador] = option;
                    contador++;
                });
            }
        }

    }

	componentDidMount(){
        if (!this.context.getPermission(permissions.EDITAR_DOCUMENTOS) && 
            !this.context.getPermission(permissions.CRIAR_DOCUMENTOS)) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        this.context.startLoading();

        this._isMounted = true;

        this.setState({
            tituloPagina: 'Novo Documento',
            atualizando: false
        });

        this.iniciarDocumento().then(data =>{
            this.inicializarCombos(data);
            this.referencia.current.focus();      
        }).catch()
        .finally(() => this.context.stopLoading())
                
        if(this.state.id > 0){
            
            this.context.startLoading();

            this.DataAtual();

            let mensagemCriacao = document.getElementById("mensagemCriacao");
            mensagemCriacao.style.display = 'none';

            this.recuperarDocumento().then(data => {
                this.setState({
                    isSolicitante: (localStorage.getItem('projartCod') === data["documento"][0]['SOLICITANTE']),
                    situacaoDocumento: data["documento"][0]['SITUACAO']
                })
                if( data["documento"][0]["SITUACAO"] === 'SOLICITADO' ||
                    data["documento"][0]["SITUACAO"] === 'REJEITADO'){
                    this.montarDocumentoSolicitado(data);
                    this.atualizarTelaDocumentoSolicitado();
                
                }
                else{

                    this.setState({tituloPagina: 'Edição do Documento: '});            
                    this.montarDocumento(data);
                    this.atualizarTela();
                }
                this.referencia.current.focus();      
            })
            .finally(() => this.context.stopLoading())

        }
        

    }

    montarDocumentoSolicitado(data){
        //console.log(data);
        if(data["mensagem"] !== 'sucesso'){
            let erro = document.getElementById('erro');
            erro.innerText = data["mensagem"];
            erro.style.visibility = 'visible';
        }
        else{
            let dataCriacaoAux = data["documento"][0]["DATA_CRIACAO"].split(" ");
            //console.log(data);
            if(data["documento"][0]["ID_ENTREVISTADOR"] !== null && data["documento"][0]["ID_ENTREVISTADOR"] !== undefined ){
                const selectNomeEntrevistador = document.getElementById("nomeEntrevistador");
                let option = new Option(data["entrevistador"][0]["nome"], data["documento"][0]["ID_ENTREVISTADOR"]);                                        
                selectNomeEntrevistador.options[1] = option;
            }

            if(data["documento"][0]["ID_ENTREVISTADOR_EXTRA"] !== null && data["documento"][0]["ID_ENTREVISTADOR_EXTRA"] !== undefined ){
                const selectNomeEntrevistadorExtra = document.getElementById("nomeEntrevistadorExtra");
                let option = new Option(data["entrevistador"][1]["nome"], data["documento"][0]["ID_ENTREVISTADOR_EXTRA"]);                                        
                selectNomeEntrevistadorExtra.options[1] = option;
            }

            this.setState({
                documento: data["documento"],    
                ano: data["documento"][0]["ANO"],
                codigoTemplate: data["documento"][0]["COD_TEMPLATE_DOCUMENTO"],
                dataCriacao: DataUtil(dataCriacaoAux[0],'D'),
                observacao: data["documento"][0]["OBSERVACAO"],
                countObs: data["documento"][0]["OBSERVACAO"] !== null && data["documento"][0]["OBSERVACAO"] !== undefined ? data["documento"][0]["OBSERVACAO"].length : 0,
                emissor: data["documento"][0]["SIGLA_EMISSOR"],
                situacaoDocumento: data["documento"][0]["SITUACAO"],
                titulo: data["documento"][0]["TITULO"],
                linkDocumento: data["documento"][0]["LINK_DOCUMENTO"],
                numeroDocumentoExterno: data["documento"][0]["NUM_DOCUMENTO_EXTERNO"] !== null && data["documento"][0]["NUM_DOCUMENTO_EXTERNO"] !== undefined ? data["documento"][0]["NUM_DOCUMENTO_EXTERNO"] : '',
                remetente: data["documento"][0]["REMETENTE"] !== null && data["documento"][0]["REMETENTE"] !== undefined ? data["documento"][0]["REMETENTE"] : '',
                nomeSolicitante: data["solicitante"] !== null && data["solicitante"] !== undefined && data["solicitante"]["nome"] !== null && data["solicitante"]["nome"] !== undefined ? data["solicitante"]["nome"] : '',
                emailSolicitante: data["solicitante"] !== null && data["solicitante"] !== undefined && data["solicitante"]["email"] !== null && data["solicitante"]["email"] !== undefined ? data["solicitante"]["email"] : '',
                dataReuniao: data["documento"][0]["DATA_REUNIAO"],
                horaInicio: data["documento"][0]["HORA_INICIO_REUNIAO"] !== null && data["documento"][0]["HORA_INICIO_REUNIAO"] !== undefined ? data["documento"][0]["HORA_INICIO_REUNIAO"] : '',
                horaTermino: data["documento"][0]["HORA_TERMINO_REUNIAO"] !== null && data["documento"][0]["HORA_TERMINO_REUNIAO"] !== undefined ? data["documento"][0]["HORA_TERMINO_REUNIAO"] : '',
                dataEntrevista:data["documento"][0]["DATA_ENTREVISTA"],
                horaInicioEntrevista: data["documento"][0]["HORA_INICIO_ENTREVISTA"] !== null && data["documento"][0]["HORA_INICIO_ENTREVISTA"] !== undefined ? data["documento"][0]["HORA_INICIO_ENTREVISTA"] : '',
                horaTerminoEntrevista: data["documento"][0]["HORA_TERMINO_ENTREVISTA"] !== null && data["documento"][0]["HORA_TERMINO_ENTREVISTA"] !== undefined ? data["documento"][0]["HORA_TERMINO_ENTREVISTA"] : '',
                cidadeEntrevista: data["documento"][0]["CIDADE"] !== null && data["documento"][0]["CIDADE"] !== undefined ? data["documento"][0]["CIDADE"] : '',
                ufEntrevista: data["documento"][0]["UF"],
                nomeEntrevistado: data["entrevistado"] !== null && data["entrevistado"] !== undefined && data["entrevistado"]["nome"] !== null && data["entrevistado"]["nome"] !== undefined ? data["entrevistado"]["nome"] : '',
                emailEntrevistado: data["entrevistado"] !== null && data["entrevistado"] !== undefined && data["entrevistado"]["email"] !== null && data["entrevistado"]["email"] !== undefined ? data["entrevistado"]["email"] : '',
                cpfEntrevistado: data["entrevistado"] !== null && data["entrevistado"] !== undefined && data["entrevistado"]["cpf"] !== null && data["entrevistado"]["cpf"] !== undefined ? data["entrevistado"]["cpf"] : '',
                instituicaoEntrevistado: data["instituicao"] !== null && data["instituicao"] !== undefined && data["instituicao"]["nome"] !== null && data["instituicao"]["nome"] !== undefined ? data["instituicao"]["nome"] : '',
                tipoInstituicaoEntrevistado: data["instituicao"] !== null && data["instituicao"] !== undefined && data["instituicao"]["tipo"] !== null && data["instituicao"]["tipo"] !== undefined ? data["instituicao"]["tipo"] : '',
                idEntrevistador: data["documento"][0]["ID_ENTREVISTADOR"],
                idEntrevistadorExtra: data["documento"][0]["ID_ENTREVISTADOR_EXTRA"],
                nomeEntrevistador: data["documento"][0]["ID_ENTREVISTADOR"],
                nomeEntrevistadorExtra: data["documento"][0]["ID_ENTREVISTADOR_EXTRA"],
                emailEntrevistador: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][0] !== null && data["entrevistador"][0] !== undefined && data["entrevistador"][0]["email"] !== null && data["entrevistador"][0]["email"] !== undefined ? data["entrevistador"][0]["email"] : '',
                emailEntrevistadorExtra: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][1] !== null && data["entrevistador"][1] !== undefined && data["entrevistador"][1]["email"] !== null && data["entrevistador"][1]["email"] !== undefined ? data["entrevistador"][1]["email"] : '',
                cpfEntrevistador: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][0] !== null && data["entrevistador"][0] !== undefined && data["entrevistador"][0]["cpf"] !== null && data["entrevistador"][0]["cpf"] !== undefined ? data["entrevistador"][0]["cpf"] : '',
                cpfEntrevistadorExtra: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][1] !== null && data["entrevistador"][1] !== undefined && data["entrevistador"][1]["cpf"] !== null && data["entrevistador"][1]["cpf"] !== undefined ? data["entrevistador"][1]["cpf"] : '',
                nomeCoordenador: data["coordenador"] !== null && data["coordenador"] !== undefined && data["coordenador"]["NOME"] !== null && data["coordenador"]["NOME"] !== undefined ? data["coordenador"]["NOME"] : '',
                emailCoordenador: data["coordenador"] !== null && data["coordenador"] !== undefined && data["coordenador"]["EMAIL"] !== null && data["coordenador"]["EMAIL"] !== undefined ? data["coordenador"]["EMAIL"] : '',
                numeroDocumento: data["documento"][0]["NUM_DOCUMENTO"],
                nomeResponsavel: data["documento"][0]["NOME_RESPONSAVEL"] !== null && data["documento"][0]["NOME_RESPONSAVEL"] !== undefined ? data["documento"][0]["NOME_RESPONSAVEL"] : '',
                emailResponsavel: data["documento"][0]["EMAIL_RESPONSAVEL"] !== null && data["documento"][0]["EMAIL_RESPONSAVEL"] !== undefined ? data["documento"][0]["EMAIL_RESPONSAVEL"] : ''
            });
            
            this.estruturarTramitacao(data["tramitacao"][0]);
            this.estruturarParticipante(data["participantes"]);
        
        }    

    }

    montarDocumento(data){
        //console.log(data);
        if(data["mensagem"] !== 'sucesso'){
            let erro = document.getElementById('erro');
            erro.innerText = data["mensagem"];
            erro.style.visibility = 'visible';
        }
        else{

            let dataCriacaoAux = data["documento"][0]["DATA_CRIACAO"].split(" ");
            //console.log(data);
            if(data["documento"][0]["ID_ENTREVISTADOR"] !== null && data["documento"][0]["ID_ENTREVISTADOR"] !== undefined ){
                const selectNomeEntrevistador = document.getElementById("nomeEntrevistador");
                let option = new Option(data["entrevistador"][0]["nome"], data["documento"][0]["ID_ENTREVISTADOR"]);                                        
                selectNomeEntrevistador.options[1] = option;
            }

            if(data["documento"][0]["ID_ENTREVISTADOR_EXTRA"] !== null && data["documento"][0]["ID_ENTREVISTADOR_EXTRA"] !== undefined ){
                const selectNomeEntrevistadorExtra = document.getElementById("nomeEntrevistadorExtra");
                let option = new Option(data["entrevistador"][1]["nome"], data["documento"][0]["ID_ENTREVISTADOR_EXTRA"]);                                        
                selectNomeEntrevistadorExtra.options[1] = option;
            }

            this.setState({
                documento: data["documento"],    
                ano: data["documento"][0]["ANO"],
                codigoTemplate: data["documento"][0]["COD_TEMPLATE_DOCUMENTO"],
                idGoogle: data["documento"][0]["ID_DOC_GOOGLE"],
                linkDocumento: data["documento"][0]["LINK_DOCUMENTO"],
                dataCriacao: DataUtil(dataCriacaoAux[0],'D'),
                numeroDocumento: data["documento"][0]["NUM_DOCUMENTO"],
                numeroDocumentoExterno: data["documento"][0]["NUM_DOCUMENTO_EXTERNO"],
                remetente: data["documento"][0]["REMETENTE"],
                sequencial:data["documento"][0]["NUM_SEQUENCIAL"],
                observacao: data["documento"][0]["OBSERVACAO"],
                countObs: data["documento"][0]["OBSERVACAO"] !== null && data["documento"][0]["OBSERVACAO"] !== undefined ? data["documento"][0]["OBSERVACAO"].length : 0,
                emissor: data["documento"][0]["SIGLA_EMISSOR"],
                situacaoDocumento: data["documento"][0]["SITUACAO"],
                titulo: data["documento"][0]["TITULO"],
                dataAprovacao: data["documento"][0]["DATA_APROVACAO"] !== null && data["documento"][0]["DATA_APROVACAO"] !== undefined ? data["documento"][0]["DATA_APROVACAO"] : '',
                dataAssinatura: data["documento"][0]["DATA_ASSINATURA"],
                anexos: [],
                nomeSolicitante: data["solicitante"] !== null && data["solicitante"] !== undefined && data["solicitante"]["nome"] !== null && data["solicitante"]["nome"] !== undefined ? data["solicitante"]["nome"] : '',
                emailSolicitante: data["solicitante"] !== null && data["solicitante"] !== undefined && data["solicitante"]["email"] !== null && data["solicitante"]["email"] !== undefined ? data["solicitante"]["email"] : '',
                dataReuniao: data["documento"][0]["DATA_REUNIAO"],
                horaInicio: data["documento"][0]["HORA_INICIO_REUNIAO"] !== null && data["documento"][0]["HORA_INICIO_REUNIAO"] !== undefined ? data["documento"][0]["HORA_INICIO_REUNIAO"] : '',
                horaTermino: data["documento"][0]["HORA_TERMINO_REUNIAO"] !== null && data["documento"][0]["HORA_TERMINO_REUNIAO"] !== undefined ? data["documento"][0]["HORA_TERMINO_REUNIAO"] : '',
                dataEntrevista:data["documento"][0]["DATA_ENTREVISTA"],
                horaInicioEntrevista: data["documento"][0]["HORA_INICIO_ENTREVISTA"] !== null && data["documento"][0]["HORA_INICIO_ENTREVISTA"] !== undefined ? data["documento"][0]["HORA_INICIO_ENTREVISTA"] : '',
                horaTerminoEntrevista: data["documento"][0]["HORA_TERMINO_ENTREVISTA"] !== null && data["documento"][0]["HORA_TERMINO_ENTREVISTA"] !== undefined ? data["documento"][0]["HORA_TERMINO_ENTREVISTA"] : '',
                cidadeEntrevista: data["documento"][0]["CIDADE"] !== null && data["documento"][0]["CIDADE"] !== undefined ? data["documento"][0]["CIDADE"] : '',
                ufEntrevista: data["documento"][0]["UF"],
                nomeEntrevistado: data["entrevistado"] !== null && data["entrevistado"] !== undefined && data["entrevistado"]["nome"] !== null && data["entrevistado"]["nome"] !== undefined ? data["entrevistado"]["nome"] : '',
                emailEntrevistado: data["entrevistado"] !== null && data["entrevistado"] !== undefined && data["entrevistado"]["email"] !== null && data["entrevistado"]["email"] !== undefined ? data["entrevistado"]["email"] : '',
                cpfEntrevistado: data["entrevistado"] !== null && data["entrevistado"] !== undefined && data["entrevistado"]["cpf"] !== null && data["entrevistado"]["cpf"] !== undefined ? data["entrevistado"]["cpf"] : '',
                instituicaoEntrevistado: data["instituicao"] !== null && data["instituicao"] !== undefined && data["instituicao"]["nome"] !== null && data["instituicao"]["nome"] !== undefined ? data["instituicao"]["nome"] : '',
                tipoInstituicaoEntrevistado: data["instituicao"] !== null && data["instituicao"] !== undefined && data["instituicao"]["tipo"] !== null && data["instituicao"]["tipo"] !== undefined ? data["instituicao"]["tipo"] : '',
                idEntrevistador: data["documento"][0]["ID_ENTREVISTADOR"],
                idEntrevistadorExtra: data["documento"][0]["ID_ENTREVISTADOR_EXTRA"],
                nomeEntrevistador: data["documento"][0]["ID_ENTREVISTADOR"],
                nomeEntrevistadorExtra: data["documento"][0]["ID_ENTREVISTADOR_EXTRA"],
                emailEntrevistador: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][0] !== null && data["entrevistador"][0] !== undefined && data["entrevistador"][0]["email"] !== null && data["entrevistador"][0]["email"] !== undefined ? data["entrevistador"][0]["email"] : '',
                emailEntrevistadorExtra: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][1] !== null && data["entrevistador"][1] !== undefined && data["entrevistador"][1]["email"] !== null && data["entrevistador"][1]["email"] !== undefined ? data["entrevistador"][1]["email"] : '',
                cpfEntrevistador: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][0] !== null && data["entrevistador"][0] !== undefined && data["entrevistador"][0]["cpf"] !== null && data["entrevistador"][0]["cpf"] !== undefined ? data["entrevistador"][0]["cpf"] : '',
                cpfEntrevistadorExtra: data["entrevistador"] !== null && data["entrevistador"] !== undefined && data["entrevistador"][1] !== null && data["entrevistador"][1] !== undefined && data["entrevistador"][1]["cpf"] !== null && data["entrevistador"][1]["cpf"] !== undefined ? data["entrevistador"][1]["cpf"] : '',
                nomeCoordenador: data["coordenador"] !== null && data["coordenador"] !== undefined && data["coordenador"]["NOME"] !== null && data["coordenador"]["NOME"] !== undefined ? data["coordenador"]["NOME"] : '',
                emailCoordenador: data["coordenador"] !== null && data["coordenador"] !== undefined && data["coordenador"]["EMAIL"] !== null && data["coordenador"]["EMAIL"] !== undefined ? data["coordenador"]["EMAIL"] : '',
                nomeResponsavel: data["documento"][0]["NOME_RESPONSAVEL"] !== null && data["documento"][0]["NOME_RESPONSAVEL"] !== undefined ? data["documento"][0]["NOME_RESPONSAVEL"] : '',
                emailResponsavel: data["documento"][0]["EMAIL_RESPONSAVEL"] !== null && data["documento"][0]["EMAIL_RESPONSAVEL"] !== undefined ? data["documento"][0]["EMAIL_RESPONSAVEL"] : ''
            });

            this.estruturarAnexo(data["anexos"]);            
            this.estruturarTramitacao(data["tramitacao"][0]);
            this.estruturarParticipante(data["participantes"]);
        }    

    }

    componentWillUnmount(){
        this._isMounted = false;
        this.setState({atualizando:false});

        let erro = document.getElementById('erro');
        erro.style.display = 'none';

        let linkDocumentoGoogle = document.getElementById('linkDocumentoGoogle');
        linkDocumentoGoogle.style.display = 'none';
    }

    
    async criarDocumento(){

        let spinner = document.getElementById("spinner");
        spinner.style.display = 'block';

        let formData = new FormData();
        formData.append('token', this.state.token);
        formData.append('id',  this.state.id);
        formData.append('template',  this.state.template);
        formData.append('emissor',  this.state.emissor);
        formData.append('titulo',  this.state.titulo);

        if(this.state.observacao !== null && this.state.observacao !== undefined && this.state.observacao !== ''){
            formData.append('observacao',  this.state.observacao);
        }
        else{
            formData.append('observacao',  ' ');
        }
        formData.append('link', this.state.linkNovo);
        formData.append('arquivoInformado', this.state.arquivoInformado);
        formData.append('arquivo', this.state.file);
        formData.append('arquivoNovo', this.state.arquivoNovo);
        formData.append('responsavel',  localStorage.getItem("projartCod"));
        formData.append('remetente',  this.state.remetente);
        formData.append('numeroDocumentoExterno',  this.state.numeroDocumentoExterno);
        
        if(this.state.dataReuniao !== null && this.state.dataReuniao !== undefined && this.state.dataReuniao !== ''){
            formData.append('dataReuniao',  this.state.dataReuniao);
        }

        if(this.state.horaInicio !== null && this.state.horaInicio !== undefined && this.state.horaInicio !== ''){
            formData.append('horaInicio',  this.state.horaInicio);
        }
        
        if(this.state.horaTermino !== null && this.state.horaTermino !== undefined && this.state.horaTermino !== ''){
            formData.append('horaTermino',  this.state.horaTermino);
        }

        if(this.state.cidadeEntrevista !== null && this.state.cidadeEntrevista !== undefined && this.state.cidadeEntrevista !== ''){
            formData.append('cidade',  this.state.cidadeEntrevista);
        }

        if(this.state.ufEntrevista !== null && this.state.ufEntrevista !== undefined && this.state.ufEntrevista !== ''){
            formData.append('uf',  this.state.ufEntrevista);
        }

        if(this.state.dataEntrevista !== null && this.state.dataEntrevista !== undefined && this.state.dataEntrevista !== ''){
            formData.append('data_entrevista',  this.state.dataEntrevista);
        }

        if(this.state.horaInicioEntrevista !== null && this.state.horaInicioEntrevista !== undefined && this.state.horaInicioEntrevista !== ''){
            formData.append('hora_inicio_entrevista',  this.state.horaInicioEntrevista);
        }

        if(this.state.horaTerminoEntrevista !== null && this.state.horaTerminoEntrevista !== undefined && this.state.horaTerminoEntrevista !== ''){
            formData.append('hora_termino_entrevista',  this.state.horaTerminoEntrevista);
        }

        if(this.state.idEntrevistador !== null && this.state.idEntrevistador !== undefined && this.state.idEntrevistador !== ''){
            formData.append('id_entrevistador',  this.state.idEntrevistador);
        }

        if(this.state.idEntrevistadorExtra !== null && this.state.idEntrevistadorExtra !== undefined && this.state.idEntrevistadorExtra !== ''){
            formData.append('id_entrevistador_extra',  this.state.idEntrevistadorExtra);
        }

        if(this.state.nomeEntrevistado !== null && this.state.nomeEntrevistado !== undefined && this.state.nomeEntrevistado !== ''){
            formData.append('nome_entrevistado',  this.state.nomeEntrevistado);
        }

        if(this.state.emailEntrevistado !== null && this.state.emailEntrevistado !== undefined && this.state.emailEntrevistado !== ''){
            formData.append('email_entrevistado',  this.state.emailEntrevistado);
        }

        if(this.state.cpfEntrevistado !== null && this.state.cpfEntrevistado !== undefined && this.state.cpfEntrevistado !== ''){
            formData.append('cpf_entrevistado',  this.state.cpfEntrevistado);
        }

        if(this.state.instituicaoEntrevistado !== null && this.state.instituicaoEntrevistado !== undefined && this.state.instituicaoEntrevistado !== ''){
            formData.append('nome_instituicao_entrevistado',  this.state.instituicaoEntrevistado);
        }

        if(this.state.tipoInstituicaoEntrevistado !== null && this.state.tipoInstituicaoEntrevistado !== undefined && this.state.tipoInstituicaoEntrevistado !== ''){
            formData.append('tipo_instituicao_entrevistado',  this.state.tipoInstituicaoEntrevistado);
        }
        
        if(this.state.nomeResponsavel !== null && this.state.nomeResponsavel !== undefined && this.state.nomeResponsavel !== ''){
            formData.append('nome_responsavel',  this.state.nomeResponsavel);
        }

        if(this.state.emailResponsavel !== null && this.state.emailResponsavel !== undefined && this.state.emailResponsavel !== ''){
            formData.append('email_responsavel',  this.state.emailResponsavel);
        }

        let contador = 0;
        this.state.adicionarParticipantes.forEach(
            participanteAdd => {
                //console.log(anexoAdd);
                formData.append('participantesAdicionar['+contador+'][id]', participanteAdd.id);
                formData.append('participantesAdicionar['+contador+'][nome]', participanteAdd.nome);
                formData.append('participantesAdicionar['+contador+'][email]', participanteAdd.email);
                contador++;
            }
        );

        contador = 0;
        this.state.removerParticipantes.forEach(
            participanteRemover => {
                //console.log(participanteRemover);
                formData.append('participantesRemover['+contador+'][id]', participanteRemover.id);
                contador++;
            }
        );

        const requestOptions = {
            method: 'POST',
            body: formData
        }

/*
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;  charset=UTF-8' },
            body: JSON.stringify({ 
                token: this.state.token,
                id: this.state.id,
                template: this.state.template,
                emissor: this.state.emissor,
                titulo: this.state.titulo,
                observacao: this.state.observacao,
                link: this.state.linkNovo,
                arquivoInformado: this.state.arquivoInformado,
                arquivo: this.state.file,
                responsavel: localStorage.getItem("projartCod"),
                remetente: this.state.remetente,
                numeroDocumentoExterno: this.state.numeroDocumentoExterno
            })
                
        };
*/     
        const response = await api(apiURL+"documento", requestOptions);
        return await response.json();

    }

    atualizarTramitacao(){
        
        let dataTramitacao = document.getElementById('dataTramitacao');
        let responsavelTramitacao = document.getElementById('responsavelTramitacao');
        let idTramitacao = document.getElementById('idTramitacao');
        let nomeDestinatario = document.getElementById('nomeDestinatario');
        let email = document.getElementById('emailDestinatario');
        let cargo = document.getElementById('cargo');
        let instituicao = document.getElementById('instituicao');
        let rua = document.getElementById('rua');
        let numero = document.getElementById('numero');
        let complemento = document.getElementById('complemento');
        let bairro = document.getElementById('bairro');
        let cidade = document.getElementById('cidade');
        let estado = document.getElementById('estadoDadosEnvio');
        let cep = document.getElementById('cep');

        if(this.state.tramitacao["id"] !== null && this.state.tramitacao["id"] !== undefined 
            && this.state.tramitacao["id"] !== ''){
            
                idTramitacao.value = this.state.tramitacao["id"]; 
            nomeDestinatario.value = this.state.tramitacao["nomeDestinatario"];
            email.value = this.state.tramitacao["email"];
            cargo.value = this.state.tramitacao["cargo"]; 
            instituicao.value = this.state.tramitacao["instituicao"]; 
            rua.value = this.state.tramitacao["rua"];
            numero.value = this.state.tramitacao["numero"];
            complemento.value = this.state.tramitacao["complemento"];
            bairro.value = this.state.tramitacao["bairro"];
            cidade.value = this.state.tramitacao["cidade"];
            estado.value = this.state.tramitacao["estado"];
            cep.value = this.state.tramitacao["cep"];
            dataTramitacao.value = this.state.tramitacao["data"];
            responsavelTramitacao.value = this.state.tramitacao["responsavel"]; 
        }
    }

    atualizarTelaDocumentoSolicitado(){
        
        let template = document.getElementById('template');
        let emissor = document.getElementById('emissor');
        let botaoCriarDocumento = document.getElementById("botaoCriarDocumento");
        let botaoAtualizarDocumento = document.getElementById("botaoAtualizarDocumento");
        let abaEscolhaDocumento = document.getElementById("abaEscolhaDocumento");
        let linkDocumentoInformado = document.getElementById("linkDocumentoInformado");
        let titulo = document.getElementById("titulo");
        let observacao = document.getElementById("observacao");
        let numeroDocumentoExterno = document.getElementById('numeroDocumentoExterno');
        let remetente = document.getElementById('remetente');
        let camposDocumentoRecebido = document.getElementById('camposDocumentoRecebido');
        let solicitador = document.getElementById('solicitador');
        let linhaTramitacao = document.getElementById("linhaTramitacao");
        let dataReuniao = document.getElementById('dataReuniao');
        let linhaDataReuniao = document.getElementById("linhaDataReuniao");
        let horaInicio = document.getElementById('horaInicio');
        let horaTermino = document.getElementById('horaTermino');
        //let botaoArquivarDocumento = document.getElementById("botaoArquivarDocumento");
        let linhaLocalizacaoEntrevista = document.getElementById('linhaLocalizacaoEntrevista');
        let linhaDataEntrevista = document.getElementById('linhaDataEntrevista');
        let linhaCoordenador = document.getElementById('linhaCoordenador');
        let linhaEntrevistador = document.getElementById('linhaEntrevistador');
        let linhaEntrevistado = document.getElementById('linhaEntrevistado');
        let linhaAbas = document.getElementById('linhaAbas');
        let linhaObs = document.getElementById('linhaObs');
        let linhaFixos = document.getElementById('fixos');
        let numeroDocumento = document.getElementById('numeroDocumento');
        let linhaResponsavel = document.getElementById('linhaResponsavel');
        let linhaParticipantes = document.getElementById('linhaParticipantes');

        numeroDocumento.value = this.state.numeroDocumento;

        // if(this.state.origem === 'solicitacao'){
        //     botaoCriarDocumento.style.display = 'none';
        // }
        // else{
        //     botaoCriarDocumento.style.display = 'block';
        // }
        botaoAtualizarDocumento.style.display = 'none';

        if(this.state.situacaoDocumento === 'SOLICITADO'){
            //botaoArquivarDocumento.style.display = 'block';
            this.setState({tituloPagina: 'Novo Documento'});
        }
        else{
            this.setState({tituloPagina: 'Documento'});
        }

        numeroDocumentoExterno.value = this.state.numeroDocumentoExterno;
        remetente.value = this.state.remetente;

        if(this.state.codigoTemplate === 4){
            abaEscolhaDocumento.style.display = 'none';
            linkDocumentoInformado.style.display = 'block';
            camposDocumentoRecebido.style.display = 'flex';
            
            remetente.setAttribute("disabled", true);
            numeroDocumentoExterno.setAttribute("disabled", true);      
            titulo.setAttribute("disabled", true);      
            this.setState({editarObservacao:true});
        }
        else{
            abaEscolhaDocumento.style.display = 'block';
            linkDocumentoInformado.style.display = 'none';
            camposDocumentoRecebido.style.display = 'none';
        }
    
        template.selectedIndex = this.state.codigoTemplate;

        this.setState({template: template.value});

        for (let i = 0; i < emissor.length; i = i + 1) {
            if(emissor.options[i].text === this.state.emissor){
                emissor.selectedIndex = i;
            }
        }
            
        titulo.value = this.state.titulo;
        observacao.value = this.state.observacao;

        template.setAttribute("disabled", true);        
        emissor.setAttribute("disabled", true);

        if(this.state.nomeSolicitante !== null && this.state.nomeSolicitante !== undefined && this.state.nomeSolicitante !== ''){
            solicitador.style.display = 'flex';
        }
        else{
            linhaFixos.style.display = 'flex';
        }
        
        if(this.state.codigoTemplate === 2){
            this.atualizarTramitacao();
            linhaTramitacao.style.display = 'block';
        }

        if(this.state.codigoTemplate === 1) {
            linhaDataReuniao.style.display = 'flex';
            linhaResponsavel.style.display = 'flex';
            linhaParticipantes.style.display = 'flex';
            dataReuniao.value = this.state.dataReuniao;
            horaInicio.value = this.state.horaInicio;
            horaTermino.value = this.state.horaTermino;
        }
        else{
            linhaDataReuniao.style.display = 'none';     
            linhaResponsavel.style.display = 'none';
            linhaParticipantes.style.display = 'none';
        }

        if(this.state.codigoTemplate === 5){
            linhaAbas.style.display = "none";
            linhaObs.style.display = "none";
            linhaLocalizacaoEntrevista.style.display = "flex";
            linhaDataEntrevista.style.display = "flex";
            linhaCoordenador.style.display = "block";
            linhaEntrevistador.style.display = "block";
            linhaEntrevistado.style.display = "block";

            if(this.state.nomeEntrevistadorExtra !== null && this.state.nomeEntrevistadorExtra !== undefined && this.state.nomeEntrevistadorExtra !== '' && this.state.nomeEntrevistadorExtra !== '0'){
                document.getElementById("haEntrevistadorExtra").value = 'S';
                document.getElementById("linhaEntrevistadorExtra").style.display = "block";
            }
            else{
                document.getElementById("linhaEntrevistadorExtra").style.display = "none";
            }

            if(this.state.situacaoDocumento === 'REJEITADO'){
                botaoCriarDocumento.style.display = "none";                
            }
            
            document.getElementById("botaoAdicionarEntrevistador").style.display = "none";

            document.getElementById("dataEntrevista").value = this.state.dataEntrevista;
            document.getElementById("cidadeEntrevista").value = this.state.cidadeEntrevista;
            document.getElementById("estadoEntrevista").value = this.state.ufEntrevista;
            document.getElementById("nomeCoordenador").value = this.state.nomeCoordenador;
            document.getElementById("emailCoordenador").value = this.state.emailCoordenador;
            document.getElementById("nomeEntrevistador").value = this.state.nomeEntrevistador;
            document.getElementById("emailEntrevistador").value = this.state.emailEntrevistador;
            document.getElementById("cpfEntrevistador").value = this.state.cpfEntrevistador;
            document.getElementById("nomeEntrevistadorExtra").value = this.state.nomeEntrevistadorExtra;
            document.getElementById("emailEntrevistadorExtra").value = this.state.emailEntrevistadorExtra;
            document.getElementById("cpfEntrevistadorExtra").value = this.state.cpfEntrevistadorExtra;
            document.getElementById("nomeEntrevistado").value = this.state.nomeEntrevistado;
            document.getElementById("emailEntrevistado").value = this.state.emailEntrevistado;
            document.getElementById("cpfEntrevistado").value = this.state.cpfEntrevistado;
            document.getElementById("tipoInstituicaoEntrevistado").value = this.state.tipoInstituicaoEntrevistado;
            document.getElementById("instituicaoEntrevistado").value = this.state.instituicaoEntrevistado;

            document.getElementById("dataEntrevista").setAttribute("disabled", true);
            document.getElementById("cidadeEntrevista").setAttribute("disabled", true);
            document.getElementById("estadoEntrevista").setAttribute("disabled", true);
            document.getElementById("nomeCoordenador").setAttribute("disabled", true);
            document.getElementById("emailCoordenador").setAttribute("disabled", true);
            document.getElementById("nomeEntrevistador").setAttribute("disabled", true);
            document.getElementById("emailEntrevistador").setAttribute("disabled", true);
            document.getElementById("cpfEntrevistador").setAttribute("disabled", true);
            document.getElementById("haEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("nomeEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("emailEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("cpfEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("nomeEntrevistado").setAttribute("disabled", true);
            document.getElementById("emailEntrevistado").setAttribute("disabled", true);
            document.getElementById("cpfEntrevistado").setAttribute("disabled", true);
            document.getElementById("tipoInstituicaoEntrevistado").setAttribute("disabled", true);
            document.getElementById("instituicaoEntrevistado").setAttribute("disabled", true);
            document.getElementById("horaInicioEntrevista").setAttribute("disabled", true);
            document.getElementById("horaTerminoEntrevista").setAttribute("disabled", true);
            document.getElementById("titulo").setAttribute("disabled", true);
        }

        if(this.state.origem === 'solicitacao'){
            this.bloquearCamposTela();
        }

    }

    atualizarTela(){
        
        let dataCriacao = document.getElementById('dataCriacao');
        let dataAssinatura = document.getElementById('dataAssinatura');
        //let dataAprovacao = document.getElementById('dataAprovacao');
        let numeroDocumento = document.getElementById('numeroDocumento');
        let numeroDocumentoExterno = document.getElementById('numeroDocumentoExterno');
        let remetente = document.getElementById('remetente');
        let camposDocumentoRecebido = document.getElementById('camposDocumentoRecebido');
        let template = document.getElementById('template');
        let emissor = document.getElementById('emissor');
        let linhaFixos = document.getElementById("fixos");
        let linhaDatas = document.getElementById("linhaDatas");
        // let botaoCriarDocumento = document.getElementById("botaoCriarDocumento");
        let botaoAtualizarDocumento = document.getElementById("botaoAtualizarDocumento");
        let abaEscolhaDocumento = document.getElementById("abaEscolhaDocumento");
        let linhaTramitacao = document.getElementById("linhaTramitacao");
        let linhaAnexos = document.getElementById("linhaAnexos");
        let titulo = document.getElementById("titulo");
        let observacao = document.getElementById("observacao");
        let linkDocumentoGoogle = document.getElementById("linkDocumentoGoogle");
        let mensagemCriacao = document.getElementById("mensagemCriacao");
        let solicitador = document.getElementById('solicitador');
        let dataReuniao = document.getElementById('dataReuniao');
        let linhaDataReuniao = document.getElementById("linhaDataReuniao");
        let horaInicio = document.getElementById('horaInicio');
        let horaTermino = document.getElementById('horaTermino');
        let linhaLocalizacaoEntrevista = document.getElementById('linhaLocalizacaoEntrevista');
        let linhaDataEntrevista = document.getElementById('linhaDataEntrevista');
        let linhaCoordenador = document.getElementById('linhaCoordenador');
        let linhaEntrevistador = document.getElementById('linhaEntrevistador');
        let linhaEntrevistado = document.getElementById('linhaEntrevistado');
        let linhaAbas = document.getElementById('linhaAbas');
        let linhaObs = document.getElementById('linhaObs');
        // let botaoSolicitarAprovacao = document.getElementById('botaoSolicitarAprovacao');
        let linhaResponsavel = document.getElementById('linhaResponsavel');
        let linhaParticipantes = document.getElementById('linhaParticipantes');
        
        if(this.state.origem !== 'solicitacao'){
            // document.getElementById("botaoAprovarDocumento").style.display = "block";
        }

        numeroDocumento.value = this.state.numeroDocumento; 
        numeroDocumentoExterno.value=this.state.numeroDocumentoExterno;
        remetente.value = this.state.remetente;

        if(this.state.codigoTemplate === 4) {
            camposDocumentoRecebido.style.display = 'flex';
            
            remetente.setAttribute("disabled", true);
            numeroDocumentoExterno.setAttribute("disabled", true);      
            titulo.setAttribute("disabled", true);      
            this.setState({editarObservacao:true});
        }
        
        linhaFixos.style.display = 'flex';
        // botaoCriarDocumento.style.display = 'none';
        if (this.context.getPermission(permissions.CRIAR_DOCUMENTOS) || 
             this.context.getPermission(permissions.CRIAR_DOCUMENTOS_SOLICITADOS)|| 
             this.context.getPermission(permissions.EDITAR_DOCUMENTOS_MINUTA)){
            botaoAtualizarDocumento.style.display = 'block';
        }
        abaEscolhaDocumento.style.display = 'none';

        if(this.state.codigoTemplate === 2) {
            linhaTramitacao.style.display = 'block';
        }
        else{
            linhaTramitacao.style.display = 'none';
        }
        
        linhaAnexos.style.display = 'block';

        if(this.state.origem === 'solicitacao'){
            linhaDatas.style.display = 'none';
            // botaoSolicitarAprovacao.style.display = 'block';
        }
        else{
            linhaDatas.style.display = 'flex';
            // botaoSolicitarAprovacao.style.display = 'none';
        }

        if(this.state.id === 0){                    
            linkDocumentoGoogle.style.display = 'none';
            
        }
        else{       

            if(this.state.situacaoDocumento === 'SOLICITADO'){                    
                linkDocumentoGoogle.style.display = 'none';
                this.setState({tituloPagina: 'Novo Documento'});
                linhaFixos.style.display = 'none';
                // botaoCriarDocumento.style.display = 'block';
                botaoAtualizarDocumento.style.display = 'none';
                abaEscolhaDocumento.style.display = 'block';
                linhaTramitacao.style.display = 'none';
                linhaAnexos.style.display = 'none';
                linhaDatas.style.display = 'none';
                
                for(let i = 0; i < this.state.templates.length; i = i + 1 ) {
                    if(this.state.templates[i]['cod_template_documento'] === this.state.codigoTemplate){
                        this.setState({template: this.state.templates[i]['cod_template_documento']});
                    }
                }

                if(this.state.codigoTemplate === 4) {
                    camposDocumentoRecebido.style.display = 'flex';
                    document.getElementById("novoLinkOpcional").style.display = 'none';
                    document.getElementById("novoArquivoOpcional").style.display = 'none';
                }
                else{
                    camposDocumentoRecebido.style.display = 'none';
                    document.getElementById("novoLinkOpcional").style.display = 'flex';
                    document.getElementById("novoArquivoOpcional").style.display = 'flex';
                }
    

            }
            else{

                this.setState({
                    tituloAux: this.state.titulo,
                    tituloPagina: 'Edição do Documento: '
                });

                //dataAprovacao.setAttribute("enabled", true);
                dataAssinatura?.setAttribute("enabled", true);
    
                if(this.state.dataAprovacao !== null && this.state.dataAprovacao !== '') {
                   // dataAprovacao.value = this.state.dataAprovacao;
                   // dataAprovacao.setAttribute("disabled", true);
                }
                
                if(this.state.dataAssinatura !== null && this.state.dataAssinatura !== '') {
                    dataAssinatura.value = this.state.dataAssinatura;
                    dataAssinatura?.setAttribute("disabled", true);
                }

                template.selectedIndex = this.state.codigoTemplate;
                /*
                for(let i = 0; i < this.state.templates.length; i = i + 1 ) {
                    if(this.state.templates[i]['cod_template_documento'] === this.state.codigoTemplate){
                        this.setState({template: this.state.templates[i]['cod_template_documento']});
                    }
                }
                */
               if (this.context.getPermission(permissions.EDITAR_DOCUMENTOS_MINUTA)){
                    linkDocumentoGoogle.style.display = 'block';
                }
                this.atualizarTramitacao();
            }

            
            for (let i = 0; i < emissor.length; i = i + 1) {
                if(emissor.options[i].text === this.state.emissor){
                    emissor.selectedIndex = i;
                }
            }
            
            titulo.value = this.state.titulo;
            observacao.value = this.state.observacao;

            if(this.state.nomeSolicitante !== null && this.state.nomeSolicitante !== undefined && this.state.nomeSolicitante !== ''){
                solicitador.style.display = 'flex';
            }

        }

        dataCriacao.setAttribute("disabled", true);
        template.setAttribute("disabled", true);        
        emissor.setAttribute("disabled", true);

        mensagemCriacao.style.display = 'none';
        linkDocumentoGoogle.classList.replace('alert-success', 'alert-primary');
     
        // document.getElementById("botaoArquivarDocumento").style.display = "flex";
        //document.getElementById("botaoArquivarDocumento").innerHTML= "CANCELAR DOCUMENTO";
        
        if(this.state.codigoTemplate === 1) {
            linhaDataReuniao.style.display = 'flex';
            linhaResponsavel.style.display = 'flex';
            linhaParticipantes.style.display = 'flex';
            dataReuniao.value = this.state.dataReuniao;
            horaInicio.value = this.state.horaInicio;
            horaTermino.value = this.state.horaTermino;
        }
        else{
            linhaDataReuniao.style.display = 'none';     
            linhaResponsavel.style.display = 'none';  
            linhaParticipantes.style.display = 'none';   
        }

        if(this.state.codigoTemplate === 5){
            linhaAbas.style.display = "none";
            linhaObs.style.display = "none";
            linhaLocalizacaoEntrevista.style.display = "flex";
            linhaDataEntrevista.style.display = "flex";
            linhaCoordenador.style.display = "block";
            linhaEntrevistador.style.display = "block";
            linhaEntrevistado.style.display = "block";

            if(this.state.nomeEntrevistadorExtra !== null && this.state.nomeEntrevistadorExtra !== undefined && this.state.nomeEntrevistadorExtra !== '' && this.state.nomeEntrevistadorExtra !== '0'){
                document.getElementById("haEntrevistadorExtra").value = 'S';
                document.getElementById("linhaEntrevistadorExtra").style.display = "block";
            }
            else{
                document.getElementById("linhaEntrevistadorExtra").style.display = "none";
            }
                        
            //document.getElementById("botaoRejeitarDocumento").innerText = "ENTREVISTA NÃO REALIZADA";
            
            // document.getElementById("botaoAdicionarEntrevistador").style.display = "none";
            
            document.getElementById("dataEntrevista").value = this.state.dataEntrevista;
            document.getElementById("cidadeEntrevista").value = this.state.cidadeEntrevista;
            document.getElementById("estadoEntrevista").value = this.state.ufEntrevista;
            document.getElementById("nomeCoordenador").value = this.state.nomeCoordenador;
            document.getElementById("emailCoordenador").value = this.state.emailCoordenador;
            document.getElementById("nomeEntrevistador").value = this.state.nomeEntrevistador;
            document.getElementById("emailEntrevistador").value = this.state.emailEntrevistador;
            document.getElementById("cpfEntrevistador").value = this.state.cpfEntrevistador;
            document.getElementById("nomeEntrevistadorExtra").value = this.state.nomeEntrevistadorExtra;
            document.getElementById("emailEntrevistadorExtra").value = this.state.emailEntrevistadorExtra;
            document.getElementById("cpfEntrevistadorExtra").value = this.state.cpfEntrevistadorExtra;
            document.getElementById("nomeEntrevistado").value = this.state.nomeEntrevistado;
            document.getElementById("emailEntrevistado").value = this.state.emailEntrevistado;
            document.getElementById("cpfEntrevistado").value = this.state.cpfEntrevistado;
            document.getElementById("tipoInstituicaoEntrevistado").value = this.state.tipoInstituicaoEntrevistado;
            document.getElementById("instituicaoEntrevistado").value = this.state.instituicaoEntrevistado;

            document.getElementById("dataEntrevista").setAttribute("disabled", true);
            document.getElementById("cidadeEntrevista").setAttribute("disabled", true);
            document.getElementById("estadoEntrevista").setAttribute("disabled", true);
            document.getElementById("nomeCoordenador").setAttribute("disabled", true);
            document.getElementById("emailCoordenador").setAttribute("disabled", true);
            document.getElementById("nomeEntrevistador").setAttribute("disabled", true);
            document.getElementById("emailEntrevistador").setAttribute("disabled", true);
            document.getElementById("cpfEntrevistador").setAttribute("disabled", true);
            document.getElementById("haEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("nomeEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("emailEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("cpfEntrevistadorExtra").setAttribute("disabled", true);
            document.getElementById("nomeEntrevistado").setAttribute("disabled", true);
            document.getElementById("emailEntrevistado").setAttribute("disabled", true);
            document.getElementById("cpfEntrevistado").setAttribute("disabled", true);
            document.getElementById("tipoInstituicaoEntrevistado").setAttribute("disabled", true);
            document.getElementById("instituicaoEntrevistado").setAttribute("disabled", true);
            document.getElementById("horaInicioEntrevista").setAttribute("disabled", true);
            document.getElementById("horaTerminoEntrevista").setAttribute("disabled", true);
            document.getElementById("titulo").setAttribute("disabled", true);
        }

        if(this.state.situacaoDocumento === 'APROVAÇÃO' && !this.state.isSolicitante){ //&& this.state.origem !== 'solicitacao'
            // document.getElementById("botaoAprovarDocumento").style.display = 'block';
            document.getElementById("botaoArquivarDocumento").innerText = 'REPROVAR DOCUMENTO';
            document.getElementById("botaoArquivarDocumento").style.display = 'block';
        }
        else{
            if(this.state.situacaoDocumento === 'APROVAÇÃO' && this.state.isSolicitante){ //this.state.origem === 'solicitacao'
                // document.getElementById("botaoSolicitarAprovacao").style.display = 'none';
            }
        }


        if(this.state.situacaoDocumento === 'APROVADO'){
            //document.getElementById("botaoArquivarDocumento").style.display = 'none';
            // document.getElementById("botaoAprovarDocumento").style.display = 'none';
            // document.getElementById("botaoSolicitarAprovacao").style.display = 'none';
        }

        if((this.state.situacaoDocumento === 'APROVADO' || this.state.situacaoDocumento === 'ASSINADO')
           && this.state.origem === 'solicitacao'){
               document.getElementById("linkDocumentoGoogle").style.display = 'none';
        }
        else{
            if((this.state.situacaoDocumento === 'APROVADO' || this.state.situacaoDocumento === 'ASSINADO')
                && this.state.origem !== 'solicitacao'){
                document.getElementById("linkEditarConteudo").innerText = 'Acesse Minuta do documento';
            }
 
        }

        if(this.state.origem === 'solicitacao'){
            this.bloquearCamposTela();
        }
    }

    handleAtualizar(e){

        e.preventDefault();
        if (this.context.getPermission(permissions.EDITAR_DOCUMENTOS)) {

            this.context.startLoading();
            
            if(this.state.atualizando === false){

                this.setState({atualizando: true});

                this.handleSubmitAtualizar().then(data => {

                    this.atualizarDocumento(data);
        
                    this.atualizarTelaAposAtualizacao(); 
                
                    this.referencia.current.focus();      

                    alert("As alterações foram salvas com sucesso");
                })
                .finally(() => this.context.stopLoading());

                this.setState({atualizando: false});

            }
            else{
                alert("O sistema já está processando uma solicitação de atualização de documento! \nGentileza aguardar o término dessa solicitação!");
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    atualizarDocumento(data){

        if(data["mensagem"] !== 'sucesso'){

            let erro = document.getElementById('erro');
            erro.innerText = data["mensagem"];
            erro.style.visibility = 'visible';            
        }
        else{

            this.setState({
                situacaoDocumento: data["situacao"],
                anexosAdd: [],
                anexosRemove: [],
                anexos: [],
                fileList: [],
                participantes: [],
                participantesAdicionar: [],
                participantesRemover: []
            });

            this.estruturarAnexo(data["anexos"]);
            this.estruturarTramitacao(data["tramitacao"]);
            this.estruturarParticipante(data["participantes"]);
        }

    }

    async handleSubmitAtualizar(){
        
        let spinner = document.getElementById("spinner");
        spinner.style.display = 'block';

        let dataTramitacao = document.getElementById('dataTramitacao');
        let responsavelTramitacao = document.getElementById('responsavelTramitacao');
        let idTramitacao = document.getElementById('idTramitacao');
        let nomeDestinatario = document.getElementById('nomeDestinatario');
        let email = document.getElementById('emailDestinatario');
        let cargo = document.getElementById('cargo');
        let instituicao = document.getElementById('instituicao');
        let rua = document.getElementById('rua');
        let numero = document.getElementById('numero');
        let complemento = document.getElementById('complemento');
        let bairro = document.getElementById('bairro');
        let cidade = document.getElementById('cidade');
        let estado = document.getElementById('estadoDadosEnvio');
        let cep = document.getElementById('cep');

        /*
        let tramitacaoAux = {
            id: idTramitacao.value, 
            nomeDestinatario: nomeDestinatario.value,
            email: email.value,
            cargo: cargo.value,
            instituicao: instituicao.value,
            rua: rua.value,
            numero: numero.value,
            complemento:complemento.value,
            bairro: bairro.value,
            cidade: cidade.value,
            estado: estado.value,
            cep: cep.value,
            data: dataTramitacao.value,
            responsavel: responsavelTramitacao.value
        };     
        */
        let formData = new FormData();
        formData.append('token', this.state.token);
        formData.append('id',  this.state.id);
        formData.append('titulo',  this.state.titulo);
        formData.append('observacao',  this.state.observacao);

        if(this.state.dataAprovacao !== null && this.state.dataAprovacao !== undefined && this.state.dataAprovacao !== ''){
            formData.append('dataAprovacao',  this.state.dataAprovacao);
        }

        if(this.state.dataAssinatura !== null && this.state.dataAssinatura !== undefined && this.state.dataAssinatura !== ''){
            formData.append('dataAssinatura',  this.state.dataAssinatura);
        }

        formData.append('tramitacao[id]', idTramitacao.value);
        formData.append('tramitacao[nomeDestinatario]', nomeDestinatario.value);
        formData.append('tramitacao[email]', email.value);
        formData.append('tramitacao[cargo]', cargo.value);
        formData.append('tramitacao[instituicao]',  instituicao.value);
        formData.append('tramitacao[rua]',  rua.value);
        formData.append('tramitacao[numero]',  numero.value);
        formData.append('tramitacao[complemento]', complemento.value);
        formData.append('tramitacao[bairro]',  bairro.value);
        formData.append('tramitacao[cidade]',  cidade.value);
        formData.append('tramitacao[estado]',  estado.value);
        formData.append('tramitacao[cep]',  cep.value);
        formData.append('tramitacao[data]',  dataTramitacao.value);
        formData.append('tramitacao[responsavel]', responsavelTramitacao.value);        
        
        formData.append('situacao',  this.state.situacaoDocumento);
                
        let contador = 0;
        this.state.anexosAdd.forEach(
            anexoAdd => {
                //console.log(anexoAdd);
                formData.append('anexosAdicionar['+contador+'][id]', anexoAdd.id);
                formData.append('anexosAdicionar['+contador+'][link]', anexoAdd.link);

                if(anexoAdd.link !== null && anexoAdd.link !== undefined && anexoAdd.link !== ''){
                    formData.append('anexosAdicionar['+contador+'][arquivo][name]', '');
                    formData.append('anexosAdicionar['+contador+'][arquivo][size]', '');
                    formData.append('anexosAdicionar['+contador+'][arquivo][type]', '');
                    formData.append('anexosAdicionar['+contador+'][arquivo][conteudo]', '');
                }
                else{
                    formData.append('anexosAdicionar['+contador+'][arquivo][name]', anexoAdd.arquivo.name);
                    formData.append('anexosAdicionar['+contador+'][arquivo][size]', anexoAdd.arquivo.size);
                    formData.append('anexosAdicionar['+contador+'][arquivo][type]', anexoAdd.arquivo.type);
                    formData.append('anexosAdicionar['+contador+'][arquivo][conteudo]', anexoAdd.arquivo.conteudo);
                    //formData.append('fileList['+contador+']', this.state.fileList[contador]);
                    
                }

                formData.append('anexosAdicionar['+contador+'][descricao]', anexoAdd.descricao);
                contador++;
            }
        );
        
        contador = 0;
        this.state.fileList.forEach(
            arq => {
                //console.log(anexoAdd);
                formData.append('fileList['+contador+']', this.state.fileList[contador]);
                contador++;
            }
        );
        
        contador = 0;
        this.state.anexosRemove.forEach(
            anexoRemove => {
                formData.append('anexosRemover['+contador+'][id]', anexoRemove.id);
                contador++;
            }
        );

        formData.append('responsavel',  localStorage.getItem("projartCod"));
        formData.append('remetente',  this.state.remetente);
        formData.append('numeroDocumentoExterno',  this.state.numeroDocumentoExterno);
        
        if(this.state.dataReuniao !== null && this.state.dataReuniao !== undefined && this.state.dataReuniao !== ''){
            formData.append('dataReuniao',  this.state.dataReuniao);
        }

        if(this.state.horaInicio !== null && this.state.horaInicio !== undefined && this.state.horaInicio !== ''){
            formData.append('horaInicio',  this.state.horaInicio);
        }
        
        if(this.state.horaTermino !== null && this.state.horaTermino !== undefined && this.state.horaTermino !== ''){
            formData.append('horaTermino',  this.state.horaTermino);
        }

        if(this.state.nomeResponsavel !== null && this.state.nomeResponsavel !== undefined && this.state.nomeResponsavel !== ''){
            formData.append('nome_responsavel',  this.state.nomeResponsavel);
        }

        if(this.state.emailResponsavel !== null && this.state.emailResponsavel !== undefined && this.state.emailResponsavel !== ''){
            formData.append('email_responsavel',  this.state.emailResponsavel);
        }

        contador = 0;
        this.state.adicionarParticipantes.forEach(
            participanteAdd => {
                //console.log(anexoAdd);
                formData.append('participantesAdicionar['+contador+'][id]', participanteAdd.id);
                formData.append('participantesAdicionar['+contador+'][nome]', participanteAdd.nome);
                formData.append('participantesAdicionar['+contador+'][email]', participanteAdd.email);
                contador++;
            }
        );

        contador = 0;
        this.state.removerParticipantes.forEach(
            participanteRemover => {
                //console.log(participanteRemover);
                formData.append('participantesRemover['+contador+'][id]', participanteRemover.id);
                contador++;
            }
        );

        const requestOptions = {
            method: 'POST',
            body: formData
        }
        /*
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token,
                id: this.state.id,
                titulo: this.state.titulo,
                observacao: this.state.observacao,
                dataAprovacao: this.state.dataAprovacao,
                dataAssinatura: this.state.dataAssinatura,
                tramitacao: tramitacaoAux,
                situacao: this.state.situacaoDocumento,
                anexosRemover:this.state.anexosRemove,
                anexosAdicionar:this.state.anexosAdd,
                responsavel: localStorage.getItem("projartCod"),
                remetente: this.state.remetente,
                numeroDocumentoExterno: this.state.numeroDocumentoExterno
            })
        };
        */
        const response = await api(apiURL+"documento/atualizar", requestOptions);
        const resp = await response.json();

        return resp;
    }

    handleSubmitArquivar(e){
        
        e.preventDefault();
        if (this.context.getPermission(permissions.REJEITAR_SOLICITACAO_DOCUMENTOS) ||
            this.context.getPermission(permissions.SOLICITAR_CANCELAMENTO_DOCUMENTOS)) {

            let spinner = document.getElementById("spinner");
            spinner.style.display = 'block';

            if(this.state.situacaoDocumento === 'APROVAÇÃO'){
                this.setState({origemEmail: 'botao'});
                document.getElementById("botaoModalEnviarEmail").click();
            }
            else{
                if(!this.state.isSolicitante){ //origem !== 'solicitacao'
                    this.setState({origemEmail: 'botaoRejeitarSolicitacao'});
                    document.getElementById("botaoModalEnviarEmail").click();
                }
                else{
                    this.setState({
                        origemEmail: 'botaoSolicitanteRejeitarSolicitacao'
                    });
                    document.getElementById("botaoModalEnviarEmail").click();
                    /*
                    this.atualizarStatusDocumento('REJEITADO').then(data => {
        
                        if(this.state.situacaoDocumento === "MINUTA"){
                            if(this.state.codigoTemplate === 5){
                                alert("Entrevista Não Realizada e Documento Rejeitado!");
                            }
                            else{
                                alert("Documento rejeitado!");
                            }
                        }
                        else{
                            alert("Solicitação rejeitada!");
                        }

                        this.setState({situacaoDocumento: 'REJEITADO'});
                        document.getElementById("botaoArquivarDocumento").style.display = 'none';
                        this.referencia.current.focus();                 
                        
                    } );
                    */
                }
            }

            spinner.style.display = 'none';

        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleSubmitRejeitarDocumento(e){
        
        e.preventDefault();
        if (this.context.getPermission(permissions.CANCELAR_DOCUMENTO)) {

            let spinner = document.getElementById("spinner");
            spinner.style.display = 'block';

            this.setState({origemEmail: 'botaoRejeitarDocumento'});
            document.getElementById("botaoModalEnviarEmail").click();

            spinner.style.display = 'none';

        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }


    async atualizarStatusDocumento(status){

        let formData = new FormData();
        formData.append('cpf', this.state.cpf);
        formData.append('status',  status);

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        const response = await api(apiURL+"documento/atualizarStatus/"+this.state.id, requestOptions);
        const resp = await response.json();

        return resp;

    }

    handleAprovarDocumento(e){
        
        e.preventDefault();
        if (this.context.getPermission(permissions.APROVAR_DOCUMENTOS)) {

            this.context.startLoading()

            this.atualizarStatusDocumento('APROVADO').then(data => {
                //console.log(data);
                this.setState({
                    situacaoDocumento: 'APROVADO',
                    dataAprovacao: data["documento"]["DATA_APROVACAO"]
                });

                //document.getElementById("dataAprovacao").value = data["documento"]["DATA_APROVACAO"];

                //if(data["anexos"] !== null && data["anexos"] !== undefined){
                //    this.estruturarAnexo(data["anexos"]); 
                //}

                this.componentDidMount();

                alert('Documento aprovado!');

                // document.getElementById("botaoAprovarDocumento").style.display = 'none';
                // document.getElementById("botaoSolicitarAprovacao").style.display = 'none';
                //document.getElementById("botaoArquivarDocumento").style.display = 'none';
                this.referencia.current.focus();                 
                    
            } )
            .finally(() => this.context.stopLoading());
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleSituacaoDocumento(situacao){
        
        if(situacao === "MINUTA"){
            if(this.state.codigoTemplate === 5){
                alert("Entrevista Não Realizada e Documento Rejeitado!");
            }
            else{
                if(this.state.codigoTemplate === 1){
                    alert("Reunião Não Realizada e Documento Rejeitado!");
                }
                else{
                    alert("Documento rejeitado!");
                }
            }
        }
        else{
            if(situacao === "REJEITADO" && this.state.origemEmail === "botaoRejeitarDocumento"){
                alert("Documento rejeitado!");
            }
            else {
                alert("Solicitação rejeitada!");
            }
        }

        this.setState({situacaoDocumento: situacao});
    }

    handleSolicitarAprovacao(e){
        
        e.preventDefault();
        if (this.context.getPermission(permissions.SOLICITAR_APROVACAO_DOCUMENTO)) {

            this.context.startLoading();

            this.atualizarStatusDocumento('APROVAÇÃO').then(data => {
    
                if(this.state.situacaoDocumento === "MINUTA")
                    alert("Solicitado a aprovação do documento para a secretaria!");
                

                this.setState({situacaoDocumento: 'SOLICITADO APROVAÇÃO'});
                // document.getElementById("botaoSolicitarAprovacao").style.display = 'none';
                this.referencia.current.focus();                 
                    
            } )
            .finally(() => this.context.stopLoading())
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    validarCamposEntrevista(){
        let retorno = true;
        let mensagem = '';

        if(this.state.template === null || this.state.template === undefined || this.state.template === '' || this.state.template === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tDocumento';
        }

        if(this.state.emissor === null  || this.state.emissor === undefined || this.state.emissor === ''  || this.state.emissor === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tEmissor';
        }

        if(this.state.cidadeEntrevista === null || this.state.cidadeEntrevista === undefined || this.state.cidadeEntrevista === ''){
            retorno = false;
            mensagem = mensagem + '\n\tCidade';
        }

        if(this.state.ufEntrevista === null || this.state.ufEntrevista === undefined || this.state.ufEntrevista === '' || this.state.ufEntrevista === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tEstado';
        }

        if(this.state.dataEntrevista === null || this.state.dataEntrevista === undefined || this.state.dataEntrevista === ''){
            retorno = false;
            mensagem = mensagem + '\n\tData Entrevista';
        }

        if(this.state.horaInicioEntrevista === null || this.state.horaInicioEntrevista === undefined || this.state.horaInicioEntrevista === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de início';
        }

        if(this.state.horaTerminoEntrevista === null || this.state.horaTerminoEntrevista === undefined || this.state.horaTerminoEntrevista === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de fim';
        }

        if(this.state.idEntrevistador === null || this.state.idEntrevistador === undefined || this.state.idEntrevistador === '' || this.state.idEntrevistador === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tNome entrevistador';
        }

        if(this.state.nomeEntrevistado === null || this.state.nomeEntrevistado === undefined || this.state.nomeEntrevistado === ''){
            retorno = false;
            mensagem = mensagem + '\n\tNome do entrevistado';
        }
        /*
        if(this.state.cpfEntrevistado === null || this.state.cpfEntrevistado === undefined || this.state.cpfEntrevistado === ''){
            retorno = false;
            mensagem = mensagem + '\n\tCPF do entrevistado';
        }
*/
        if(this.state.emailEntrevistado === null || this.state.emailEntrevistado === undefined || this.state.emailEntrevistado === ''){
            retorno = false;
            mensagem = mensagem + '\n\tE-mail do entrevistado';
        }

        if(this.state.instituicaoEntrevistado === null || this.state.instituicaoEntrevistado === undefined || this.state.instituicaoEntrevistado === ''){
            retorno = false;
            mensagem = mensagem + '\n\tInstituição do Entrevistado';
        }

        if(this.state.tipoInstituicaoEntrevistado === null || this.state.tipoInstituicaoEntrevistado === undefined || this.state.tipoInstituicaoEntrevistado === '0' || this.state.tipoInstituicaoEntrevistado === '' || this.state.tipoInstituicaoEntrevistado === 'selecione'){
            retorno = false;
            mensagem = mensagem + '\n\tTipo instituição';
        }

        this.mensagemValidacao = mensagem;
        return retorno;
    }

    validarCamposReuniao(){
        let retorno = true;
        let mensagem = '';

        if(this.state.template === null || this.state.template === undefined || this.state.template === '' || this.state.template === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tDocumento';
        }

        if(this.state.emissor === null  || this.state.emissor === undefined || this.state.emissor === ''  || this.state.emissor === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tEmissor';
        }

        if(this.state.titulo === null || this.state.titulo === undefined || this.state.titulo === ''){
            retorno = false;
            mensagem = mensagem + '\n\tTítulo';
        }

        if(this.state.dataReuniao === null || this.state.dataReuniao === undefined || this.state.dataReuniao === ''){
            retorno = false;
            mensagem = mensagem + '\n\tData Reunião';
        }

        if(this.state.horaInicio === null || this.state.horaInicio === undefined || this.state.horaInicio === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de início';
        }
        else{
            if(validarHora(this.soNumeros(this.state.horaInicio)) === false){
                retorno = false;
                mensagem = mensagem + '\n\tHora de início da reunião inválida!';
            }
        }

        if(this.state.horaTermino === null || this.state.horaTermino === undefined || this.state.horaTermino === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de fim';
        }
        else{
            if(validarHora(this.soNumeros(this.state.horaTermino)) === false){
                retorno = false;
                mensagem = mensagem + '\n\tHora de término da reunião inválida!'; 
            }
        }

        if(this.state.nomeResponsavel === null  || this.state.nomeResponsavel === undefined || this.state.nomeResponsavel === ''){
            retorno = false;
            mensagem = mensagem + '\n\tResponsável';
        }

        if(this.state.emailResponsavel === null || this.state.emailResponsavel === undefined || this.state.emailResponsavel === ''){
            retorno = false;
            mensagem = mensagem + '\n\tE-mail do responsável';
        }
        else{
            if(ValidacaoEmail(this.state.emailResponsavel) === false){
                retorno = false;
                mensagem = mensagem + '\n\tE-mail do responsável inválido!';
            }
        }

        this.mensagemValidacao = mensagem;
        return retorno;
    }

    handleSubmitCriar(e){

        e.preventDefault();
        if (this.context.getPermission(permissions.CRIAR_DOCUMENTOS) || 
            this.context.getPermission(permissions.CRIAR_DOCUMENTOS_SOLICITADOS)) {
            if(this.state.atualizando === false){

                this.setState({atualizando: true});

                if(this.state.template === "5" && (
                    this.state.titulo === null || this.state.titulo === undefined ||this.state.titulo === '')){
                        this.setState({titulo: 'Entrevista com '+this.state.nomeEntrevistado});
                }

                if(this.state.template === "5" && this.validarCamposEntrevista() === false){
                    alert('Certifique-se de preencher os campos: '+this.mensagemValidacao);
                }
                else{
                    if(this.state.template !== "5" && (this.state.template === '' || this.state.template === '0'  || this.state.emissor === '' || this.state.emissor === '0' || this.state.titulo === '')){
                        alert('Gentileza verique se os campos abaixo estão preenchidos:\n\tDocumento \n\tEmissor \n\tTítulo');
                    }
                    else{

                        if(this.state.template === "4" && this.state.situacaoDocumento !== 'SOLICITADO' && 
                            (
                                    (this.state.linkNovo === null ||this.state.linkNovo === undefined ||this.state.linkNovo === '')
                                &&  (this.state.file === null ||this.state.file === undefined ||this.state.file.name === '')
                            )
                        ){
                            alert('Para registrar documentos recebidos é necessário informar o link do documento ou anexar o arquivo!');
                        }
                        else{
                            if(this.state.template === "1" && this.validarCamposReuniao() === false){
                                alert('Certifique-se de preencher os campos: '+this.mensagemValidacao);
                            }
                            else{
                                if(this.state.linkNovo !== null && this.state.linkNovo !== undefined && this.state.linkNovo !== '' && validarURLDoc(this.state.linkNovo) === false){
                                    alert('Link do documento é inválido!\n' +
                                            'Certifique-se de informar os seguintes itens:\n\n' +
                                            'Protocolo (Ex. http:// ou https://)\n' +
                                            'Verificar se não há espaços em branco\n' +
                                            'Verificar se a url termina com /nome_do_arquivo.pdf ou /nome_do_arquivo.doc ou /nome_do_arquivo.docx');                
                                }
                                else{

                                    if (this.context.getPermission(permissions.CRIAR_DOCUMENTOS) || 
                                        this.context.getPermission(permissions.CRIAR_DOCUMENTOS_SOLICITADOS)
                                    ) {
                                        this.context.startLoading();

                                        this.criarDocumento().then(data => {
                                            if (data['status'] === true) {
                                                this.montarTela(data);
                                                this.atualizarTelaAposCriacao();
                                                let spinner = document.getElementById("spinner");
                                                spinner.style.display = 'none';
                                                this.referencia.current.focus();     
                                                alert("Documento criado com sucesso!");
                                            }
                                            else {
                                                alert("Não foi possível criar o documento!");
                                            }
                                        })
                                        .finally(() => this.context.stopLoading())
                                    }
                                    else {
                                        alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
                                    }
                                }
                            }
                        }
                    }
                }

                this.setState({atualizando: false});
            }
            else{
                alert("O sistema já está processando uma solicitação de criação de documento! \nGentileza aguardar o término dessa solicitação!");
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    atualizarTelaAposAtualizacao(){
        
        let mensagemCriacao = document.getElementById("mensagemCriacao");
        let linkDocumentoGoogle = document.getElementById("linkDocumentoGoogle");

        mensagemCriacao.style.display = 'none';
        linkDocumentoGoogle.classList.replace('alert-success', 'alert-primary');
    }

    montarTela(data){

        if(data["mensagem"] !== 'sucesso'){
        
            let erro = document.getElementById('erro');
            erro.innerText = data["mensagem"];
            erro.style.visibility = 'visible';
        }
        else{
            this.setState({situacaoDocumento: data["situacaoDocumento"]}); 
            this.setState({numeroDocumento: data["numeroDocumento"]});
            this.setState({dataCriacao: data["dataCriacao"]});
            this.setState({codigoDocumento: data["codigoDocumento"]});
            this.setState({id: data["codigoDocumento"]});
            this.setState({linkDocumento: data["linkDocumento"]});
        
            if(data["anexo"] !== null){

                let anexoAux = {
                    id: data["anexo"]["COD_ANEXO_DOCUMENTO"], 
                    link: data["anexo"]["link"],
                    arquivo: {
                        name: data["anexo"]["nome"],                    
                        size: '',
                        type: '',                    
                        conteudo: ''
                    },
                    descricao: data["anexo"]["descricao"]
                };     
                
                let anexosAux = this.state.anexos.slice(0);
                anexosAux.push(anexoAux);
                this.setState({anexos: anexosAux});
            }
            this.setState({adicionarParticipantes: []});
            
        }

    }


    atualizarTelaAposCriacao(){
        
        let dataCriacao = document.getElementById('dataCriacao');
        // let dataAssinatura = document.getElementById('dataAssinatura');
        //let dataAprovacao = document.getElementById('dataAprovacao');
        let numeroDocumento = document.getElementById('numeroDocumento');
        let template = document.getElementById('template');
        let emissor = document.getElementById('emissor');
        let linhaFixos = document.getElementById("fixos");
        let linhaDatas = document.getElementById("linhaDatas");
        // let botaoCriarDocumento = document.getElementById("botaoCriarDocumento");
        let botaoAtualizarDocumento = document.getElementById("botaoAtualizarDocumento");
        let abaEscolhaDocumento = document.getElementById("abaEscolhaDocumento");
        let linhaTramitacao = document.getElementById("linhaTramitacao");
        let linhaAnexos = document.getElementById("linhaAnexos");
        let linkDocumentoGoogle = document.getElementById("linkDocumentoGoogle");
        let linkDocumentoInformado = document.getElementById("linkDocumentoInformado");
        let linhaTitulo = document.getElementById("linhaTitulo");

        //document.getElementById("botaoRejeitarDocumento").innerText = "ENTREVISTA NÃO REALIZADA";
        // document.getElementById("botaoAprovarDocumento").style.display = "block";

        numeroDocumento.value = this.state.numeroDocumento; 
        
        linhaFixos.style.display = 'flex';
        // botaoCriarDocumento.style.display = 'none';
        botaoAtualizarDocumento.style.display = 'block';
        abaEscolhaDocumento.style.display = 'none';

        //if(!this.state.isSolicitante){
            if(this.state.template === "1"){
               // document.getElementById("botaoRejeitarDocumento").innerText = "REUNIÃO NÃO REALIZADA";
            }

            if(this.state.template === "2"){
                //document.getElementById("botaoArquivarDocumento").innerText = "CANCELAR DOCUMENTO";
                linhaTramitacao.style.display = 'block';
            }
            else{
                linhaTramitacao.style.display = 'none';
            }

            if(this.state.template === "3"){
                //document.getElementById("botaoArquivarDocumento").innerText = "CANCELAR DOCUMENTO";
            }

            if(this.state.template === "4"){
                //document.getElementById("botaoArquivarDocumento").innerText = "CANCELAR DOCUMENTO";
            }
        //}

        linhaAnexos.style.display = 'block';
        linhaDatas.style.display = 'flex';
        linkDocumentoInformado.style.display = 'none';
        
        this.setState({
            tituloAux: this.state.titulo,
            tituloPagina: 'Edição do Documento: '
        });

        //dataAprovacao.setAttribute("enabled", true);
        // dataAssinatura.setAttribute("enabled", true);
    
        if(this.state.dataAprovacao !== null && this.state.dataAprovacao !== '') {
            //dataAprovacao.value = this.state.dataAprovacao;
            //dataAprovacao.setAttribute("disabled", true);
        }
                
        if(this.state.dataAssinatura !== null && this.state.dataAssinatura !== '') {
            // dataAssinatura.value = this.state.dataAssinatura;
            // dataAssinatura.setAttribute("disabled", true);
        }

        if (this.context.getPermission(permissions.EDITAR_DOCUMENTOS_MINUTA)){
            linkDocumentoGoogle.style.display = 'block';
        }
        this.atualizarTramitacao();
        
        dataCriacao.setAttribute("disabled", true);
        template.setAttribute("disabled", true);        
        emissor.setAttribute("disabled", true);

        if(this.state.template === "5"){
            document.getElementById("titulo").value = this.state.titulo;
            linhaTitulo.setAttribute("disabled", true);
            linhaTitulo.style.display = "block";
            this.bloquearCamposEntrevista();
        }    
    }

    bloquearCamposTela(){

        document.getElementById("titulo").setAttribute("disabled", true);
        //document.getElementById("linhaObs").style.display = 'none';
        this.setState({editarObservacao: true});
        document.getElementById("linhaAbas").style.display = 'none';
        document.getElementById("dataReuniao").setAttribute("disabled", true);
        document.getElementById("horaInicio").setAttribute("disabled", true);
        document.getElementById("horaTermino").setAttribute("disabled", true);
        document.getElementById("nomeResponsavel").setAttribute("disabled", true);
        document.getElementById("emailResponsavel").setAttribute("disabled", true);
        document.getElementById("numeroDocumentoExterno").setAttribute("disabled", true);
        document.getElementById("remetente").setAttribute("disabled", true);
        if(this.state.situacaoDocumento !== 'MINUTA'){
            document.getElementById("modalAnexoNovo").style.display = 'none';
            document.getElementById("botaoAdicionarParticipante").setAttribute("disabled", true);
            document.getElementById("botaoAtualizarDocumento").style.display = 'none';     
        }
   
    }

    bloquearCamposEntrevista(){
          
        document.getElementById("botaoAdicionarEntrevistador").style.display = "none";
    
        document.getElementById("dataEntrevista").setAttribute("disabled", true);
        document.getElementById("cidadeEntrevista").setAttribute("disabled", true);
        document.getElementById("estadoEntrevista").setAttribute("disabled", true);
        document.getElementById("nomeCoordenador").setAttribute("disabled", true);
        document.getElementById("emailCoordenador").setAttribute("disabled", true);
        document.getElementById("nomeEntrevistador").setAttribute("disabled", true);
        document.getElementById("emailEntrevistador").setAttribute("disabled", true);
        document.getElementById("cpfEntrevistadorExtra").setAttribute("disabled", true);
        document.getElementById("nomeEntrevistadorExtra").setAttribute("disabled", true);
        document.getElementById("emailEntrevistadorExtra").setAttribute("disabled", true);
        document.getElementById("cpfEntrevistador").setAttribute("disabled", true);
        document.getElementById("nomeEntrevistado").setAttribute("disabled", true);
        document.getElementById("emailEntrevistado").setAttribute("disabled", true);
        document.getElementById("cpfEntrevistado").setAttribute("disabled", true);
        document.getElementById("tipoInstituicaoEntrevistado").setAttribute("disabled", true);
        document.getElementById("instituicaoEntrevistado").setAttribute("disabled", true);
        document.getElementById("horaInicioEntrevista").setAttribute("disabled", true);
        document.getElementById("horaTerminoEntrevista").setAttribute("disabled", true);
        document.getElementById("titulo").setAttribute("disabled", true);    
    }

    handleChangeTipoInstituicaoEntrevistado(e){        
        this.setState({tipoInstituicaoEntrevistado: e.target.value});
    }

    handleChangeInstituicaoEntrevistado(e){        
        this.setState({instituicaoEntrevistado: e.target.value});
    }

    handleChangeNomeEntrevistado(e){        
        this.setState({nomeEntrevistado: e.target.value});
    }
    
    handleChangeEmailEntrevistado(e){        
        this.setState({emailEntrevistado: e.target.value});
    }
   
    handleChangeEmailEntrevistador(e){        
        this.setState({emailEntrevistador: e.target.value});
    }

    handleChangeNomeResponsavel(e){        
        this.setState({nomeResponsavel: e.target.value});
    }
    
    handleChangeEmailResponsavel(e){        
        this.setState({emailResponsavel: e.target.value});
    }

    soNumeros(str){
        return str.replace(/[^0-9]/g, '');
    }

    handleChangeCpfEntrevistado(e){        
        this.setState({cpfEntrevistado: this.soNumeros(e.target.value)});
    }

    handleChangeCpfEntrevistador(e){        
        this.setState({cpfEntrevistador: this.soNumeros(e.target.value)});
    }


    handleOnChangeLinkAnexo(e){
        this.setState({
            linkAnexo: e.target.value,
            arquivoAnexo: ''
        });
    }

    handleOnChangeArquivoAnexo(e){

        this.setState({
            arquivoAnexo: e.target.files[0],
            linkAnexo: ''
        });      


        let fileAux = null;            
        this.state.fileList.push(e.target.files[0]);
        if(e.target.files[0] !== null){

                fileAux = {                                      
                        name: e.target.files[0].name,                    
                        size: e.target.files[0].size,
                        type: e.target.files[0].type,                 
                        conteudo: e.target.files[0]
                }

                this.setState({fileAnexo: fileAux});            

 
        }

    }

    handleOnChangeDescricaoAnexo(e){
        this.setState({descricaoAnexo: e.target.value});
    }

    handleOnChangeIdAnexo(e){
      this.setState({idAnexo: e.target.value});
    }

    handleChangeArquivoNovo(e){
        
        this.setState({
            arquivoNovo: e.target.files[0],
            linkNovo: ''
        });      

        let arquivoInformadoAux = '';
        let fileAux = null;            
        /*
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
        */
            fileAux = {                                      
                    name: this.state.arquivoNovo.name,                    
                    size: this.state.arquivoNovo.size,
                    type: this.state.arquivoNovo.type,                    
                    conteudo: ''
            }
            arquivoInformadoAux = 'S';

            this.setState({file: fileAux});
            this.setState({arquivoInformado: arquivoInformadoAux});
            
        //}

    }

    handleChangeLinkNovo(e){
        this.setState({
            linkNovo: e.target.value,
            arquivoNovo: '',
            file: '',
            arquivoInformado: ''
        });        
    }

    validaData(data){
        var partesData = data.split("-");
        var dataAjustada = new Date(partesData[2], partesData[1] - 1, partesData[0]-1);
        if(dataAjustada > new Date()){
            return false;
        }
        return true;
   
    }
    handleChangeDataAssinatura(e){
        if(this.validaData(e.target.value) === false){
            alert("Não é possível informar uma data futura.");
            this.setState({dataAssinatura: ''});
            document.getElementById("dataAssinatura").value = '';
        }
        else{
             this.setState({dataAssinatura: e.target.value});        
            }
        }

    handleChangeDataAprovacao(e){
        if(this.validaData(e.target.value) === false){
            alert("Não é possível informar uma data futura.");
            this.setState({dataAprovacao: ''});
            document.getElementById("dataAprovacao").value = '';
        }
        else{
            this.setState({dataAprovacao: e.target.value});     
        }   
    }

    handleChangeHoraInicioEntrevista(e){
    
        this.setState({horaInicioEntrevista: e.target.value});        
    }

    handleChangeHoraTerminoEntrevista(e){
    
        this.setState({horaTerminoEntrevista: e.target.value});        
    }

    handleChangeHoraInicio(e){
    
        this.setState({horaInicio: e.target.value});        
    }

    handleChangeHoraTermino(e){
    
        this.setState({horaTermino: e.target.value});        
    }

    handleChangeDataEntrevista(e){
        this.setState({dataEntrevista: e.target.value});        
    }

    handleChangeDataReuniao(e){
        this.setState({dataReuniao: e.target.value});        
    }

    handleChangeObservacao(e){
        this.setState({
            observacao: e.target.value,
            countObs: e.target.value.length
        });
    }

    handleNumeroDocumentoExterno(e){
        this.setState({numeroDocumentoExterno: e.target.value});
    }

    handleRemetente(e){
        this.setState({remetente: e.target.value});
    }

    handleChangeTemplate(e){
        this.setState({template: e.target.value});

        let camposDocumentoRecebido = document.getElementById('camposDocumentoRecebido');
        let linhaDataReuniao = document.getElementById('linhaDataReuniao');
        let linhaLocalizacaoEntrevista = document.getElementById('linhaLocalizacaoEntrevista');
        let linhaDataEntrevista = document.getElementById('linhaDataEntrevista');
        let linhaCoordenador = document.getElementById('linhaCoordenador');
        let linhaEntrevistador = document.getElementById('linhaEntrevistador');
        let linhaEntrevistado = document.getElementById('linhaEntrevistado');
        let linhaAbas = document.getElementById('linhaAbas');
        let linhaTitulo = document.getElementById('linhaTitulo');
        let linhaObs = document.getElementById('linhaObs');
        let linhaResponsavel = document.getElementById('linhaResponsavel');
        let linhaParticipantes = document.getElementById('linhaParticipantes');

        if(e.target.value === "5"){
            linhaAbas.style.display = "none";
            linhaTitulo.style.display = "none";
            linhaObs.style.display = "none";
            linhaLocalizacaoEntrevista.style.display = "flex";
            linhaDataEntrevista.style.display = "flex";
            linhaCoordenador.style.display = "block";
            linhaEntrevistador.style.display = "block";
            linhaEntrevistado.style.display = "block";
        }
        else{
            linhaAbas.style.display = "flex";
            linhaTitulo.style.display = "flex";
            linhaObs.style.display = "flex";
            linhaLocalizacaoEntrevista.style.display = "none";
            linhaDataEntrevista.style.display = "none";
            linhaCoordenador.style.display = "none";
            linhaEntrevistador.style.display = "none";
            linhaEntrevistado.style.display = "none";

            this.setState({
                cidadeEntrevista: '',
                ufEntrevista: '',
                dataEntrevista: '',
                horaInicioEntrevista: '',
                horaTerminoEntrevista: '',
                nomeCoordenador:'',
                emailCoordenador:'',
                idEntrevistador: '',
                nomeEntrevistador: '',
                emailEntrevistador:'',
                cpfEntrevistador:'',
                idEntrevistadorExtra: '',
                nomeEntrevistadorExtra: '',
                emailEntrevistadorExtra:'',
                cpfEntrevistadorExtra:'',
                nomeEntrevistado: '',
                emailEntrevistado:'',
                cpfEntrevistado:'',
                instituicaoEntrevistado:'',
                tipoInstituicaoEntrevistado:''
            });

            document.getElementById("dataEntrevista").value = '';
            document.getElementById("cidadeEntrevista").value = '';
            document.getElementById("estadoEntrevista").value = '0';
            document.getElementById("nomeCoordenador").value = '';
            document.getElementById("emailCoordenador").value = '';
            document.getElementById("nomeEntrevistador").value = '0';
            document.getElementById("emailEntrevistador").value = '';
            document.getElementById("cpfEntrevistador").value = '';
            document.getElementById("nomeEntrevistadorExtra").value = '0';
            document.getElementById("emailEntrevistadorExtra").value = '';
            document.getElementById("cpfEntrevistadorExtra").value = '';
            document.getElementById("nomeEntrevistado").value = '';
            document.getElementById("emailEntrevistado").value = '';
            document.getElementById("cpfEntrevistado").value = '';
            document.getElementById("tipoInstituicaoEntrevistado").value = '0';
            document.getElementById("instituicaoEntrevistado").value = '';
        }



        if(e.target.value === "4"){
            camposDocumentoRecebido.style.display = 'flex';
            document.getElementById("novoLinkOpcional").style.display = 'none';
            document.getElementById("novoArquivoOpcional").style.display = 'none';
        }
        else{
            camposDocumentoRecebido.style.display = 'none';
            document.getElementById("novoLinkOpcional").style.display = 'flex';
            document.getElementById("novoArquivoOpcional").style.display = 'flex';
        }

        if(e.target.value === "1"){

            linhaDataReuniao.style.display = 'flex';
            linhaResponsavel.style.display = 'flex';
            linhaParticipantes.style.display = 'flex';
            
            let payload = parseJwt(this.state.token);
            
            this.setState({
                nomeResponsavel: payload['nome'] !== undefined ? payload['nome'] : '',
                emailResponsavel: payload['email'] !== undefined ? payload['email'] : ''
            });
        }
        else{
            linhaDataReuniao.style.display = 'none';
            linhaResponsavel.style.display = 'none';
            linhaParticipantes.style.display = 'none';

            this.setState({
                dataReuniao: '',
                nomeResponsavel:'',
                emailResponsavel:''
            });
            document.getElementById("dataReuniao").value = '';
        }

    }

    async carregarDadosEquipe(id){
                
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }

        const response = await api(apiURL+"equipe/getMembrosEntrevista/"+id, requestOptions);
        const resp = await response.json();

        return resp;

    }

    preencherCamposDadosEntrevista(data){

        this.setState({
            nomeCoordenador: data['coordenador'] !== null && data['coordenador'] !== undefined && data['coordenador']['NOME'] !== null && data['coordenador']['NOME'] !== undefined ? data['coordenador']['NOME'] : '',
            emailCoordenador: data['coordenador'] !== null && data['coordenador'] !== undefined && data['coordenador']['EMAIL'] !== null && data['coordenador']['EMAIL'] !== undefined ? data['coordenador']['EMAIL'] : '',
            nomeEntrevistador : 0,
            nomeEntrevistadorExtra : 0,
            entrevistadores: data['entrevistadores'] !== null && data['entrevistadores'] !== undefined ? data['entrevistadores'] : '',
            entrevistadoresExtra: data['entrevistadores'] !== null && data['entrevistadores'] !== undefined ? data['entrevistadores'] : '',
            emailEntrevistador: '',
            cpfEntrevistador: '',
            emailEntrevistadorExtra: '',
            cpfEntrevistadorExtra: '',
            emissor: data['equipe']['COD_EQUIPE'] !== null && data['equipe']['COD_EQUIPE']  !== undefined ? data['equipe']['COD_EQUIPE'] : document.getElementById("emissor").value
        });

        let selectEntrevistador = document.getElementById('nomeEntrevistador');
        let selectEntrevistadorExtra = document.getElementById('nomeEntrevistadorExtra');
  
        //Limpar select
        var length = selectEntrevistador.options.length;
        for (let i = length-1; i >= 0; i--) {
            selectEntrevistador.remove(i);
        }

        length = selectEntrevistadorExtra.options.length;
        for (let i = length-1; i >= 0; i--) {
            selectEntrevistadorExtra.remove(i);
        }

        //Carregar select com o resultado
        let option = new Option('Selecione', '0');
        let opcao = new Option('Selecione', '0');                                        
        selectEntrevistador.options[0] = option;
        selectEntrevistadorExtra.options[0] = opcao;

        if(this.state.entrevistadores !== ''){
            let contador = 1;            
            data["entrevistadores"].forEach(temp => {                    
                option = new Option(temp[0]['NOME'], temp[0]['ID']);
                selectEntrevistador.options[contador] = option;
                //selectEntrevistadorExtra.options[contador] = option;
                contador++;
            });
        }

        if(this.state.entrevistadoresExtra !== ''){
            let contador = 1;            
            data["entrevistadores"].forEach(temp => {                    
                option = new Option(temp[0]['NOME'], temp[0]['ID']);
                selectEntrevistadorExtra.options[contador] = option;
                contador++;
            });
        }

        selectEntrevistador.value = '0';
        selectEntrevistadorExtra.value = '0';

    }

    handleChangeEmissor(e){

        this.setState({emissor: e.target.value});

        if(this.state.template === "5"){
            this.carregarDadosEquipe(e.target.value).then(data => {
                this.preencherCamposDadosEntrevista(data);
            }).catch(erro => {
                console.log('Erro ao carregar dados da equipe: ' + erro);
            });
        }

    }

    handleChangeTitulo(e){
        this.setState({titulo: e.target.value});
    }

    limparCamposAnexo(){

        this.setState({
            linkAnexo:'',
            arquivoAnexo: '',
            idAnexo: '',
            descricaoAnexo: ''
        });

        let idAnexo = document.getElementById("idAnexo");
        let linkAnexo = document.getElementById("linkAnexo");
        let arquivoAnexo = document.getElementById("arquivoAnexo");
        let descricaoAnexo = document.getElementById("descricaoAnexo");
        let modalTitulo = document.getElementById("modalAnexoLabel");

        modalTitulo.value = 'Inclusão de Anexos';                                                                                            
    
        idAnexo.value = '';
    
        if(linkAnexo !== null && linkAnexo !== undefined)
            linkAnexo.value = '';

        if(arquivoAnexo !== null && arquivoAnexo !== undefined)
            arquivoAnexo.value = '';

        descricaoAnexo.value = '';
            
    }

    atualizarAnexo(){
        
        if(this.state.descricaoAnexo === null ||this.state.descricaoAnexo === undefined ||this.state.descricaoAnexo === ''){
            alert('Por gentileza preencha o campo título do anexo!');
        }
        else{

            document.getElementById("fecharModalAnexo").click();

            alert('Anexo atualizado com sucesso! \nA alteração será efetivada quando o usuário salvar as alterações realizadas no documento.');
            
        }

    }

    salvarAnexo(){

        if( 
            (
                    (this.state.linkAnexo === null ||this.state.linkAnexo === undefined ||this.state.linkAnexo === '')
                &&  (this.state.fileAnexo === null ||this.state.fileAnexo === undefined ||this.state.fileAnexo.name === '')
            )   
            || (this.state.descricaoAnexo === null ||this.state.descricaoAnexo === undefined ||this.state.descricaoAnexo === '')
        ){
            
            alert('Gentileza verique se os campos abaixo estão preenchidos:\n\tTítulo \n\tLink ou Arquivo');
            
        }
        else{
            if(this.state.linkAnexo !== null && this.state.linkAnexo !== undefined && this.state.linkAnexo !== '' && validarURLDoc(this.state.linkAnexo) === false){
                alert('Link do anexo é inválido!\n' +
                    'Certifique-se de informar os seguintes itens:\n\n' +
                    'Protocolo (Ex. http:// ou https://)\n' +
                    'Verificar se não há espaços em branco\n' +
                    'Verificar se a url termina com /nome_do_arquivo.pdf ou /nome_do_arquivo.doc ou /nome_do_arquivo.docx'); 
            }
            else{

                let uniqueId = Date.now();
            
                let anexo = {
                    id: this.state.idAnexo !== '' ? this.state.idAnexo : uniqueId, 
                    link: this.state.linkAnexo,
                    arquivo: this.state.fileAnexo,
                    descricao: this.state.descricaoAnexo
                };
                        
                let anexosAux = this.state.anexos.slice(0);
                anexosAux.push(anexo);
                this.setState({anexos: anexosAux});

                anexosAux = this.state.anexosAdd.slice(0);
                anexosAux.push(anexo);
                this.setState({anexosAdd: anexosAux});
        
                this.limparCamposAnexo();

                document.getElementById("fecharModalAnexo").click();

                alert('Anexo adicionado à lista com sucesso! \nEle será efetivado quando o usuário salvar as alterações realizadas no documento.');

            }
        }
    }


    salvarTramitacao(){
        let dataTramitacao = document.getElementById('dataTramitacao');
        let responsavelTramitacao = document.getElementById('responsavelTramitacao');
        let idTramitacao = document.getElementById('idTramitacao');
        let nomeDestinatario = document.getElementById('nomeDestinatario');
        let email = document.getElementById('emailDestinatario');
        let cargo = document.getElementById('cargo');
        let instituicao = document.getElementById('instituicao');
        let rua = document.getElementById('rua');
        let numero = document.getElementById('numero');
        let complemento = document.getElementById('complemento');
        let bairro = document.getElementById('bairro');
        let cidade = document.getElementById('cidade');
        let estado = document.getElementById('estadoDadosEnvio');
        let cep = document.getElementById('cep');

        let tramitacaoAux = {
            id: idTramitacao.value, 
            nomeDestinatario: nomeDestinatario.value,
            email: email.value,
            cargo: cargo.value,
            instituicao: instituicao.value,
            rua: rua.value,
            numero: numero.value,
            complemento:complemento.value,
            bairro: bairro.value,
            cidade: cidade.value,
            estado: estado.value,
            cep: cep.value,
            data: dataTramitacao.value,
            responsavel: responsavelTramitacao.value
        };     
        
        //console.log(tramitacaoAux);
        //console.log(this.state.tramitacao);
        this.setState({tramitacao: tramitacaoAux});
        //console.log(tramitacaoAux);
        //console.log(this.state.tramitacao);

   }

    limparTemplate(e){
        //alert(e.keyCode);
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({template: ''});
            document.getElementById("template").selectedIndex = 0;
            //console.log('Entrou no limpar');
        }
    }

    limparDataEntrevista(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataEntrevista: ''});
            document.getElementById("dataEntrevista").value = '';
        }
    }

    limparDataReuniao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataReuniao: ''});
            document.getElementById("dataReuniao").value = '';
        }
    }

    limparDataAssinatura(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataAssinatura: ''});
            document.getElementById("dataAssinatura").value = '';
        }
    }

    limparDataAprovacao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataAprovacao: ''});
            document.getElementById("dataAprovacao").value = '';
        }
    }
    iniciarModalAnexo(){
        document.getElementById("adicionarAnexoBotao").style.display = 'block';
        document.getElementById("atualizarAnexoBotao").style.display = 'none';
        document.getElementById("modalAnexoLabel").innerHTML = 'Inclusão de Anexo';
        document.getElementById("abaEscolhaAnexo").style.display = 'block';
    }

    handleOnChangeUFEntrevista(e){
        this.setState({ufEntrevista: e.target.value});
    }

    handleOnChangeCidadeEntrevista(e){
        this.setState({cidadeEntrevista: e.target.value});
    }

    handleChangeEntrevistador(e){

        this.setState({idEntrevistador: e.target.value});
        
        let id = e.target.value;
        if(id === "0"){
            this.setState({
                emailEntrevistador: '',
                cpfEntrevistador: ''
            });
        }
        else {
            if(this.state.entrevistadores !== null && this.state.entrevistadores !== undefined && this.state.entrevistadores !== ''){
                this.state.entrevistadores.forEach(data => {

                    if(data[0]["ID"] === parseInt(id)){
                        this.setState({
                            emailEntrevistador: data[0]["EMAIL"],
                            cpfEntrevistador: data[0]["CPF"]
                        });
                    }          
                });
            }
        }

    }

    handleChangeIdEntrevistador(id){

        this.carregarDadosEquipe(this.state.emissor).then(data => {
            this.preencherCamposDadosEntrevista(data);
        }).catch(erro => {
            console.log('Erro ao carregar dados da equipe: ' + erro);
        });

        this.setState({idEntrevistador: id});
        
    }

    handleBotaoVoltar(){
        if(this.state.origem === 'solicitacao'){
            document.getElementById("linkVoltarSolicitacao").click();
        }
        else{
            document.getElementById("linkVoltarPesquisa").click();
        }
    }

	render(){

        const { loading } = this.context;

	    return (		
			
            <div className="container-xl-fluid">         
                <Menu idMenuOpen = "btnDocumentos"></Menu>             
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container-fluid">  
                                    <br/>
                                    <Titulo name={this.state.tituloPagina} tituloDocumento={": "+this.state.titulo} className=".mt-3"/>        
                                                                   
                                    <div className="row my-1">    
                                        <div className = "col">                                            
                                            <form className="container" encType="multipart/form-data">
                                                <div className="alert alert-danger erroLogin" role="alert" id="erro">
                                                </div>
                                                    <div className="alert alert-success" role="alert" id="linkDocumentoGoogle" style={{display:'none'}}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xs-12 col-sm-12 col-md-12">                                                           
                                                                <span id="mensagemCriacao" style={{color: 'blue', marginLeft: '5px'}}> SEU DOCUMENTO FOI CRIADO COM SUCESSO.</span>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-12">
                                                                <a className="btn btn-primary my-3" href={this.state.linkDocumento} target="_blank" rel="noopener noreferrer" >
                                                                        <span id="linkEditarConteudo">Editar conteúdo</span>
                                                                        <i className="fab fa-google-drive iconeGoogleDrive"></i>    
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                <div className="row  mt-xs-3 mt-sm-3 mt-md-6" id="fixos" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="numeroDocumento">Número do documento</label>
                                                        <input type="text" className="form-control" id="numeroDocumento" value={this.state.numeroDocumento} readOnly/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataCriacao">Data de criação</label>
                                                        <label className="form-control" id="dataCriacao" readOnly> {this.state.dataCriacao} </label>
                                                    </div>                    
                                                </div>                                                                                   
                                                <div className="row my-3">
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="template">Documento</label>
                                                        <select id="template"className="form-select" onChange={this.handleChangeTemplate}>                    
                                                        </select>
                                                    </div>                                                
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="emissor">Emissor</label>
                                                        <select id="emissor" className="form-select" onChange={this.handleChangeEmissor}>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaLocalizacaoEntrevista"  style={{display: "none"}}>  
                                                    <ComboEstados id="estadoEntrevista" onChange={this.handleOnChangeUFEntrevista}> </ComboEstados>   
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="cidadeEntrevista">Cidade</label>
                                                        <input type="text" className="form-control" id="cidadeEntrevista" onChange={this.handleOnChangeCidadeEntrevista}/>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaAbas">  
                                                    <div className="col-xs-12 col-sm-12 col">
                                                        <Tabs id="abaEscolhaDocumento">
                                                            <TabList>
                                                                <Tab><span className="d-flex">Novo a partir de link <span className="mx-1" id="novoLinkOpcional">(opcional)</span></span></Tab>
                                                                <Tab><span className="d-flex">Novo a partir de arquivo <span className="mx-1" id="novoArquivoOpcional">(opcional)</span></span></Tab>
                                                            </TabList>
                                                            <TabPanel >
                                                                <label htmlFor="linkNovo">Insira o link:</label>
                                                                <input type="text" className="form-control" id="linkNovo" onChange={this.handleChangeLinkNovo}
                                                                    
                                                                />
                                                            </TabPanel>
                                                            <TabPanel >
                                                            <label htmlFor="arquivoNovo">Selecione o arquivo:</label>
                                                                <input type="file" accept="application/pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" className="form-control" id="arquivoNovo" name="arquivoNovo" onChange={this.handleChangeArquivoNovo}/>
                                                            </TabPanel>
                                                        </Tabs>
                                                        <div id="linkDocumentoInformado" style={{display: 'none'}}>  
                                                            <label htmlFor="linkDocumentoInformado">Link para o documento informado: </label>    
                                                            <a className="btn mx-1" href={this.state.linkDocumento} target="_blank" rel="noopener noreferrer" ><i className="fas fa-external-link-alt"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" id="camposDocumentoRecebido" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="numeroDocumentoExterno">Número do documento externo (opcional)</label>
                                                        <input type="text" className="form-control" id="numeroDocumentoExterno" onChange={this.handleNumeroDocumentoExterno}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="remetente">Remetente (opcional)</label>
                                                        <input className="form-control" id="remetente" onChange={this.handleRemetente}/>
                                                    </div>                    
                                                </div>   
                                                <div className="row my-3" id="linhaDataEntrevista"  style={{display: "none"}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataEntrevista">Data da Entrevista</label>
                                                        <input type="date" className="form-control" id="dataEntrevista" onKeyUp={this.limparDataEntrevista}  onChange={this.handleChangeDataEntrevista}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaInicioEntrevista">Hora de início</label>
                                                        <MaskedInput value={this.state.horaInicioEntrevista} mask="99:99" className="form-control" id="horaInicioEntrevista" onChange={this.handleChangeHoraInicioEntrevista}></MaskedInput>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaTermino">Hora de fim</label>
                                                        <MaskedInput value={this.state.horaTerminoEntrevista} mask="99:99" className="form-control" id="horaTerminoEntrevista" onChange={this.handleChangeHoraTerminoEntrevista}></MaskedInput>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col">
                                                        <label><b>Situação:</b> {this.state.situacaoDocumento}</label>
                                                    </div>
                                                </div>                                                
                                                <div className="row my-3" id="solicitador" style={{display:'none'}}>
                                                    <div className="col">
                                                        <label><b>Solicitado por: </b> {this.state.nomeSolicitante}</label>
                                                    </div>
                                                    <div className="col">
                                                        <label><b>E-mail:</b> <Link to="#" onClick={() =>{
                                                            if(document.getElementById("botaoModalEnviarEmail") !== null){
                                                                this.setState({origemEmail: 'contato'});
                                                                document.getElementById("botaoModalEnviarEmail").click();
                                                            }
                                                        }}>{this.state.emailSolicitante}</Link></label>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaTitulo">
                                                    <div className="col">
                                                        <label htmlFor="titulo">Título</label>
                                                        <input type="text" className="form-control" id="titulo" required onChange={this.handleChangeTitulo}/>
                                                    </div>
                                                </div>        
                                                <div id="linhaCoordenador"  style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>COORDENADOR DA EQUIPE REGIONAL</b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeCoordenador">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6">
                                                            <input type="text" className="form-control" id="nomeCoordenador" value={this.state.nomeCoordenador} readOnly/>    
                                                        </div>                                                        
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailCoordenador">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailCoordenador" value={this.state.emailCoordenador} readOnly/>    
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div id="linhaEntrevistador"  style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>ENTREVISTADOR </b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeEntrevistador">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6" style={{display:"flex", verticalAlign:"middle"}}>
                                                            <select id="nomeEntrevistador"className="form-select" onChange={this.handleChangeEntrevistador} >       
                                                                <option value="0">Selecione...</option>             
                                                            </select>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-2" id="botaoAdicionarEntrevistador">
                                                            <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#modalEntrevistador">
                                                            <i className="fas fa-plus"></i>
                                                            </button>
                                                            <ModalAdicionarEntrevistador
                                                                idEntrevistador={this.state.idEntrevistador}
                                                                handleChangeEntrevistador = {this.handleChangeIdEntrevistador}
                                                            >                                                                
                                                            </ModalAdicionarEntrevistador>
                                                        </div>

                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailEntrevistador">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailEntrevistador" value={this.state.emailEntrevistador} readOnly/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="cpfEntrevistador">CPF</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <MaskedInput required mask="999.999.999-99" className="form-control" id="cpfEntrevistador" value={this.state.cpfEntrevistador} readOnly={true}></MaskedInput>    
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div  className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="haEntrevistadorExtra">Há mais um entrevistador?</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-2">    
                                                            
                                                            <select id="haEntrevistadorExtra"className="form-select">       
                                                                <option value="N">Não</option>
                                                                <option value="S">Sim</option>             
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="linhaEntrevistadorExtra"  style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>ENTREVISTADOR </b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeEntrevistadorExtra">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6" style={{display:"flex", verticalAlign:"middle"}}>
                                                            <select id="nomeEntrevistadorExtra"className="form-select" >       
                                                                <option value="0">Selecione...</option>             
                                                            </select>
                                                        </div>                                                       
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailEntrevistadorExtra">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailEntrevistadorExtra" value={this.state.emailEntrevistadorExtra} readOnly/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="cpfEntrevistadorExtra">CPF</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <MaskedInput required mask="999.999.999-99" className="form-control" id="cpfEntrevistadorExtra" value={this.state.cpfEntrevistadorExtra} readOnly={true}></MaskedInput>    
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="linhaEntrevistado" style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>ENTREVISTADO </b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeEntrevistado">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6">
                                                            <input type="text" className="form-control" id="nomeEntrevistado" onChange={this.handleChangeNomeEntrevistado} value={this.state.nomeEntrevistado } required/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="cpfEntrevistado">CPF</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <MaskedInput required mask="999.999.999-99" className="form-control" id="cpfEntrevistado" onChange={this.handleChangeCpfEntrevistado} value={this.state.cpfEntrevistado}></MaskedInput>
                                                        </div>

                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailEntrevistado">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailEntrevistado" onChange={this.handleChangeEmailEntrevistado} value={this.state.emailEntrevistado} required/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="instituicaoEntrevistado">Instituição</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="instituicaoEntrevistado" onChange={this.handleChangeInstituicaoEntrevistado} value={this.state.instituicaoEntrevistado} required/>    
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-2">
                                                            <label htmlFor="tipoInstituicao">Tipo instituição</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-5">
                                                            <select id="tipoInstituicaoEntrevistado" className="form-select" onChange={this.handleChangeTipoInstituicaoEntrevistado}>  
                                                                <option value="selecione">Selecione...</option>
                                                                <option value="gestao_publica">GESTÃO PÚBLICA</option>                  
                                                                <option value="empresa_privada">EMPRESA PRIVADA</option>
                                                                <option value="instituicao_fomento">INSTITUIÇÃO DE FOMENTO</option>
                                                                <option value="federacao">FEDERAÇÃO</option>
                                                                <option value="confederacao">CONFEDERAÇÃO</option>
                                                                <option value="associacao">ASSOCIAÇÃO</option>
                                                                <option value="cooperativa">COOPERATIVA</option> 
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row my-3" id="linhaDataReuniao" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataReuniao">Data da reunião</label>
                                                        <input type="date" className="form-control" id="dataReuniao" onKeyUp={this.limparDataReuniao}  onChange={this.handleChangeDataReuniao}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaInicio">Início da Reunião</label>
                                                        <MaskedInput value={this.state.horaInicio} mask="99:99" className="form-control" id="horaInicio" onChange={this.handleChangeHoraInicio}></MaskedInput>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaTermino">Término da Reunião</label>
                                                        <MaskedInput value={this.state.horaTermino} mask="99:99" className="form-control" id="horaTermino" onChange={this.handleChangeHoraTermino}></MaskedInput>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaResponsavel" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="nomeResponsavel">Responsável</label>
                                                        <input type="text" className="form-control" id="nomeResponsavel" value={this.state.nomeResponsavel} onChange={this.handleChangeNomeResponsavel}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="emailResponsavel">E-mail do Responsável</label>
                                                        <input type="text" className="form-control" id="emailResponsavel" value={this.state.emailResponsavel} onChange={this.handleChangeEmailResponsavel}/>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaParticipantes" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-12 col-12">
                                                        <ListaParticipantes
                                                            participantes = {this.state.participantes}
                                                            removerParticipante = {this.removerParticipante}
                                                            adicionarParticipante = {this.adicionarParticipante}
                                                        ></ListaParticipantes>        
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaDatas" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataAprovacao">Data de aprovação (opcional)</label>
                                                        <input type="date" className="form-control" id="dataAprovacao" value={this.state.dataAprovacao} readOnly/>
                                                    </div>
                                                    {
                                                        this.context.getPermission(permissions.DEFINIR_DATA_ASSINATURA_DOCUMENTO) &&
                                                        <div className = "col-xs-12 col-sm-12 col-md-6">
                                                            <label htmlFor="dataAssinatura">Data de assinatura (opcional)</label>
                                                            <input type="date" className="form-control" id="dataAssinatura" 
                                                                    value={this.state.dataAssinatura ?? ""} 
                                                                    onKeyUp={this.limparDataAssinatura}  onChange={this.handleChangeDataAssinatura}/>
                                                        </div>
                                                    }
                                                    {
                                                        !this.context.getPermission(permissions.DEFINIR_DATA_ASSINATURA_DOCUMENTO) &&
                                                        <div className = "col-xs-12 col-sm-12 col-md-6">
                                                            <label htmlFor="dataAssinatura">Data de assinatura (opcional)</label>
                                                            <input type="date" disabled className="form-control" value={this.state.dataAssinatura ?? ""}  />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row my-4" id="linhaTramitacao" style={{display: 'none'}} >
                                                    <div className="col">
                                                        <div className="accordion" id="accordionTramitacao">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        Envio de documento (opcional)
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body">
                                                                        <ModalDocumentoEdit tramitacao={this.state.tramitacao}></ModalDocumentoEdit>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaAnexos" style={{display: 'none'}}>
                                                    <div className="col">
                                                        <div>
                                                            <label>Arquivos e links associados (opcional)</label>
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead  className="table-light">
                                                                        <tr>                        
                                                                            <th scope="col-xs-10 col-sm-10 col-md-10">Título</th>
                                                                            <th scope="col-xs-2 col-sm-2 col-md-2">Ação</th>
                                                                        </tr>
                                                                    </thead>                 
                                                                    <tbody>                                                                        
                                                                        {

                                                                            this.state.anexos.map(dado => (
                                                                                
                                                                                <tr id={dado.id} key={dado.id}>
                                                                                    <th scope="col">
                                                                                        <input type="hidden" value={dado.id}></input>
                                                                                        <input type="hidden" value={dado.link}></input>
                                                                                        <label>{dado.descricao}</label>
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        <button style={{display:'none'}} onClick={(e) =>{
                                                                                            e.preventDefault();
                                                                                            
                                                                                            let idAnexo = document.getElementById("idAnexo");
                                                                                            let linkAnexo = document.getElementById("linkAnexo");
                                                                                            let descricaoAnexo = document.getElementById("descricaoAnexo");
                                                                                            let botaoModal = document.getElementById('modalAnexoNovo');
                                                                                            let modalTitulo = document.getElementById("modalAnexoLabel");
                                                                                            let abaEscolhaAnexo = document.getElementById("abaEscolhaAnexo");
                                                                                            
                                                                                            modalTitulo.innerHTML = 'Edição de Anexos';                                                                                           

                                                                                            idAnexo.value = dado.id;
                                                                                            if(linkAnexo !== null && linkAnexo!== undefined)
                                                                                                linkAnexo.value = dado.link !== '' ? dado.link : '';
                                                                                            descricaoAnexo.value = dado.descricao
                                                                                            
                                                                                            abaEscolhaAnexo.style.display = 'none';

                                                                                            botaoModal.click();

                                                                                            document.getElementById("adicionarAnexoBotao").style.display = 'none';
                                                                                            document.getElementById("atualizarAnexoBotao").style.display = 'block';

                                                                                        }} className="btn mx-1"><i className="far fa-edit"></i></button> 

                                                                                        {dado.link !== null && dado.link !== undefined && dado.link!== ''  && (
                                                                                            <a className="btn mx-1" href={dado.link} target="_blank" rel="noopener noreferrer" ><i className="fas fa-external-link-alt"></i></a>
                                                                                        )}
                                                                                        <button onClick={(e) =>{
                                                                                            e.preventDefault();
                                                                                            
                                                                                            try{

                                                                                                if(window.confirm("Você tem certeza que quer excluir o anexo: '"+dado.descricao+"'?")){
                                                                                                    let anexosRemover = this.state.anexosRemove.splice(0);
                                                                                                    anexosRemover.push(dado);
                                                                                                    this.setState({anexosRemove: anexosRemover});
                                                                                                    document.getElementById(dado.id).style.display = 'none';
                                                                                                    alert('Anexo removido da lista com sucesso! \nA remoção será efetivada quando o usuário salvar as alterações realizadas no documento.');
                                                                                                }

                                                                                            }
                                                                                            catch(erro){
                                                                                                console.log(erro);
                                                                                            }
                                                                                        }} className="btn mx-1 size-3"><i className="fas fa-times"></i></button>
                                                                                    </th>

                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>                
                                                                </table>
                                                            </div>
                                                        </div>                                                        
                                                        <div className="row">
                                                            <div className="col d-flex flex-row-reverse">
                                                                <div>
                                                                    <button onClick={this.iniciarModalAnexo} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAnexo" id="modalAnexoNovo">
                                                                        NOVO ANEXO
                                                                    </button>

                                                                    <div className="modal fade" id="modalAnexo" tabIndex="-1" aria-labelledby="modalAnexoLabel" aria-hidden="true">
                                                                        <div className="modal-dialog">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="modalAnexoLabel">Inclusão de anexos</h5>
                                                                                    <button id="fecharModalAnexo" type="button" onClick={this.limparCamposAnexo} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    <Tabs id="abaEscolhaAnexo">
                                                                                    <TabList>
                                                                                        <Tab>Link</Tab>
                                                                                        <Tab>Arquivo</Tab>
                                                                                    </TabList>
                                                                                    <TabPanel >
                                                                                        <label htmlFor="link">Insira o link:</label>
                                                                                        <input type="text" className="form-control" id="linkAnexo" onChange={this.handleOnChangeLinkAnexo}/>
                                                                                    </TabPanel>
                                                                                    <TabPanel >
                                                                                        <label htmlFor="anexo">Selecione o arquivo:</label>
                                                                                        <input type="file" className="form-control" id="arquivoAnexo" onChange={this.handleOnChangeArquivoAnexo}/>
                                                                                    </TabPanel>
                                                                                    </Tabs>
                                                                                    <div className="row my-3">
                                                                                    <div className="col">
                                                                                        <label htmlFor="descricaoAnexo" >Título do Anexo:</label>
                                                                                        <input type="text" className="form-control" id="descricaoAnexo" value={this.state.descricaoAnexo} onChange={this.handleOnChangeDescricaoAnexo}/>
                                                                                        <input type="hidden" id="idAnexo" onChange={this.handleOnChangeIdAnexo} ></input>                       
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="modal-footer">´
                                                                                    <button id="adicionarAnexoBotao" type="button" className="btn btn-primary"  onClick={this.salvarAnexo}  >Adicionar</button>
                                                                                    <button id="atualizarAnexoBotao" type="button" className="btn btn-primary" style={{display:'none'}}  onClick={this.atualizarAnexo}  >Atualizar</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaObs">
                                                    <div className="col">
                                                        <label htmlFor="observacao">Observações (opcional)</label>
                                                        <textarea 
                                                            value={this.state.observacao !== null && this.state.observacao !== undefined ? this.state.observacao :''} 
                                                            className="form-control" 
                                                            id="observacao" 
                                                            onChange={this.handleChangeObservacao} 
                                                            rows="5" 
                                                            maxLength={1500}
                                                            readOnly={this.state.editarObservacao}>
                                                        </textarea>
                                                        <small style={{ float: 'right' }}>{this.state.countObs}/1500</small>
                                                    </div>
                                                </div>
                                                <div className="d-flex my-3 align-items-center justify-content-end">
                                                    {
                                                        (
                                                            (
                                                                this.state.id === 0 &&
                                                                this.context.getPermission(permissions.CRIAR_DOCUMENTOS)
                                                            )
                                                            ||
                                                            (
                                                                this.state.id > 0 &&
                                                                this.context.getPermission(permissions.CRIAR_DOCUMENTOS_SOLICITADOS) &&
                                                                this.state.situacaoDocumento === 'SOLICITADO'
                                                            )
                                                            ||
                                                            (
                                                                this.state.id > 0 &&
                                                                this.context.getPermission(permissions.CRIAR_DOCUMENTOS_REJEITADOS) &&
                                                                this.state.situacaoDocumento === 'REJEITADO'
                                                            )
                                                        )
                                                        &&
                                                        <button type="submit" className="btn btn-primary my-3"
                                                            onClick={this.handleSubmitCriar} disabled={loading}>
                                                            CRIAR DOCUMENTO
                                                        </button>
                                                    }
                                                        <button type="submit" className="btn btn-primary my-3 mx-3" id='botaoAtualizarDocumento'
                                                            style={{ display: 'none' }} onClick={this.handleAtualizar} 
                                                            disabled={loading || !this.context.getPermission(permissions.EDITAR_DOCUMENTOS)}>
                                                            SALVAR ALTERAÇÕES
                                                        </button> 
                                                    {
                                                        (
                                                            this.state.id > 0 &&
                                                            this.context.getPermission(permissions.SOLICITAR_APROVACAO_DOCUMENTO) &&
                                                            !this.context.getPermission(permissions.APROVAR_DOCUMENTOS) &&
                                                            this.state.situacaoDocumento === 'MINUTA'
                                                        )
                                                        &&
                                                        <button type="submit" className="btn btn-primary my-3 mx-3"
                                                            onClick={this.handleSolicitarAprovacao} 
                                                            disabled={loading || !this.context.getPermission(permissions.SOLICITAR_APROVACAO_DOCUMENTO)}>
                                                                SOLICITAR APROVAÇÃO
                                                        </button>   
                                                    }
                                                    {
                                                        (
                                                            this.state.id > 0 &&
                                                            this.context.getPermission(permissions.APROVAR_DOCUMENTOS) &&
                                                            (this.state.situacaoDocumento === "APROVAÇÃO" || this.state.situacaoDocumento === "MINUTA")
                                                        )
                                                        &&
                                                        <button type="submit" className="btn btn-primary my-3 mx-3"
                                                            onClick={this.handleAprovarDocumento} 
                                                            disabled={loading || !this.context.getPermission(permissions.APROVAR_DOCUMENTOS)}>
                                                                APROVAR DOCUMENTO
                                                        </button>           
                                                    }
                                                    {
                                                        (
                                                            this.state.id > 0 &&
                                                            (
                                                                (
                                                                    this.state.situacaoDocumento === 'SOLICITADO' &&
                                                                    this.state.isSolicitante &&                                                                    
                                                                    this.context.getPermission(permissions.SOLICITAR_CANCELAMENTO_DOCUMENTOS)
                                                                ) ||
                                                                (
                                                                    (
                                                                        this.state.situacaoDocumento === 'SOLICITADO' ||
                                                                        this.state.situacaoDocumento === 'APROVAÇÃO' 
                                                                    ) &&
                                                                    !this.state.isSolicitante &&
                                                                    this.context.getPermission(permissions.REJEITAR_SOLICITACAO_DOCUMENTOS)
                                                                    
                                                                )
                                                            )
                                                        )
                                                        &&
                                                        <button type="submit" className="btn btn-danger my-3 mx-3"
                                                            id="botaoArquivarDocumento"
                                                            onClick={this.handleSubmitArquivar} 
                                                            disabled={loading}>                                                            
                                                                REJEITAR SOLICITAÇÃO
                                                        </button>                                                                                                               
                                                    }
                                                    {
                                                        (
                                                            this.state.id > 0 &&
                                                            this.state.situacaoDocumento === "MINUTA" &&
                                                            !this.state.isSolicitante &&
                                                            this.context.getPermission(permissions.CANCELAR_DOCUMENTO)
                                                        )
                                                        &&
                                                        <button type="submit" className="btn btn-danger my-3 mx-3"
                                                            id="botaoRejeitarDocumento"
                                                            onClick={this.handleSubmitRejeitarDocumento} 
                                                            disabled={loading || !this.context.getPermission(permissions.CANCELAR_DOCUMENTO)}>                                                            
                                                                REJEITAR DOCUMENTO
                                                        </button>                                                                                                               
                                                    }
                                                    <button type="button" className="btn btn-outline-primary my-3 mx-3"
                                                        onClick={this.handleBotaoVoltar} id="botaoVoltar" disabled={loading}>
                                                            VOLTAR
                                                    </button>
                                                    <Link style={{display: 'none'}} className="btn" id="linkVoltarPesquisa" to="/painel/GestaoDocumento/DocumentoPesquisa"></Link>
                                                    <Link style={{display: 'none'}} className="btn" id="linkVoltarSolicitacao" to="/painel/GestaoDocumento/SolicitacaoDocumento"></Link>
                                                    <button id="botaoModalEnviarEmail" style={{display: 'none'}}className="btn" type="button" data-bs-toggle="modal" data-bs-target="#modalEnviarEmail"></button>
                                                    <ModalEnviarEmailAvulso
                                                        id={this.state.id}
                                                        handleSituacaoDocumento = {this.handleSituacaoDocumento}
                                                        nome = {this.state.origemEmail === 'botaoSolicitanteRejeitarSolicitacao' ? 'Secretaria do Projeto' : this.state.nomeSolicitante}
                                                        email = {this.state.origemEmail === 'botaoSolicitanteRejeitarSolicitacao' ? 'secretaria@redeartesanatobrasil.com.br' : this.state.emailSolicitante}
                                                        origem = {this.state.origemEmail}
                                                    >                
                                                    </ModalEnviarEmailAvulso>
                                                </div>
                                            </form>
                                        </div>
                                    </div>  
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>  
            </div>              
        );
    }


}

export default DocumentoEdit;