import React from 'react';
import logoRAB from './img/marcarab-tr.png';
import logoUFMG from './img/ufmgLogo.png';
import logoEquipeWeb from './img/equipeWEB.png';
import logoRABIcone from './img/simbolotr-quadrado.png';
import feedback from './img/bt-feedback.png';
import acaoMenu from '../../Components/Menu/acaoMenu';
import { apiURL } from '../../Services/env';
import { apiWithToken } from '../../Services/Api';
import { GlobalContext } from '../../Context/GlobalContext/context';


class LogoParceriaInterna extends React.Component {


    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckFeedback = this.handleCheckFeedback.bind(this);
        this.handleCheckErro = this.handleCheckErro.bind(this);
        this.handleCheckSugestao = this.handleCheckSugestao.bind(this);
        this.handleCheckOutros = this.handleCheckOutros.bind(this);
        this.handleObservacao = this.handleObservacao.bind(this);
        this.handleLink = this.handleLink.bind(this);
        this.handleArquivo = this.handleArquivo.bind(this);
        this.enviarFeedback = this.enviarFeedback.bind(this);
        this.limparCampos = this.limparCampos.bind(this);
        this.handleOutros = this.handleOutros.bind(this);

        this.state = {
            cpf: localStorage.getItem('projartCod'),
            nome: localStorage.getItem('projartUsuario'),
            checkFeedback: false,
            checkErro: false,
            checkSugestao: false,
            checkOutros: false,
            outros: '',
            observacao: '',
            link: '',
            file: {
                name: '',
                size: '',
                type: '',
                conteudo: ''
            },
            arquivo: '',
            referencia: props.referencia

        }

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    async enviarFeedback() {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: this.state.nome,
                cpf: this.state.cpf,
                checkFeedback: this.state.checkFeedback,
                checkErro: this.state.checkErro,
                checkSugestao: this.state.checkSugestao,
                checkOutros: this.state.checkOutros,
                outros: this.state.outros,
                observacao: this.state.observacao,
                link: this.state.link,
                foto: this.state.file
            })
        };

        try {

            const response = await apiWithToken(apiURL + "feedback", requestOptions);
            return await response.json();

        }
        catch (erro) {
            return "Erro ao enviar o feedback!";
        }

    }

    handleSubmit(e) {

        e.preventDefault();

        if (this.state.checkErro === false && this.state.checkFeedback &&
            this.state.checkSugestao && this.state.checkOutros) {
            alert("Por gentileza selecione o tipo da ocorrência!");
        }
        else {
            if (this.state.observacao === null || this.state.observacao === undefined || this.state.observacao === '') {
                alert("Por gentileza preencha o detalhamento do feedback!");
            }
            else {

                let spinner = document.getElementById("spinner");
                spinner.style.display = 'block';

                this.enviarFeedback().then(data => {
                    alert(data["mensagem"]);

                });


                this.limparCampos();

                spinner.style.display = 'none';

                let botaoFechar = document.getElementById("botaoFechar");
                botaoFechar.click();
            }

        }

    }

    limparCampos() {
        this.setState({
            cpf: localStorage.getItem('projartCod'),
            checkFeedback: false,
            checkErro: false,
            checkSugestao: false,
            checkOutros: false,
            outros: '',
            observacao: '',
            link: '',
            file: {
                name: '',
                size: '',
                type: '',
                conteudo: ''
            }
        });
        document.getElementById("checkFeedback").checked = false;
        document.getElementById("checkErro").checked = false;
        document.getElementById("checkSugestao").checked = false;
        document.getElementById("checkOutros").checked = false;
        document.getElementById("observacao").value = '';
        document.getElementById("outros").value = '';
        document.getElementById("link").value = '';
        document.getElementById("arquivo").files = null;

    }

    handleCheckFeedback(e) {
        this.setState({ checkFeedback: e.target.checked });
    }

    handleCheckErro(e) {
        this.setState({ checkErro: e.target.checked });
    }

    handleCheckSugestao(e) {
        this.setState({ checkSugestao: e.target.checked });
    }

    handleCheckOutros(e) {
        this.setState({ checkOutros: e.target.checked });
    }

    handleObservacao(e) {
        this.setState({ observacao: e.target.value });
    }

    handleLink(e) {
        this.setState({ link: e.target.value });
    }

    handleOutros(e) {
        this.setState({ outros: e.target.value });
    }

    handleArquivo(e) {

        this.setState({
            arquivo: e.target.files[0]
        });

        let fileAux = null;

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
            fileAux = {
                name: this.state.arquivo.name,
                size: this.state.arquivo.size,
                type: this.state.arquivo.type,
                conteudo: e.target.result
            }
            this.setState({ file: fileAux });

        }

    }

    render() {
        return (
            <div className="container-fluid cabecalhoPainel">
                <div className="row d-flex align-items-center">
                    <div className="col-xs-1 col-sm-1 col-md-1 col-1">
                        <button ref={this.state.referencia} 
                            onClick={acaoMenu} 
                            id="sidebarCollapse" 
                            type="button" 
                            className="btn btnToggleMenu" 
                            style={{ color: '#FFFFFF' }} 
                        > 
                            <i className="fa fa-bars mr-2"></i>
                        </button>
                    </div>
                    <div className="col-1 mobile">

                    </div>
                    <div className="col-10 logoParceria mobile">
                        <img src={logoRABIcone} alt="Ícone do logo da Rede Artesanato Brasil" className="logoImgRABIcone" /> Rede Artesanato Brasil
                    </div>
                    <div className="col-xs-0 col-sm-0 col-md-8 col-8 my-1 logos">
                        <img src={logoRAB} alt="logo da Rede Artesanato Brasil" className="logoImgRAB" />
                        <img src={logoUFMG} alt="logo da UFMG" className="logoImgUFMG" />
                    </div>
                    <div className="col-xs-0 col-sm-0 col-md-1 col-1 logout">
                        <a href='#/' onClick={this.context.logout} style={{ display: 'flex', alignItems: 'center' }}><i className="fas fa-sign-out-alt"></i> Sair</a>
                    </div>
                    <div className="col-xs-0 col-sm-0 col-md-2 col-2 d-flex flex-row-reverse feedback my-0 py-0">
                        <button data-bs-toggle="modal" data-bs-target="#modalFeedBack" className="feedback">
                            <img src={feedback} alt="botaoFeed" />
                        </button>

                        <div className="modal" id="modalFeedBack">
                            <div className="modal-dialog">
                                <div className="modal-content fundoModal">
                                    <div className="modal-body">
                                        <form >
                                            <div className="row my-3">
                                                <div className="col align-items-center">
                                                    <center>
                                                        <img src={logoEquipeWeb} alt="Logo equipe WEB" />
                                                    </center>
                                                </div>
                                            </div>

                                            <div className="row my-1">
                                                <div className="col">
                                                    <h4> Enviar Feedback </h4>
                                                </div>
                                            </div>
                                            <div className="row my-1">
                                                <div className="col-xs-12 col-sm-12 col-md-4 col-5">
                                                    <span style={{ fontSize: '14px' }}>Tipo de ocorrência</span>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-8 col-7">
                                                    <input type="checkbox" className="form-check-input" id="checkFeedback" onChange={this.handleCheckFeedback} />
                                                    <label className="form-check-label mx-1" htmlFor="checkFeedback" >FEEDBACK</label>
                                                    <br />
                                                    <input type="checkbox" className="form-check-input" id="checkErro" onChange={this.handleCheckErro} />
                                                    <label className="form-check-label mx-1" htmlFor="checkErro">ERRO</label>
                                                    <br />
                                                    <input type="checkbox" className="form-check-input" id="checkSugestao" onChange={this.handleCheckSugestao} />
                                                    <label className="form-check-label mx-1" htmlFor="checkSugestao">SUGESTÃO</label>
                                                    <br />
                                                    <input type="checkbox" className="form-check-input" id="checkOutros" onClick={() => {
                                                        let textoOutro = document.getElementById("outros");
                                                        if (textoOutro.disabled) {
                                                            textoOutro.disabled = false;
                                                        }
                                                        else {
                                                            textoOutro.disabled = true;
                                                        }
                                                    }} onChange={this.handleCheckOutros} />
                                                    <label className="form-check-label mx-1" htmlFor="checkOutros">OUTROS</label>
                                                    <br />
                                                    <input disabled type="text" className="form-control" placeholder="Preencha em caso de 'OUTROS'" id="outros" onChange={this.handleOutros} />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col">
                                                    <label style={{ fontSize: '14px' }} htmlFor="observacao">Descreva objetivamente</label>
                                                    <textarea className="form-control" id="observacao" rows="5" onChange={this.handleObservacao}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col">
                                                    <label htmlFor="link" style={{ fontSize: '14px' }}>Insira o link da página de erro: (Opcional)</label>
                                                    <input type="text" className="form-control" id="link" onChange={this.handleLink} />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col">
                                                    <label htmlFor="arquivo" style={{ fontSize: '14px' }}>Envie um print da tela: (Opcional)</label>
                                                    <input type="file" className="form-control" id="arquivo" onChange={this.handleArquivo} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button id="botaoFechar" type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Enviar</button>
                                        <span style={{ display: 'none' }} id="spinner" className="spinner-border spinner-border-sm mx-3 text-primary" role="status" aria-hidden="true"> </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );

    }


}
export default LogoParceriaInterna;