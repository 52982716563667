import { Component } from "react";
import LogoParceriaInterna from "../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../Components/Menu/Menu";
import Titulo from "../../../Components/Titulo";
import { GlobalContext } from "../../../Context/GlobalContext/context";
import { apiURL } from "../../../Services/env";
import logo_projeto_red from "../../../Components/Menu/simbolotr.png";
import { apiWithToken, login } from "../../../Services/Api";
import {ButtonBack} from "../../../Components/ButtonBack";

export default class Termos extends Component {

    static contextType = GlobalContext;

    state = {
        termo: '',
        nome: '', 
        nacionalidade: '',
        profissao: '',
        rg: '',
        cpf: '',
        orgao_rg: '',
        cep: '',
        endereco: '',
        cidade: '',
        bairro: '',
        estado: '',
    }

    componentDidMount(){
        this.getDados();
    }

    getDados = () => {
        
        this.context.startLoading();

        const requestOptions = {
            method: 'GET'
        };
        apiWithToken(apiURL + "pessoa/termo", requestOptions)
            .then(async res => {
                const json = await res.json();
                if (res.status === 200) return json
                else if (json.redirect) {
                        alert(json.mensagem)
                        window.location.href = json.redirect;
                    }
                else {
                    login();
                }
            })
            .then(res => {
                this.setState({ 
                    nome: res.usuario.pessoa.NOME,
                    nacionalidade: res.usuario.pessoa.NACIONALIDADE,
                    profissao: res.usuario.pessoa.PROFISSAO,
                    rg: res.usuario.pessoa.RG,
                    cpf: res.usuario.pessoa.CPF,
                    orgao_rg: res.usuario.pessoa.ORGAO_RG,
                    cep: res.usuario.pessoa.ENDERECO_CEP,
                    endereco: res.usuario.pessoa.ENDERECO_BAIRRO + ", " + res.usuario.pessoa.ENDERECO_CIDADE + " - " + res.usuario.pessoa.ENDERECO_UF,
                    cidade: res.usuario.pessoa.ENDERECO_CIDADE,
                    bairro: res.usuario.pessoa.ENDERECO_BAIRRO,
                    estado: res.usuario.pessoa.ENDERECO_UF,
                    termo: res.usuario.pessoa.TERMO
                })
            })
            .finally(() => {
                this.context.stopLoading();
            })
    
    }

    handleTermo = (e) => {

        const op = window.confirm("Confirmar:");

        if (op) {
            
            this.context.startLoading();
      
            const formData = new FormData();
            formData.append('termo', e.target.checked ? 'SIM' : 'NAO')
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('projartToken')
                }),
                body: formData
            };

            fetch(apiURL + "pessoa/termo", requestOptions)
                .then(async res => await res.json())
                .then(res => {
                    if (res['status'] && res['status'] === true) {
                        alert(res['message']);
                    }
                })
                .catch(err => console.log(err))
                .finally(() => {
                    this.context.stopLoading();
                    this.getDados();
                })
        }
        else {
            e.target.checked = false;
        }
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu></Menu>
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container">
                                    <ButtonBack
                                        to="/painel/minhaConta?tab=4"
                                    />
                                    <div className="row my-4" >
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <Titulo name="Termo de confidencialidade" className="text-center text-uppercase my-4" />
                                                    <p className="text-uppercase text-center">
                                                        UNIVERSIDADE FEDERAL DE MINAS GERAIS<br />
                                                        PRÓ-REITORIA DE EXTENSÃO<br />
                                                    </p>
                                                    <p className="text-center">
                                                        <img width={100} src={logo_projeto_red} alt='logo' />
                                                    </p>
                                                </div>
                                                <div className="col-md-12 mt-5">
                                                    <p style={{ textAlign: 'justify' }}>
                                                        Eu, {this.state.nome}, {this.state.nacionalidade}, {this.state.profissao}, portador da carteira de identidade n.º {this.state.rg}, expedida pelo {this.state.orgao_rg}, e do CPF n.º {this.state.cpf}, residente e domiciliado na {this.state.endereco}, {this.state.bairro}, {this.state.cep}, {this.state.cidade + "-" + this.state.estado}, assumo o compromisso de manter confidencialidade e sigilo sobre todas as informações técnicas, científicas, metodologias, processos e observações apresentadas e discutidas no âmbito do projeto de Estruturação do Sistema de Gestão do Artesanato Brasileiro: Diagnóstico e Planejamento Estratégico.
                                                        Estou ciente também que não poderei fazer registro fotográfico pessoal, filmar ou mesmo gravar informalmente as discussões, apresentações técnicas e/ou outras informações verbais ocorridas no ambiente do referido projeto. 
                                                    </p>
                                                    <p className="text-uppercase">
                                                        Considerando as expressões assim definidas:
                                                    </p>
                                                    <p style={{ textAlign: 'justify' }}>
                                                        <strong>“Informação Confidencial”</strong> significará toda informação revelada através da execução do projeto sob a forma escrita, verbal ou por quaisquer outros meios.
                                                    </p>
                                                    <p style={{ textAlign: 'justify' }}>
                                                        <strong>“Informação Confidencial”</strong> inclui, mas não se limita à informação relativa às operações, processos, planos ou intenções, discussões, entrevistas, reuniões, dados, habilidades especializadas, métodos e metodologia, fluxogramas, especificações, componentes, produtos, amostras, diagramas, desenhos, documentos e possíveis oportunidades de mercado revelados durante a execução do projeto.
                                                    </p>
                                                    <p className="text-uppercase">
                                                        Por este termo de confidencialidade e sigilo comprometo-me também:
                                                    </p>
                                                    <div>
                                                        <ul style={{ textAlign: 'justify' }}>
                                                            <li>
                                                                A não utilizar as informações confidenciais a que tiver acesso, divara gerar benefício próprio exclusivo e/ou unilateral, presente ou futuro, ou para o uso de terceiros;
                                                            </li>
                                                            <li>
                                                                A não apropriar para si ou para outrem de material confidencial e/ou sigiloso das informações que venham a ser disponibilizadas;
                                                            </li>
                                                            <li>
                                                                A não repassar o conhecimento das informações confidenciais, por todas as pessoas que vierem a ter acesso às informações, por seu intermédio, e obrigando-se, assim, a ressarcir a ocorrência de qualquer dano e/ou prejuízo oriundo de uma eventual quebra de sigilo das informações fornecidas sem a autorização escrita da Coordenação Geral do projeto.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <p style={{ textAlign: 'justify' }}>
                                                        A vigência da obrigação de confidencialidade e sigilo, assumida pela minha pessoa, por meio deste termo, só poderá ser quebrada mediante autorização por escrito, concedida à minha pessoa, pela Coordenação Geral do projeto.
                                                    </p>
                                                    <p style={{ textAlign: 'justify' }}>
                                                        Pelo não cumprimento do presente Termo de Confidencialidade e Sigilo, fica o abaixo assinado ciente de todas as sanções judiciais que poderão advir.
                                                    </p>

                                                </div>
                                                <hr />
                                                <div className="col-md-12">
                                                    {this.state.termo === 'NAO' && <div className='form-check float-end'>
                                                        <input type="checkbox" className="form-check-input" id="termos" onChange={this.handleTermo} />
                                                        <label className='required form-check-label' htmlFor="termos">Concordo com os termos</label>
                                                    </div>}

                                                    {this.state.termo === 'SIM' && <div className='form-check float-end'>
                                                        <input type="checkbox" disabled className="form-check-input" id='termos' checked />
                                                        <label className='required form-check-label' htmlFor="termos">Concordo com os termos</label>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
