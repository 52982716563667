import React from 'react';
import ValidacaoEmail from '../Validacao';
import { apiURL } from '../../Services/env';
import { api } from "../../Services/Api";
import { GlobalContext } from '../../Context/GlobalContext/context';
import * as permissions from "../../Context/GlobalContext/permissions";
import Select from 'react-select';

class ListaParticipantes extends React.Component{

    static contextType = GlobalContext;

    constructor(props){
        super(props);

        this.handleChangeNomeParticipante = this.handleChangeNomeParticipante.bind(this);
        this.handleChangeEmailParticipante = this.handleChangeEmailParticipante.bind(this);
        this.handleAdicionarParticipante = this.handleAdicionarParticipante.bind(this);
        this.validarParticipante = this.validarParticipante.bind(this);
        this.removerParticipante = props.removerParticipante;
        this.adicionarParticipante = props.adicionarParticipante;
        this.handleChangeSelectNomeParticipante = this.handleChangeSelectNomeParticipante.bind(this);
        this.handleChangeSelectEmailParticipante = this.handleChangeSelectEmailParticipante.bind(this);
        this.recuperarParticipantes = this.recuperarParticipantes.bind(this);
        this.inicializarSelects = this.inicializarSelects.bind(this);

        this.mensagemValidacao = '';

        this.state = {
            nomeParticipante: '',
            emailParticipante: '',
            participantes: props.participantes,
            nome_participante_pesquisar: null,
            email_participante_pesquisar: '',
            listaNome: [],
            listaEmail: [],
            token: localStorage.getItem("projartToken")    
        };
    }

    componentDidMount(){
        if (!this.context.getPermission(permissions.EDITAR_DOCUMENTOS) && 
            !this.context.getPermission(permissions.CRIAR_DOCUMENTOS)) {
                alert('Permissão negada!');
                window.location.href = '/painel'
        }
        this.context.startLoading();

        this.recuperarParticipantes().then(data =>{
            this.inicializarSelects(data);
              
        }).catch()
        .finally(() => this.context.stopLoading())
    }

    async recuperarParticipantes(){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token
            })
        };
        
        const response = await api(apiURL+"documentoSuporte/recuperarParticipantes", requestOptions);
        return await response.json();
            
    }

    inicializarSelects(data){
                
            if(data["mensagem"] !== 'sucesso'){
                alert(data["mensagem"]);
            }
            else{        
                this.setState({listaNome: data["listaNomeParticipantes"]});
                this.setState({listaEmail: data["listaEmailParticipantes"]});            
            }
        

    }

    componentDidUpdate(prevProps) {
        // Uso típico, (não esqueça de comparar as props):
        if (this.props.participantes !== prevProps.participantes) {
          this.setState({participantes: this.props.participantes});
        }
    }

    handleChangeNomeParticipante(e){
        this.setState({nomeParticipante: e.target.value});
    }

    handleChangeEmailParticipante(e){
        this.setState({emailParticipante: e.target.value});
    }

    handleChangeSelectNomeParticipante(e){

        this.setState({
            nomeParticipante: e !== null  ? e.label : '',
            emailParticipante: e !== null  ? e.value : '',
            nome_participante_pesquisar: null
        });

    }

    handleChangeSelectEmailParticipante(e){
        this.setState({
            emailParticipante: e !== null ? e.label : '',
            email_participante_pesquisar: e !== null ? e.label : ''
        });
    }

    validarParticipante(){

        let retorno = true;
        let mensagem = '';

        if(this.state.nomeParticipante === null || this.state.nomeParticipante === undefined || this.state.nomeParticipante === ''){
            retorno = false;
            mensagem = mensagem + '\n\tNome do participante';
        }
        
        if(this.state.emailParticipante === null || this.state.emailParticipante === undefined || this.state.emailParticipante === ''){
            retorno = false;
            mensagem = mensagem + '\n\tE-mail do participante';
        }
        else{
            if(ValidacaoEmail(this.state.emailParticipante) === false){
                retorno = false;
                mensagem = mensagem + '\n\tE-mail do participante inválido!';
            }
        }

        this.mensagemValidacao = mensagem;
        return retorno;

    }

    handleAdicionarParticipante(){

        if(this.validarParticipante() === false){
            alert('Certifique-se de preencher os campos: '+this.mensagemValidacao);
        }
        else{
            let uniqueId = Date.now();

            let participante = {
                id: uniqueId, 
                nome: this.state.nomeParticipante,
                email: this.state.emailParticipante            
            };

            let participantesAux = this.state.participantes.slice(0);
            participantesAux.push(participante);

            this.setState({
                participantes: participantesAux,
                nomeParticipante: '',
                emailParticipante: '',
                nome_participante_pesquisar: null
            });

            document.getElementById('listaNome').value = null;
            //document.getElementById('listaEmail').value = null;

            this.adicionarParticipante(participante);
                        
        }
    }

    render(){            
        return (
            <div>
                <div className = "row">
                    <div className = "col-xs-12 col-sm-12 col-md-12 col-12">
                        <label>Participantes</label>                                  
                    </div>
                </div>
                <div className="row  my-3">
                    <div className = "col-xs-12, col-sm-12 col-md-5">
                        <label htmlFor="listaNome">Selecione o participante:</label>
                        <Select id ='listaNome'
                            isClearable={true}                           
                            onChange={this.handleChangeSelectNomeParticipante}
                            options={this.state.listaNome}
                            placeholder={"Digite ou selecione"}     
                            value = {this.state.nome_participante_pesquisar}
                        />
                    </div>
                </div>    
                <div className="row my-3">
                    <div className = "col-xs-12, col-sm-12 col-md-5">
                        ou informe o nome e o e-mail do participante:
                    </div>
                </div>
                <div className="row  my-3">
                    <div className = "col-xs-12 col-sm-12 col-md-5">
                        <label htmlFor="nomeParticipante">Nome</label>
                        <input type="text" className="form-control" id="nomeParticipante" value={this.state.nomeParticipante} onChange={this.handleChangeNomeParticipante}/>
                    </div>
                    <div className = "col-xs-12 col-sm-12 col-md-5">
                        <label htmlFor="emailParticipante">E-mail</label>
                        <input type="text" className="form-control" id="emailParticipante" value={this.state.emailParticipante} onChange={this.handleChangeEmailParticipante}/>
                    </div>
                    <div className = "col-xs-12 col-sm-12 col-md-2" style={{lineHeight:'80px', verticalAlign: 'bottom'}}>
                        <button className="btn" type="button" id="botaoAdicionarParticipante" onClick={this.handleAdicionarParticipante}>
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead  className="table-light">
                                    <tr>                        
                                        <th scope="col-xs-5 col-sm-5 col-md-5">Nome</th>
                                        <th scope="col-xs-5 col-sm-5 col-md-5">E-mail</th>
                                        <th scope="col-xs-2 col-sm-2 col-md-2">Ação</th>
                                    </tr>
                                </thead> 
                                <tbody>           
                                    {
                                        this.state.participantes.map(dado => (
                                            <tr id={dado.id} key={dado.id}>
                                                <th scope="col">
                                                    <input type="hidden" value={dado.id}></input>
                                                    <label>{dado.nome}</label>
                                                </th>
                                                <th scope="col">
                                                    <label>{dado.email}</label>
                                                </th>
                                                <th scope="col">
                                                    <button onClick={(e) =>{
                                                        e.preventDefault();
                                                                                
                                                        try{

                                                            if(window.confirm("Você tem certeza que quer excluir o participante: '"+dado.nome+"'?")){
                                                                
                                                                this.removerParticipante(dado);

                                                                document.getElementById(dado.id).style.display = 'none';
                                                                alert('Participante removido da lista com sucesso! \nA remoção será efetivada quando o usuário criar o documento / salvar as alterações.');
                                                            }

                                                        }
                                                        catch(erro){
                                                            console.log(erro);
                                                        }
                                                    }} className="btn mx-1 size-3"><i className="fas fa-times"></i></button>
                                                </th>
                                            </tr>
                                        ))
                                    }
                                </tbody>             
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ListaParticipantes;