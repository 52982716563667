import { Component } from "react";
import LogoParceriaInterna from "../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../Components/Menu/Menu";
import { GlobalContext } from "../../../Context/GlobalContext/context";
import { apiURL } from "../../../Services/env";
import { apiWithToken } from "../../../Services/Api";
import {ButtonBack} from "../../../Components/ButtonBack";

export default class TermoParticipacaoDetalhesUsuario extends Component {

    static contextType = GlobalContext;

    state = {
        pessoa: {},
        participacao: {},
        status: false
    }

    componentDidMount(){
        this.getDados();
    }

    getDados = () => {
        
        this.context.startLoading();
        apiWithToken(apiURL + "participacao/getDados")
            .then(async res => {
                const json = await res.json();
                const {pessoa, data} = json.resultado;
                this.setState({
                    pessoa,
                    participacao: data
                })
                if (!data) {
                    alert("Acesso negado! Solicite junto a secretaria o termo de participação para assinatura.");
                    window.location = "/painel/minhaConta?tab=4"
                }
                else if (!data.DATA_ASSINATURA){
                    alert("Acesso negado! É necessário aceitar os termos, consulte o seu e-mail ou solicite outro link de acesso junto a secretaria.");
                    window.location = "/painel/minhaConta?tab=4"
                }
                else {
                    this.setState({
                        status: true
                    })
                }
            })
            .finally(() => {
                this.context.stopLoading();
            })
    
    }

    downloadPdf = () => {
        this.context.startLoading();
        fetch(apiURL + "participacao/download?token=" + this.state.participacao.TOKEN_ACESSO + "&pessoa=" + this.state.pessoa.ID + "&participacao=" + this.state.participacao.ID)
            .then(async res => await res.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob,{type: 'application/pdf;charset=UTF-8'});
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Termo de participação.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .finally(() => {
                this.context.stopLoading();
            })
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu></Menu>
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container">
                                    <ButtonBack
                                        to="/painel/minhaConta?tab=4"
                                    />
                                    <div className="row my-4" >
                                        <div className='col-md-12'>
                                            {
                                                this.state.participacao && this.state.status &&
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                            <h4 className="text-center text-uppercase fw-bold">
                                                                Participação em pesquisa  nas modalidades <br />
                                                                grupo de discussão e/ou entrevista
                                                            </h4>
                                                        <div className="text-center">
                                                            <h5 className="text-uppercase">
                                                                Termo de consentimento livre e esclarecido (tcle)
                                                            </h5>
                                                            <small>
                                                                (Em atendimento à Resolução 466/12 do Conselho <br />Nacional de Saúde/Ministério da Saúde)<br />
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-12 mt-3">
                                                        <div className="text-center">
                                                            <h5 className="text-uppercase">
                                                                Consentimento
                                                            </h5>
                                                        </div>
                                                        <p style={{ textAlign: 'justify' }}>
                                                            Eu, {this.state.pessoa.NOME}, residente à Rua/Av.  {this.state.pessoa.ENDERECO_RUA}, {this.state.pessoa.ENDERECO_NUMERO},  bairro, {this.state.pessoa.ENDERECO_BAIRRO}, na cidade {this.state.pessoa.ENDERECO_CIDADE}, 
                                                            {" " + this.state.pessoa.ENDERECO_UF} - CEP: {this.state.pessoa.ENDERECO_CEP2}, identiﬁcado pelo CPF/RG {this.state.pessoa.CPF2}/{this.state.pessoa.RG}-{this.state.pessoa.ORGAO_RG}.
                                                        </p>
                                                        <p>
                                                            DECLARO QUE:
                                                        </p>
                                                        <div>
                                                            <ol style={{listStyle: "lower-alpha"}}>
                                                                <li>
                                                                    Fui devidamente informado(a) sobre todos os aspectos e motivações desta pesquisa, pude esclarecer minhas dúvidas e sei que, a qualquer momento, 
                                                                    poderei solicitar novas informações e modiﬁcar minha decisão sobre a participação se assim o desejar.
                                                                </li>
                                                                <li>
                                                                    Concordo com a minha participação nesta pesquisa
                                                                </li>
                                                                <li>
                                                                    Concordo com o uso de gravação em áudio/vídeo durante o projeto, sem minha identiﬁcação.
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <p>
                                                            Número do IP, data  e hora do aceite digital ao TCLE: {this.state.participacao.IP_ASSINATURA} | {this.state.participacao.DATA_ASSINATURA} <br />
                                                            Meu e-mail: {this.state.pessoa.EMAIL}
                                                        </p> 
                                                        <p>
                                                            Responsável pela Pesquisa: Dra. Laura de Souza Cota Carvalho Silva Pinto
                                                        </p>
                                                        <h5 className="text-uppercase">
                                                            Contatos
                                                        </h5>
                                                        <p>
                                                            <strong>Responsável pela Pesquisa</strong><br />
                                                            Profa. Dra. Laura de Souza Cota Carvalho Silva Pinto - Telefone para contato: (31)98706-0201 - E-mail: <a href="mailto:coordenacao@redeartesanatobrasil.com.br">coordenacao@redeartesanatobrasil.com.br</a>
                                                        </p>
                                                        <p>
                                                            <strong>Instituição</strong><br />
                                                            Universidade Federal de Minas Gerais (UFMG). Endereço: Av. Antônio Carlos, Nº 6627, Pampulha, BH/MG, CEP: 31270-901 
                                                        </p>
                                                        <p>
                                                            <strong>Projeto aprovado pelo Comitê de Ética.</strong><br /> 
                                                            Em caso de dúvidas éticas ou para informar ocorrências irregulares ou danosas durante sua participação neste estudo, dirija-se ao <strong>Comitê de Ética em 
                                                            Pesquisa da Universidade Federal de Minas Gerais</strong>: Av. Antônio Carlos, 6627. Unidade Administrativa II -2º andar 
                                                            - Sala 2005. Campus Pampulha Belo Horizonte, MG -Brasil. Telefone: (31)3409-4592. e-mail: <a href="mailto:coep@prpq.ufmg.br">coep@prpq.ufmg.br</a>. 
                                                        </p>
                                                        <p>
                                                            <strong>REDE ARTESANATO BRASIL</strong><br /> 
                                                            Site: <a href="www.redeartesanatobrasil.com.br">www.redeartesanatobrasil.com.br</a>
                                                        </p>
                                                        <p>
                                                            <strong>Projeto Estruturação do Sistema de Gestão do Artesanato Brasileiro: iagnóstico e Planejamento Estratégico</strong><br /> 
                                                            PROEX-Av. Pres. Antônio Carlos, 6627 – Campus da Pampulha Prédio da Reitoria, 6º andar / CEP: 31270-010 Pampulha, Belo Horizonte – MG – BRASIL CEP 31270-010 
                                                        </p>
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-12 text-end">
                                                        <button 
                                                            className="btn btn-primary"
                                                            onClick={this.downloadPdf}
                                                        >
                                                            <i className="fas fa-file-pdf me-2"></i>
                                                            Download PDF
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
