export default function validarEmail(email){
    let expressao = new RegExp(/^\w+([.]?\w+)*@\w+([.]?\w+)*(\.\w\w+)+$/);
    return expressao.test(email);
}

export function validarURL(url){
    let pattern = new RegExp(/(ftp|http|https):\/\/(www\d?|[a-zA-Z0-9]+)?\.[a-zA-Z0-9-]+((:|\.)([a-zA-Z0-9.]+|(\d+)?)([/?:].*)?)?/);
    return pattern.test(url);    
}

export function validarURLDoc(url){
    let patterBrancos = new RegExp(/\s/) // verifica se tem espaços
    let patternHttp = new RegExp(/^(http|https):\/\//); // verifica se começa com http ou https
    let patternTipoDoc = new RegExp(/\.(pdf|doc|docx)$/); // verifica se termina com .pdf, .doc ou .docx

    return !patterBrancos.test(url) && patternHttp.test(url) && patternTipoDoc.test(url);    
}

export function validarHora(hora){

    if(hora.length !== 4){
        return false;
    }
    else{

        let digito1 = parseInt(hora[0]);
        let digito2 = parseInt(hora[1]);
        let digito3 = parseInt(hora[2]);

        if(digito1 >= 0 && digito1 <= 2){
            
            if(digito1 === 1 && (digito2 < 0 || digito2 > 9 )){
                return false;
            }

            if(digito1 === 2 && (digito2 < 0 || digito2 > 3 )){
                return false;
            }

            if(digito3 < 0 || digito3 > 5 ){
                return false;
            }

        }    
        else 
            return false;        
    }
    
    return true;
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};