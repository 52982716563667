import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import LogoParceriaInterna from "../../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../../Components/Menu/Menu";
import Titulo from "../../../../Components/Titulo";
import { GlobalContext } from "../../../../Context/GlobalContext/context";
import { apiURL } from "../../../../Services/env";
import { api } from '../../../../Services/Api';
import Select from "react-select";
import SelectCreatable from "react-select/creatable";
import ReactTooltip from "react-tooltip";
import * as permissions from "../../../../Context/GlobalContext/permissions";

export default class ManterEquipeUsuario extends Component {


    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.referencia = React.createRef();

        this.state = {
            isMobile: false,
            usuarios: [],
            equipes: [],
            papeis: [],
            siglasEquipes: [],
            siglasPapeis: [],
            papeisEquipe: [],
            papeisUsuarioNaEquipe: [],
            equipesPaginado: [],
            page: 0,
            offset: 0,
            limit: 10,
            pageCount: 0,
            pesquisa: '',
            pesquisaType: '',

            equipe_pessoa_papel_id: null,
            usuario_pesquisar: null,
            usuario: null,
            equipe: null,
            papel: null,
            atuacao: '',
            condicao: '',
            condicoes: [
                {
                    label: 'CONTRATANTE',
                    value: 'CONTRATANTE',
                },
                {
                    label: 'COLABORADOR EXTERNO',
                    value: 'COLABORADOR EXTERNO',
                },
                {
                    label: 'COLABORADOR INTERNO',
                    value: 'COLABORADOR INTERNO',
                },
                {
                    label: 'BOLSISTA',
                    value: 'BOLSISTA',
                },
                {
                    label: 'PESQUISADOR',
                    value: 'PESQUISADOR',
                },
                {
                    label: 'PROFESSOR',
                    value: 'PROFESSOR',
                },
            ],
            data_entrada: '',
            data_saida: '',
            area_publica: ''
        }
    }

    componentDidMount() {
        if (!this.context.getPermission(permissions.VISUALIZAR_USUARIOS_FUNCOES_EQUIPES) &&
            !this.context.getPermission(permissions.VISUALIZAR_USUARIOS_FUNCOES_TODAS_EQUIPES)
        ) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        this.setState({ isMobile: window.screen.width <= 400 })
        this.getDados();
    }


    getDados = (limit = 10, offset = 0, usuario = '', area_publica = '', equipe = '', papel = '') => {
        
        this.context.startLoading();

        const body = new FormData();
        body.append('limit', limit);
        body.append('offset', offset);
        body.append('usuario', usuario);
        body.append('area_publica', area_publica);
        body.append('equipe', equipe);
        body.append('papel', papel);
        
        const requestOptions = {
            method: 'POST',
            body
        }

        api(apiURL + 'gerirAcesso/manterEquipes/getDados', requestOptions)
            .then(async res => await res.json())
            .then(res => {
                this.setState({ siglasEquipes: res.equipes, siglasPapeis: res.papeis })
                this.setState({ equipesPaginado: res.resultado, pageCount: res.total, usuarios: res.usuarios })
            })
            .finally(() => {
                this.context.stopLoading()
            })

    }

    getPapeisEquipe = (cod_equipe) => {

        const select = document.getElementById('papel_salvar');
        select.selectedIndex = 0;
        select.disabled = true;

        const requestOptions = {
            method: 'POST'
        }
        
        api(apiURL + 'auxiliar/buscarPapeisEquipe/' + cod_equipe, requestOptions)
            .then(async res => await res.json())
            .then(res => this.setState({ papeisEquipe: res.resultado }))
            .finally(() => {
                this.getPapeisUsuarioNaEquipe(this.state.usuario.value, cod_equipe)
                select.disabled = false
            })
    }

    getPapeisUsuarioNaEquipe = (pessoa_id, cod_equipe) => {

        const body = new FormData();
        body.append('pessoa_id', pessoa_id);
        body.append('cod_equipe', cod_equipe);

        const requestOptions = {
            method: 'POST',
            body
        }
        
        api(apiURL + 'auxiliar/buscarPapeisEquipeUsuario', requestOptions)
            .then(async res => await res.json())
            .then(res => this.setState({ papeisUsuarioNaEquipe: res.resultado }))
    }

    paginate = (e) => {

        const page = e.selected;

        this.setState({ page, offset: page + 1 })

        if (this.state.pesquisaType !== '' && this.state.pesquisa !== '') {
            this.handlePesquisa(null, this.state.pesquisaType, this.state.pesquisa, this.state.limit, page + 1)            
        }
        else {
            this.getDados(this.state.limit, page + 1)
        }

    }

    limparFiltro = async (force = false) => {

        if (force) {

        
            document.getElementById('equipe_seletor').value = 'Todos';
            document.getElementById('papel_seletor').value = 'Todos';
            document.getElementById('area_publica').value = 'Todos';

            await this.setState({ page: 0, limit: 10, offset: 0, pesquisa: '', pesquisaType: '', usuario_pesquisar: null })
            this.getDados(this.state.limit, 0)
        }

        else {

            if (this.state.pesquisaType !== '' && this.state.pesquisa !== '') {
                this.handlePesquisa(null, this.state.pesquisaType, this.state.pesquisa, this.state.limit, this.state.offset)            
            }
            else if (this.state.page > 0){
                this.getDados(this.state.limit, this.state.page + 1)
            }
            else {
            
                document.getElementById('equipe_seletor').value = 'Todos';
                document.getElementById('papel_seletor').value = 'Todos';
                document.getElementById('area_publica').value = 'Todos';
    
                await this.setState({ page: 0, limit: 10, offset: 0, pesquisa: '', pesquisaType: '', usuario_pesquisar: null })
                this.getDados(this.state.limit, 0)
            }
        }
    }

    limpartForm = () => {
        this.setState({
            usuario: null,
            equipe: null,
            papel: null,
            atuacao: '',
            condicao: '',
            data_entrada: '',
            data_saida: '',
            area_publica: '',
        })
        document.getElementById('usuario_salvar').value = '';
        document.getElementById('equipe_salvar').selectedIndex = 0;
        document.getElementById('papel_salvar').selectedIndex = 0;
        document.getElementById('atuacao_salvar').value = '';
        document.getElementById('condicao_salvar').value = '';
        document.getElementById('data_entrada_salvar').value = '';
        document.getElementById('data_saida_salvar').value = '';
    }

    handlePesquisa = (e = null, type = 1, pesquisa ='', limit = 10, offset = 0) => {
        let texto = '';

        if (type === 1 && e !== null) texto = e?.value;
        else if ((type === 2 || type === 3 || type === 4) && e !== null) texto = e.target.value ?? '';
        else {
            texto = pesquisa;
        }

        if (texto === '' || texto === 'Todos') {
            this.setState({ page: 0, limit: 10, offset: 0, pesquisa: '', pesquisaType: '', usuario_pesquisar: null })
            this.getDados(this.state.limit, 0)
        }

        else {

            if (type === 3) {

                this.setState(s => {
                    this.getDados(limit, offset, '', texto);
                    return { ...s, pesquisa: texto, pesquisaType: type, page: 0, limit, offset, usuario_pesquisar: null }
                })

                document.getElementById('usuario_pesquisar').value = '';
                document.getElementById('equipe_seletor').value = 'Todos';
                document.getElementById('papel_seletor').value = 'Todos';
            }

            else if (type === 4) {

                this.setState(s => {
                    this.getDados(limit, offset, '', '', '', texto);
                    return { ...s, pesquisa: texto, pesquisaType: type, page: 0, limit, offset, usuario_pesquisar: null }
                })

                document.getElementById('usuario_pesquisar').value = '';
                document.getElementById('equipe_seletor').value = 'Todos';
                document.getElementById('area_publica').value = 'Todos';
            }

            else if(type === 2) {

                this.setState(s => {
                    this.getDados(limit, offset, '', '', texto);                    
                    return { ...s, pesquisa: texto, pesquisaType: type, page: 0, limit, offset, usuario_pesquisar: null }
                })
  
                document.getElementById('usuario_pesquisar').value = '';
                document.getElementById('papel_seletor').value = 'Todos';
                document.getElementById('area_publica').value = 'Todos';
            }

            else {

                this.setState(s => {
                    this.getDados(limit, offset, texto);
                    return { ...s, pesquisa: texto, pesquisaType: type, page: 0, limit, offset }
                })

                document.getElementById('equipe_seletor').value = 'Todos';
                document.getElementById('papel_seletor').value = 'Todos';
                document.getElementById('area_publica').value = 'Todos';
            }
        }
    }

    handleChangeStatusAreaPublica = (p, status) => {

        if (this.context.getPermission(permissions.OCULTAR_EXIBIR_USUARIOS_AREA_PUBLICA)) {   
            let message = (status === 'S') ? 'Exibir pessoa na área pública?' : 'Ocultar pessoa na área pública?';
            const op = window.confirm(message);

            if (op){

                let body = new FormData();
                body.append('cod_usuario_papel', p.COD_USUARIO_PAPEL)
                body.append('status', status)

                this.context.startLoading();

                api(apiURL + 'gerirAcesso/manterEquipes', {
                    method: 'POST',
                    body
                })
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.context.stopLoading()
                        this.limparFiltro();
                    })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleSetEquipe = async (e) => {
        const equipe = e.target.value
        this.setState({ equipe: equipe, papel: null })
        this.getPapeisEquipe(equipe);
        
    }

    salvarDados = () => {
        
        if (this.context.getPermission(permissions.CRIAR_USUARIOS_FUNCOES_EQUIPES) || this.context.getPermission(permissions.CRIAR_USUARIOS_FUNCOES_TODAS_EQUIPES)) {
            const usuario = this.state.usuario.value;
            const equipe = this.state.equipe;
            const papel = this.state.papel;
            const atuacao = this.state.atuacao;
            const condicao = this.state.condicao;
            const data_entrada = this.state.data_entrada;
            const data_saida = this.state.data_saida;

            if (usuario !== '' && usuario !== null && equipe !== '' && equipe !== null && papel !== '' && papel !== null){

                document.getElementById('btnCloseModal').click();

                this.context.startLoading();

                const body = new FormData();
                body.append('usuario', usuario);
                body.append('equipe', equipe);
                body.append('papel', papel);
                body.append('atuacao', atuacao);
                body.append('condicao', condicao.value ?? '');
                body.append('data_entrada', data_entrada);
                body.append('data_saida', data_saida);
                body.append('area_publica', this.state.area_publica === true ? 'N' : 'S');

                const requestOptions = {
                    method: 'POST',
                    body
                }

                api(apiURL + 'gerirAcesso/manterEquipes/salvarUsuarioEquipePapel', requestOptions)
                .then(async res => await res.json())
                .then(res => alert(res.message))
                .finally(() => {
                    this.limpartForm();
                    this.context.stopLoading();
                    this.limparFiltro();
                })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    removerUsuarioPapel = (id_pessoa, id_papel) => {

        if (this.context.getPermission(permissions.EXCLUIR_USUARIOS_FUNCOES_EQUIPES)) {
            const op = window.confirm("Confirme a exlusão do registro: ");
            if (op){

                this.context.startLoading();

                const body = new FormData();
                body.append('usuario', id_pessoa)
                body.append('papel', id_papel)

                const requestOptions = {
                    method: 'POST',
                    body
                }
                api(apiURL + 'gerirAcesso/manterEquipes/removerUsuarioPapel', requestOptions)
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.context.stopLoading();
                        this.limparFiltro();
                    })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }
    
    editarUsuarioPapel = (pessoa, equipe, papel) => {

        if (this.context.getPermission(permissions.EDITAR_USUARIOS_FUNCOES_EQUIPES)) {

            this.setState({
                equipe_pessoa_papel_id: papel.COD_USUARIO_PAPEL,
                pessoa: pessoa.ID ?? '',
                papel: papel.ID_PAPEL ?? '',
                equipe: equipe.COD_EQUIPE ?? '',
                atuacao: papel.ATUACAO ?? '',
                condicao: papel.CONDICAO ? { label:papel.CONDICAO, value: papel.CONDICAO } : '',
                data_entrada: papel.DATA_ENTRADA ?? '',
                data_saida: papel.DATA_SAIDA ?? '',
                area_publica: papel.AREA_PUBLICA ?? 'N',
            })
            
            document.getElementById('btnLaunchModalUpdate').click();
            document.getElementById('nome_pessoa_mostrar').value = pessoa.NOME;
            document.getElementById('nome_papel_mostrar').value = papel.NOME;
            document.getElementById('nome_equipe_mostrar').value = equipe.NOME;
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    atualizarUsuarioPapel = () => {
               
        if (this.context.getPermission(permissions.EDITAR_USUARIOS_FUNCOES_EQUIPES)) {   
            const atuacao = this.state.atuacao;
            const condicao = this.state.condicao;
            const data_entrada = this.state.data_entrada;
            const data_saida = this.state.data_saida;
            const area_publica = this.state.area_publica;

            this.context.startLoading();
            document.getElementById('btnCloseModalUpdate').click();

            const body = new FormData();
            body.append('id', this.state.equipe_pessoa_papel_id)
            body.append('atuacao', atuacao)
            body.append('condicao', condicao.value ?? '')
            body.append('data_entrada', data_entrada)
            body.append('data_saida', data_saida)
            body.append('area_publica', area_publica === '' ? 'N' : area_publica === true ? 'N' : 'S')

            const requestOptions = {
                method: 'POST',
                body
            }
            api(apiURL + 'gerirAcesso/manterEquipes/atualizarUsuarioPapel', requestOptions)
                .then(async res => await res.json())
                .then(res => alert(res.message))
                .finally(() => {
                    this.limpartForm()
                    this.context.stopLoading();
                    this.limparFiltro();
                })
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleUsuario = (e) => {
        this.setState({ usuario: e, equipe: null, papel: null })
        document.getElementById('equipe_salvar').selectedIndex = 0;
        document.getElementById('papel_salvar').selectedIndex = 0;
    }
    
    checkPessoaTemPapelNaequipe = (papel) => {

        if (this.state.papeisUsuarioNaEquipe.length > 0){
     
            let papeis = this.state.papeisUsuarioNaEquipe.map(f => f.COD_PAPEL);
            return papeis.includes(papel);
        }

        return false;
    }

    handleCondicao = (e) => {
        if (e === null || e === undefined || e === '') {
            this.setState({ condicao: '' })
            
        }else {

            this.setState({ condicao: e })
        }
    }
        
    handlePesquisarUsuario = e => {
        this.setState( s => {
            return { ...s, usuario_pesquisar: e }
        })
        this.handlePesquisa(e, 1)
    }
    
    componentDidUpdate(){
        ReactTooltip.rebuild()
    }

    handleLauchModalCreate = () => {
        if (this.context.getPermission(permissions.CRIAR_USUARIOS_FUNCOES_EQUIPES) || this.context.getPermission(permissions.CRIAR_USUARIOS_FUNCOES_TODAS_EQUIPES)) {
            document.getElementById('btnLaunchModalCreate').click();
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu idMenuOpen="btnAcesso"></Menu>
                <div className="page-content fundoAreaTrabalho" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid" >
                        <div className="row" >
                            <div className="col p-2" style={{ backgroundColor: '#F2F3F7' }}>
                                <div className="container-fluid">
                                    <br />
                                    <Titulo 
                                        name="Associar pessoas" 
                                        className="mt-3" 
                                        tooltip={{ 
                                            texto: `<p>Para adicionar uma pessoa com uma função a uma determinada equipe<br />
                                                        é necessário que essa função tenha sido atribuída à equipe;
                                            </p>`, 
                                            direcao: 'right' 
                                        }}   
                                    />
                                    <br />

                                    <div className="row my-4" >
                                        <div className="col">
                                            <div className="accordion" id="accordionExample">
                                            
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Filtrar Pessoas
                                                    </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <label htmlFor="area_publica">Pessoas</label>
                                                                            <Select
                                                                                inputId="usuario_pesquisar"
                                                                                onChange={ this.handlePesquisarUsuario }
                                                                                options={this.state.usuarios}
                                                                                value={this.state.usuario_pesquisar}
                                                                                isClearable={true}
                                                                                placeholder="Todos"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <label htmlFor="area_publica">Visibilidade na área pública ( <a href="https://redeartesanatobrasil.com.br/institucional/gestao-do-projeto/" target="_blank" rel="noreferrer">ver no site</a>)</label>
                                                                            <select className="form-control" id='area_publica' onChange={e => this.handlePesquisa(e, 3)}>
                                                                                <option>Todos</option>
                                                                                <option value='S'>Habilitado</option>
                                                                                <option value='N'>Desabilitado</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <label htmlFor="equipe_seletor">Equipe</label>
                                                                            <select id='equipe_seletor' className="form-control" onChange={e => this.handlePesquisa(e, 2)}>
                                                                                <option>Todos</option>
                                                                                {this.state.siglasEquipes.map((seq, index) => {
                                                                                    return <option key={index} value={seq.COD_EQUIPE}>{seq.NOME}</option>
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <label htmlFor="papel_seletor">Função</label>
                                                                            <select id='papel_seletor' className="form-control" onChange={e => this.handlePesquisa(e, 4)}>
                                                                                <option>Todos</option>
                                                                                {this.state.siglasPapeis.map((seq, index) => {
                                                                                    return <option key={index} value={seq.COD_PAPEL}>{seq.NOME}</option>
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12" style={{ textAlign: 'right' }}>
                                                                        <button type="button" className="btn btn-secondary" onClick={() => this.limparFiltro(true)}>Limpar</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group my-4" style={{textAlign: 'right'}}>
                                        <button 
                                            className="btn btn-primary"
                                            type="button" 
                                            onClick={this.handleLauchModalCreate}
                                        >    
                                            Adicionar pessoa à equipe
                                        </button>
                                        <button 
                                            id="btnLaunchModalCreate"
                                            className="d-none"
                                            type="button" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#modalCreate">
                                            </button>

                                        <button 
                                            id="btnLaunchModalUpdate"
                                            type="button" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#modalUpdate"
                                            className="d-none"
                                        ></button>
                                    </div>

                                    <div className="row my-1">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Pessoa</th>
                                                            <th scope="col">Equipe</th>
                                                            <th scope="col">Função/Atividade</th>
                                                        </tr>
                                                    </thead>
                                                    <tfoot>
                                                        <tr>
                                                            <th scope="col">Pessoa</th>
                                                            <th scope="col">Equipe</th>
                                                            <th scope="col">Função/Atividade</th>
                                                        </tr>
                                                    </tfoot>
                                                    <tbody>
                                                        {this.state.equipesPaginado.map(pessoa => (
                                                            pessoa.equipes.map((e, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{pessoa.NOME.toUpperCase()}</td>
                                                                        <td>{e.NOME.toUpperCase()}</td>
                                                                        <td>
                                                                            {e.papeis.map((p, j) => {
                                                                                return <p key={j} style={j > 0 ? { borderTop: '1px solid #ccc', paddingTop: '10px' } : {} }>
                                                                                    <strong>{p.NOME}</strong>
                                                                                    <span style={{float: 'right'}}>
                                                                                        {p.AREA_PUBLICA === 'N' &&
                                                                                            <button 
                                                                                                className="btn" 
                                                                                                onClick={() => this.handleChangeStatusAreaPublica(p, 'S')}
                                                                                                data-tip="Exibir pessoa na área pública"
                                                                                                data-place="bottom"
                                                                                                data-effect="solid"
                                                                                            >
                                                                                                <i className="fas fa-eye-slash text-primary"></i>
                                                                                            </button>
                                                                                        }
                                                                                        {p.AREA_PUBLICA === 'S' &&
                                                                                            <button 
                                                                                                className="btn" 
                                                                                                onClick={() => this.handleChangeStatusAreaPublica(p, 'N')}
                                                                                                data-tip="Ocultar pessoa da área pública"
                                                                                                data-place="bottom"
                                                                                                data-effect="solid"
                                                                                            >
                                                                                                <i className="fas fa-eye text-primary"></i>
                                                                                            </button>
                                                                                        }
                                                                                        <button 
                                                                                            className="btn" 
                                                                                            onClick={() => this.editarUsuarioPapel(pessoa, e, p)} 
                                                                                            data-tip="Editar relação função da pessoa"
                                                                                            data-place="bottom"
                                                                                            data-effect="solid"
                                                                                        >
                                                                                            <i className="fas fa-edit text-success"></i>
                                                                                        </button>
                                                                                        <button 
                                                                                            className="btn" 
                                                                                            onClick={() => this.removerUsuarioPapel(pessoa.ID, p.ID_PAPEL)} 
                                                                                            data-tip="Remover função da pessoa"
                                                                                            data-place="bottom"
                                                                                            data-effect="solid"
                                                                                        >
                                                                                            <i className="fas fa-trash text-danger"></i>
                                                                                        </button>
                                                                                    </span>
                                                                                    <br />
                                                                                    <span>{p.ATUACAO}</span>
                                                                                    <br />
                                                                                    <span>{p.CONDICAO}</span>
                                                                                    <br />
                                                                                </p>
                                                                            })}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={"Anterior"}
                                            nextLabel={"Próximo"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}

                                            marginPagesDisplayed={this.state.isMobile ? 1 : 5}
                                            pageRangeDisplayed={this.state.isMobile ? 1 : 2}
                                            onPageChange={this.paginate}
                                            forcePage={this.state.page}

                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                    </div>

                                    <div className="modal fade" id="modalCreate" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="modalLabel">Adicionar pessoa à equipe</h5>
                                                    <button 
                                                        type="button" 
                                                        id="btnCloseModal" 
                                                        className="btn-close" 
                                                        data-bs-dismiss="modal" 
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row my-3">
                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <label htmlFor="usuario_salvar" >Pessoa:</label>
                                                            <Select
                                                                inputId="usuario_salvar"
                                                                onChange={ this.handleUsuario }
                                                                placeholder='-- SELECIONE UMA PESSOA --'
                                                                options={this.state.usuarios}
                                                                value={this.state.usuario}
                                                                isClearable={true}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="equipe_salvar" >Equipe:</label>
                                                            <select 
                                                                className="form-control" 
                                                                id="equipe_salvar" 
                                                                onChange={this.handleSetEquipe}
                                                                defaultValue="-- SELECIONE UMA EQUIPE --"
                                                                disabled={!this.state.usuario}
                                                            >
                                                                    <option disabled>-- SELECIONE UMA EQUIPE --</option>
                                                                    {this.state.siglasEquipes.map((e, index) => {
                                                                        return <option key={index} value={e.COD_EQUIPE}>{e.NOME}</option>
                                                                    })}
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="papel_salvar">Função:</label>
                                                            <select 
                                                                className="form-control" 
                                                                id="papel_salvar" 
                                                                onChange={e => this.setState({ papel: e.target.value })}
                                                                defaultValue='-- SELECIONE UMA FUNÇÂO --'    
                                                                disabled={!this.state.usuario || !this.state.equipe}
                                                            >
                                                                <option>-- SELECIONE UM FUNÇÂO --</option>
                                                                {this.state.papeisEquipe.map((p, index) => {
                                                                    return <option key={index} value={p.COD_PAPEL} disabled={this.checkPessoaTemPapelNaequipe(p.COD_PAPEL)}>{p.NOME}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                        
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="atuacao_salvar">Atuação:</label>
                                                            <input 
                                                                className="form-control" 
                                                                id="atuacao_salvar" 
                                                                onChange={e => this.setState({ atuacao: e.target.value })}
                                                            />
                                                        </div>
                                                        
                                                        <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="condicao_salvar" >Condição:</label>
                                                            <SelectCreatable
                                                                inputId="condicao_salvar"
                                                                onChange={ this.handleCondicao }
                                                                options={this.state.condicoes}
                                                                value={this.state.condicao}
                                                                placeholder=""
                                                                isClearable
                                                            />
                                                        </div>

                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="data_entrada_salvar">Data entrada:</label>
                                                            <input 
                                                                type="date"
                                                                className="form-control" 
                                                                id="data_entrada_salvar" 
                                                                onChange={e => this.setState({ data_entrada: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="data_saida_salvar">Data saída:</label>
                                                            <input 
                                                                type="date"
                                                                className="form-control" 
                                                                id="data_saida_salvar" 
                                                                onChange={e => this.setState({ data_saida: e.target.value })}
                                                            />
                                                        </div>

                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3" style={{textAlign: 'right'}}>
                                                            <input
                                                                type='checkbox'
                                                                id="area_publica_salvar" 
                                                                checked={this.state.area_publica === true}
                                                                onChange={e => this.setState({ area_publica: e.target.checked })}
                                                            />
                                                            <label htmlFor="area_publica_salvar" style={{marginLeft: '2px'}}>
                                                                Ocultar da área pública
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal" 
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                        disabled={this.context.loading}
                                                    >
                                                        Fechar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={this.salvarDados}
                                                        disabled={!this.state.usuario || !this.state.equipe || !this.state.papel || this.context.loading}
                                                    >
                                                        Salvar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="modalUpdate" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="modalLabel">Editar atuação da pessoa na equipe</h5>
                                                    <button 
                                                        type="button" 
                                                        id="btnCloseModalUpdate" 
                                                        className="btn-close" 
                                                        data-bs-dismiss="modal" 
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row my-3">
                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <label htmlFor="nome_pessoa_mostrar">Pessoa:</label>
                                                            <input 
                                                                className="form-control" 
                                                                id="nome_pessoa_mostrar" 
                                                                disabled={true}
                                                            />
                                                        </div>                                         
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="nome_equipe_mostrar">Equipe:</label>
                                                            <input 
                                                                className="form-control" 
                                                                id="nome_equipe_mostrar" 
                                                                disabled={true}
                                                            />
                                                        </div>                                                       
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="nome_papel_mostrar">Função:</label>
                                                            <input 
                                                                className="form-control" 
                                                                id="nome_papel_mostrar" 
                                                                disabled={true}
                                                            />
                                                        </div>                                                       
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="atuacao_salvar">Atuação:</label>
                                                            <input 
                                                                className="form-control" 
                                                                id="atuacao_salvar" 
                                                                onChange={e => this.setState({ atuacao: e.target.value })}
                                                                value={this.state.atuacao}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="condicao_salvar" >Condição:</label>
                                                            <SelectCreatable
                                                                inputId="condicao_salvar"
                                                                onChange={ this.handleCondicao }
                                                                options={this.state.condicoes}
                                                                value={this.state.condicao}
                                                                placeholder=""
                                                                isClearable
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="data_entrada_salvar">Data entrada:</label>
                                                            <input 
                                                                type="date"
                                                                className="form-control" 
                                                                id="data_entrada_salvar" 
                                                                onChange={e => this.setState({ data_entrada: e.target.value })}
                                                                value={this.state.data_entrada}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="data_saida_salvar">Data saída:</label>
                                                            <input 
                                                                type="date"
                                                                className="form-control" 
                                                                id="data_saida_salvar" 
                                                                onChange={e => this.setState({ data_saida: e.target.value })}
                                                                value={this.state.data_saida}
                                                            />
                                                        </div>

                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3" style={{textAlign: 'right'}}>
                                                            <input
                                                                type='checkbox'
                                                                id="area_publica_salvar" 
                                                                checked={this.state.area_publica === 'N' || this.state.area_publica === true}
                                                                onChange={e => this.setState({ area_publica: e.target.checked })}
                                                            />
                                                            <label htmlFor="area_publica_salvar" style={{marginLeft: '2px'}}>
                                                                Ocultar da área pública
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal" 
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                        disabled={this.context.loading}
                                                    >
                                                        Fechar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={this.atualizarUsuarioPapel}
                                                        disabled={this.context.loading}
                                                    >
                                                        Atualizar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}