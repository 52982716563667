import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Titulo from '../../../Components/Titulo';
import LogoParceriaInterna from '../../../Components/LogoParceria/LogoParceriaInterna';
import DataUtil from '../../../Components/Data';
import ReactPaginate from 'react-paginate';
import '../../../css/style.css';
import { apiURL } from '../../../Services/env';
import { api } from "../../../Services/Api";
import DocumentoRecebido from '../../../Components/DocumentoRecebido';
import ModalDocumentoEdit from '../../../Components/Modal/ModalDocumentoEdit';
import MaskedInput from '../../../Components/InputMask';
import ModalAdicionarEntrevistador from '../../../Components/Modal/ModalAdicionarEntrevistador';
import ComboEstados from '../../../Components/ComboEstados';
import { Redirect } from 'react-router';
import ValidacaoEmail, { validarURL, validarHora, parseJwt } from '../../../Components/Validacao';
import ListaParticipantes from '../../../Components/Lista/ListaParticipantes';

import { GlobalContext } from "../../../Context/GlobalContext/context";
import * as permissions from "../../../Context/GlobalContext/permissions";

class SolicitacaoDocumento extends React.Component {

    static contextType = GlobalContext;

	constructor(props){
        super(props);

        this.handleSubmitSolicitacao = this.handleSubmitSolicitacao.bind(this);
        this.handleChangeTemplate = this.handleChangeTemplate.bind(this);
        this.handleChangeEmissor = this.handleChangeEmissor.bind(this);
        this.handleChangeTitulo = this.handleChangeTitulo.bind(this);
        this.handleChangeObservacao = this.handleChangeObservacao.bind(this);
        this.handleChangeLinkNovo = this.handleChangeLinkNovo.bind(this);
        this.handleChangeArquivoNovo = this.handleChangeArquivoNovo.bind(this);
        this.handleNumeroDocumentoExterno = this.handleNumeroDocumentoExterno.bind(this);
        this.handleRemetente = this.handleRemetente.bind(this);
        this.atualizarTela = this.atualizarTela.bind(this);
        this.popularCombos = this.popularCombos.bind(this);
        this.limparCamposTramitacao = this.limparCamposTramitacao.bind(this);
        this.limparDataReuniao = this.limparDataReuniao.bind(this);
        this.handleChangeDataReuniao = this.handleChangeDataReuniao.bind(this);
        this.handleChangeHoraInicio = this.handleChangeHoraInicio.bind(this);
        this.handleChangeHoraTermino = this.handleChangeHoraTermino.bind(this);
        this.handleChangeHoraInicioEntrevista = this.handleChangeHoraInicioEntrevista.bind(this);
        this.handleChangeHoraTerminoEntrevista = this.handleChangeHoraTerminoEntrevista.bind(this);
        this.handleOnChangeCidadeEntrevista = this.handleOnChangeCidadeEntrevista.bind(this);
        this.handleOnChangeUFEntrevista = this.handleOnChangeUFEntrevista.bind(this);
        this.handleChangeCpfEntrevistador = this.handleChangeCpfEntrevistador.bind(this);
        this.handleChangeCpfEntrevistadorExtra = this.handleChangeCpfEntrevistadorExtra.bind(this);
        this.handleChangeCpfEntrevistado = this.handleChangeCpfEntrevistado.bind(this);
        this.limparDataEntrevista = this.limparDataEntrevista.bind(this);
        this.handleChangeDataEntrevista = this.handleChangeDataEntrevista.bind(this);
        this.handleChangeNomeEntrevistado = this.handleChangeNomeEntrevistado.bind(this);
        this.handleChangeEmailEntrevistado = this.handleChangeEmailEntrevistado.bind(this);
        this.handleChangeEmailEntrevistador = this.handleChangeEmailEntrevistador.bind(this);
        this.handleChangeEmailEntrevistadorExtra = this.handleChangeEmailEntrevistadorExtra.bind(this);
        this.handleChangeTipoInstituicaoEntrevistado = this.handleChangeTipoInstituicaoEntrevistado.bind(this);
        this.handleChangeInstituicaoEntrevistado = this.handleChangeInstituicaoEntrevistado.bind(this);
        this.handleChangeEntrevistador = this.handleChangeEntrevistador.bind(this);
        this.handleChangeIdEntrevistador = this.handleChangeIdEntrevistador.bind(this);
        this.handleChangeEntrevistadorExtra = this.handleChangeEntrevistadorExtra.bind(this);
        this.carregarDadosEquipe = this.carregarDadosEquipe.bind(this);
        this.preencherCamposDadosEntrevista = this.preencherCamposDadosEntrevista.bind(this);
        this.editarDocumento = this.editarDocumento.bind(this);
        this.carregarCidades = this.carregarCidades.bind(this);
        this.preencherCampoCidadeEntrevista = this.preencherCampoCidadeEntrevista.bind(this);
        this.validarCamposEntrevista = this.validarCamposEntrevista.bind(this);
        this.validarCamposReuniao = this.validarCamposReuniao.bind(this);
        this.handleChangeNomeResponsavel = this.handleChangeNomeResponsavel.bind(this);
        this.handleChangeEmailResponsavel = this.handleChangeEmailResponsavel.bind(this);
        this.removerParticipante = this.removerParticipante.bind(this);
        this.adicionarParticipante = this.adicionarParticipante.bind(this);
        this.handleChangeHaEntrevistadorExtra = this.handleChangeHaEntrevistadorExtra.bind(this);
        
        this._isMounted = false;
        this.codigo = 0;
        this.mensagemValidacao = '';
        this.atualizarLista = false;
        this.referencia = React.createRef();

        this.state = {
            isMobile: false,
            cpf: localStorage.getItem("projartCod"),
            id: 0,
            token: localStorage.getItem("projartToken"),
            papeis: localStorage.getItem('projartPapeis'),
            documento:'',
            codigoTemplate: 0,
            template: '',
            emissor: '',
            templates: '',
            emissores: '',
            movimentacao:'',
            nome: localStorage.getItem('projartUsuario'),
            titulo: '',
            tituloAux: '',
            situacaoDocumento: 'Solicitação',
            numeroDocumento: '',
            dataCriacao: '',
            codigoDocumento: '',  
            observacao: '',
            tituloPagina: '', 
            documentos: [],            
            offset:0,
            orgTabelaDoc: [],
            perPage: 10,
            currentPage: 0,
            pageCount: 0,
            linkNovo: '',
            arquivoNovo: '',
            file: {
                name: '',
                size: '',
                type: '',
                conteudo:''
            },
            arquivoInformado: '',
            mostrar: 'none',
            tramitacao: {
                id:'', 
                nomeDestinatario:'',
                email:'',
                cargo:'',
                instituicao:'',
                rua:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                cep:'',
                data:'',
                responsavel: ''
            },
            dataReuniao:'',
            horaInicio: '',
            horaTermino: '',
            cidadeEntrevista: '',
            cidades: '',
            ufEntrevista: '',
            dataEntrevista: '',
            horaInicioEntrevista: '',
            horaTerminoEntrevista: '',
            nomeCoordenador:'',
            emailCoordenador: '',
            idEntrevistador: '',
            nomeEntrevistador: '',
            emailEntrevistador:'',
            cpfEntrevistador:'',
            idEntrevistadorExtra: '',
            nomeEntrevistadorExtra: '',
            emailEntrevistadorExtra:'',
            cpfEntrevistadorExtra:'',
            nomeEntrevistado: '',
            emailEntrevistado:'',
            cpfEntrevistado:'',
            instituicaoEntrevistado:'',
            tipoInstituicaoEntrevistado:'',
            dataAssinatura:'',
            dataAprovacao:'',
            numeroDocumentoExterno: '',
            remetente: '',
            redirecionar: false,
            nomeResponsavel: '',
            emailResponsavel: '',
            participantes:[],
            removerParticipantes: [],
            adicionarParticipantes: [],
            entrevistadores:'',
            entrevistadoresExtra:'',
            countObs: 0
        };

             
    }

    removerParticipante(participante){
        
        if(this.state.id === 0){

            let participantesAdd = [];
            this.state.adicionarParticipantes.forEach(
                participanteAdd => {
                    //console.log(anexoAdd);
                    if(participanteAdd.id !== participante.id){
                        participantesAdd.push(participanteAdd);                                    
                    }
                }
            );
            this.setState({adicionarParticipantes: participantesAdd});
        }
        else{
            let participantesRemover = this.state.removerParticipantes.splice(0);
            participantesRemover.push(participante);
            this.setState({removerParticipantes: participantesRemover});
        }
    }

    adicionarParticipante(participante){
        
        let participantesAdicionar = this.state.adicionarParticipantes.splice(0);
        participantesAdicionar.push(participante);
        this.setState({adicionarParticipantes: participantesAdicionar});
                
    }

    editarDocumento(id){   
        this.codigo = id;        
        this.setState({redirecionar: true});          
    }

    limparDataReuniao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataReuniao: ''});
            document.getElementById("dataReuniao").value = '';
        }
    }

    limparDataEntrevista(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataEntrevista: ''});
            document.getElementById("dataEntrevista").value = '';
        }
    }

    handleChangeDataEntrevista(e){
        this.setState({dataEntrevista: e.target.value});        
    }

    handleChangeHoraInicio(e){
    
        this.setState({horaInicio: e.target.value});        
    }

    handleChangeHoraTermino(e){
    
        this.setState({horaTermino: e.target.value});        
    }

    handleChangeDataReuniao(e){
        this.setState({dataReuniao: e.target.value});        
    }

    async iniciarDocumento(){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token,
                responsavel: this.state.cpf
            })
        };

        const response = await api(apiURL+"documentoSuporteSolicitacao", requestOptions);
        return await response.json();

    }

	componentDidMount(){
        if (!this.context.getPermission(permissions.VISUALIZAR_DOCUMENTOS) &&
            !this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS) &&
            !this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS_TODAS_EQUIPES)
        ) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        this._isMounted = true;
        this._visualizar = false;

        this.setState({
            tituloPagina: 'Solicitação de Documento',
            mostrar: 'none',
            isMobile: window.screen.width <= 400
        });
        if (this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS) ||
            this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS_TODAS_EQUIPES)
        ) {
            this.context.startLoading();

            this.iniciarDocumento().then(data => {

                if(this._isMounted){
                    if(data["mensagem"] !== 'sucesso'){
                        alert(data["mensagem"]);
                    }
                    else{
                        let listaReversa = data["documentos"].reverse();
                        this.setState({
                            documentos: listaReversa,
                            orgTabelaDoc: listaReversa.slice(this.state.offset, this.state.offset + this.state.perPage),
                            pageCount: Math.ceil(data["documentos"].length / this.state.perPage)
                        });
        
                        this.setState({templates: data["templates"]}); 
                        this.setState({emissores: data["emissores"]});
                    
                        this.popularCombos();
                    }
                }
            })
            // .catch(e => alert('Erro ao iniciar a tela!'))
            .finally(() => this.context.stopLoading());
        }
        this.referencia.current.focus();      
        
    }

    popularCombos(){

        //console.log(this.state.templates);
        const selectTemplates = document.getElementById("template");
        let option = new Option('Selecione', '0');                                        
        selectTemplates.options[0] = option;
        
        let contador = 1;            
        
        this.state.templates.forEach(temp => {                    
            option = new Option(temp['nome'], temp['cod_template_documento']);                                        
            selectTemplates.options[contador] = option;
            contador++;        
        });

        if(!this.context.getPermission(permissions.CRIAR_DOCUMENTOS) && 
            !this.context.getPermission(permissions.CRIAR_DOCUMENTOS_SOLICITADOS)){
           selectTemplates.remove(5);
        }
                            
        const selectEmissores = document.getElementById("emissor");
        option = new Option('Selecione...', '0');
        selectEmissores.options[0] = option;
        
        contador = 1;
      
        //console.log(this.state.emissores);
        this.state.emissores.forEach(temp => {
            //console.log(temp['SIGLA'] + ' - ' + temp['COD_EQUIPE']);
            option = new Option(temp['SIGLA'], temp['COD_EQUIPE']);
            selectEmissores.options[contador] = option;
            contador++;
        });
                            
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    async criarSolicitacao(){
        
        /*
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;  charset=UTF-8' },
            body: JSON.stringify({ 
                token: this.state.token,
                template: this.state.template,
                emissor: this.state.emissor,
                titulo: this.state.titulo,
                observacao: this.state.observacao,
                responsavel: this.state.cpf
            })
                
        };
        */

        let formData = new FormData();
        formData.append('token', this.state.token);
        formData.append('template', this.state.template);
        formData.append('emissor', this.state.emissor);
        formData.append('titulo',  this.state.titulo);
        formData.append('observacao',  this.state.observacao);
        formData.append('responsavel',  this.state.cpf);

        if(this.state.dataReuniao !== null && this.state.dataReuniao !== undefined && this.state.dataReuniao !== ''){
            formData.append('dataReuniao',  this.state.dataReuniao);
        }

        if(this.state.horaInicio !== null && this.state.horaInicio !== undefined && this.state.horaInicio !== ''){
            formData.append('horaInicio',  this.state.horaInicio);
        }
        
        if(this.state.horaTermino !== null && this.state.horaTermino !== undefined && this.state.horaTermino !== ''){
            formData.append('horaTermino',  this.state.horaTermino);
        }

        if(this.state.linkNovo !== null && this.state.linkNovo !== undefined && this.state.linkNovo !== ''){
            formData.append('linkNovo', this.state.linkNovo);
        }

        if(this.state.file !== null ||this.state.file !== undefined ||this.state.file.name !== ''){
            formData.append('file[name]', this.state.file.name);
            formData.append('file[size]', this.state.file.size);
            formData.append('file[type]', this.state.file.type);

            formData.append('arquivo', this.state.arquivoNovo);

        }
          
        if(this.state.numeroDocumentoExterno  !== null &&  this.state.numeroDocumentoExterno !== undefined){
            formData.append('numeroDocumentoExterno', this.state.numeroDocumentoExterno);
        }
        else{
            formData.append('numeroDocumentoExterno', '');
        }

        if(this.state.remetente  !== null &&  this.state.remetente !== undefined){
            formData.append('remetente', this.state.remetente);
        }
        else{
            formData.append('remetente', '');
        }

        let dataTramitacao = document.getElementById('dataTramitacao');
        let responsavelTramitacao = document.getElementById('responsavelTramitacao');
        let idTramitacao = document.getElementById('idTramitacao');
        let nomeDestinatario = document.getElementById('nomeDestinatario');
        let email = document.getElementById('emailDestinatario');
        let cargo = document.getElementById('cargo');
        let instituicao = document.getElementById('instituicao');
        let rua = document.getElementById('rua');
        let numero = document.getElementById('numero');
        let complemento = document.getElementById('complemento');
        let bairro = document.getElementById('bairro');
        let cidade = document.getElementById('cidade');
        let estado = document.getElementById('estadoDadosEnvio');
        let cep = document.getElementById('cep');

        formData.append('tramitacao[id]', idTramitacao.value);
        formData.append('tramitacao[nomeDestinatario]', nomeDestinatario.value);
        formData.append('tramitacao[email]', email.value);
        formData.append('tramitacao[cargo]', cargo.value);
        formData.append('tramitacao[instituicao]',  instituicao.value);
        formData.append('tramitacao[rua]',  rua.value);
        formData.append('tramitacao[numero]',  numero.value);
        formData.append('tramitacao[complemento]', complemento.value);
        formData.append('tramitacao[bairro]',  bairro.value);
        formData.append('tramitacao[cidade]',  cidade.value);
        formData.append('tramitacao[estado]',  estado.value);
        formData.append('tramitacao[cep]',  cep.value);
        formData.append('tramitacao[data]',  dataTramitacao.value);
        formData.append('tramitacao[responsavel]', responsavelTramitacao.value);        
        
        if(this.state.cidadeEntrevista !== null && this.state.cidadeEntrevista !== undefined && this.state.cidadeEntrevista !== ''){
            formData.append('cidade',  this.state.cidadeEntrevista);
        }

        if(this.state.ufEntrevista !== null && this.state.ufEntrevista !== undefined && this.state.ufEntrevista !== ''){
            formData.append('uf',  this.state.ufEntrevista);
        }

        if(this.state.dataEntrevista !== null && this.state.dataEntrevista !== undefined && this.state.dataEntrevista !== ''){
            formData.append('data_entrevista',  this.state.dataEntrevista);
        }

        if(this.state.horaInicioEntrevista !== null && this.state.horaInicioEntrevista !== undefined && this.state.horaInicioEntrevista !== ''){
            formData.append('hora_inicio_entrevista',  this.state.horaInicioEntrevista);
        }

        if(this.state.horaTerminoEntrevista !== null && this.state.horaTerminoEntrevista !== undefined && this.state.horaTerminoEntrevista !== ''){
            formData.append('hora_termino_entrevista',  this.state.horaTerminoEntrevista);
        }

        if(this.state.idEntrevistador !== null && this.state.idEntrevistador !== undefined && this.state.idEntrevistador !== ''){
            formData.append('id_entrevistador',  this.state.idEntrevistador);
        }

        if(this.state.idEntrevistadorExtra !== null && this.state.idEntrevistadorExtra !== undefined && this.state.idEntrevistadorExtra !== ''){
            formData.append('id_entrevistador_extra',  this.state.idEntrevistadorExtra);
        }

        if(this.state.nomeEntrevistado !== null && this.state.nomeEntrevistado !== undefined && this.state.nomeEntrevistado !== ''){
            formData.append('nome_entrevistado',  this.state.nomeEntrevistado);
        }

        if(this.state.emailEntrevistado !== null && this.state.emailEntrevistado !== undefined && this.state.emailEntrevistado !== ''){
            formData.append('email_entrevistado',  this.state.emailEntrevistado);
        }

        if(this.state.cpfEntrevistado !== null && this.state.cpfEntrevistado !== undefined && this.state.cpfEntrevistado !== ''){
            formData.append('cpf_entrevistado',  this.state.cpfEntrevistado);
        }

        if(this.state.instituicaoEntrevistado !== null && this.state.instituicaoEntrevistado !== undefined && this.state.instituicaoEntrevistado !== ''){
            formData.append('nome_instituicao_entrevistado',  this.state.instituicaoEntrevistado);
        }

        if(this.state.tipoInstituicaoEntrevistado !== null && this.state.tipoInstituicaoEntrevistado !== undefined && this.state.tipoInstituicaoEntrevistado !== ''){
            formData.append('tipo_instituicao_entrevistado',  this.state.tipoInstituicaoEntrevistado);
        }

        if(this.state.nomeResponsavel !== null && this.state.nomeResponsavel !== undefined && this.state.nomeResponsavel !== ''){
            formData.append('nome_responsavel',  this.state.nomeResponsavel);
        }

        if(this.state.emailResponsavel !== null && this.state.emailResponsavel !== undefined && this.state.emailResponsavel !== ''){
            formData.append('email_responsavel',  this.state.emailResponsavel);
        }

        let contador = 0;
        this.state.adicionarParticipantes.forEach(
            participanteAdd => {
                //console.log(anexoAdd);
                formData.append('participantesAdicionar['+contador+'][id]', participanteAdd.id);
                formData.append('participantesAdicionar['+contador+'][nome]', participanteAdd.nome);
                formData.append('participantesAdicionar['+contador+'][email]', participanteAdd.email);
                contador++;
            }
        );

        contador = 0;
        this.state.removerParticipantes.forEach(
            participanteRemover => {
                //console.log(participanteRemover);
                formData.append('participantesRemover['+contador+'][id]', participanteRemover.id);
                contador++;
            }
        );


        const requestOptions = {
            method: 'POST',
            body: formData
        }

        try{
            const response = await api(apiURL+"documento/solicitar", requestOptions);
            return await response.json();
        }
        catch(error){
            console.log(error);            
        }

    }

    atualizarTela(){

        let tramitacaoAux = {
            id:'', 
            nomeDestinatario:'',
            email:'',
            cargo:'',
            instituicao:'',
            rua:'',
            numero:'',
            complemento:'',
            bairro:'',
            cidade:'',
            estado:'',
            cep:'',
            data:'',
            responsavel: ''
        }

        this.setState({
            template: 0,
            emissor: 0,
            titulo:'',
            observacao: '',
            linkNovo: '',
            arquivoNovo: '',
            file: '',
            arquivoInformado: '',
            mostrar: 'none',
            numeroDocumentoExterno: '',
            remetente: '',
            tramitacao: tramitacaoAux,
            dataReuniao: '',
            horaInicio: '',
            horaTermino: '',
            cidadeEntrevista: '',
            ufEntrevista: '',
            dataEntrevista: '',
            horaInicioEntrevista: '',
            horaTerminoEntrevista: '',
            nomeCoordenador:'',
            emailCoordenador:'',
            idEntrevistador: '',
            nomeEntrevistador: '',
            emailEntrevistador:'',
            cpfEntrevistador:'',
            idEntrevistadorExtra: '',
            nomeEntrevistadorExtra: '',
            emailEntrevistadorExtra:'',
            cpfEntrevistadorExtra:'',
            nomeEntrevistado: '',
            emailEntrevistado:'',
            cpfEntrevistado:'',
            instituicaoEntrevistado:'',
            tipoInstituicaoEntrevistado:'',
            nomeResponsavel: '',
            emailResponsavel: '',
            participantesRemover: [],
            participantes: [],
            participantesAdicionar: []
        });

        let template = document.getElementById('template');
        let emissor = document.getElementById('emissor');
        let titulo = document.getElementById("titulo");
        let observacao = document.getElementById("observacao");
        let linkNovo = document.getElementById("linkNovo");
        let arquivoNovo = document.getElementById("arquivoNovo");
        let informeDocumento = document.getElementById("informeDocumento");
        let numeroDocumentoExterno = document.getElementById("numeroDocumentoExterno");
        let remetente = document.getElementById("remetente");
        let linhaTramitacao = document.getElementById("linhaTramitacao");
        let linhaDataReuniao = document.getElementById('linhaDataReuniao');
        let linhaResponsavel = document.getElementById('linhaResponsavel');
        let linhaParticipantes = document.getElementById('linhaParticipantes');
        let dataReuniao = document.getElementById('dataReuniao');
        let horaInicio = document.getElementById('horaInicio');
        let horaTermino = document.getElementById('horaTermino');

        template.selectedIndex = this.state.template;            
        emissor.selectedIndex = this.state.emissor;
        titulo.value = this.state.titulo;
        observacao.innerText = '';
        observacao.value = '';        
        numeroDocumentoExterno.value = this.state.numeroDocumentoExterno;
        remetente.value = this.state.remetente;        

        if(linkNovo !== null && linkNovo !== undefined)
            linkNovo.value = '';

        if(arquivoNovo !== null && arquivoNovo !== undefined)
            arquivoNovo.value = '';

        informeDocumento.style.display = 'none';

        this.limparCamposTramitacao();
        linhaTramitacao.style.display = 'none';

        if(this.state.codigoTemplate === 1) {
            linhaDataReuniao.style.display = 'flex';
            linhaResponsavel.style.display = 'flex';
            linhaParticipantes.style.display = 'flex';
            dataReuniao.value = this.state.dataReuniao;     
            horaInicio.value = this.state.horaInicio;
            horaTermino.value = this.state.horaTermino;
        }
        else{
            linhaDataReuniao.style.display = 'none';
            linhaResponsavel.style.display = 'none';  
            linhaParticipantes.style.display = 'none';          
        }
    
        document.getElementById("dataEntrevista").value = '';
        document.getElementById("cidadeEntrevista").value = '0';
        document.getElementById("estadoEntrevista").value = '0';
        document.getElementById("nomeCoordenador").value = '';
        document.getElementById("emailCoordenador").value = '';
        document.getElementById("nomeEntrevistador").value = '0';
        document.getElementById("emailEntrevistador").value = '';
        document.getElementById("cpfEntrevistador").value = '';
        document.getElementById("nomeEntrevistadorExtra").value = '0';
        document.getElementById("emailEntrevistadorExtra").value = '';
        document.getElementById("cpfEntrevistadorExtra").value = '';
        document.getElementById("nomeEntrevistado").value = '';
        document.getElementById("emailEntrevistado").value = '';
        document.getElementById("cpfEntrevistado").value = '';
        document.getElementById("tipoInstituicaoEntrevistado").value = 'selecione';
        document.getElementById("instituicaoEntrevistado").value = '';
    
        document.getElementById("observacao").value = '';

        document.getElementById("linhaLocalizacaoEntrevista").style.display = 'none';
        document.getElementById("linhaDataEntrevista").style.display = 'none';
        document.getElementById('linhaCoordenador').style.display = 'none';
        document.getElementById('linhaEntrevistador').style.display = 'none';
        document.getElementById('linhaEntrevistadorExtra').style.display = 'none';
        document.getElementById('linhaEntrevistado').style.display = 'none';
        
        document.getElementById('linhaTitulo').style.display = 'block';
        document.getElementById('linhaObs').style.display = 'block';

        alert('Sua solicitação foi encaminhada com sucesso! \nEm breve analisaremos seu pedido e daremos retorno.');

    }

    validarCamposEntrevista(){
        let retorno = true;
        let mensagem = '';

        if(this.state.template === null || this.state.template === undefined || this.state.template === '' || this.state.template === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tDocumento';
        }

        if(this.state.emissor === null  || this.state.emissor === undefined || this.state.emissor === ''  || this.state.emissor === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tEmissor';
        }

        if(this.state.cidadeEntrevista === null || this.state.cidadeEntrevista === undefined || this.state.cidadeEntrevista === '' || this.state.cidadeEntrevista === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tCidade';
        }

        if(this.state.ufEntrevista === null || this.state.ufEntrevista === undefined || this.state.ufEntrevista === '' || this.state.ufEntrevista === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tEstado';
        }

        if(this.state.dataEntrevista === null || this.state.dataEntrevista === undefined || this.state.dataEntrevista === ''){
            retorno = false;
            mensagem = mensagem + '\n\tData Entrevista';
        }

        if(this.state.horaInicioEntrevista === null || this.state.horaInicioEntrevista === undefined || this.state.horaInicioEntrevista === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de início';
        }
        else{
            if(validarHora(this.soNumeros(this.state.horaInicioEntrevista)) === false){
                retorno = false;
                mensagem = mensagem + '\n\tHora de início da entrevista inválida!';
            }
        }

        if(this.state.horaTerminoEntrevista === null || this.state.horaTerminoEntrevista === undefined || this.state.horaTerminoEntrevista === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de fim';
        }
        else{
            if(validarHora(this.soNumeros(this.state.horaTerminoEntrevista)) === false){
                retorno = false;
                mensagem = mensagem + '\n\tHora de término da entrevista inválida!'; 
            }
        }


        if(this.state.idEntrevistador === null || this.state.idEntrevistador === undefined || this.state.idEntrevistador === '' || this.state.idEntrevistador === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tNome entrevistador';
        }

        let haEntrevistadorExtra = document.getElementById("haEntrevistadorExtra").value;
        if(haEntrevistadorExtra === 'S'){
            if(this.state.idEntrevistadorExtra === null || this.state.idEntrevistadorExtra === undefined || this.state.idEntrevistadorExtra === '' || this.state.idEntrevistadorExtra === '0'){
                retorno = false;
                mensagem = mensagem + '\n\tNome do segundo entrevistador';
            }
        }

        if(this.state.nomeEntrevistado === null || this.state.nomeEntrevistado === undefined || this.state.nomeEntrevistado === ''){
            retorno = false;
            mensagem = mensagem + '\n\tNome do entrevistado';
        }
        
        //if(this.state.cpfEntrevistado === null || this.state.cpfEntrevistado === undefined || this.state.cpfEntrevistado === ''){
        //    retorno = false;
        //    mensagem = mensagem + '\n\tCPF do entrevistado';
        //}

        if(this.state.emailEntrevistado === null || this.state.emailEntrevistado === undefined || this.state.emailEntrevistado === ''){
            retorno = false;
            mensagem = mensagem + '\n\tE-mail do entrevistado';
        }
        else{
            if(ValidacaoEmail(this.state.emailEntrevistado) === false){
                retorno = false;
                mensagem = mensagem + '\n\tE-mail do entrevistado inválido!';
            }
        }

        if(this.state.instituicaoEntrevistado === null || this.state.instituicaoEntrevistado === undefined || this.state.instituicaoEntrevistado === ''){
            retorno = false;
            mensagem = mensagem + '\n\tInstituição do Entrevistado';
        }

        if(this.state.tipoInstituicaoEntrevistado === null || this.state.tipoInstituicaoEntrevistado === undefined || this.state.tipoInstituicaoEntrevistado === '0' || this.state.tipoInstituicaoEntrevistado === '' || this.state.tipoInstituicaoEntrevistado === 'selecione'){
            retorno = false;
            mensagem = mensagem + '\n\tTipo instituição';
        }

        this.mensagemValidacao = mensagem;
        return retorno;
    }

    validarCamposReuniao(){
        let retorno = true;
        let mensagem = '';

        if(this.state.template === null || this.state.template === undefined || this.state.template === '' || this.state.template === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tDocumento';
        }

        if(this.state.emissor === null  || this.state.emissor === undefined || this.state.emissor === ''  || this.state.emissor === '0'){
            retorno = false;
            mensagem = mensagem + '\n\tEmissor';
        }

        if(this.state.titulo === null || this.state.titulo === undefined || this.state.titulo === ''){
            retorno = false;
            mensagem = mensagem + '\n\tTítulo';
        }

        if(this.state.dataReuniao === null || this.state.dataReuniao === undefined || this.state.dataReuniao === ''){
            retorno = false;
            mensagem = mensagem + '\n\tData Reunião';
        }

        if(this.state.horaInicio === null || this.state.horaInicio === undefined || this.state.horaInicio === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de início';
        }
        else{
            if(validarHora(this.soNumeros(this.state.horaInicio)) === false){
                retorno = false;
                mensagem = mensagem + '\n\tHora de início da reunião inválida!';
            }
        }

        if(this.state.horaTermino === null || this.state.horaTermino === undefined || this.state.horaTermino === ''){
            retorno = false;
            mensagem = mensagem + '\n\tHora de fim';
        }
        else{
            if(validarHora(this.soNumeros(this.state.horaTermino)) === false){
                retorno = false;
                mensagem = mensagem + '\n\tHora de término da reunião inválida!'; 
            }
        }

        if(this.state.nomeResponsavel === null  || this.state.nomeResponsavel === undefined || this.state.nomeResponsavel === ''){
            retorno = false;
            mensagem = mensagem + '\n\tResponsável';
        }

        if(this.state.emailResponsavel === null || this.state.emailResponsavel === undefined || this.state.emailResponsavel === ''){
            retorno = false;
            mensagem = mensagem + '\n\tE-mail do responsável';
        }
        else{
            if(ValidacaoEmail(this.state.emailResponsavel) === false){
                retorno = false;
                mensagem = mensagem + '\n\tE-mail do responsável inválido!';
            }
        }

        this.mensagemValidacao = mensagem;
        return retorno;
    }

    handleSubmitSolicitacao(e){

        e.preventDefault();

        if (this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS) ||
            this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS_TODAS_EQUIPES)
        ) {
            if(this.state.template === "5" && (
                this.state.titulo === null || this.state.titulo === undefined ||this.state.titulo === '')){
                    this.setState({titulo: 'Entrevista com '+this.state.nomeEntrevistado});
            }

            if(this.state.template === "5" && this.validarCamposEntrevista() === false){
                alert('Certifique-se de preencher os campos: '+this.mensagemValidacao);
            }
            else{
                if(this.state.template !== "5" && (this.state.template === '' || this.state.template === '0'  || this.state.emissor === '' || this.state.emissor === '0' || this.state.titulo === '')){
                    alert('Gentileza verique se os campos abaixo estão preenchidos:\n\tDocumento \n\tEmissor \n\tTítulo');
                }
                else{
                        if(this.state.template === "4" && 
                            (
                                    (this.state.linkNovo === null ||this.state.linkNovo === undefined ||this.state.linkNovo === '')
                                &&  (this.state.file === null ||this.state.file === undefined ||this.state.file.name === '')
                            )
                        ){
                            alert('Para registrar solicitação de documentos recebidos é necessário informar o link do documento ou anexar o arquivo!');
                        }
                        else{
                            if(this.state.template === "1" && this.validarCamposReuniao() === false){
                                alert('Certifique-se de preencher os campos: '+this.mensagemValidacao);
                            }
                            else{
                                if(this.state.linkNovo !== null && this.state.linkNovo !== undefined && this.state.linkNovo !== '' && validarURL(this.state.linkNovo) === false){
                                    alert('Link do novo documento é inválido!\nCertifique-se de informar o protocolo (Ex. http:// ou https://) e verificar se não há espaços em branco!');                
                                }
                                else{
                                    
                                    this.context.startLoading();

                                    this.criarSolicitacao().then( data => {

                                        try{                            
                                            if(data["mensagem"] !== 'sucesso'){
                                                let erro = document.getElementById('erro');
                                                erro.innerText = data["mensagem"];
                                                erro.style.visibility = 'visible';
                                            }
                                            else{
                                                let listaReversa = data["documentos"].reverse();
                                                this.setState({
                                                    documentos: listaReversa,
                                                    orgTabelaDoc: listaReversa.slice(this.state.offset, this.state.offset + this.state.perPage),
                                                    pageCount: Math.ceil(data["documentos"].length / this.state.perPage)
                                                });
                                                
                                                this.atualizarTela(); 
                                            }
                                        }
                                        catch(error){
                                            console.log(error); 
                                            alert("Solicitação registrada, porém não possível atualizar a tela!")           
                                        }

                                    })
                                    .catch( e => {
                                        console.log(e);
                                        alert("Não foi possível registrar a solicitação. \nPor gentileza tente novamente!");
                                    })
                                    .finally(() => this.context.stopLoading());
                            
                                }
                            }
                        }
                }
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleChangeTipoInstituicaoEntrevistado(e){        
        this.setState({tipoInstituicaoEntrevistado: e.target.value});
    }

    handleChangeInstituicaoEntrevistado(e){        
        this.setState({instituicaoEntrevistado: e.target.value});
    }

    handleChangeNomeEntrevistado(e){        
        this.setState({nomeEntrevistado: e.target.value});
    }
    
    handleChangeEmailEntrevistado(e){        
        this.setState({emailEntrevistado: e.target.value});
    }

    handleChangeEmailEntrevistador(e){        
        this.setState({emailEntrevistador: e.target.value});
    }

    handleChangeEmailEntrevistadorExtra(e){        
        this.setState({emailEntrevistadorExtra: e.target.value});
    }

    handleChangeObservacao(e){
        this.setState({
            observacao: e.target.value,
            countObs: e.target.value.length
        });
    }

    handleChangeTemplate(e){
        this.setState({template: e.target.value});

        document.getElementById("novoArquivoOpcional").style.display = 'none';
        document.getElementById("novoLinkOpcional").style.display = 'none';

        let linhaDataReuniao = document.getElementById('linhaDataReuniao');

        let linhaLocalizacaoEntrevista = document.getElementById('linhaLocalizacaoEntrevista');
        let linhaDataEntrevista = document.getElementById('linhaDataEntrevista');
        let linhaCoordenador = document.getElementById('linhaCoordenador');
        let linhaEntrevistador = document.getElementById('linhaEntrevistador');
        let linhaEntrevistadorExtra = document.getElementById('linhaEntrevistadorExtra');
        let linhaEntrevistado = document.getElementById('linhaEntrevistado');
        let linhaAbas = document.getElementById('informeDocumento');
        let linhaTitulo = document.getElementById('linhaTitulo');
        let linhaObs = document.getElementById('linhaObs');
        let linhaResponsavel = document.getElementById('linhaResponsavel');
        let linhaParticipantes = document.getElementById('linhaParticipantes');
        
        if(e.target.value === "5"){
            linhaAbas.style.display = "none";
            linhaTitulo.style.display = "none";
            linhaObs.style.display = "none";
            linhaLocalizacaoEntrevista.style.display = "flex";
            linhaDataEntrevista.style.display = "flex";
            linhaCoordenador.style.display = "block";
            linhaEntrevistador.style.display = "block";
            linhaEntrevistado.style.display = "block";
        }
        else{
            linhaAbas.style.display = "flex";
            linhaTitulo.style.display = "flex";
            linhaObs.style.display = "flex";
            linhaLocalizacaoEntrevista.style.display = "none";
            linhaDataEntrevista.style.display = "none";
            linhaCoordenador.style.display = "none";
            linhaEntrevistador.style.display = "none";
            linhaEntrevistadorExtra.style.display = "none";
            linhaEntrevistado.style.display = "none";

            this.setState({
                cidadeEntrevista: '',
                ufEntrevista: '',
                dataEntrevista: '',
                horaInicioEntrevista: '',
                horaTerminoEntrevista: '',
                nomeCoordenador:'',
                emailCoordenador: '',
                idEntrevistador: '',
                nomeEntrevistador: '',
                emailEntrevistador:'',
                cpfEntrevistador:'',
                idEntrevistadorExtra: '',
                nomeEntrevistadorExtra: '',
                emailEntrevistadorExtra:'',
                cpfEntrevistadorExtra:'',
                nomeEntrevistado: ' ',
                emailEntrevistado:'',
                cpfEntrevistado:'',
                instituicaoEntrevistado:'',
                tipoInstituicaoEntrevistado:''
            });
            document.getElementById("dataEntrevista").value = '';
            document.getElementById("cidadeEntrevista").value = '0';
            document.getElementById("estadoEntrevista").value = '0';
            document.getElementById("nomeCoordenador").value = '';
            document.getElementById("emailCoordenador").value = '';
            document.getElementById("nomeEntrevistador").value = '0';
            document.getElementById("emailEntrevistador").value = '';
            document.getElementById("cpfEntrevistador").value = '';
            document.getElementById("nomeEntrevistadorExtra").value = '0';
            document.getElementById("emailEntrevistadorExtra").value = '';
            document.getElementById("cpfEntrevistadorExtra").value = '';
            document.getElementById("nomeEntrevistado").value = '';
            document.getElementById("emailEntrevistado").value = '';
            document.getElementById("cpfEntrevistado").value = '';
            document.getElementById("tipoInstituicaoEntrevistado").value = '0';
            document.getElementById("instituicaoEntrevistado").value = '';
        }

        if(e.target.value === "4"){            
            document.getElementById("informeDocumento").style.display = 'block';
        }
        else{
       
            this.setState({
                arquivoNovo:'',
                linkNovo: ''
            });
        
            let linkNovo = document.getElementById("linkNovo");
            let arquivoNovo = document.getElementById("arquivoNovo");
            
            if(linkNovo !== null && linkNovo !== undefined)
                linkNovo.value = '';
        
            if(arquivoNovo !== null && arquivoNovo !== undefined)
                arquivoNovo.value = '';
                
            

            document.getElementById("informeDocumento").style.display = 'none';
        }

        if(e.target.value === "2"){
            document.getElementById("linhaTramitacao").style.display = 'block';
        }
        else{
            let tramitacaoAux = {
                id:'', 
                nomeDestinatario:'',
                email:'',
                cargo:'',
                instituicao:'',
                rua:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                cep:'',
                data:'',
                responsavel: ''
            }

            this.setState({tramitacao: tramitacaoAux});

            this.limparCamposTramitacao();

            document.getElementById("linhaTramitacao").style.display = 'none';
        }

        if(e.target.value === "1"){
            linhaDataReuniao.style.display = 'flex';
            linhaResponsavel.style.display = 'flex';
            linhaParticipantes.style.display = 'flex';
            
            let payload = parseJwt(this.state.token);

            this.setState({
                dataReuniao: '',
                nomeResponsavel: payload['nome'] !== undefined ? payload['nome'] : '',
                emailResponsavel: payload['email'] !== undefined ? payload['email'] : ''
            });
            document.getElementById("dataReuniao").value = '';
        }
        else{
            linhaDataReuniao.style.display = 'none';
            linhaResponsavel.style.display = 'none';
            linhaParticipantes.style.display = 'none';

            this.setState({
                dataReuniao: '',
                nomeResponsavel:'',
                emailResponsavel:''
            });
            document.getElementById("dataReuniao").value = '';
        }

    }

    limparCamposTramitacao(){

        let nomeDestinatario = document.getElementById('nomeDestinatario');
        nomeDestinatario.value = '';

        let email = document.getElementById('emailDestinatario');
        email.value = '';

        let cargo = document.getElementById('cargo');
        cargo.value = '';

        let instituicao = document.getElementById('instituicao');
        instituicao.value = '';

        let rua = document.getElementById('rua');
        rua.value = '';

        let numero = document.getElementById('numero');
        numero.value = '';

        let complemento = document.getElementById('complemento');
        complemento.value = '';

        let bairro = document.getElementById('bairro');
        bairro.value = '';

        let cidade = document.getElementById('cidade');
        cidade.value = '';

        let estado = document.getElementById('estadoDadosEnvio');
        estado.value = '--';

        let cep = document.getElementById('cep');
        cep.value = '';

        let dataTramitacao = document.getElementById('dataTramitacao');
        dataTramitacao.value = '';

        let responsavelTramitacao = document.getElementById('responsavelTramitacao');
        responsavelTramitacao.value = '';

    }

    async carregarDadosEquipe(id){
                
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }

        const response = await api(apiURL+"equipe/getMembrosEntrevista/"+id, requestOptions);
        const resp = await response.json();

        return resp;

    }

    async carregarCidades(uf){
                
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        const response = await api(apiURL+"usuarios/getCidadePelaSiglaDoEstado/"+uf, requestOptions);
        const resp = await response.json();

        return resp;

    }

    preencherCamposDadosEntrevista(data){

        this.setState({
            nomeCoordenador: data['coordenador'] !== null && data['coordenador'] !== undefined && data['coordenador']['NOME'] !== null && data['coordenador']['NOME'] !== undefined ? data['coordenador']['NOME'] : '',
            emailCoordenador: data['coordenador'] !== null && data['coordenador'] !== undefined && data['coordenador']['EMAIL'] !== null && data['coordenador']['EMAIL'] !== undefined ? data['coordenador']['EMAIL'] : '',
            nomeEntrevistador : 0,
            nomeEntrevistadorExtra : 0,
            entrevistadores: data['entrevistadores'] !== null && data['entrevistadores'] !== undefined ? data['entrevistadores'] : '',
            entrevistadoresExtra: data['entrevistadores'] !== null && data['entrevistadores'] !== undefined ? data['entrevistadores'] : '',
            emailEntrevistador: '',
            cpfEntrevistador: '',
            emailEntrevistadorExtra: '',
            cpfEntrevistadorExtra: '',
            emissor: data['equipe']['COD_EQUIPE'] !== null && data['equipe']['COD_EQUIPE']  !== undefined ? data['equipe']['COD_EQUIPE'] : document.getElementById("emissor").value
        });

        let selectEntrevistador = document.getElementById('nomeEntrevistador');
        let selectEntrevistadorExtra = document.getElementById('nomeEntrevistadorExtra');
  
        //Limpar select
        var length = selectEntrevistador.options.length;
        for (let i = length-1; i >= 0; i--) {
            selectEntrevistador.remove(i);
        }

        length = selectEntrevistadorExtra.options.length;
        for (let i = length-1; i >= 0; i--) {
            selectEntrevistadorExtra.remove(i);
        }

        //Carregar select com o resultado
        let option = new Option('Selecione', '0');
        let opcao = new Option('Selecione', '0');                                        
        selectEntrevistador.options[0] = option;
        selectEntrevistadorExtra.options[0] = opcao;

        if(this.state.entrevistadores !== ''){
            let contador = 1;            
            data["entrevistadores"].forEach(temp => {                    
                option = new Option(temp[0]['NOME'], temp[0]['ID']);
                selectEntrevistador.options[contador] = option;
                //selectEntrevistadorExtra.options[contador] = option;
                contador++;
            });
        }

        if(this.state.entrevistadoresExtra !== ''){
            let contador = 1;            
            data["entrevistadores"].forEach(temp => {                    
                option = new Option(temp[0]['NOME'], temp[0]['ID']);
                selectEntrevistadorExtra.options[contador] = option;
                contador++;
            });
        }

        selectEntrevistador.value = '0';
        selectEntrevistadorExtra.value = '0';

    }

    preencherCampoCidadeEntrevista(data){

        this.setState({
            cidadeEntrevista: '',
            cidades: data['dados'] !== null && data['dados'] !== undefined ? data['dados'] : '',
        });

        let selectCidadeEntrevista = document.getElementById('cidadeEntrevista');
  
        //Limpar select
        var length = selectCidadeEntrevista.options.length;
        for (let i = length-1; i >= 0; i--) {
            selectCidadeEntrevista.remove(i);
        }

        //Carregar select com o resultado
        let option = new Option('Selecione', '0');                                        
        selectCidadeEntrevista.options[0] = option;

        if(this.state.cidades !== ''){
            let contador = 1;            
            data["dados"].forEach(temp => {                    
                option = new Option(temp['NOME'], temp['NOME']);
                selectCidadeEntrevista.options[contador] = option;
                contador++;
            });
        }

        selectCidadeEntrevista.value = '0';

    }

    handleChangeEmissor(e){
        this.setState({emissor: e.target.value});

        if(this.state.template === "5"){
            this.carregarDadosEquipe(e.target.value).then(data => {
                this.preencherCamposDadosEntrevista(data);
            }).catch(erro => {
                console.log('Erro ao carregar dados da equipe: ' + erro);
            });
        }
    }

    handleChangeTitulo(e){
        this.setState({titulo: e.target.value});
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };

    loadMoreData() {
		const data = this.state.documentos;
		
		const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
		this.setState({
			pageCount: Math.ceil(data.length / this.state.perPage),
			orgTabelaDoc:slice
		})
	
    }

    retornaNomeTemplate(codigo){
        let nome = "";

        for(let i = 0; i < this.state.templates.length; i = i + 1 ) {
            if(this.state.templates[i]['cod_template_documento'] === codigo){
                nome = this.state.templates[i]['nome'];
            }
        }

        return nome;
    }

    handleChangeLinkNovo(e){
        this.setState({
            linkNovo: e.target.value,
            arquivoNovo: '',
            file: '',
            arquivoInformado: ''
        });        
    }

    handleChangeArquivoNovo(e){

        this.setState({
            arquivoNovo: e.target.files[0],
            linkNovo: ''
        });      

        let arquivoInformadoAux = 'S';
        let fileAux = {                                      
                    name: this.state.arquivoNovo.name,                    
                    size: this.state.arquivoNovo.size,
                    type: this.state.arquivoNovo.type
        }

        this.setState({file: fileAux});
        this.setState({arquivoInformado: arquivoInformadoAux});            
        
    }

    handleNumeroDocumentoExterno(e){
        this.setState({numeroDocumentoExterno: e.target.value});
    }

    handleRemetente(e){
        this.setState({remetente: e.target.value});
    }

    handleChangeHoraInicioEntrevista(e){
    
        this.setState({horaInicioEntrevista: e.target.value});        
    }

    handleChangeHoraTerminoEntrevista(e){
    
        this.setState({horaTerminoEntrevista: e.target.value});        
    }

    handleOnChangeUFEntrevista(e){

        if(e.target.value !== '0'){
            this.carregarCidades(e.target.value).then(data => {
                this.preencherCampoCidadeEntrevista(data);
            }).catch(erro => {
                console.log('Erro ao carregar dados da equipe: ' + erro);
            });
        }

        this.setState({ufEntrevista: e.target.value});
    }

    handleOnChangeCidadeEntrevista(e){
        this.setState({cidadeEntrevista: e.target.value});
    }

    handleChangeNomeResponsavel(e){        
        this.setState({nomeResponsavel: e.target.value});
    }
    
    handleChangeEmailResponsavel(e){        
        this.setState({emailResponsavel: e.target.value});
    }

    soNumeros(str){
        return str.replace(/[^0-9]/g, '');
    }

    handleChangeCpfEntrevistado(e){        
        this.setState({cpfEntrevistado: this.soNumeros(e.target.value)});
    }

    handleChangeCpfEntrevistador(e){        
        this.setState({cpfEntrevistador: this.soNumeros(e.target.value)});
    }

    handleChangeCpfEntrevistadorExtra(e){        
        this.setState({cpfEntrevistadorExtra: this.soNumeros(e.target.value)});
    }

    handleChangeEntrevistador(e){

        this.setState({idEntrevistador: e.target.value});

        let id = e.target.value;
        if(id === "0"){
            this.setState({
                emailEntrevistador: '',
                cpfEntrevistador: ''
            });
        }
        else {
            if(this.state.entrevistadores !== null && this.state.entrevistadores !== undefined && this.state.entrevistadores !== ''){
                this.state.entrevistadores.forEach(data => {

                    if(data[0]["ID"] === parseInt(id)){
                        this.setState({
                            emailEntrevistador: data[0]["EMAIL"],
                            cpfEntrevistador: data[0]["CPF"]
                        });
                    }          
                });
            }
        }

    }

    handleChangeEntrevistadorExtra(e){

        this.setState({idEntrevistadorExtra: e.target.value});

        let id = e.target.value;
        if(id === "0"){
            this.setState({
                emailEntrevistadorExtra: '',
                cpfEntrevistadorExtra: ''
            });
        }
        else {
            if(this.state.entrevistadoresExtra !== null && this.state.entrevistadoresExtra !== undefined && this.state.entrevistadoresExtra !== ''){
                this.state.entrevistadoresExtra.forEach(data => {

                    if(data[0]["ID"] === parseInt(id)){
                        this.setState({
                            emailEntrevistadorExtra: data[0]["EMAIL"],
                            cpfEntrevistadorExtra: data[0]["CPF"]
                        });
                    }          
                });
            }
        }

    }

    handleChangeIdEntrevistador(id){

        this.carregarDadosEquipe(this.state.emissor).then(data => {
            this.preencherCamposDadosEntrevista(data);
        }).catch(erro => {
            console.log('Erro ao carregar dados da equipe: ' + erro);
        });

        this.setState({idEntrevistador: id});
        
    }

    handleChangeHaEntrevistadorExtra(e){
        
        let linhaEntrevistadorExtra = document.getElementById("linhaEntrevistadorExtra");
        if(e.target.value === 'N'){

            this.setState({
                idEntrevistadorExtra: '',
                nomeEntrevistadorExtra: '',
                emailEntrevistadorExtra:'',
                cpfEntrevistadorExtra:'',    
            });

            linhaEntrevistadorExtra.style.display = 'none';
            document.getElementById("nomeEntrevistadorExtra").value = '0';
            document.getElementById("emailEntrevistadorExtra").value = '';
            document.getElementById("cpfEntrevistadorExtra").value = '';
            
        }
        else{
            linhaEntrevistadorExtra.style.display = 'block';
        }

    }

	render(){

        const { loading } = this.context;

        if(this.state.redirecionar === true && this.codigo !== 0){
            return ( 
                <Redirect 
                    to={{
                        pathname:"/painel/GestaoDocumento/DocumentoEdicao",
                        state: {id: this.codigo, origem: 'solicitacao'}
                     }} >
                </Redirect>);
        }
        else
        
	    return (		
			
            <div className="container-xl-fluid">         
                <Menu  idMenuOpen = "btnDocumentos"></Menu>             
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container-fluid">  
                                    <br/>
                                    <Titulo name={this.state.tituloPagina} className=".mt-3"/>        
                                                                   
                                    <div className="row my-1">    
                                        <div className = "col">                                            
                                            <form className="container" encType="multipart/form-data">
                                                <div className="alert alert-danger erroLogin" role="alert" id="erro">
                                                </div>                                                
                                                <div className="row my-3">
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="template">Documento</label>
                                                        <select id="template" className="form-select" onChange={this.handleChangeTemplate}>                    
                                                        </select>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="emissor">Emissor</label>
                                                        <select id="emissor" className="form-select" onChange={this.handleChangeEmissor}>
                                                        </select>
                                                    </div>                                    
                                                </div>
                                                <div className="row my-3" id="linhaLocalizacaoEntrevista"  style={{display: "none"}}>  
                                                    <ComboEstados id="estadoEntrevista" onChange={this.handleOnChangeUFEntrevista}> </ComboEstados>   
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="cidadeEntrevista">Cidade</label>
                                                        <select id="cidadeEntrevista" className="form-select" onChange={this.handleOnChangeCidadeEntrevista}>
                                                        </select>
                                                    </div>                                 
                                                </div>
                                                <div className="row my-3" id="linhaDataReuniao" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataReuniao">Data da reunião</label>
                                                        <input type="date" className="form-control" id="dataReuniao" onKeyUp={this.limparDataReuniao}  onChange={this.handleChangeDataReuniao}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaInicio">Hora início</label>
                                                        <MaskedInput value={this.state.horaInicio} mask="99:99" className="form-control" id="horaInicio" onChange={this.handleChangeHoraInicio}></MaskedInput>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaTermino">Hora término</label>
                                                        <MaskedInput value={this.state.horaTermino} mask="99:99" className="form-control" id="horaTermino" onChange={this.handleChangeHoraTermino}></MaskedInput>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaResponsavel" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="nomeResponsavel">Responsável</label>
                                                        <input type="text" className="form-control" id="nomeResponsavel" value={this.state.nomeResponsavel} onChange={this.handleChangeNomeResponsavel}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="emailResponsavel">E-mail do Responsável</label>
                                                        <input type="text" className="form-control" id="emailResponsavel" value={this.state.emailResponsavel} onChange={this.handleChangeEmailResponsavel}/>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaParticipantes" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-12 col-12">
                                                        <ListaParticipantes
                                                            participantes = {this.state.participantes}
                                                            removerParticipante = {this.removerParticipante}
                                                            adicionarParticipante = {this.adicionarParticipante}
                                                        ></ListaParticipantes>        
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaDataEntrevista"  style={{display: "none"}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataEntrevista">Data da Entrevista</label>
                                                        <input type="date" className="form-control" id="dataEntrevista" onKeyUp={this.limparDataEntrevista}  onChange={this.handleChangeDataEntrevista}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaInicioEntrevista">Hora de início</label>
                                                        <MaskedInput value={this.state.horaInicioEntrevista} mask="99:99" className="form-control" id="horaInicioEntrevista" onChange={this.handleChangeHoraInicioEntrevista}></MaskedInput>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="horaTermino">Hora de fim</label>
                                                        <MaskedInput value={this.state.horaTerminoEntrevista} mask="99:99" className="form-control" id="horaTerminoEntrevista" onChange={this.handleChangeHoraTerminoEntrevista}></MaskedInput>
                                                    </div>
                                                </div>
                                                <div id="linhaCoordenador"  style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>COORDENADOR DA EQUIPE REGIONAL</b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeCoordenador">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6">
                                                            <input type="text" className="form-control" id="nomeCoordenador" value={this.state.nomeCoordenador} readOnly/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailCoordenador">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailCoordenador" value={this.state.emailCoordenador} readOnly/>    
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div id="linhaEntrevistador"  style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>ENTREVISTADOR </b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeEntrevistador">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6" style={{display:"flex", verticalAlign:"middle"}}>
                                                            <select id="nomeEntrevistador"className="form-select" onChange={this.handleChangeEntrevistador}>       
                                                                <option value="0">Selecione...</option>             
                                                            </select>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-2" >
                                                            <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#modalEntrevistador">
                                                            <i className="fas fa-plus"></i>
                                                            </button>
                                                            <ModalAdicionarEntrevistador 
                                                                idEntrevistador={this.state.idEntrevistador}
                                                                handleChangeEntrevistador = {this.handleChangeIdEntrevistador}
                                                            >
                                                            </ModalAdicionarEntrevistador>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailEntrevistador">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailEntrevistador" value={this.state.emailEntrevistador} readOnly/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="cpfEntrevistador">CPF</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <MaskedInput required mask="999.999.999-99" className="form-control" id="cpfEntrevistador" value={this.state.cpfEntrevistador} readOnly={true}></MaskedInput>    
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div  className = "col-xs-12 col-sm-12 col-md-3">
                                                        <label htmlFor="haEntrevistadorExtra">Há mais um entrevistador?</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-2">    
                                                            
                                                            <select id="haEntrevistadorExtra"className="form-select" onChange={this.handleChangeHaEntrevistadorExtra}>       
                                                                <option value="N">Não</option>
                                                                <option value="S">Sim</option>             
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="linhaEntrevistadorExtra"  style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>ENTREVISTADOR </b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeEntrevistadorExtra">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6" style={{display:"flex", verticalAlign:"middle"}}>
                                                            <select id="nomeEntrevistadorExtra"className="form-select" onChange={this.handleChangeEntrevistadorExtra}>       
                                                                <option value="0">Selecione...</option>             
                                                            </select>
                                                        </div>                                                       
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailEntrevistadorExtra">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailEntrevistadorExtra" value={this.state.emailEntrevistadorExtra} readOnly/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="cpfEntrevistadorExtra">CPF</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <MaskedInput required mask="999.999.999-99" className="form-control" id="cpfEntrevistadorExtra" value={this.state.cpfEntrevistadorExtra} readOnly={true}></MaskedInput>    
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="linhaEntrevistado" style={{display: "none"}}>
                                                    <div className="row mt-3" >
                                                        <div className="col">
                                                            <label><b>ENTREVISTADO </b> </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="nomeEntrevistado">Nome</label>
                                                        </div>
                                                        <div className = "col-xs-12 col-sm-12 col-md-6">
                                                            <input type="text" className="form-control" id="nomeEntrevistado" onChange={this.handleChangeNomeEntrevistado} value={this.state.nomeEntrevistado } required/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="cpfEntrevistado">CPF</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <MaskedInput required mask="999.999.999-99" className="form-control" id="cpfEntrevistado" onChange={this.handleChangeCpfEntrevistado} value={this.state.cpfEntrevistado}></MaskedInput>
                                                        </div>

                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="emailEntrevistado">E-mail</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="emailEntrevistado" onChange={this.handleChangeEmailEntrevistado} value={this.state.emailEntrevistado} required/>    
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-1">
                                                            <label htmlFor="instituicaoEntrevistado">Instituição</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-4">
                                                            <input type="text" className="form-control" id="instituicaoEntrevistado"  onChange={this.handleChangeInstituicaoEntrevistado} value={this.state.instituicaoEntrevistado}  required/>    
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2" >
                                                        <div  className = "col-xs-12 col-sm-12 col-md-2">
                                                            <label htmlFor="tipoInstituicao">Tipo instituição</label>
                                                        </div>
                                                        <div  className = "col-xs-12 col-sm-12 col-md-5">
                                                            <select id="tipoInstituicaoEntrevistado"className="form-select"  onChange={this.handleChangeTipoInstituicaoEntrevistado}>  
                                                                <option value="selecione">Selecione...</option>
                                                                <option value="gestao_publica">GESTÃO PÚBLICA</option>                  
                                                                <option value="empresa_privada">EMPRESA PRIVADA</option>
                                                                <option value="instituicao_fomento">INSTITUIÇÃO DE FOMENTO</option>
                                                                <option value="federacao">FEDERAÇÃO</option>
                                                                <option value="confederacao">CONFEDERAÇÃO</option>
                                                                <option value="associacao">ASSOCIAÇÃO</option>
                                                                <option value="cooperativa">COOPERATIVA</option> 
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>                                                
                                                <DocumentoRecebido 
                                                    mostrar = {this.state.mostrar}
                                                    handleChangeLinkNovo = {this.handleChangeLinkNovo} 
                                                    handleChangeArquivoNovo = {this.handleChangeArquivoNovo}
                                                    handleNumeroDocumentoExterno = {this.handleNumeroDocumentoExterno} 
                                                    handleRemetente = {this.handleRemetente}
                                                    tabLink = "Informar link do documento"
                                                    tabArquivo = "Informar arquivo do documento"
                                                >
                                                </DocumentoRecebido>
                                                <div className="row my-3" id="linhaTitulo">
                                                    <div className="col">
                                                        <label htmlFor="titulo">Título</label>
                                                        <input type="text" className="form-control" id="titulo" required onChange={this.handleChangeTitulo}/>
                                                    </div>
                                                </div>
                                                <div className="row my-4" id="linhaTramitacao" style={{display: 'none'}} >
                                                    <div className="col">
                                                        <div className="accordion" id="accordionTramitacao">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        Envio de documento (opcional)
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body">
                                                                        <ModalDocumentoEdit tramitacao={this.state.tramitacao}></ModalDocumentoEdit>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row my-3" id="linhaObs">
                                                    <div className="col">
                                                        <label htmlFor="observacao">Observações (opcional)</label>
                                                        <textarea 
                                                            className="form-control" 
                                                            id="observacao" 
                                                            onChange={this.handleChangeObservacao} 
                                                            rows="5"
                                                            maxLength={1500}
                                                            value={this.state.observacao}>
                                                        </textarea>
                                                        <small style={{ float: 'right' }}>{this.state.countObs}/1500</small>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col d-flex flex-row-reverse align-items-center">
                                                        {
                                                            (this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS) ||
                                                            this.context.getPermission(permissions.SOLICITAR_DOCUMENTOS_TODAS_EQUIPES)) &&
                                                            <button type="submit" className="btn btn-primary my-3"
                                                                onClick={this.handleSubmitSolicitacao} id="botaocriarSolicitacao"
                                                                disabled={loading}
                                                                >                                                                
                                                                    Solicitar documento
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {
                                        this.context.getPermission(permissions.VISUALIZAR_DOCUMENTOS) &&
                                        <>
                                            <div className='text-left'>
                                                <p>
                                                    <i className='fas fa-file-alt'></i>
                                                    <span className='text-uppercase ms-2'>Meus documentos solicitados</span>
                                                </p>
                                            </div>
                                            <hr />

                                            <div className="row my-3">                                
                                                <div className="col">                                            
                                                    <div className="table-responsive">
                                                        
                                                        { this.state.orgTabelaDoc.length > 0 &&
                                                        <table className="table table-striped">
                                                            <thead  className="table-light">                                                        
                                                                <tr>                        
                                                                    <th scope="col">Nº Protocolo</th>                                                            
                                                                    <th scope="col">Nº Doc.</th> 
                                                                    <th scope="col">Criado em</th>
                                                                    <th scope="col">Documento</th>                                                            
                                                                    <th scope="col">Emissor</th>                                                            
                                                                    <th scope="col-2">Título</th>                                                            
                                                                    <th scope="col">Situacao</th>
                                                                    <th scope="col">Ações</th>
                                                                </tr>
                                                            </thead>                 
                                                            <tbody> 
                                                                {                                                               
                                                                    this.state.orgTabelaDoc.map(doc => (                                                            
                                                                            
                                                                        <tr key={doc.COD_DOCUMENTO}>
                                                                                <th scope="col">{doc.COD_DOCUMENTO}</th>
                                                                                <th scope="col">{doc.NUM_DOCUMENTO}</th>
                                                                                <th scope="col"><center>{DataUtil(doc.DATA_CRIACAO,'TS')}</center></th>                                                            
                                                                                <th scope="col">{this.retornaNomeTemplate(doc.COD_TEMPLATE_DOCUMENTO)}</th> 
                                                                                <th scope="col">{doc.SIGLA_EMISSOR}</th>                                                            
                                                                                <th scope="col">{doc.TITULO}</th>                                                          
                                                                                <th scope="col">{doc.SITUACAO}</th>
                                                                                <th scope="col">
                                                                                    {
                                                                                        this.context.getPermission(permissions.EDITAR_DOCUMENTOS) &&
                                                                                        <button type="button" className="btn" onClick={() => this.editarDocumento(doc.COD_DOCUMENTO)}>
                                                                                            <i className="far fa-edit"></i>
                                                                                        </button>    
                                                                                    }
                                                                                </th>
                                                                        </tr>
                                                                        
                                                                    ))                                                       
                                                                }                                                           
                                                            </tbody>                
                                                        </table>     
                                                        }
                                                        {
                                                            this.state.orgTabelaDoc.length === 0 && 
                                                            <p className='alert alert-warning'>
                                                                Nenhum documento encontrado!
                                                            </p>
                                                        }
                                                        {
                                                            this.state.orgTabelaDoc.length > 0 &&
                                                            <ReactPaginate
                                                                previousLabel={"Anterior"}
                                                                nextLabel={"Próximo"}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={this.state.pageCount}
                                                                marginPagesDisplayed={this.state.isMobile ? 1 : 5}
                                                                pageRangeDisplayed={this.state.isMobile ? 1 : 2}
                                                                onPageChange={this.handlePageClick}
                                                                containerClassName={"pagination"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"}/>    
                                                        }                                       
                                                    </div>
                                                </div>    
                                            </div>
                                        </>
                                    }
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>  
            </div>              
        );
    }


}

export default SolicitacaoDocumento;