import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Topo from '../../../Components/Topo';
import Titulo from '../../../Components/Titulo';
import { Link }  from 'react-router-dom';
import MenuDocumento from '../../../Components/Menu/MenuDocumento';

class Documentos extends React.Component {

	constructor(props){
        super(props);
                
        this.state = {
        };

    }

	componentDidMount(){

    }

    componentWillUnmount(){

    }

	render(){
	    return (		
			
            <div className="container-fluid">                 
				<Topo></Topo>
				<Menu></Menu>                
                <div className="container">     
                    <br/><br/>
                    <Titulo name="Documentos" className=".mt-3"/>
                    <br/>
                    <div className="row my-1">
                        <MenuDocumento></MenuDocumento>
                        <div className = "col-lg-10">
                            <form >
                                <div className="row">
                                    <h4>Documentos modificados recentemente</h4>
                                </div>
                                
                                <ul className="list-group">
                                    <li className="list-group-item my-0">
                                        <div className="d-flex align-items-between justify-content-between">
                                            <span>Número documento</span>
                                            <span>Título</span>
                                            <span>Data modificação</span>
                                        </div>
                                         
                                    </li>
                                    <li className="list-group-item my-0">
                                        <div className="d-flex align-items-between justify-content-between">
                                            <Link to="/painel/GestaoDocumento/DocumentoEdit">ATA00001-2021CG</Link>
                                            <span>Ata da reunião de conselheiros</span>
                                            <span>15/04/2021</span>
                                        </div> 
                                    </li>
                                    <li className="list-group-item my-0">
                                        <div className="d-flex align-items-between justify-content-between">
                                            <Link to="/painel/GestaoDocumento/DocumentoEdit">ATA00045-2021CG</Link>
                                            <span>Ata da reunião equipe de campo</span>
                                            <span>14/04/2021</span>
                                        </div> 
                                    </li>
                                    <li className="list-group-item my-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Link to="/painel/GestaoDocumento/DocumentoEdit">ATA00003-2021CG</Link>
                                            <span>Ata da reunião de conselheiros</span>
                                            <span>13/04/2021</span>
                                        </div> 
                                    </li>
                                    <li className="list-group-item my-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Link to="/painel/GestaoDocumento/DocumentoEdit">EDITAL00001-2021CG</Link>
                                            <span>Edital de publicação</span>
                                            <span>13/04/2021</span>
                                        </div> 
                                    </li>
                                </ul> 

                                <br/>
                                <div className="row">
                                    <h4>Consolidado</h4>
                                </div>
                                <ul className="list-group">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        ATA
                                        <span className="badge bg-primary rounded-pill">14</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        EDITAL
                                        <span className="badge bg-primary rounded-pill">2</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        MEMORANDO
                                        <span className="badge bg-primary rounded-pill">1</span>
                                    </li>
                                </ul>

                            </form>
                        </div>
                    </div>    
                </div>
            </div>  
                        
        );
    }


}

export default Documentos;