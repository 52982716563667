import React from 'react';
import Logo from "../Logo";

function Topo(props) {
    return(        
        <header>
			<div className = "container-fluid">				
				<div className = "row">
					<div className="col-lg-8">
						<Logo></Logo>				
					</div>
					<div className="col-lg-4 align-items-center justify-content-center my-3">
						Bem vindo {props.nome}
					</div>
				</div>
			</div>
		</header>	
        );
    
}
export default Topo;