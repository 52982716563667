import React, { Component } from "react";
import LogoParceriaInterna from "../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../Components/Menu/Menu";
import Titulo from "../../../Components/Titulo";
import { GlobalContext } from "../../../Context/GlobalContext/context";
import './style.css';
import ReactTooltip from "react-tooltip";
import { TablePessoas } from "../../../Components/GestaoAcesso/ManterUsuario/Table";

export default class ManterUsuario extends Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.referencia = React.createRef();

    }

    componentDidUpdate(){
        ReactTooltip.rebuild()
    }

    render() {
        return (
            <>
            <div className="container-xl-fluid">
                <Menu idMenuOpen="btnAcesso"></Menu>
                <div className="page-content fundoAreaTrabalho" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid" >
                        <div className="row" >
                            <div className="col p-2" style={{ backgroundColor: '#F2F3F7' }}>
                                <div className="container-fluid">
                                    <br />
                                    <Titulo 
                                        name="Gestão de Pessoas"
                                        className="mt-3" 
                                        tooltip={{ 
                                            texto: `<p>Você pode , pesquisar Pessoas  pelo nome, e-mail, cpf, cnpj ou pela condição.<br />
                                                    Clique em “Filtrar Pessoas” e insira uma palavra ou número no campo de pesquisa,<br />
                                                    logo em seguida clique no botão “pesquisar”. Caso queira pesquisar pela condição,<br />
                                                    selecione um item da lista de condições da pessoa e os resultados serão<br />
                                                    atualizados automaticamente<br />
                                                    Caso queira limpar os filtros, clique em “limpar”.<br />
                                                    <br />
                                                    Para adicionar uma pessoa manualmente, clique no botão ”Adicionar<br />
                                                    Pessoa e insira os dados desejados.<br />
                                                    <br />`, 
                                            direcao: 'right' 
                                        }}
                                    />
                                    <br />
                                    <TablePessoas/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}