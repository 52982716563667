import React, {useContext, useEffect, useState} from 'react';
import { apiWithToken } from '../../Services/Api';
import { apiURL } from '../../Services/env';
import * as permissions from '../../Context/GlobalContext/permissions';
import {GlobalContext} from "../../Context/GlobalContext/context";

function ModalEnviarEmail({show, handleClose, user}) {

    const [assunto, setAssunto] = useState(null);
    const [mensagem, setMensagem] = useState(null);
    const [nome, setNome] = useState(null);
    const [email, setEmail] = useState(null);
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const context = useContext(GlobalContext);

    useEffect(() => {
        if(show === true)
        {
            setNome(user.NOME)
            setEmail(user.EMAIL)
            setId(user.ID)
        }
    }, [show, user, setNome, setEmail, setId])

    const limparDados = () => {
        setAssunto(null)
        setMensagem(null)
        document.getElementById('assunto').value = '';
        document.getElementById('mensagem').value = '';

        handleClose();
    }

    const enviarEmailContato = async () => {

        let formData = new FormData();
        formData.append('de_type', 'cpf');
        formData.append('de', localStorage.getItem('projartCod'));
        formData.append('para', id);
        formData.append('assunto', assunto);
        formData.append('mensagem', mensagem);

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        const response = await apiWithToken(apiURL + "usuario/enviarMensagem", requestOptions);
        const resp = await response.json();

        return resp;
    }

    const enviarEmail = () => {

        if (context.getPermission(permissions.ENVIAR_EMAIL_CONTATO)) {

            setLoading(true);
            
            if (assunto === null || assunto === undefined || assunto === '' ||
                mensagem === null || mensagem === undefined || mensagem === '') {
                alert('Preencha o assunto e a mensagem para poder enviar o e-mail!');
            }
            else {

                document.getElementById('closeModalBtn').click();

                enviarEmailContato()
                    .then(data => alert(data['mensagem']))
                    .catch()
                    .finally(() => {
                        limparDados();     
                        setLoading(false)
                    });
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    let modalClass = (show) ? 'modal fade show' : 'modal fade';
    
    return (
        <div className={modalClass} aria-labelledby="exampleModalLabel1" 
                aria-hidden="true" style={ !show ? {display: 'none'} : {display: 'block'} } role={ show ? 'dialog' : 'none' } aria-modal={ show ? "true" : "false" } >
            <div className="modal-dialog modal-lg" >
                <div className="modal-content">
                    <div className="modal-header">
                        <button id='closeModalBtn' type="button" className="close d-none" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row my-2" >
                                <div style={{ color: '#707070' }} className="col-xs-12 col-sm-12 col-md-12 col-12">
                                    Enviar e-mail para:
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xs-12 col-sm-12 col-md-12 col-12">
                                    {nome}
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xs-12 col-sm-12 col-md-12 col-12">
                                    {email}
                                </div>
                            </div>
                            <div className="row mt-3" >
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <label htmlFor="assunto">Assunto</label>
                                    <input type="text" id="assunto" className="form-control" onChange={e => setAssunto(e.target.value)} disabled={loading} required />
                                </div>
                            </div>
                            <div className="row my-3" >
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <label htmlFor="mensagem">Mensagem</label>
                                    <div>
                                        <textarea 
                                            className="form-control" 
                                            id="mensagem" 
                                            onChange={e => setMensagem(e.target.value)}
                                            rows={7}
                                            disabled={loading}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div style={ !loading ? { display: 'none' } : { display: 'block', marginRight: 'auto' }}>
                                <div className="spinner-border spinner-border-sm" role="status" style={{ margin: '0 10px' }}></div>
                                <span>Carregando...</span>
                            </div>
                        
                            <button type="button" className="btn btn-outline-secondary" onClick={() => limparDados()} disabled={loading}>Fechar</button>
                            <button type="button" className="btn btn-primary" onClick={() => enviarEmail()} disabled={!assunto || !mensagem || loading}>Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ModalEnviarEmail;