import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Titulo from '../../../Components/Titulo';
import { Redirect }  from 'react-router-dom';
import LogoParceriaInterna from '../../../Components/LogoParceria/LogoParceriaInterna';
import ReactPaginate from 'react-paginate';
import DataUtil from '../../../Components/Data';
import '../../../css/style.css';
import { apiURL } from '../../../Services/env';
import { api } from "../../../Services/Api";

import { GlobalContext } from "../../../Context/GlobalContext/context";
import * as permissions from "../../../Context/GlobalContext/permissions";

class DocumentoPesquisa extends React.Component {

    static contextType = GlobalContext

	constructor(props){
        super(props);
                
        this.ordernarDocumento = this.ordernarDocumento.bind(this);
        this.limparDataCriacao = this.limparDataCriacao.bind(this);
        this.limparDataTramitacao = this.limparDataTramitacao.bind(this);
        this.limparDataAssinatura = this.limparDataAssinatura.bind(this);
        this.limparDataAprovacao = this.limparDataAprovacao.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleChangeTemplate = this.handleChangeTemplate.bind(this);
        this.handleChangeAno = this.handleChangeAno.bind(this);
        this.handleChangeDataAprovacao = this.handleChangeDataAprovacao.bind(this);
        this.handleChangeDataCriacao = this.handleChangeDataCriacao.bind(this);
        this.handleChangeDataTramitacao = this.handleChangeDataTramitacao.bind(this);
        this.handleChangeDataAssinatura = this.handleChangeDataAssinatura.bind(this);
        this.handleChangeSequencial = this.handleChangeSequencial.bind(this);
        this.handleChangeSituacao = this.handleChangeSituacao.bind(this);
        this.handleChangeEmissor = this.handleChangeEmissor.bind(this);
        this.handleChangeNumeroDocumento = this.handleChangeNumeroDocumento.bind(this);
        this.handleChangeTituloDocumento = this.handleChangeTituloDocumento.bind(this);
        this.handleClickPesquisar = this.handleClickPesquisar.bind(this);
        this.editarDocumento = this.editarDocumento.bind(this);
        this.deletarDocumento = this.deletarDocumento.bind(this);
        this.iniciarPesquisa = this.iniciarPesquisa.bind(this);
        this.iniciarPesquisaCoordenador = this.iniciarPesquisaCoordenador.bind(this);
        this.handleChangeDataReuniao = this.handleChangeDataReuniao.bind(this);
        this.limparDataReuniao = this.limparDataReuniao.bind(this);
        this.popularCampos = this.popularCampos.bind(this);
        this.carregarDadosEquipe = this.carregarDadosEquipe.bind(this);
        this.preencherEntrevistador = this.preencherEntrevistador.bind(this);
        this.handleChangeEntrevistador = this.handleChangeEntrevistador.bind(this);
        this.handleClickExportar = this.handleClickExportar.bind(this);
        this.handleClickExportarPDF = this.handleClickExportarPDF.bind(this);
        this.exportarDocumento = this.exportarDocumento.bind(this);
        this.handlePesquisarParticipante = this.handlePesquisarParticipante.bind(this);
        this.handleLimparPesquisa = this.handleLimparPesquisa.bind(this);
        this.handleSalvarFiltroPesquisa = this.handleSalvarFiltroPesquisa.bind(this);
        this.preencherFiltro = this.preencherFiltro.bind(this);

        this._isMounted = false;
        this.codigo = 0;

        this.referencia = React.createRef();

        this.cabecalho= ["Documento","Criado em","Emissor","Título", "Situacao","Última tramitação"];
        
        this.consultaAlterada = true;

        this.state = {            
            isMobile: false,
            token: localStorage.getItem("projartToken"),
            nome: localStorage.getItem('projartUsuario'),
            cpf: localStorage.getItem('projartCod'),
            papeis: localStorage.getItem('projartPapeis'),
            filtro: localStorage.getItem('filtroPesquisa'),
            templates: '',
            emissores: '',
            situacoes:'',
            anos: '',
            template: '',
            ano: '',
            dataAssinatura: '',
            dataAprovacao: '',
            dataCriacao: '',
            dataTramitacao: '',
            sequencial:'',
            situacao:'',
            emissor:'',
            numeroDocumento:'',
            tituloDocumento: '',              
            documentos: [],            
            offset:0,
            orgTabelaDoc: [],
            perPage: 10,
            currentPage: 0,
            pageCount: 0,
            redirecionar: false,
            id: 0,
            dataReuniao: '',
            entrevistadores: '',
            nomeEntrevistador: '',
            total: 0,
            participantes: [],
            participante: ''
        };

    }

    handleSalvarFiltroPesquisa(){
        
        localStorage.removeItem('filtroPesquisa');

        let filtro = [
            (this.state.template !== undefined && this.state.template !== null && this.state.template !== '') ? this.state.template : 'null',
            (this.state.ano !== undefined && this.state.ano !== null && this.state.ano !== '') ? this.state.ano : 'null',
            (this.state.dataAssinatura !== undefined && this.state.dataAssinatura !== null && this.state.dataAssinatura !== '') ? this.state.dataAssinatura : 'null',
            (this.state.dataAprovacao !== undefined && this.state.dataAprovacao !== null  && this.state.dataAprovacao !== '') ? this.state.dataAprovacao : 'null',
            (this.state.dataCriacao !== undefined && this.state.dataCriacao !== null  && this.state.dataCriacao !== '') ? this.state.dataCriacao : 'null',
            (this.state.dataTramitacao !== undefined && this.state.dataTramitacao !== null && this.state.dataTramitacao !== '') ? this.state.dataTramitacao : 'null',
            (this.state.sequencial !== undefined && this.state.sequencial !== null && this.state.sequencial !== '') ? this.state.sequencial : 'null',
            (this.state.situacao !== undefined && this.state.situacao !== null && this.state.situacao !== '') ? this.state.situacao : 'null',
            (this.state.emissor !== undefined && this.state.emissor !== null && this.state.emissor !== '') ? this.state.emissor : 'null',
            (this.state.numeroDocumento !== undefined && this.state.numeroDocumento !== null  && this.state.numeroDocumento !== '') ? this.state.numeroDocumento : 'null',
            (this.state.tituloDocumento !== undefined && this.state.tituloDocumento !== null && this.state.tituloDocumento !== '') ? this.state.tituloDocumento : 'null',
            (this.state.dataReuniao !== undefined && this.state.dataReuniao !== null && this.state.dataReuniao !== '') ? this.state.dataReuniao : 'null',
            (this.state.nomeEntrevistador !== undefined && this.state.nomeEntrevistador !== null && this.state.nomeEntrevistador !== '') ? this.state.nomeEntrevistador : 'null',
            (this.state.participante !== undefined && this.state.participante !== null  && this.state.participante !== '') ? this.state.participante : 'null'
        ]

        localStorage.setItem('filtroPesquisa', filtro);
    }

    handleLimparPesquisa(e){

        e.preventDefault();

        localStorage.removeItem('filtroPesquisa');

        this.setState({            
            template: '',
            ano: '',
            dataAssinatura: '',
            dataAprovacao: '',
            dataCriacao: '',
            dataTramitacao: '',
            sequencial:'',
            situacao:'',
            emissor:'',
            numeroDocumento:'',
            tituloDocumento: '',              
            dataReuniao: '',
            nomeEntrevistador: '',
            participante: ''
        });
    
    }

    preencherFiltro(){

        let filtro = this.state.filtro.split(",");

        this.setState({
            template: (filtro[0] !== 'null' ? filtro[0] : ''),
            ano: (filtro[1] !== 'null' ? filtro[1] : ''),
            dataAssinatura: (filtro[2] !== 'null' ? filtro[2] : ''),
            dataAprovacao: (filtro[3] !== 'null' ? filtro[3] : ''),
            dataCriacao: (filtro[4] !== 'null' ? filtro[4] : ''),
            dataTramitacao: (filtro[5] !== 'null' ? filtro[5] : ''),
            sequencial: (filtro[6] !== 'null' ? filtro[6] : ''),
            situacao: (filtro[7] !== 'null' ? filtro[7] : ''),
            emissor: (filtro[8] !== 'null' ? filtro[8] : ''),
            numeroDocumento: (filtro[9] !== 'null' ? filtro[9] : ''),
            tituloDocumento: (filtro[10] !== 'null' ? filtro[10] : ''),
            dataReuniao: (filtro[11] !== 'null' ? filtro[11] : ''),
            nomeEntrevistador: (filtro[12] !== 'null' ? filtro[12] : ''),
            participante: (filtro[13] !== 'null' ? filtro[13] : '')
        });

    }

    handleChangeEntrevistador(e){

        this.setState({nomeEntrevistador: e.target.value});        

    }


    preencherEntrevistador(data){

        this.setState({
            nomeEntrevistador : 0,
            entrevistadores: data['entrevistadores'] !== null && data['entrevistadores'] !== undefined ? data['entrevistadores'] : '',
        });

        //console.log(data);

        let selectEntrevistador = document.getElementById('nomeEntrevistador');
  
        //Limpar select
        var length = selectEntrevistador.options.length;
        for (let i = length-1; i >= 0; i--) {
            selectEntrevistador.remove(i);
        }

        //Carregar select com o resultado
        let option = new Option('Selecione', '0');
        selectEntrevistador.options[0] = option;

        if(this.state.entrevistadores !== ''){
            let contador = 1;            
            data["entrevistadores"].forEach(temp => {                    
                option = new Option(temp[0]['NOME'], temp[0]['CPF']);
                selectEntrevistador.options[contador] = option;
                contador++;
            });
        }

        selectEntrevistador.value = '0';

    }

    async carregarDadosEquipe(id){
                
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }

        const response = await api(apiURL+"equipe/getMembrosEntrevista/"+id, requestOptions);
        const resp = await response.json();

        return resp;

    }

	componentDidMount(){
        if (!this.context.getPermission(permissions.VISUALIZAR_DOCUMENTOS_EQUIPE) &&
            !this.context.getPermission(permissions.VISUALIZAR_DOCUMENTOS_TODAS_EQUIPES)
        ) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        this._isMounted = true;
        this.consultaAlterada = true;
        this.setState({ isMobile: window.screen.width <= 400 })

        if(this.context.getPermission("VISUALIZAR_DOCUMENTOS_TODAS_EQUIPES")){
            this.iniciarPesquisa();
        }
        else if(this.context.getPermission("VISUALIZAR_DOCUMENTOS_EQUIPE")
        ){
            this.iniciarPesquisaCoordenador();
            document.getElementById("linhaSolicitante").style.display = 'block';            
        }

        if(this.state.filtro !== undefined && this.state.filtro !== null){
            this.preencherFiltro();
        }

        this.referencia.current.focus();      
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    async iniciarPesquisaCoordenador(){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token,
                responsavel: this.state.cpf
            })
        };

        const response = await api(apiURL+"documentoSuporte/pesquisaCoordenador", requestOptions);
        const data = await response.json();
        
        this.popularCampos(data, 'coordenador');

    }

    async iniciarPesquisa(){

        this.context.startLoading();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token
            })
        };
        const response = await api(apiURL+"documentoSuporte/pesquisa", requestOptions);
        const data = await response.json();
        this.popularCampos(data, 'gestor');
        this.context.stopLoading();
        
    }

    popularCampos(data, origem){

        if(this._isMounted){
            if(data["mensagem"] !== 'sucesso'){
                alert(data["mensagem"]);
            }
            else{
                this.setState({templates: data["templates"]}); 
                this.setState({emissores: data["emissores"]});
                this.setState({situacoes: data["situacoes"]});
                this.setState({anos: data["anos"]});
                this.setState({participantes: data["participantes"]});        
                
                const selectTemplates = document.getElementById("template");
                let option = new Option('Selecione', '0');                                        
                selectTemplates.options[0] = option;
                let contador = 1;            
                this.state.templates.forEach(temp => {                    
                    option = new Option(temp['nome'], temp['cod_template_documento']);                                        
                    selectTemplates.options[contador] = option;
                    contador++;

                });
                                
                const selectEmissores = document.getElementById("emissor");
                option = new Option('Selecione...', '0');
                selectEmissores.options[0] = option;
                contador = 1;
                this.state.emissores.forEach(temp => {
                    
                    if(origem === 'gestor'){
                        option = new Option(temp['sigla'], temp['cod_equipe']);
                    }
                    else{
                        option = new Option(temp['SIGLA'], temp['COD_EQUIPE']);
                    }

                    selectEmissores.options[contador] = option;
                    contador++;
                });

                const selectSituacao = document.getElementById("situacao");
                option = new Option('Selecione...', '0');
                selectSituacao.options[0] = option;
                contador = 1;
                this.state.situacoes.forEach(temp => {
                    option = new Option(temp['situacao'], temp['situacao']);
                    selectSituacao.options[contador] = option;
                    contador++;
                });

                const selectParticipante = document.getElementById("pesquisarParticipante");
                option = new Option('Selecione...', '0');
                selectParticipante.options[0] = option;
                contador = 1;
                this.state.participantes.forEach(temp => {
                    option = new Option((temp['NOME']+' - '+temp['EMAIL']), temp['EMAIL']);
                    selectParticipante.options[contador] = option;
                    contador++;
                });

                const selectAno = document.getElementById("ano");
                option = new Option('Selecione...', '0');
                selectAno.options[0] = option;
                contador = 1;
                this.state.anos.forEach(temp => {
                    option = new Option((temp['ano']), temp['ano']);
                    selectAno.options[contador] = option;
                    contador++;
                });

            }
        }
    }

    async handleClickPesquisar(e){
        
        if (this.context.getPermission(permissions.VISUALIZAR_DOCUMENTOS_EQUIPE) ||
            this.context.getPermission(permissions.VISUALIZAR_DOCUMENTOS_TODAS_EQUIPES)
        ) {
        
            if(e !== null){
                e.preventDefault();
            }

            this.handleSalvarFiltroPesquisa();

            let mensagemPesquisaVazia = document.getElementById("mensagemPesquisaVazia");
            mensagemPesquisaVazia.style.display = 'none';

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    token: this.state.token,
                    offset: this.consultaAlterada === false ? this.state.offset : 0,
                    limit: this.state.perPage,
                    template: this.state.template,
                    ano: this.state.ano,
                    dataAssinatura: this.state.dataAssinatura,
                    dataAprovacao: this.state.dataAprovacao,
                    dataCriacao: this.state.dataCriacao,
                    dataTramitacao: this.state.dataTramitacao,
                    sequencial: this.state.sequencial,
                    situacao: this.state.situacao,
                    emissor: this.state.emissor,
                    numeroDocumento: this.state.numeroDocumento,
                    tituloDocumento: this.state.tituloDocumento,
                    dataReuniao: this.state.dataReuniao,
                    solicitante: this.state.nomeEntrevistador,
                    cpf: this.state.cpf,
                    participante: this.state.participante
                })
            };

            this.context.startLoading();

            try{


                const response = await api(apiURL+"documento/pesquisar2", requestOptions);
                const data = await response.json();
                
                if(this._isMounted){
                    if(data["mensagem"] !== 'sucesso'){
                        alert(data["mensagem"]);
                    }
                    else{
                        //console.log(data["total"] + ' / '+this.state.perPage+ ' = ' +Math.ceil(data["total"] / this.state.perPage));
                        //console.log(data);
                        //orgTabelaDoc: data["documentos"].slice(this.state.offset, this.state.offset + this.state.perPage),
                        if(this.consultaAlterada === true){
                            this.setState({
                                currentPage: 0,
                                offset: 0
                            });                        
                        }

                        //console.log(this.state.offset + ', ' + this.state.currentPage);
                        this.setState({
                            documentos: data["documentos"],
                            orgTabelaDoc: data["documentos"],
                            pageCount: Math.ceil(data["total"] / this.state.perPage),
                            total: data["total"] !== null ? data["total"] : 1 
                        });                   

                        if(data["documentos"] === null || data["documentos"] === undefined || data["documentos"].length <= 0){
                            mensagemPesquisaVazia.style.display = 'block';
                        }

                        this.consultaAlterada = true;
                    }
                }      
                    
            }
            catch(error){
                console.log(error);
            }

            this.context.stopLoading();
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }

    }

    async exportarDocumento(tipo){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token,
                offset: this.consultaAlterada === false ? this.state.offset : 0,
                limit: this.state.perPage,
                template: this.state.template,
                ano: this.state.ano,
                dataAssinatura: this.state.dataAssinatura,
                dataAprovacao: this.state.dataAprovacao,
                dataCriacao: this.state.dataCriacao,
                dataTramitacao: this.state.dataTramitacao,
                sequencial: this.state.sequencial,
                situacao: this.state.situacao,
                emissor: this.state.emissor,
                numeroDocumento: this.state.numeroDocumento,
                tituloDocumento: this.state.tituloDocumento,
                dataReuniao: this.state.dataReuniao,
                solicitante: this.state.nomeEntrevistador,
                cpf: this.state.cpf,
                nome: this.state.nome,
                tipo: tipo,
                participante: this.state.participante
            })
        };

        const response = await api(apiURL+"documento/pesquisar", requestOptions);
        return response;

    }

    handleClickExportar(e){
        
        e.preventDefault();
        
        let mensagemPesquisaVazia = document.getElementById("mensagemPesquisaVazia");
        mensagemPesquisaVazia.style.display = 'none';

        this.context.startLoading();

        this.exportarDocumento('WORD').then(response => { 
            return response.blob();       
        })
        .then(blob => {     
            const url = URL.createObjectURL(blob,{type: 'application/vnd.ms-word;charset=UTF-8'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'listaDocumentos.doc');
            document.body.appendChild(link);
            link.click();       
            document.body.removeChild(link);
        })
        .catch(erra => {console.log(erra);}).finally(() => this.context.stopLoading());
    }

    handleClickExportarPDF(e){
        
        e.preventDefault();
        
        let mensagemPesquisaVazia = document.getElementById("mensagemPesquisaVazia");
        mensagemPesquisaVazia.style.display = 'none';

        this.context.startLoading();

        this.exportarDocumento('PDF').then(response => { 
            return response.blob();       
        })
        .then(blob => {     
            const url = URL.createObjectURL(blob,{type: 'application/pdf;charset=UTF-8'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'listaDocumentos.pdf');
            document.body.appendChild(link);
            link.click();       
            document.body.removeChild(link);
        })
        .catch(erra => {console.log(erra);}).finally(() => this.context.stopLoading());
    }

    ordernarDocumento(e){

    }

    limparDataReuniao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataReuniao: ''});
            document.getElementById("dataReuniao").value = '';
        }
    }

    limparDataCriacao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataCriacao: ''});
            document.getElementById("dataCriacao").value = '';
        }
    }

    limparDataAssinatura(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataAssinatura: ''});
            document.getElementById("dataAssinatura").value = '';
        }
    }

    limparDataAprovacao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataAprovacao: ''});
            document.getElementById("dataAprovacao").value = '';
        }
    }

    limparDataTramitacao(e){
        if(e.keyCode === 8 || e.keyCode === 46){
            this.setState({dataTramitacao: ''});
            document.getElementById("dataTramitacao").value = '';
        }
    }

    handleChangeDataReuniao(e){
        this.setState({dataReuniao: e.target.value});        
    }

    handleChangeTemplate(e){
        this.setState({template: e.target.value});

        let linhaDataReuniao = document.getElementById('linhaDataReuniao');

        if(e.target.value === "1"){
            linhaDataReuniao.style.display = 'flex';
        }
        else{
            linhaDataReuniao.style.display = 'none';
            this.setState({dataReuniao: ''});
            document.getElementById("dataReuniao").value = '';
        }
    }

    handlePesquisarParticipante(e){
        this.setState({participante: e.target.value === '0' ? '' : e.target.value })
    }

    handleChangeAno(e){
        this.setState({ano: e.target.value});
    }
    handleChangeDataAssinatura(e){
        this.setState({dataAssinatura: e.target.value});
    }
    handleChangeDataAprovacao(e){
        this.setState({dataAprovacao: e.target.value});
    }
    handleChangeDataCriacao(e){
        this.setState({dataCriacao: e.target.value});
    }
    handleChangeDataTramitacao(e){
        this.setState({dataTramitacao: e.target.value});
    }
    handleChangeSequencial(e){
        this.setState({sequencial: e.target.value});
    }
    handleChangeSituacao(e){
        this.setState({situacao: e.target.value});
    }
    handleChangeEmissor(e){
        this.setState({emissor: e.target.value});

        if(this.context.getPermission("VISUALIZAR_DOCUMENTOS_EQUIPE")){
            this.carregarDadosEquipe(e.target.value).then(data => {
                this.preencherEntrevistador(data);
            }).catch(erro => {
                console.log('Erro ao carregar dados da equipe: ' + erro);
            });
        }

    }

    handleChangeNumeroDocumento(e){
        this.setState({numeroDocumento: e.target.value});
    }
    handleChangeTituloDocumento(e){
        this.setState({tituloDocumento: e.target.value});
    }
    
    handlePageClick = async (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.consultaAlterada = false;

        await this.setState({
            currentPage: selectedPage,
            offset: offset
        });

        this.handleClickPesquisar(null);

    };

    loadMoreData() {
		const data = this.state.documentos;

		const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
		this.setState({
			pageCount: Math.ceil(data.length / this.state.perPage),
			orgTabelaDoc:slice
		})
	
    }

    editarDocumento(id){   
        this.codigo = id;        
        this.setState({redirecionar: true});          
    }

    deletarDocumento(e){
       
        this.codigo = e.target.id;
        let docs = this.state.documentos.filter( doc => (
            doc.COD_DOCUMENTO !== this.codigo      
        ));
        this.setState({documentos: docs});
        //this.loadMoreData();
    }

	render(){
        if(this.state.redirecionar === true){
            return ( 
                <Redirect 
                    to={{
                        pathname:"/painel/GestaoDocumento/DocumentoEdicao",
                        state: {id: this.codigo}
                     }} >
                </Redirect>);
        }
        else 
	        return (		
			
            <div className="container-xl-fluid">         
                <Menu idMenuOpen = "btnDocumentos"></Menu>             
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container">  
                                    <br/>
                                    <Titulo name="Pesquisar Documentos" className=".mt-3"/>
                                    <br/>
                                    <div className="row my-1">
                                        <div className = "col">
                                            <form >
                                                <div className="row my-2">
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="template">Documento</label>
                                                        <select id="template" className="form-select" onChange={this.handleChangeTemplate} value={this.state.template}>                                                           
                                                        </select>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="ano">Ano do documento</label>
                                                        <select id="ano" className="form-select" onChange={this.handleChangeAno} value={this.state.ano}>
                                                        </select>
                                                    </div> 
                                                </div>
                                                <div className="row my-2" id="linhaDataReuniao" style={{display:'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="pesquisarParticipante">Participante</label>
                                                        <select id="pesquisarParticipante" className="form-select" onChange={this.handlePesquisarParticipante} value={this.state.participante}>
                                                        </select>
                                                    </div>                                                    
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataReuniao">Data da reunião</label>
                                                        <input type="date" className="form-control" id="dataReuniao" onKeyUp={this.limparDataReuniao}  onChange={this.handleChangeDataReuniao} value={this.state.dataReuniao}/>
                                                    </div>
                                                </div>
                                                <div className="row my-2">
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="sequencialDocumento">Sequencial documento</label>
                                                        <input type="text" className="form-control" id="sequencialDocumento" onChange={this.handleChangeSequencial} value={this.state.sequencial}/>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="situacao">Situação do documento</label>
                                                        <select id="situacao" className="form-select" onChange={this.handleChangeSituacao} value={this.state.situacao}>                                                                                                   
                                                        </select>
                                                    </div> 
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="emissor">Emissor</label>
                                                        <select id="emissor" className="form-select" onChange={this.handleChangeEmissor} value={this.state.emissor}>
                                                            
                                                        </select>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="numeroDocumento">Número do documento</label>
                                                        <input type="text" className="form-control" id="numeroDocumento" onChange={this.handleChangeNumeroDocumento} value={this.state.numeroDocumento}/>
                                                    </div>                                                                        
                                                </div>
                                                <div className="row my-3" id="linhaSolicitante" style={{display: 'none'}}>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="nomeEntrevistador">Solicitante</label>
                                                        <select id="nomeEntrevistador"className="form-select" onChange={this.handleChangeEntrevistador} value={this.state.nomeEntrevistador}>       
                                                            <option value="0">Selecione...</option>             
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col">
                                                        <label htmlFor="titulo">Título documento</label>                                                        
                                                        <small style={{ float: 'right', color: 'GrayText'}}>Utilize * como coringa</small>
                                                        <input type="text" className="form-control" id="titulo" onChange={this.handleChangeTituloDocumento} value={this.state.tituloDocumento}/>                                    
                                                    </div>
                                                </div>
                                                <div className="row my-3">                                            
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataCriacao">Data criação</label>
                                                        <input type="date" className="form-control" id="dataCriacao" placeholder="31/08/2021" onKeyUp={this.limparDataCriacao} onChange={this.handleChangeDataCriacao} value={this.state.dataCriacao}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataTramitacao">Data envio</label>
                                                        <input type="date" className="form-control" id="dataTramitacao" placeholder="31/08/2021" onKeyUp={this.limparDataTramitacao} onChange={this.handleChangeDataTramitacao} value={this.state.dataTramitacao}/>
                                                    </div>
                                                </div>
                                                <div className="row my-3">         
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataAprovacao">Data aprovação</label>
                                                        <input type="date" className="form-control" id="dataAprovacao" placeholder="31/08/2021" onKeyUp={this.limparDataAprovacao} onChange={this.handleChangeDataAprovacao} value={this.state.dataAprovacao}/>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-12 col-md-6">
                                                        <label htmlFor="dataAssinatura">Data assinatura</label>
                                                        <input type="date" className="form-control" id="dataAssinatura" placeholder="31/08/2021" onKeyUp={this.limparDataAssinatura} onChange={this.handleChangeDataAssinatura} value={this.state.dataAssinatura}/>
                                                    </div>
                                                </div>
                                                <div className="row my-5">
                                                    <div className = "d-flex justify-content-end align-items-center">
                                                        <button className="btn btn-primary me-2" onClick={this.handleLimparPesquisa} disabled={this.context.loading}>Limpar filtros</button>
                                                        <button type="submit" className="btn btn-primary me-2" onClick={this.handleClickPesquisar} disabled={this.context.loading}>Pesquisar</button>
                                                        {
                                                            this.context.getPermission("EXPORTAR_DOCUMENTOS_WORD") && 
                                                            <button id="btnExportarWord" type="submit" className="btn btn-primary me-2" onClick={this.handleClickExportar} disabled={this.context.loading}>Exportar em Word</button>
                                                        }
                                                        {
                                                            this.context.getPermission("EXPORTAR_DOCUMENTOS_PDF") &&
                                                            <button id="btnExportarPDF" type="submit" className="btn btn-primary me-2" onClick={this.handleClickExportarPDF} disabled={this.context.loading}>Exportar em PDF</button>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="row my-3" style={{display:'none'}} id="mensagemPesquisaVazia">                                
                                        <div className="col">    
                                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                                <strong>Nenhum resultado foi encontrado com os parâmetros definidos, por favor inicie uma nova busca.</strong>
                                            </div>
                                        </div>
                                    </div>           
                                    <div className="row my-3">                                
                                        <div className="col">                                            
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead  className="table-light">                                                        
                                                        <tr>                        
                                                            <th scope="col" onClick={this.ordernarDocumento}>Documento</th>                                                            
                                                            <th scope="col">Atualizado</th>
                                                            <th scope="col">Criado</th>                                                            
                                                            <th scope="col">Emissor</th>                                                            
                                                            <th scope="col">Título</th>   
                                                            <th scope="col">Enviado</th>                                                         
                                                            <th scope="col">Situacao</th>
                                                            <th scope="col">Aprovado</th>
                                                            <th scope="col">Assinado</th>
                                                            <th scope="col">Ações</th>

                                                        </tr>
                                                    </thead>                 
                                                    <tbody>     
                                                        
                                                        {                                                            
                                                            this.state.orgTabelaDoc.map(doc => (                                                                
                                                                  <tr key={doc.COD_DOCUMENTO}>
                                                                        <th scope="col">{doc.NUM_DOCUMENTO}</th>
                                                                        <th scope="col"><center>{DataUtil(doc.MOVIMENTACAO,'TS')}</center></th>
                                                                        <th scope="col"><center>{DataUtil(doc.DATA_CRIACAO,'TS')}</center></th>                                                            
                                                                        <th scope="col">{doc.SIGLA_EMISSOR}</th>                                                            
                                                                        <th scope="col">{doc.TITULO}</th>    
                                                                        <th scope="col"><center>{doc.TRAMITACAO !== null && doc.TRAMITACAO !== undefined && doc.TRAMITACAO.length > 0 ? DataUtil(doc.TRAMITACAO[0]['DATA_ENVIO'],'D') : '--'}</center></th>                                                        
                                                                        <th scope="col">{doc.SITUACAO}</th>
                                                                        <th scope="col"><center>{DataUtil(doc.DATA_APROVACAO,'D')}</center></th>
                                                                        <th scope="col"><center>{DataUtil(doc.DATA_ASSINATURA,'D')}</center></th>
                                                                        <th scope="col">
                                                                            {
                                                                                this.context.getPermission(permissions.EDITAR_DOCUMENTOS) &&
                                                                                <button type="button" className="btn" onClick={() => this.editarDocumento(doc.COD_DOCUMENTO)}>
                                                                                    <i className="far fa-edit"></i>
                                                                                </button>
                                                                            }
                                                                        </th>
                                                                  </tr>
                                                                
                                                            ))                                                       
                                                        }                                                           
                                                    </tbody>                
                                                </table>     
                                                <ReactPaginate
                                                    previousLabel={"Anterior"}
                                                    nextLabel={"Próximo"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={this.state.isMobile ? 1 : 5}
                                                    pageRangeDisplayed={this.state.isMobile ? 1 : 2}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}/>                                           
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>  
                        
        );
    }


}

export default DocumentoPesquisa;