import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Titulo from '../../../Components/Titulo';
import LogoParceriaInterna from '../../../Components/LogoParceria/LogoParceriaInterna';
import imagem from '../../../Components/Menu/simbolotr-quadrado.png';
import MaskedInput from '../../../Components/InputMask';

import '../../../css/style.css';
import './style.css';

import { apiURL } from '../../../Services/env';

import { GlobalContext } from "../../../Context/GlobalContext/context";
import { apiWithToken, login } from '../../../Services/Api';
import { ViaCep } from '../../../Services/ViaCep';
import Select from "react-select";

class MinhaConta extends React.Component {

    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this._isMounted = false;

        this.handleSenha = this.handleSenha.bind(this);
        this.handleSenhaNova = this.handleSenhaNova.bind(this);
        this.handleSubmitAtualizar = this.handleSubmitAtualizar.bind(this);
        this.handleFoto = this.handleFoto.bind(this);
        this.mostrarSenha = this.mostrarSenha.bind(this);
        this.montarTela = this.montarTela.bind(this);

        var url = props.location.search;
        url = url.substring(1, url.length);

        const tab = url.split('tab=')[1];

        this.state = {
            token: localStorage.getItem("projartToken"),
            nome: localStorage.getItem('projartUsuario'),
            cpf: localStorage.getItem('projartCod'),
            email: '',
            pass: null,
            passNovo: null,
            oldImage: (localStorage.getItem('projartFoto') !== null &&
                localStorage.getItem('projartFoto') !== undefined &&
                localStorage.getItem('projartFoto') !== '') ? localStorage.getItem('projartFoto') : imagem,

            foto: '',
            file: {
                name: '',
                size: '',
                type: '',
                conteudo: ''
            },
            equipesPessoa: [],
            countBio: 0,
            tab,
            termo: 'NAO',
        };

    }

    componentDidMount() {

        // if (!this.context.getPermission('VISUALIZAR_MINHA_CONTA')) {
        //     alert('Permissão negada!');
        //     this.props.history.push('/')
        // }
        this._isMounted = true;
        if (this._isMounted) {
            this.carregarDados();      
            if (this.state.tab !== undefined && this.state.tab !== null && this.state.tab !== "" ) {
                switch (this.state.tab) {
                    case "1":
                        document.getElementById('home-tab').click();
                        break;
                    case "2":
                        document.getElementById('fundep-tab').click();
                        break;
                    case "3":
                        document.getElementById('contact-tab').click();
                        break;
                    case "4":
                        document.getElementById('config-tab').click();
                        break;
                    default:
                        document.getElementById('home-tab').click();
                        break;
                    
                }
            }
        }

        let mensagemSucesso = document.getElementById("sucesso");
        mensagemSucesso.style.display = "none";

    }

    componentWillUnmount() {
        this._isMounted = false;

        let erro = document.getElementById('erro');
        erro.style.display = 'none';

        let mensagemSucesso = document.getElementById("sucesso");
        mensagemSucesso.style.display = "none";

    }

    carregarDados() {

        this.context.startLoading();

        if (this._isMounted) {
            
            apiWithToken(apiURL + "bancos-brasileiros")
            .then(async res => await res.json())
            .then(r => this.setState({bancos: r}))
            .finally(() => {

                apiWithToken(apiURL + "usuario/recuperar", { method: 'POST' })
                    .then(async res => {
                        const json = await res.json();
                        if (res.status === 200) return json
                        else if (json.redirect) {
                                alert(json.mensagem)
                                window.location.href = json.redirect;
                            }
                        else {
                            login();
                        }
                    })
                    .then(data => {
                        if (this._isMounted) {
                            if (data["mensagem"] !== 'sucesso') {
                                alert(data["mensagem"]);
                            }
                            else {
                                let vinculo_endereco_instituicao = data['pessoa']['VINCULO_ENDERECO_INSTITUICAO'];
                                vinculo_endereco_instituicao = vinculo_endereco_instituicao !== null && vinculo_endereco_instituicao !== undefined ? JSON.parse(vinculo_endereco_instituicao) : {};

                                let atividade_pagamento_dados = data['pessoa']['ATIVIDADE_PAGAMENTO_DADOS'];
                                atividade_pagamento_dados = atividade_pagamento_dados !== null && atividade_pagamento_dados !== undefined ? JSON.parse(atividade_pagamento_dados) : {};
                                
                                this.setState({
                                    foto: data["foto"],
                                    nome: data['pessoa']['NOME'] ?? '',
                                    email: data['pessoa']['EMAIL'] ?? '',
                                    cpf: data['pessoa']['CPF'] ?? '',
                                    rg: data['pessoa']['RG'] ?? '',
                                    data_nascimento: data['pessoa']['DATA_NASCIMENTO'] ?? '',
                                    orgao_rg: data['pessoa']['ORGAO_RG'] ?? '',
                                    expedicao_rg: data['pessoa']['EXPEDICAO_RG'] ?? '',
                                    profissao: data['pessoa']['PROFISSAO'] ?? '',
                                    nacionalidade: data['pessoa']['NACIONALIDADE'] ?? '',
                                    biografia: data['pessoa']['BIOGRAFIA'] ?? '',
                                    
                                    link_pdf_cpf: data['pessoa']['PDF_CPF'], 
                                    link_pdf_rg: data['pessoa']['PDF_RG'], 
                                    link_pdf_lattes: data['pessoa']['PDF_LATTES'], 
                                    link_pdf_conta_bancaria: data['pessoa']['PDF_CONTA_BANCARIA'], 
                                    link_pdf_comprovante_matricula: data['pessoa']['PDF_COMPROVANTE_MATRICULA'], 

                                    vinculo_nome_instituicao: data['pessoa']['VINCULO_NOME_INSTITUICAO'],
                                    vinculo_endereco_cep_instituicao: vinculo_endereco_instituicao.cep,
                                    vinculo_endereco_uf_instituicao: vinculo_endereco_instituicao.uf,
                                    vinculo_endereco_cidade_instituicao: vinculo_endereco_instituicao.cidade,
                                    vinculo_endereco_bairro_instituicao: vinculo_endereco_instituicao.bairro,
                                    vinculo_endereco_rua_instituicao: vinculo_endereco_instituicao.rua,
                                    vinculo_endereco_numero_instituicao: vinculo_endereco_instituicao.numero,
                                    vinculo_endereco_complemento_instituicao: vinculo_endereco_instituicao.complemento,
                                    vinculo_endereco_campus_instituicao: vinculo_endereco_instituicao.campus,
                                    vinculo_categoria: data['pessoa']['VINCULO_CATEGORIA'],
                                    vinculo_cargo: data['pessoa']['VINCULO_CARGO'],
                                    vinculo_matricula_siape: data['pessoa']['VINCULO_MATRICULA_SIAPE'],

                                    atividade_data_inicio: data['pessoa']['ATIVIDADE_DATA_INICIO'],
                                    atividade_data_fim: data['pessoa']['ATIVIDADE_DATA_FIM'] ?? '2023-03-31',
                                    atividade_carga_horaria: data['pessoa']['ATIVIDADE_CARGA_HORARIA'],

                                    atividade_pagamento_tipo: data['pessoa']['ATIVIDADE_PAGAMENTO_TIPO'],
                                    atividade_pagamento_dados_banco: atividade_pagamento_dados.banco,
                                    atividade_pagamento_dados_banco_select: this.state.bancos.filter(b => b.value === atividade_pagamento_dados.banco)[0],
                                    atividade_pagamento_dados_agencia: atividade_pagamento_dados.agencia,
                                    atividade_pagamento_dados_conta: atividade_pagamento_dados.conta,

                                    endereco: data['pessoa']['ENDERECO'] ?? '',
                                    endereco_cep: data['pessoa']['ENDERECO_CEP'] ?? '',
                                    endereco_uf: data['pessoa']['ENDERECO_UF'] ?? '',
                                    endereco_cidade: data['pessoa']['ENDERECO_CIDADE'] ?? '',
                                    endereco_estado: data['pessoa']['ENDERECO_ESTADO'] ?? '',
                                    endereco_bairro: data['pessoa']['ENDERECO_BAIRRO'] ?? '',
                                    endereco_rua: data['pessoa']['ENDERECO_RUA'] ?? '',
                                    endereco_numero: data['pessoa']['ENDERECO_NUMERO'] ?? '',
                                    endereco_complemento: data['pessoa']['COMPLEMENTO'] ?? '',

                                    ddd_celular: data['pessoa']['DDD_CELULAR'] ?? '',
                                    celular: data['pessoa']['CELULAR'] ?? '',

                                    ddd_whatsapp: data['pessoa']['DDD_WHATSAPP'] ?? '',
                                    whatsapp: data['pessoa']['WHATSAPP'] ?? '',

                                    ddd_telefone: data['pessoa']['DDD_TELEFONE'] ?? '',
                                    ramal_telefone: data['pessoa']['RAMAL_TELEFONE'] ?? '',
                                    telefone: data['pessoa']['TELEFONE'] ?? '',

                                    twitter: data['pessoa']['TWITTER'] ?? '',
                                    facebook: data['pessoa']['FACEBOOK'] ?? '',
                                    instagram: data['pessoa']['INSTAGRAM'] ?? '',
                                    linkedin: data['pessoa']['LINKEDIN'] ?? '',
                                    lattes: data['pessoa']['LINK_LATTES'] ?? '',
                                    termo: data['pessoa']['TERMO'] ?? '',
                                    termo_participacao: data['pessoa']['TERMO_PARTICIPACAO'] ?? '',

                                    equipesPessoa: data['equipes'] ?? '',
                                    countBio: (data['pessoa']['BIOGRAFIA'] ?? '').length

                                });

                                let permissoesUsuario = JSON.stringify(data.permissoes);

                                localStorage.removeItem('projartPreviegio');
                                localStorage.removeItem('projartPapeis');
                                localStorage.removeItem('projartPermissoesUsuario');
                                localStorage.setItem('projartPreviegio', data.superuser);
                                localStorage.setItem('projartPapeis', data.papeis);
                                localStorage.setItem('projartPermissoesUsuario', permissoesUsuario);

                                let mensagemSucesso = document.getElementById("sucesso");
                                mensagemSucesso.style.display = "none";

                            }
                        }
                    })
                    .finally(() => this.context.stopLoading())
                })
        }
    }

    handleSenha(e) {
        this.setState({ pass: e.target.value });
    }

    handleSenhaNova(e) {
        this.setState({ passNovo: e.target.value });
    }

    handleFoto(e) {

        this.setState({
            foto: e.target.files[0]
        });

        let fileAux = null;

        if (e.target.files[0] !== null && e.target.files[0] !== undefined && e.target.files[0] !== '') {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (e) => {
                fileAux = {
                    name: this.state.foto.name,
                    size: this.state.foto.size,
                    type: this.state.foto.type,
                    conteudo: e.target.result
                }

                this.setState({ file: fileAux });

                const op = window.confirm("Atualizar foto?")
                if (op) {

                    this.context.startLoading();
                    this.atualizarFotoUsuario()
                        .then(data => this.montarTela(data))
                        .finally(() => this.context.stopLoading());
                }
            }

        }
    }

    handleSubmitAtualizar(e) {

        e.preventDefault();

        if (this.state.pass === '' && this.state.passNovo !== '') {
            alert('Gentileza informar sua senha atual!');
        }
        else {
            if (this.state.pass !== '' && this.state.passNovo === '') {
                alert('Gentileza informar a nova senha ou apagar o conteúdo da senha atual!');
            }
            else {
                if ((this.state.pass === '' && this.state.passNovo === '') && this.state.file.conteudo === '') {
                    alert("Não há alterações para salvar!");
                }
                else {
                    this.context.startLoading();
                    this.handleAtualizarSenha()
                        .then(data => {
                            alert(data['mensagem'])
                            if (data['status'] && data['status'] === true) {
                                this.context.limparStorage();
                                window.location.href = "/"
                            }
                        })
                        .finally((e) => {
                            this.context.stopLoading()
                            this.limparFormAtualizarSenha()
                        });

                }
            }
        }

    }

    atualizarFotoUsuario = async () => {

        const formData = new FormData();

        formData.append('token', this.state.token);
        formData.append('cpf', this.state.cpf);
        formData.append('foto', this.state.foto);

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        const response = await apiWithToken(apiURL + "usuario/atualizarFoto", requestOptions);
        return await response.json();
    }

    montarTela(data) {

        let mensagemSucesso = document.getElementById("sucesso");
        let erro = document.getElementById('erro');

        if (!data["status"]) {
            let erro = document.getElementById('erro');
            erro.innerText = data["mensagem"];
            erro.style.visibility = 'visible';

            mensagemSucesso.style.display = "none";
            erro.style.display = "block";

        }
        else {

            if (this.state.file.conteudo !== '') {
                localStorage.removeItem("projartFoto");
                localStorage.setItem('projartFoto', this.state.file.conteudo);
                let profile = document.querySelectorAll("#profile");
                profile.forEach(p => p.style.backgroundImage = `url(${this.state.file.conteudo})`)
            }
            mensagemSucesso.innerText = data["mensagem"];
            mensagemSucesso.style.display = "block";
            erro.style.display = 'none';

        }

    }

    handleAtualizarSenha = async () => {

        const formData = new FormData();

        formData.append('token', this.state.token);
        formData.append('pass', this.state.pass);
        formData.append('passNovo', this.state.passNovo);
        formData.append('cpf', this.state.cpf);
        formData.append('foto', this.state.foto);

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        const response = await apiWithToken(apiURL + "usuario/senha/atualizar", requestOptions);
        return await response.json();

    }

    handleAtualizar = (e) => {

        e.preventDefault();

        this.context.startLoading();

        const formData = new FormData();

        formData.append('nome', this.state.nome);
        formData.append('cpf', this.state.cpf);
        formData.append('rg', this.state.rg);
        formData.append('data_nascimento', this.state.data_nascimento);
        formData.append('orgao_rg', this.state.orgao_rg);
        formData.append('expedicao_rg', this.state.expedicao_rg);
        formData.append('profissao', this.state.profissao);
        formData.append('nacionalidade', this.state.nacionalidade);
        formData.append('biografia', this.state.biografia);
        
        formData.append('endereco_cep', this.state.endereco_cep);
        formData.append('endereco_uf', this.state.endereco_uf);
        formData.append('endereco_cidade', this.state.endereco_cidade);
        formData.append('endereco_bairro', this.state.endereco_bairro);
        formData.append('endereco_rua', this.state.endereco_rua);
        formData.append('endereco_numero', this.state.endereco_numero);
        formData.append('endereco_complemento', this.state.endereco_complemento);

        formData.append('ddd_celular', this.state.ddd_celular);
        formData.append('celular', this.state.celular);
        formData.append('ddd_whatsapp', this.state.ddd_whatsapp);
        formData.append('whatsapp', this.state.whatsapp);
        formData.append('telefone', this.state.telefone);
        formData.append('ddd_telefone', this.state.ddd_telefone);
        formData.append('ramal_telefone', this.state.ramal_telefone);

        formData.append('facebook', this.state.facebook);
        formData.append('instagram', this.state.instagram);
        formData.append('linkedin', this.state.linkedin);

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        apiWithToken(apiURL + "usuario/atualizar", requestOptions)
            .then(async res => await res.json())
            .then(res => {
                console.log(res);
                if (res['status'] && res['status'] === true) {
                    alert(res['mensagem']);
                }
                this.limparCamposArquivos();
            })
            .catch(err => console.log(err))
            .finally(async () => { this.context.stopLoading(); await this.carregarDados() })

    }

    handleAtualizarFundep = (e) => {

        e.preventDefault();

        this.context.startLoading();

        const formData = new FormData();

        formData.append('cpf', this.state.cpf);

        formData.append('copia_cpf', this.state.copia_cpf);
        formData.append('copia_rg', this.state.copia_rg);
        formData.append('copia_conta_bancaria', this.state.copia_conta_bancaria);
        formData.append('copia_curriculo_lattes', this.state.copia_curriculo_lattes);
        formData.append('copia_comprovante_matricula', this.state.copia_comprovante_matricula);

        formData.append('vinculo_nome_instituicao', this.state.vinculo_nome_instituicao);
        formData.append('vinculo_endereco_instituicao', JSON.stringify({
            cep: this.state.vinculo_endereco_cep_instituicao,
            uf: this.state.vinculo_endereco_uf_instituicao,
            cidade: this.state.vinculo_endereco_cidade_instituicao,
            bairro: this.state.vinculo_endereco_bairro_instituicao,
            rua: this.state.vinculo_endereco_rua_instituicao,
            numero: this.state.vinculo_endereco_numero_instituicao,
            complemento: this.state.vinculo_endereco_complemento_instituicao,
            campus: this.state.vinculo_endereco_campus_instituicao
        }));
        formData.append('vinculo_categoria', this.state.vinculo_categoria);
        formData.append('vinculo_cargo', this.state.vinculo_cargo);
        formData.append('vinculo_matricula_siape', this.state.vinculo_matricula_siape);
        
        formData.append('atividade_data_inicio', this.state.atividade_data_inicio);
        formData.append('atividade_data_fim', this.state.atividade_data_fim);
        formData.append('atividade_carga_horaria', this.state.atividade_carga_horaria);
        formData.append('atividade_pagamento_tipo', this.state.atividade_pagamento_tipo);
        formData.append('atividade_pagamento_dados', JSON.stringify({
            banco: this.state.atividade_pagamento_dados_banco,
            agencia: this.state.atividade_pagamento_dados_agencia,
            conta: this.state.atividade_pagamento_dados_conta
        }));
        
        formData.append('lattes', this.state.lattes);

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        apiWithToken(apiURL + "usuario/atualizarFundep", requestOptions)
            .then(async res => await res.json())
            .then(res => {
                console.log(res);
                if (res['status'] && res['status'] === true) {
                    alert(res['mensagem']);
                }
                this.limparCamposArquivos();
            })
            .catch(err => console.log(err))
            .finally(async () => { this.context.stopLoading(); await this.carregarDados() })

    }

    limparCamposArquivos = () => {
        document.getElementById('copia_cpf').value = "";
        document.getElementById('copia_rg').value = "";
        document.getElementById('copia_conta_bancaria').value = "";
        document.getElementById('copia_curriculo_lattes').value = "";
        document.getElementById('copia_comprovante_matricula').value = "";
    }

    mostrarSenha(e) {

        e.preventDefault();

        let senhaAtual = document.getElementById("passAtual");
        let senhaNova = document.getElementById("passNovo");
        let mostra = document.getElementById("mostra");

        if (senhaAtual.type === 'text') {
            senhaAtual.type = 'password';
            senhaNova.type = 'password';
            mostra.innerText = 'Mostrar senhas';
        }
        else {
            senhaAtual.type = 'text';
            senhaNova.type = 'text';
            mostra.innerText = 'Ocultar senhas';
        }
    }

    inputFileFocus = () => {
        document.getElementById('user-image').click()
    }

    limparFormAtualizarSenha = () => {
        this.setState({
            pass: '',
            passNovo: ''
        })
        document.getElementById('passAtual').value = ''
        document.getElementById('passNovo').value = ''
    }

    handleBiografia = (e) => {
        this.setState(s => ({ ...s, countBio: e.target.value.length, biografia: e.target.value }))
    }

    handleCep = (e) => {

        let value = e.target.value.replace('-', '');
        value = value.replace('_', '');
        this.setState({ endereco_cep: value })

        if (value.length === 8){
            this.context.startLoading();
            ViaCep(value).then(r => {
                this.setState({
                    endereco_cidade: r.localidade ?? '',
                    endereco_uf: r.uf ?? '',
                    endereco_bairro: r.bairro ?? ''
                })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => this.context.stopLoading())
        }
    }

    handleCepInstituicao = (e) => {

        let value = e.target.value.replace('-', '');
        value = value.replace('_', '');
        this.setState({ vinculo_endereco_cep_instituicao: value })

        if (value.length === 8){
            this.context.startLoading();
            ViaCep(value).then(r => {
                this.setState({
                    vinculo_endereco_cidade_instituicao: r.localidade ?? '',
                    vinculo_endereco_uf_instituicao: r.uf ?? '',
                    vinculo_endereco_bairro_instituicao: r.bairro ?? ''
                })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => this.context.stopLoading())
        }
    }

    verificarTermo = () => {
        this.context.startLoading();
        const requestOptions = {
            method: 'POST',
        };

        apiWithToken(apiURL + "/participacao/checarLinkAssinatura", requestOptions)
            .then(async res => await res.json())
            .then(res => {
                console.log(res);
            })
            // .catch(err => console.log(err))
            // .finally(async () => { this.context.stopLoading(); await this.carregarDados() })
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu></Menu>
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container">
                                    <br />
                                    <Titulo name="Minha Conta" className=".mt-3" />

                                    <div className="alert alert-danger erroLogin my-3" role="alert" id="erro">
                                    </div>
                                    <div className="alert alert-success" role="alert" id="sucesso" style={{ display: 'none' }}>
                                        As alterações foram salvas com sucesso!
                                    </div>
                                    <div className="row my-4" >
                                        <div className='col-md-12'>

                                            <ul className="nav nav-tabs nav-tabs-e" id="myTab" role="tablist">
                                                <li className="nav-item nav-item-e" role="presentation">
                                                    <a className="nav-link nav-link-e active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                                        <i className="fas fa-user-cog"></i>
                                                        <strong className='text-uppercase ml-2' style={{ marginLeft: '5px' }}>Meus dados</strong>
                                                    </a>
                                                </li>
                                                <li className="nav-item nav-item-e" role="presentation">
                                                    <a className="nav-link nav-link-e" id="fundep-tab" data-bs-toggle="tab" href="#fundep" role="tab" aria-controls="fundep" aria-selected="false">
                                                        <i className="fas fa-address-card"></i>
                                                        <strong className='text-uppercase ml-2' style={{ marginLeft: '5px' }}>Dados FUNDEP</strong>
                                                    </a>
                                                </li>
                                                <li className="nav-item nav-item-e" role="presentation">
                                                    <a className="nav-link nav-link-e" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                                                        <i className="fas fa-users"></i>
                                                        <strong className='text-uppercase ml-2' style={{ marginLeft: '5px' }}>Minhas equipes</strong>
                                                    </a>
                                                </li>
                                                <li className="nav-item nav-item-e" role="presentation">
                                                    <a className="nav-link nav-link-e" id="config-tab" data-bs-toggle="tab" href="#config" role="tab" aria-controls="config" aria-selected="false">
                                                        <i className="fas fa-user-lock"></i>
                                                        <strong className='text-uppercase ml-2' style={{ marginLeft: '5px' }}>Configurações</strong>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane tab-pane-e fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <form className='container' autoComplete='none'>
                                                        <div className="row">
                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Dados</legend>
                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <div className='form-group my-2'>
                                                                                <div
                                                                                    id='profile'
                                                                                    className='user-image-container'
                                                                                    onClick={this.inputFileFocus}
                                                                                    style={{ backgroundImage: `url(${this.state.oldImage})` }}
                                                                                >
                                                                                    <div className='overlay'></div>
                                                                                    <input
                                                                                        type="file"
                                                                                        id='user-image'
                                                                                        onChange={this.handleFoto}
                                                                                    />
                                                                                    <i className='fas fa-camera fa-3x'></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='my-4'>
                                                                            <strong className='me-2' style={{color:'red'}}>*</strong>
                                                                            <span>Campos obrigatórios</span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className='form-group my-2'>
                                                                                <label className='required' htmlFor="nome">Nome:</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={this.state.nome}
                                                                                    onChange={e => this.setState({ nome: e.target.value })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className='form-group my-2'>
                                                                                <label htmlFor="email">E-mail:</label>
                                                                                <input
                                                                                    id='email'
                                                                                    type="email"
                                                                                    className="form-control"
                                                                                    defaultValue={this.state.email}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className='form-group my-2'>
                                                                                <label htmlFor="cpf">CPF:</label>
                                                                                <input
                                                                                    id="cpf"
                                                                                    className="form-control"
                                                                                    defaultValue={this.state.cpf}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className='form-group my-2'>
                                                                                <label className='required' htmlFor="data_nascimento">Data nascimento:</label>
                                                                                <input
                                                                                    id="data_nascimento"
                                                                                    type={'date'}
                                                                                    className="form-control"
                                                                                    defaultValue={this.state.data_nascimento}
                                                                                    onChange={e => this.setState({ data_nascimento: e.target.value })}
                                                                                    value={this.state.data_nascimento ?? ''}  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className='form-group my-2'>
                                                                                <label className='required' htmlFor="rg">RG:</label>
                                                                                <input
                                                                                    id='rg'
                                                                                    type="rg"
                                                                                    className="form-control"
                                                                                    defaultValue={this.state.rg}
                                                                                    onChange={e => this.setState({ rg: e.target.value })}
                                                                                    value={this.state.rg ?? ''}        
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className='form-group my-2'>
                                                                                <label className='required' htmlFor="orgao_rg">Orgão RG:</label>
                                                                                <input
                                                                                    id='orgao_rg'
                                                                                    type="orgao_rg"
                                                                                    className="form-control"
                                                                                    onChange={e => this.setState({ orgao_rg: e.target.value })}
                                                                                    value={this.state.orgao_rg ?? ''}        
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className='form-group my-2'>
                                                                                <label className='required' htmlFor="expedicao_rg">Data emissão RG:</label>
                                                                                <input
                                                                                    id='expedicao_rg'
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    onChange={e => this.setState({ expedicao_rg: e.target.value })}
                                                                                    value={this.state.expedicao_rg ?? ''}        
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className='form-group my-2'>
                                                                                <label className='required' htmlFor="nacionalidade">Nacionalidade:</label>
                                                                                <input
                                                                                    id='nacionalidade'
                                                                                    className="form-control"
                                                                                    defaultValue={this.state.nacionalidade}
                                                                                    onChange={e => this.setState({ nacionalidade: e.target.value })}
                                                                                    value={this.state.nacionalidade ?? ''}        
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className='form-group my-2'>
                                                                                <label className='required' htmlFor="profissao">Profissão:</label>
                                                                                <input
                                                                                    id='profissao'
                                                                                    className="form-control"
                                                                                    onChange={e => this.setState({ profissao: e.target.value })}
                                                                                    value={this.state.profissao ?? ''}        
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                            <label htmlFor="biografia">Biografia:</label>
                                                                            <textarea
                                                                                rows={5}
                                                                                className="form-control"
                                                                                id="biografia"
                                                                                value={this.state.biografia ?? ''}
                                                                                onChange={e => this.handleBiografia(e)}
                                                                                maxLength={1200}
                                                                            ></textarea>
                                                                            <small style={{ float: 'right' }}>{this.state.countBio}/1200</small>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-12'>
                                                            <fieldset>
                                                                <legend>Endereço</legend>
                                                                <div className='row'>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label className='required' htmlFor='endereco_cep'>CEP:</label>
                                                                        <MaskedInput
                                                                            required
                                                                            mask="99999-999"
                                                                            className="form-control"
                                                                            id="endereco_cep"
                                                                            onChange={e => this.handleCep(e)}
                                                                            value={this.state.endereco_cep ?? ''}
                                                                            autoComplete="none"
                                                                            disabled={this.context.loading}
                                                                        />

                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label className='required' htmlFor='endereco_cidade'>Cidade:</label>
                                                                        <input
                                                                            id='endereco_cidade'
                                                                            className="form-control"
                                                                            value={this.state.endereco_cidade ?? ''}
                                                                            onChange={e => this.setState({ endereco_cidade: e.target.value })}
                                                                            autoComplete="none"
                                                                            disabled={this.context.loading}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label className='required' htmlFor='endereco_uf'>UF:</label>
                                                                        <input
                                                                            id='endereco_uf'
                                                                            className="form-control"
                                                                            value={this.state.endereco_uf ?? ''}
                                                                            onChange={e => this.setState({ endereco_uf: e.target.value.toUpperCase() })}
                                                                            maxLength={2}
                                                                            autoComplete="none"
                                                                            disabled={this.context.loading}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label className='required' htmlFor='endereco_bairro'>Bairro:</label>
                                                                        <input
                                                                            id='endereco_bairro'
                                                                            className="form-control"
                                                                            value={this.state.endereco_bairro ?? ''}
                                                                            onChange={e => this.setState({ endereco_bairro: e.target.value })}
                                                                            autoComplete="none"
                                                                            disabled={this.context.loading}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                        <label className='required' htmlFor='endereco_rua'>Rua:</label>
                                                                        <input
                                                                            id='endereco_rua'
                                                                            className="form-control"
                                                                            value={this.state.endereco_rua ?? ''}
                                                                            onChange={e => this.setState({ endereco_rua: e.target.value })}
                                                                            autoComplete="none"
                                                                            disabled={this.context.loading}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-3">
                                                                        <label htmlFor='endereco_complemento'>Complemento:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.endereco_complemento ?? ''}
                                                                            onChange={e => this.setState({ endereco_complemento: e.target.value })}
                                                                            autoComplete="none"
                                                                            disabled={this.context.loading}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                        <label className='required' htmlFor='endereco_numero'>Número:</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={this.state.endereco_numero ?? ''}
                                                                            onChange={e => this.setState({ endereco_numero: e.target.value })}
                                                                            autoComplete="none"
                                                                            disabled={this.context.loading}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </fieldset>
                                                        </div>
                                                        
                                                        <div className='col-md-12'>
                                                            <fieldset>
                                                                <legend>Contato</legend>
                                                                <div className='row'>
                                                                    <p className="my-2">Celular:</p>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='ddd_celular'>DDD:</label>
                                                                        <input
                                                                            id='ddd_celular'
                                                                            className="form-control"
                                                                            value={this.state.ddd_celular ?? ''}
                                                                            onChange={e => this.setState({ ddd_celular: e.target.value })}
                                                                            maxLength={2}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='celular'>Número:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={this.state.celular ?? ''}
                                                                            onChange={e => this.setState({ celular: e.target.value })}
                                                                            maxLength={9}
                                                                        />
                                                                    </div>
                                                                    <p className="my-2">Telefone:</p>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='ddd_telefone'>DDD:</label>
                                                                        <input
                                                                            id='ddd_telefone'
                                                                            className="form-control"
                                                                            value={this.state.ddd_telefone ?? ''}
                                                                            onChange={e => this.setState({ ddd_telefone: e.target.value })}
                                                                            maxLength={2}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='ramal_telefone'>Ramal:</label>
                                                                        <input
                                                                            id='ramal_telefone'
                                                                            className="form-control"
                                                                            value={this.state.ramal_telefone ?? ''}
                                                                            onChange={e => this.setState({ ramal_telefone: e.target.value })}
                                                                            maxLength={4}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='telefone_numero'>Número:</label>
                                                                        <input
                                                                            id='telefone_numero'
                                                                            className="form-control"
                                                                            value={this.state.telefone ?? ''}
                                                                            onChange={e => this.setState({ telefone: e.target.value })}
                                                                            maxLength={10}
                                                                        />
                                                                    </div>
                                                                    <p className="my-2">Whatsapp:</p>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='ddd_whatsapp'>DDD:</label>
                                                                        <input
                                                                            id='ddd_whatsapp'
                                                                            className="form-control"
                                                                            value={this.state.ddd_whatsapp ?? ''}
                                                                            onChange={e => this.setState({ ddd_whatsapp: e.target.value })}
                                                                            maxLength={2}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='whatsapp'>Número:</label>
                                                                        <input
                                                                            id='whatsapp'
                                                                            className="form-control"
                                                                            value={this.state.whatsapp ?? ''}
                                                                            onChange={e => this.setState({ whatsapp: e.target.value })}
                                                                            maxLength={9}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        
                                                        <div className='col-md-12'>
                                                            <fieldset>
                                                                <legend>Redes sociais:</legend>
                                                                <div className='row'>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='facebook'>Facebook:</label>
                                                                        <input
                                                                            id='facebook'
                                                                            className="form-control"
                                                                            value={this.state.facebook ?? ''}
                                                                            onChange={e => this.setState({ facebook: e.target.value })}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='instagram'>Instagram:</label>
                                                                        <input
                                                                            id='instagram'
                                                                            className="form-control"
                                                                            value={this.state.instagram ?? ''}
                                                                            onChange={e => this.setState({ instagram: e.target.value })}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                        <label htmlFor='linkedin'>Linkedin:</label>
                                                                        <input
                                                                            id='linkedin'
                                                                            className="form-control"
                                                                            value={this.state.linkedin ?? ''}
                                                                            onChange={e => this.setState({ linkedin: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        
                                                        <div className="row my-3">
                                                            <div className="col d-flex flex-row-reverse align-items-center">
                                                                <button
                                                                    className="btn btn-primary my-3"
                                                                    disabled={
                                                                        !this.state.nome ||
                                                                        !this.state.rg ||
                                                                        !this.state.data_nascimento ||
                                                                        !this.state.orgao_rg ||
                                                                        !this.state.expedicao_rg ||
                                                                        !this.state.nacionalidade ||
                                                                        !this.state.profissao ||
                                                                        !this.state.endereco_cep ||
                                                                        !this.state.endereco_cidade ||
                                                                        !this.state.endereco_uf ||
                                                                        !this.state.endereco_bairro ||
                                                                        !this.state.endereco_rua ||
                                                                        !this.state.endereco_numero
                                                                    }
                                                                    onClick={this.handleAtualizar}
                                                                >
                                                                    Salvar alterações
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="tab-pane tab-pane-e fade" id="fundep" role="tabpanel" aria-labelledby="fundep-tab">
                                                    <form className='container'>
                                                        <div className="row">
                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Arquivos</legend>
                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <div className='row importarDoc'>
                                                                                <div className="col-md-1">
                                                                                    {this.state.link_pdf_cpf && <a href={this.state.link_pdf_cpf} download target='_blank' rel='noreferrer'>
                                                                                        <i className='fas fa-download fa-2x' style={{color:'#51cb32'}}></i>
                                                                                    </a>}
                                                                                    {!this.state.link_pdf_cpf && <i className='fas fa-download fa-2x' style={{color:'#51cb32', opacity: '0.5'}}></i>}
                                                                                </div>
                                                                                <div className='col-md-11'>
                                                                                    <div className='form-group my-2'>
                                                                                        <label className='form-label' htmlFor="copia_cpf">Cópia CPF:</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            id='copia_cpf'
                                                                                            className='form-control'
                                                                                            accept='application/pdf'
                                                                                            onChange={e => this.setState({ copia_cpf: e.target.files[0] })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">

                                                                            <div className='row importarDoc'>
                                                                                <div className="col-md-1">
                                                                                    {this.state.link_pdf_rg && <a href={this.state.link_pdf_rg} download target='_blank' rel='noreferrer'>
                                                                                        <i className='fas fa-download fa-2x' style={{color:'#51cb32'}}></i>
                                                                                    </a>}
                                                                                    {!this.state.link_pdf_rg && <i className='fas fa-download fa-2x' style={{color:'#51cb32', opacity: '0.5'}}></i>}
                                                                                </div>
                                                                                <div className='col-md-11'>
                                                                                    <div className='form-group my-2'>
                                                                                        <label className='form-label' htmlFor="copia_rg">Cópia RG:</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            id='copia_rg'
                                                                                            className='form-control'
                                                                                            accept='application/pdf'
                                                                                            onChange={e => this.setState({ copia_rg: e.target.files[0] })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12">
                                                                            <div className='row importarDoc'>
                                                                                <div className="col-md-1">
                                                                                    {this.state.link_pdf_conta_bancaria && <a href={this.state.link_pdf_conta_bancaria} download target='_blank' rel='noreferrer'>
                                                                                        <i className='fas fa-download fa-2x' style={{color:'#51cb32'}}></i>
                                                                                    </a>}
                                                                                    {!this.state.link_pdf_conta_bancaria && <i className='fas fa-download fa-2x' style={{color:'#51cb32', opacity: '0.5'}}></i>}
                                                                                </div>
                                                                                <div className='col-md-11'>
                                                                                    <div className='form-group my-2'>
                                                                                        <label className='form-label' htmlFor="copia_conta_bancaria">Cópia Cartão Conta Bancária:</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            id='copia_conta_bancaria'
                                                                                            className='form-control'
                                                                                            accept='application/pdf'
                                                                                            onChange={e => this.setState({ copia_conta_bancaria: e.target.files[0] })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12">
                                                                            <div className='row importarDoc'>
                                                                                <div className="col-md-1">
                                                                                    {this.state.link_pdf_lattes && <a href={this.state.link_pdf_lattes} download target='_blank' rel='noreferrer'>
                                                                                        <i className='fas fa-download fa-2x' style={{color:'#51cb32'}}></i>
                                                                                    </a>}
                                                                                    {!this.state.link_pdf_lattes && <i className='fas fa-download fa-2x' style={{color:'#51cb32', opacity: '0.5'}}></i>}
                                                                                </div>
                                                                                <div className='col-md-11'>
                                                                                    <div className='form-group my-2'>
                                                                                        <label className='form-label' htmlFor="copia_curriculo_lattes">Cópia Currículo Lattes:</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            id='copia_curriculo_lattes'
                                                                                            className='form-control'
                                                                                            accept='application/pdf'
                                                                                            onChange={e => this.setState({ copia_curriculo_lattes: e.target.files[0] })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12">
                                                                            <div className='row importarDoc'>
                                                                                <div className="col-md-1">
                                                                                    {this.state.link_pdf_comprovante_matricula && <a href={this.state.link_pdf_comprovante_matricula} download target='_blank' rel='noreferrer'>
                                                                                        <i className='fas fa-download fa-2x' style={{color:'#51cb32'}}></i>
                                                                                    </a>}
                                                                                    {!this.state.link_pdf_comprovante_matricula && <i className='fas fa-download fa-2x' style={{color:'#51cb32', opacity: '0.5'}}></i>}
                                                                                </div>
                                                                                <div className='col-md-11'>
                                                                                    <div className='form-group my-2'>
                                                                                        <label className='form-label' htmlFor="copia_comprovante_matricula">Comprovante de mátrícula atualizado (graduação e pós-graduação - atualização semestral):</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            id='copia_comprovante_matricula'
                                                                                            className='form-control'
                                                                                            accept='application/pdf'
                                                                                            onChange={e => this.setState({ copia_comprovante_matricula: e.target.files[0] })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            
                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Vínculo institucional</legend>
                                                                    <div className='row mt-4'>
                                                                        <div className="col-md-12">
                                                                            <fieldset>
                                                                                <legend style={{fontSize:"11pt"}}>Dados da instituição</legend>
                                                                                <div className='row'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="vinculo_nome_instituicao">Nome da instituição:</label>
                                                                                            <input
                                                                                                id="vinculo_nome_instituicao"
                                                                                                type="text"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ vinculo_nome_instituicao: e.target.value })}
                                                                                                value={this.state.vinculo_nome_instituicao ?? ''}
                                                                                                />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                                        <label className='required' htmlFor='vinculo_endereco_cep_instituicao'>CEP:</label>
                                                                                        <MaskedInput
                                                                                            required
                                                                                            mask="99999-999"
                                                                                            className="form-control"
                                                                                            id="vinculo_endereco_cep_instituicao"
                                                                                            onChange={e => this.handleCepInstituicao(e)}
                                                                                            value={this.state.vinculo_endereco_cep_instituicao ?? ''}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />

                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                                        <label className='required' htmlFor='vinculo_endereco_cidade_instituicao'>Cidade:</label>
                                                                                        <input
                                                                                            id='vinculo_endereco_cidade_instituicao'
                                                                                            className="form-control"
                                                                                            value={this.state.vinculo_endereco_cidade_instituicao ?? ''}
                                                                                            onChange={e => this.setState({ vinculo_endereco_cidade_instituicao: e.target.value })}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                                        <label className='required' htmlFor='vinculo_endereco_uf_instituicao'>UF:</label>
                                                                                        <input
                                                                                            id='vinculo_endereco_uf_instituicao'
                                                                                            className="form-control"
                                                                                            value={this.state.vinculo_endereco_uf_instituicao ?? ''}
                                                                                            onChange={e => this.setState({ vinculo_endereco_uf_instituicao: e.target.value.toUpperCase() })}
                                                                                            maxLength={2}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                                        <label className='required' htmlFor='vinculo_endereco_bairro_instituicao'>Bairro:</label>
                                                                                        <input
                                                                                            id='vinculo_endereco_bairro_instituicao'
                                                                                            className="form-control"
                                                                                            value={this.state.vinculo_endereco_bairro_instituicao ?? ''}
                                                                                            onChange={e => this.setState({ vinculo_endereco_bairro_instituicao: e.target.value })}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                                                        <label className='required' htmlFor='vinculo_endereco_rua_instituicao'>Rua:</label>
                                                                                        <input
                                                                                            id='vinculo_endereco_rua_instituicao'
                                                                                            className="form-control"
                                                                                            value={this.state.vinculo_endereco_rua_instituicao ?? ''}
                                                                                            onChange={e => this.setState({ vinculo_endereco_rua_instituicao: e.target.value })}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-10 col-lg-10 mb-3">
                                                                                        <label htmlFor='vinculo_endereco_complemento_instituicao'>Complemento:</label>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            value={this.state.vinculo_endereco_complemento_instituicao ?? ''}
                                                                                            onChange={e => this.setState({ vinculo_endereco_complemento_instituicao: e.target.value })}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-2 col-lg-2 mb-3">
                                                                                        <label className='required' htmlFor='vinculo_endereco_numero_instituicao'>Número:</label>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={this.state.vinculo_endereco_numero_instituicao ?? ''}
                                                                                            onChange={e => this.setState({ vinculo_endereco_numero_instituicao: e.target.value })}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                                        <label htmlFor='vinculo_endereco_campus_instituicao'>Campus:</label>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            value={this.state.vinculo_endereco_campus_instituicao ?? ''}
                                                                                            onChange={e => this.setState({ vinculo_endereco_campus_instituicao: e.target.value })}
                                                                                            autoComplete="none"
                                                                                            disabled={this.context.loading}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <fieldset>
                                                                                <legend style={{fontSize:"11pt"}}>Dados do vínculo</legend>
                                                                                <div className='row'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="vinculo_categoria">Categoria:</label>
                                                                                            <select
                                                                                                id="vinculo_categoria"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ vinculo_categoria: e.target.value })}
                                                                                                defaultValue={this.state.vinculo_categoria}
                                                                                                value={this.state.vinculo_categoria ?? ''}
                                                                                            >
                                                                                                <option>-- SELECIONE UMA OPÇÃO --</option>
                                                                                                <option value="Discente Ensino Sup">Discente Ensino Sup</option>
                                                                                                <option value="Servidor Aposentado">Servidor Aposentado</option>
                                                                                                <option value="Servidor Docente">Servidor Docente</option>
                                                                                                <option value="Servidor Técnico">Servidor Técnico</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="vinculo_cargo">Cargo:</label>
                                                                                            <input
                                                                                                id="vinculo_cargo"
                                                                                                type="text"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ vinculo_cargo: e.target.value })}
                                                                                                value={this.state.vinculo_cargo ?? ''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        this.state.vinculo_categoria === 'Servidor Docente' &&
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group my-2'>
                                                                                                <label className='form-label required' htmlFor="vinculo_matricula_siape">Matrícula SIAPE:</label>
                                                                                                <input
                                                                                                    id="vinculo_matricula_siape"
                                                                                                    type="text"
                                                                                                    className='form-control'
                                                                                                    onChange={e => this.setState({ vinculo_matricula_siape: e.target.value })}
                                                                                                    value={this.state.vinculo_matricula_siape ?? ''}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    <div className="col-md-12 ">
                                                                                        <div className='form-group my-2'>
                                                                                            <label htmlFor='lattes'>Lattes(link):</label>
                                                                                            <input
                                                                                                id='lattes'
                                                                                                className="form-control"
                                                                                                value={this.state.lattes ?? ''}
                                                                                                onChange={e => this.setState({ lattes: e.target.value })}
                                                                                                placeholder='http://lattes.cnpq.br/xxxx'
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            
                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Atividade de extensão</legend>
                                                                    <div className='row mt-4'>
                                                                        <div className='col-md-12'>
                                                                            <fieldset>
                                                                                <legend style={{fontSize:"11pt"}}>Dados das atividades</legend>
                                                                                <div className='row'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="atividade_data_inicio">Data de início das atividades no projeto:</label>
                                                                                            <input
                                                                                                id="atividade_data_inicio"
                                                                                                type="date"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ atividade_data_inicio: e.target.value })}
                                                                                                value={this.state.atividade_data_inicio ?? ''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="atividade_data_fim">Data de fim das atividades no projeto:</label>
                                                                                            <input
                                                                                                id="atividade_data_fim"
                                                                                                type="date"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ atividade_data_fim: e.target.value })}
                                                                                                value={this.state.atividade_data_fim ?? ''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="atividade_carga_horaria">Carga horária mensal:</label>
                                                                                            <input
                                                                                                id="atividade_carga_horaria"
                                                                                                type="number"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ atividade_carga_horaria: e.target.value })}
                                                                                                value={this.state.atividade_carga_horaria ?? ''}
                                                                                                min={0}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>

                                                                        <div className='col-md-12'>
                                                                            <fieldset>
                                                                                <legend style={{fontSize:"11pt"}}>Dados dos pagamentos</legend>
                                                                                <div className='row'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="atividade_pagamento_dados_banco">Banco da conta:</label>
                                                                                            <Select
                                                                                                inputId="atividade_pagamento_dados_banco"
                                                                                                onChange={ (e) =>  this.setState({ atividade_pagamento_dados_banco_select: e, atividade_pagamento_dados_banco: e.value }) }
                                                                                                placeholder='-- SELECIONE UM BANCO --'
                                                                                                options={this.state.bancos}
                                                                                                value={this.state.atividade_pagamento_dados_banco_select}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="atividade_pagamento_tipo">Tipo de conta:</label>
                                                                                            <select
                                                                                                id="atividade_pagamento_tipo"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ atividade_pagamento_tipo: e.target.value })}
                                                                                                defaultValue={this.state.atividade_pagamento_tipo}
                                                                                                value={this.state.atividade_pagamento_tipo ?? ''}
                                                                                            >
                                                                                                <option>-- SELECIONE UMA OPÇÃO --</option>
                                                                                                <option value='Conta Corrente'>Conta Corrente</option>
                                                                                                <option value='Conta Poupança'>Conta Poupança</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="atividade_pagamento_dados_agencia">Agência:</label>
                                                                                            <input
                                                                                                id="atividade_pagamento_dados_agencia"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ atividade_pagamento_dados_agencia: e.target.value })}
                                                                                                value={this.state.atividade_pagamento_dados_agencia ?? ''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group my-2'>
                                                                                            <label className='form-label required' htmlFor="atividade_pagamento_dados_conta">Conta:</label>
                                                                                            <input
                                                                                                id="atividade_pagamento_dados_conta"
                                                                                                className='form-control'
                                                                                                onChange={e => this.setState({ atividade_pagamento_dados_conta: e.target.value })}
                                                                                                value={this.state.atividade_pagamento_dados_conta ?? ''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                        <div className="row my-3">
                                                            <div className="col d-flex flex-row-reverse align-items-center">
                                                                <button
                                                                    className="btn btn-primary my-3"
                                                                    disabled={
                                                                        !this.state.vinculo_nome_instituicao ||
                                                                        !this.state.vinculo_endereco_cep_instituicao ||
                                                                        !this.state.vinculo_endereco_uf_instituicao ||
                                                                        !this.state.vinculo_endereco_cidade_instituicao ||
                                                                        !this.state.vinculo_endereco_bairro_instituicao ||
                                                                        !this.state.vinculo_endereco_rua_instituicao ||
                                                                        !this.state.vinculo_endereco_numero_instituicao ||
                                                                        !this.state.vinculo_categoria ||
                                                                        !this.state.vinculo_cargo ||
                                                                        (
                                                                            this.state.vinculo_categoria &&
                                                                            this.state.vinculo_categoria === 'Servidor Docente' &&
                                                                            !this.state.vinculo_matricula_siape
                                                                        )
                                                                        ||

                                                                        !this.state.atividade_data_inicio ||
                                                                        !this.state.atividade_data_fim ||
                                                                        !this.state.atividade_carga_horaria ||
                                                                        !this.state.atividade_pagamento_tipo ||
                                                                        !this.state.atividade_pagamento_dados_banco ||
                                                                        !this.state.atividade_pagamento_dados_agencia ||
                                                                        !this.state.atividade_pagamento_dados_conta
                                                                    }
                                                                    onClick={this.handleAtualizarFundep}
                                                                >
                                                                    Salvar alterações
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>

                                                <div className="tab-pane tab-pane-e fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                    {
                                                        this.state.equipesPessoa.length > 0 &&
                                                        <div className='table-responsive'>
                                                            <table className='table table-stripped'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Equipe</th>
                                                                        <th>Função</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        this.state.equipesPessoa.map((e, i) => (
                                                                            <tr key={i}>
                                                                                <td style={i > 0 ? { borderBottom: 'none' } : {}}>{e.NOME}</td>
                                                                                <td style={i > 0 ? { borderBottom: 'none' } : {}}>
                                                                                    {
                                                                                        e.papeis.map((p, j) => (
                                                                                            <div key={j}>
                                                                                                {j > 0 && <hr />}
                                                                                                <strong>{p.NOME}</strong><br />
                                                                                                <strong>Atuação: </strong><span>{p.ATUACAO ?? '-'}</span><br />
                                                                                                <strong>Condição: </strong><span>{p.CONDICAO ?? '-'}</span><br />
                                                                                                <strong>Data entrada: </strong><span>{p.DATA_ENTRADA2 ?? '-'}</span><br />
                                                                                                <strong>Data saída: </strong><span>{p.DATA_SAIDA2 ?? '-'}</span><br /><br />
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.equipesPessoa.length === 0 &&
                                                        <div className='px-5'>
                                                            <p className='alert alert-warning'>Nenhum registro encontrado!</p>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="tab-pane tab-pane-e fade" id="config" role="tabpanel" aria-labelledby="config-tab">
                                                    <form className='container mt-4'>
                                                        <div className="row">
                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Atualizar Senha</legend>
                                                                    <div className='row'>
                                                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                                                            <label className='required' htmlFor="passAtual">Senha Atual:</label>
                                                                            <div className="input-group mb-3">
                                                                                <input type="password" className="form-control" id="passAtual" onChange={this.handleSenha} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                                                            <label className='required' htmlFor="passNovo">Nova senha:</label>
                                                                            <input type="password" className="form-control" id="passNovo" onChange={this.handleSenhaNova} />
                                                                        </div>
                                                                        <div style={{ textAlign: 'right' }}>
                                                                            <button id="mostra" type="button" onClick={this.mostrarSenha} className="btn btn-secondary" >
                                                                                Mostrar senhas
                                                                            </button>
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-primary my-3"
                                                                                style={{ marginLeft: '5px' }}
                                                                                onClick={this.handleSubmitAtualizar}
                                                                                disabled={!this.state.pass || !this.state.passNovo || this.context.loading}>
                                                                                Salvar alterações
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Termos de serviço</legend>
                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <button type="button" className="btn btn-primary" onClick={() => this.props.history.push('/painel/minhaConta/TermoServico')}>
                                                                                <i className='fas fa-file-alt'></i>
                                                                                <strong className='ms-2'>Clique aqui para ler os termos de serviço.</strong>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>

                                                            {(this.state.termo_participacao === "SIM"
                                                                ) && 
                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Termos de participação</legend>
                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <button type="button" className="btn btn-primary" onClick={() => this.props.history.push('/painel/minhaConta/TermoParticipacao')}>
                                                                                <i className='fas fa-file-alt'></i>
                                                                                <strong className='ms-2'>Clique aqui para ler os termos de participação.</strong>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            }

                                                            <div className='col-md-12'>
                                                                <fieldset>
                                                                    <legend>Declaração de participação no projeto Rede Artesanto Brasil</legend>
                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <button type="button" className="btn btn-primary" onClick={() => this.props.history.push('/painel/minhaConta/DeclaracaoParticipacaoProjeto')}>
                                                                                <i className='fas fa-file-alt'></i>
                                                                                <strong className='ms-2'>Clique aqui para emitir a declaração de participação no projeto.</strong>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default MinhaConta;