import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Topo from '../../../Components/Topo';

class Templates extends React.Component {

	constructor(props){
        super(props);
                
        this.state = {
        };

    }

	componentDidMount(){

    }

    componentWillUnmount(){

    }

	render(){
	    return (		
			
            <div className="container-fluid">                 
				<Topo></Topo>
				<Menu></Menu>
				
                <br/>
				<h1>Templates de documentos</h1>
            </div>       
                        
        );
    }


}

export default Templates;