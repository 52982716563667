const dataFormatada = (data, tipo) => {
        
        let dataFormatada = '';
        let datetimePartido = []; 
        let dataPartida = [];

        if(data !== null && data !== undefined){
            if(tipo === 'TS'){
                datetimePartido = data.split(" ");
                dataPartida = datetimePartido[0].split("-");
            }
            else{
                dataPartida = data.split("-");
            }
            
            return dataFormatada.concat(dataPartida[2], "/", dataPartida[1], "/", dataPartida[0]); 
        }

        return '--';

}

export default dataFormatada;