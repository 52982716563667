import React from 'react';
import LogoParceriaInterna from '../../Components/LogoParceria/LogoParceriaInterna';

import Menu from '../../Components/Menu/Menu';
import '../../css/style.css';

class Painel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nome: localStorage.getItem('projartUsuario'),
            papeis: localStorage.getItem('projartPapeis')
        };

        this.referencia = React.createRef();

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

        return (

            <div className="container-xl-fluid fundoBoasVindas">
                <Menu> </Menu>
                <div className='row m-0 p-0'>
                    <div className='col-lg-12 p-0'>
                        <div className="page-content" id="content">
                            <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                        </div>
                        <div className="container-fluid boasvindas">
                            <div className="row align-items-center" >
                                <div className="col" >
                                    <center style={{ color: 'gray' }}>
                                        <p className="my-1 mb-2 d-flex flex-column align-items-center" style={{ color: 'gray' }}>
                                            <span style={{ fontSize: '80px' }} > Olá</span><br />
                                            {this.state.nome}, você acessou a área administrativa do Projeto Rede Artesanato Brasil.<br />
                                            e pode iniciar o trabalho clicando no menu ao lado.
                                        </p>

                                    </center>
                                </div>
                            </div>
                            <div className="row d-flex align-items-center" >
                                <div className="col" style={{ fontSize: 13, color: 'gray' }}>
                                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                                    <center>
                                        <p className="mb-3 pt-5 my-10 d-flex flex-column align-items-center">
                                            A Equipe Web | Rede Artesanato Brasil, continua trabalhando em melhorias nesta interface.<br />
                                            A qualquer momento você pode falar conosco enviando um e-mail para <br />
                                            <a href="mailto:contato@caneladeema.com">contato@caneladeema.com</a>
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );


    }


}

export default Painel;