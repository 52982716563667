
const acaoMenu = () => {
        const content = document.getElementById('content');
        const sidebar = document.getElementById('sidebar');

        sidebar.classList.toggle('active');

        if (window.screen.width > 768) {
                content.classList.toggle('active');
        }
}

export default acaoMenu