import { Component } from "react";
import LogoParceriaInterna from "../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../Components/Menu/Menu";
import Titulo from "../../../Components/Titulo";
import { GlobalContext } from "../../../Context/GlobalContext/context";
import { apiURL } from "../../../Services/env";
import logo_projeto_red from "../../../Components/Menu/simbolotr.png";
import { apiWithToken } from "../../../Services/Api";

import "./index.css";
import {ButtonBack} from "../../../Components/ButtonBack";

export default class DeclaracaoParticipacaoProjeto extends Component {

    static contextType = GlobalContext;

    
    emitirDeclaracao = this.emitirDeclaracao.bind(this);
    handleClickEmissaoDeclaracaoParticipacaoProjeto = this.handleClickEmissaoDeclaracaoParticipacaoProjeto.bind(this);
    imprimirPeriodo = this.imprimirPeriodo.bind(this);

    state = {
        pessoa: '',
        vinculos: [],
        status: false,
        cpf: localStorage.getItem('projartCod'),
        vetorMes: {
            1:'Janeiro',
            2:'Fevereiro',
            3:'Março',
            4:'Abril',
            5:'Maio',
            6:'Junho',
            7:'Julho',
            8:'Agosto',
            9:'Setembro',
            10:'Outubro',
            11:'Novembro',
            12:'Dezembro'},
        data: ''
    }

    componentDidMount(){
        this.getDados();
        this.montarData();
    }

    montarData = () => {
        let date = new Date();
        let dia = date.getDate();
        let mes = date.getMonth() + 1;
        let ano = date.getFullYear();
        this.setState({data: dia +' de '+ this.state.vetorMes[mes] +' de ' + ano});
    }

    getDados = () => {
        
        this.context.startLoading();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token,
                cpf: this.state.cpf
            })
        };

        apiWithToken(apiURL + "getDadosDeclaracao", requestOptions)
            .then(async res => {
                const data = await res.json();
                //console.log(data);
                if (data['status'] && data['status'] === false) {
                    alert(res['mensagem']);
                }
                else{               
                    this.setState({
                        pessoa: data['pessoa'],
                        vinculos: data['vinculos'],
                        status: true
                    })
                }
            })
            .finally(() => {
                this.context.stopLoading();
            })
    
    }

    async emitirDeclaracao(){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                token: this.state.token,
                cpfSolicitante: this.state.cpf,
                cpfDeclaracao: this.state.cpf
            })
        };

        const response = await apiWithToken(apiURL+"emitirDeclaracao", requestOptions);
        return response;

    }        

    handleClickEmissaoDeclaracaoParticipacaoProjeto(e){
        
        e.preventDefault();
        
        this.context.startLoading();

        this.emitirDeclaracao().then(response => { 
            return response.blob();       
        })
        .then(blob => {     
            const url = URL.createObjectURL(blob,{type: 'application/pdf;charset=UTF-8'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Declaração de participação.pdf');
            document.body.appendChild(link);
            link.click();       
            document.body.removeChild(link);
        })
        .catch(erra => {console.log(erra);}).finally(() => this.context.stopLoading());
    
    }

    imprimirPeriodo(termino){

        let resultado = '';

        if(termino !== null){
            resultado = resultado + 'Término: ' + termino;
        }

        return resultado;

    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu></Menu>
                <div className="page-content" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col fundoAreaTrabalho  p-2">
                                <div className="container">
                                    <ButtonBack
                                        to="/painel/minhaConta?tab=4"
                                    />
                                    <div className="row my-4" >
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <Titulo name="Declaração de participação" className="text-center text-uppercase my-4" />
                                                    <p className="text-uppercase text-center">
                                                        UNIVERSIDADE FEDERAL DE MINAS GERAIS<br />
                                                        PRÓ-REITORIA DE EXTENSÃO<br />
                                                    </p>
                                                    <p className="text-center">
                                                        <img width={100} src={logo_projeto_red} alt='logo' />
                                                    </p>
                                                </div>
                                                <div className="col-md-12 mt-5">
                                                    <p className="text-center">
                                                        DECLARAÇÃO
                                                    </p>                                                    
                                                    <p className="text-end">
                                                        <br/><br/>
                                                        Belo Horizonte, {this.state.data}. 
                                                    </p>
                                                    <p style={{ textAlign: 'justify' }}>
                                                        <br/>
                                                        Declaramos, para os devidos fins, que {this.state.pessoa['NOME']}, CPF {this.state.pessoa['CPF2']}, 
                                                        participa como:
                                                    </p> 
                                                </div>
                                                <div className="col-md-12 mt-5">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead  className="table-light">                                                        
                                                                <tr>                        
                                                                    <th scope="col">Condição</th>
                                                                    <th scope="col">Equipe</th>                                                            
                                                                    <th scope="col">Período</th>                                                            
                                                                </tr>
                                                            </thead>                 
                                                            <tbody>                                                         
                                                            {                                                            
                                                                this.state.vinculos.map(vinculo => (                                                                
                                                                  <tr key={vinculo['codigo']}>
                                                                        <th scope="col">{vinculo['condicao']}</th>
                                                                        <th scope="col">{vinculo['equipe']}</th>
                                                                        <th scope="col">
                                                                            Início: &nbsp;&nbsp;&nbsp;&nbsp; {vinculo['inicio']} <br/>
                                                                            {this.imprimirPeriodo(vinculo['termino'])}
                                                                       </th>
                                                                  </tr>                                                                
                                                                ))                                                       
                                                            }                                                           
                                                            </tbody>                
                                                        </table>                                
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-5">
                                                    <p style={{ textAlign: 'justify' }}>
                                                        Totalizando a carga horária mensal de {this.state.pessoa['ATIVIDADE_CARGA_HORARIA'] !== undefined && this.state.pessoa['ATIVIDADE_CARGA_HORARIA'] !== null ? this.state.pessoa['ATIVIDADE_CARGA_HORARIA'] : '20'} horas dedicadas ao 
                                                        Projeto "Estruturação do Sistema de Gestão do Artesanato Brasileiro: Diagnóstico e Planejamento Estratégico", 
                                                        nº de registro no SIEX 404036,  executado pela Pró-Reitoria de Extensão da UFMG 
                                                        com recursos provenientes do  Programa do Artesanato Brasileiro, da Secretaria de Desenvolvimento da Indústria,  
                                                        Comércio, Serviço e Inovação, do Ministério da Economia.
                                                    </p>
                                                    <p>
                                                        Atenciosamente,                             
                                                    </p>
                                                    <p className="text-center my-5">
                                                        <strong>PROFa. Me. MARIANA MORAIS POMPERMAYER</strong><br />
                                                        Coordenadora Executiva Geral 
                                                    </p>
                                                    <div style={{fontSize: '11pt'}}>
                                                        <p style={{color: '#E7302A'}}>
                                                            <a style={{color: '#E7302A'}} href="www.redeartesanatobrasil.com.br">www.redeartesanatobrasil.com.br</a> | <a style={{color: '#E7302A'}} href="secretaria@redeartesanatobrasil.com.br">secretaria@redeartesanatobrasil.com.br</a> 
                                                        </p>
                                                        <p>
                                                            Projeto viabilizado pelo Termo de Execução Descentralizada (TED) Nº 03/2020  <br/>
                                                            Firmado entre a Secretaria de Desenvolvimento da Indústria, Comércio, Serviço e Inovação - SDIC e a UFMG. 
                                                        </p>
                                                    </div>

                                                </div>
                                                <hr />
                                                <div className="col-md-12 text-end">
                                                    <button 
                                                        className="btn btn-primary"
                                                        onClick={this.handleClickEmissaoDeclaracaoParticipacaoProjeto}
                                                        id="downloadDeclaracao"
                                                    >
                                                        <i className="fas fa-file-pdf me-2"></i>
                                                        Download PDF
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
