import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import LogoParceriaInterna from "../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../Components/Menu/Menu";
import Titulo from "../../../Components/Titulo";
import { GlobalContext } from "../../../Context/GlobalContext/context";
import * as permissions from "../../../Context/GlobalContext/permissions";
import { apiURL } from "../../../Services/env";
import { api } from "../../../Services/Api";
import './style.css';
import ReactTooltip from "react-tooltip";
import Select from "react-select";


export default class TermoParticipacao extends Component {


    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.referencia = React.createRef();

        this.state = {
            isMobile: false,
            participacoes: [],
            page: 0,
            offset: 0,
            limit: 10,
            pageCount: 0,
            pessoa_id: '',
            tipo: ''
        }
    }

    componentDidMount() {
        if (!this.context.getPermission(permissions.GERENCIAR_TERMO_PARTICIPACAO)) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        else {
            this.getDados(this.state.limit, this.state.offset);
            this.setState({ isMobile: window.screen.width <= 400 })
        }
    }

    getDados = (limit, offset, pessoa_id = "", tipo = "") => {

        this.context.startLoading();

        const body = new FormData();
        body.append('limit', limit);
        body.append('offset', offset);
        if (pessoa_id !== "") {
            body.append('pessoa_id', pessoa_id);
        }
        if (tipo !== "") {
            body.append('tipo', tipo);
        }
        
        const requestOptions = {
            method: 'POST',
            body
        }

        api(apiURL + 'participacao/getDados', requestOptions)
            .then(async res => await res.json())
            .then(res => {
                const {data, total, pessoas, pessoasSelect} = res.resultado;
                this.setState({ 
                    participacoes: data,
                    pageCount: total,
                    pessoas: Object.values(pessoas),
                    pessoasSelect: Object.values(pessoasSelect),
                })
            })
            .finally(() => {
                this.context.stopLoading()
            })

    }

    paginate = (e) => {

        let page = e.selected;

        this.setState({ offset: page + 1, page, checkAllUsers: false })

        if (this.state.pesquisa !== '' || this.state.type !== '') {
            this.getDados(this.state.limit, page + 1, this.state.pesquisa, this.state.type);
        }
        else {
            this.getDados(this.state.limit, page + 1);
        }
    }

    limparFiltro = () => {
        document.getElementById('pessoaFiltro').value = '';
        document.getElementById('tipoFiltro').selectedIndex = 0;
        this.setState({
            limit: 10,
            offset: 0,
            page: 0,
            pessoaFiltro: '',
            tipoFiltro: ''
        })
        this.getDados(10, 0);
    }

    limpartForm = () => {
        this.setState({
            pessoa_id: null,
            tipo: null
        })
        document.getElementById('pessoa_id').value = '';
        document.getElementById('tipo').selectedIndex = 0;
        this.limparFiltro()
    }

    handlePesquisa = (e) => {
        e.preventDefault();
        this.setState({ page: 0 })
        this.getDados(10, 0, this.state.pessoaFiltro?.value, this.state.tipoFiltro)
    }

    salvarParticipacao = () => {
        
        if (true){//this.context.getPermission(permissions.CRIAR_USUARIOS_PAINEL)) {
            const pessoa_id = this.state.pessoa_id ?? '';
            const tipo = this.state.tipo ?? '';

            if (pessoa_id !== '' && pessoa_id !== null && tipo !== '' && tipo !== null){
                this.setState({
                    limit: 10,
                    offset: 0
                })
                
                this.context.startLoading();
                document.getElementById('btnCloseModal').click();

                const body = new FormData();
                body.append('pessoa_id', pessoa_id.value);
                body.append('tipo', tipo);

                const requestOptions = {
                    method: 'POST',
                    body
                }

                api(apiURL + 'participacao/salvar', requestOptions)
                .then(async res => await res.json())
                .then(res => alert(res.message))
                .finally(() => {
                    this.limpartForm();
                    this.context.stopLoading();
                    this.getDados(this.state.limit, 0);
                })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleModalCreateShow = () => {
        if (true){//this.context.getPermission(permissions.CRIAR_USUARIOS_PAINEL)) {
            document.getElementById('modalCreateParticipacao').click();
            ReactTooltip.rebuild();
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }

    }

    aumentarLimite = () => {
        const valor = document.getElementById('aumentar_limite').value
        this.setState({ limit: valor, offset: 0, page: 1 });
        this.getDados(valor, 0)
    }

    componentDidUpdate(){
        ReactTooltip.rebuild()
    }

    enviarNovoEmailTokenAcesso = (participacao) => {
        this.context.startLoading();

        const body = new FormData();
        body.append('pessoa_id', participacao.PESSOA_ID);
        body.append('participacao_id', participacao.ID);
        const requestOptions = {
            method: 'POST',
            body
        }
        api(apiURL + 'participacao/novoLinkAcesso', requestOptions)
            .then(async res => await res.json())
            .then(res => alert(res.mensagem))
            .finally(() => {
                this.context.stopLoading();
                this.getDados(this.state.limit, 0);
            })
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu idMenuOpen="btnAcesso"></Menu>
                <div className="page-content fundoAreaTrabalho" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid" >
                        <div className="row" >
                            <div className="col p-2" style={{ backgroundColor: '#F2F3F7' }}>
                                <div className="container-fluid">
                                    <br />
                                    <Titulo 
                                        name="Termo de participacação" 
                                        className="mt-3" 
                                        tooltip={{ 
                                            texto: `<p>Você pode pesquisar os termos que foram enviados para os usuários, pela pessoa ou tipo.<br />
                                                    Clique em “Filtrar participações termos e selecione uma pessoa ou tipo de participação,<br />
                                                    logo em seguida clique no botão “pesquisar”. <br />
                                                    Caso queira limpar os filtros, clique em “limpar”.<br />
                                                    <br />
                                                    Para adicionar uma nova participaçao, clique no botão ”Adicionar<br />
                                                    participação” e insira os dados desejados.<br />
                                                    <br />`, 
                                            direcao: 'right' 
                                        }}
                                    />
                                    <br />

                                    <div className="row my-4" >
                                        <div className="col">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Filtrar participações
                                                    </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <Select
                                                                                id="pessoaFiltro"
                                                                                placeholder='-- SELECIONE UMA PESSOA --'
                                                                                options={this.state.pessoasSelect}
                                                                                value={this.state.pessoaFiltro}
                                                                                onChange={e => this.setState({ pessoaFiltro: e })}
                                                                                styles={this.context.sylePlaceholderSelect()}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <select 
                                                                                className="form-control" id="tipoFiltro" 
                                                                                onChange={e => this.setState({ tipoFiltro: e.target.value }) } 
                                                                            >
                                                                                <option value={'ambos'}>Todos</option>
                                                                                <option value={'entrevista'}>Entrevista</option>
                                                                                <option value={'grupo'}>Grupo</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12" style={{ textAlign: 'right' }}>
                                                                        <button type="button" className="btn btn-secondary" onClick={this.limparFiltro}>Limpar</button>
                                                                        <button type="submit" className="btn btn-primary mx-2" onClick={this.handlePesquisa}>Pesquisar</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1 justify-content-between" style={{alignItems:'center' }}>
                                        <div className="col-sm-6 col-md-2 col-lg-2">
                                            <div className="form-group my-4">
                                                <label htmlFor="aumentar_limite">Itens na página</label>
                                                <select className="form-control" id="aumentar_limite" onChange={() => this.aumentarLimite()} 
                                                        style={{width: '50px'}}>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                    <option value={30}>30</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group my-4" style={{textAlign: 'right'}}>
                                                <button 
                                                    className="btn btn-primary btn-block mb-2"
                                                    type="button" 
                                                    style={{marginRight: '15px'}}
                                                    onClick={this.handleModalCreateShow}
                                                >
                                                    Adicionar participação
                                                </button>
                                                <button 
                                                    className="d-none"
                                                    id="modalCreateParticipacao"
                                                    type="button" 
                                                    data-bs-toggle="modal" 
                                                    data-bs-target="#modalCreate"
                                                    style={{marginRight: '15px'}}
                                                >
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-1">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="text-left">ID</th>
                                                            <th scope="col">PESSOA</th>
                                                            <th scope="col">TIPO PARTICIPAÇÂO</th>
                                                            <th scope="col">ÚTIMO E-MAIL ENVIADO</th>
                                                            <th scope="col">ASSINADO</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.participacoes.map((user, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{user.ID}</td>
                                                                    <td>{user.PESSOA}</td>
                                                                    <td>{(user.TIPO.charAt(0).toUpperCase() + user.TIPO.slice(1)) === 'Grupo' ? 'Grupo de Discussão':user.TIPO.charAt(0).toUpperCase() + user.TIPO.slice(1)}</td>
                                                                    <td>{user.ULTIMO_EMAIL.split('-').reverse().join('/')}</td>
                                                                    <td>{user.DATA_ASSINATURA !== null ? 'SIM' : 'NÃO'}</td>
                                                                    <td>
                                                                        {
                                                                            user.DATA_ASSINATURA === null && 
                                                                            <button 
                                                                                className="btn m-0 p-0"
                                                                                data-tip="Reenviar link"
                                                                                data-place="bottom"
                                                                                data-effect="solid"
                                                                                onClick={() => this.enviarNovoEmailTokenAcesso(user)}
                                                                            >
                                                                                <i className="fas fa-share text-success"></i>
                                                                            </button>
                                                                        }
                                                                        {
                                                                            user.DATA_ASSINATURA !== null &&
                                                                            <button
                                                                                className="btn m-0 p-0 disabled"
                                                                                disabled
                                                                            >
                                                                                <i className="fas fa-share text-muted"></i>
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={"Anterior"}
                                            nextLabel={"Próximo"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}

                                            marginPagesDisplayed={this.state.isMobile ? 1 : 5}
                                            pageRangeDisplayed={this.state.isMobile ? 1 : 2}
                                            onPageChange={this.paginate}

                                            forcePage={this.state.page}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />                                            
                                    </div>

                                    <div className="modal fade" id="modalCreate" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="modalLabel">Adicionar Nova Participação</h5>
                                                    <button 
                                                        type="button" 
                                                        id="btnCloseModal" 
                                                        className="btn-close" 
                                                        data-bs-dismiss="modal" 
                                                        aria-label="Close"
                                                        onClick={this.limpartFormAux}
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                    <form className="container">
                                                        <div className='my-4'>
                                                            <p className="alert alert-warning">
                                                                <strong className='text-danger me-2'>*</strong>
                                                                <span>Campos obrigatórios</span>
                                                            </p>
                                                        </div>
                                                        <div className="row my-3">
                                                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                <label className="required" htmlFor="pessoa_id">Pessoa:</label>
                                                                <Select 
                                                                    inputId="pessoa_id" 
                                                                    onChange={e => this.setState({ pessoa_id: e }) }
                                                                    options={this.state.pessoas}
                                                                    value={this.state.pessoa_id}
                                                                    placeholder="-- SELECIONE UMA PESSOA --"
                                                                    styles={this.context.sylePlaceholderSelect()}
                                                                />
                                                            </div>
                                                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                                <label className="required" htmlFor="tipo">Tipo:</label>
                                                                <select
                                                                    className="form-control" 
                                                                    id="tipo" 
                                                                    onChange={e => this.setState({ tipo: e.target.value })}
                                                                >
                                                                    <option>-- SELECIONE UM TIPO --</option>
                                                                    <option value={'ambos'}>Ambos</option>
                                                                    <option value={'entrevista'}>Entrevista</option>
                                                                    <option value={'grupo'}> Grupo de Discussão </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal" 
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                        disabled={this.context.loading}
                                                    >
                                                        Fechar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={this.salvarParticipacao}
                                                        disabled={
                                                            !this.state.pessoa_id || 
                                                            !this.state.tipo || 
                                                            this.context.loading
                                                        }
                                                    >
                                                        Enviar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}