import React, { Component, Fragment } from "react";
import ReactPaginate from "react-paginate";
import ReactTooltip from "react-tooltip";
import LogoParceriaInterna from "../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../Components/Menu/Menu";
import Titulo from "../../../Components/Titulo";
import { GlobalContext } from "../../../Context/GlobalContext/context";
import * as permissions from "../../../Context/GlobalContext/permissions";
import { apiURL } from "../../../Services/env";
import { api } from '../../../Services/Api';
import './style.css';


export class ManterPapel extends Component {


    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.referencia = React.createRef();

        this.state = {
            isMobile: false,
            data_equipes: [],
            equipes: [],
            papeis: [],
            funcionalidades: [],
            equipesPaginado: [],
            page: 0,
            offset: 0,
            limit: 10,
            pageCount: 0,
            pesquisa: '',

            equipe: null, 
            equipeFunc: null, 
            equipeFuncNome: null, 
            papel: null,
            papelFunc: null,
            codPapelFunc: null,
            papelFuncNome: null,
            papeldesc: null,
            funcionalidade: [],
            funcionalidadesEquipe: [],
            funcsPapelEquipe: []
        }
    }

    componentDidMount() {
        if (!this.context.getPermission(permissions.VISUALIZAR_FUNCOES_EQUIPES) && 
            !this.context.getPermission(permissions.VISUALIZAR_FUNCOES_TODAS_EQUIPES)) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        this.setState({ isMobile: window.screen.width <= 400 })
        this.getDados();
    }

    getDados = () => {

        this.context.startLoading();

        api(apiURL + 'gerirAcesso/manterPapeis')
            .then(async res => await res.json())
            .then(res => {

                let arr = res.resultado;

                this.setState({ equipes: res.equipes, papeis: res.papeis, funcionalidades: res.funcionalidades })
                this.setState({ data_equipes: arr })
                this.setState({ pageCount: Math.ceil(res.resultado.length / this.state.limit) })
                this.paginarDados(this.state.offset, this.state.limit)
            })
            .finally(() => {
                this.context.stopLoading()
            })

    }

    paginarDados = (offset, limit) => {
        this.setState({ equipesPaginado: this.state.data_equipes.slice(offset, limit) })
    }

    paginate = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.limit;
        const limit = offset + this.state.limit;

        this.paginarDados(offset, limit);
    }

    limparFiltro = () => {
        document.getElementById('equipe_seletor').value = 'Todos';
        this.getDados();
    }

    handlePesquisa = (e, type = 1) => {

        let texto = '';

        if (type === 1) texto = e.target.value;
        if (type === 2) texto = document.getElementById('equipe_seletor').value;

        if (texto === '' || texto === 'Escolha') {
            this.getDados();
        }
        else {
            let res = this.state.data_equipes.filter(r => r.equipe.NOME.toLowerCase().includes(texto.toLowerCase()));
            this.setState({ pageCount: Math.ceil(res.length / this.state.limit) })
            this.setState({ equipesPaginado: res })

        }
    }

    getPapeisDaEquipe = () => {

        const equipe = document.getElementById('equipe_salvar').value

        if (equipe !== '' && equipe !== '-- Selecione uma equipe --'){

            this.context.startLoading();

            this.setState({ equipe: equipe })

            api(apiURL + 'equipe/getPapeisEquipe/' + equipe)
                .then(async res => await res.json())
                .then(res => {
                    this.setState({ siglasPapeis: res.papeis })
                })
                .finally(() => {
                    this.context.stopLoading();
                })
        }
    }

    limparForm = () => {
        this.setState({
            equipe: null,
            papel: null,
            funcionalidade: []
        })
        document.getElementById('equipe_salvar').value = '-- Selecione uma equipe --';
        document.getElementById('papel_salvar').value = '';
        document.getElementById('papeldesc_salvar').value = '';
    }

    handleModalSalvar = () => {
        if (this.context.getPermission(permissions.CRIAR_FUNCOES_EQUIPES) || this.context.getPermission(permissions.CRIAR_FUNCOES_TODAS_EQUIPES)) {
            this.setState({ id: null, codigo: '', descricao: '' });
            document.getElementById('modalShowCreate').click()
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    salvarDados = () => {
        if (this.context.getPermission(permissions.CRIAR_FUNCOES_EQUIPES) || this.context.getPermission(permissions.CRIAR_FUNCOES_TODAS_EQUIPES)) {
            let equipe = this.state.equipe;
            const papel = this.state.papel;
            const papeldesc = this.state.papeldesc;

            if (equipe !== '' && equipe !== null && papel !== '' && papel !== null){

                let op = true;

                if (equipe === 'Todas'){
                    equipe = ''
                    op = window.confirm('Você selecionou todas as equipes, então a alteração afetará todos os papeis das equipes!\nDeseja prosseguir com a ação?')
                }

                if (op){

                    document.getElementById('btnCloseModal').click();
                    this.context.startLoading();

                    const body = new FormData();
                    body.append('equipe', equipe);
                    body.append('papel', papel);
                    body.append('papeldesc', (papeldesc !== null && papeldesc !== '') ? papeldesc : '');

                    const requestOptions = {
                        method: 'POST',
                        body
                    }

                    api(apiURL + 'gerirAcesso/manterPapeis', requestOptions)
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.limparForm();
                        this.context.startLoading();
                        this.getDados();
                        
                    })
                }
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    removerPapelDaEquipe = (cod_equipe, id_papel) => {
        if (this.context.getPermission(permissions.EXCLUIR_FUNCOES_EQUIPES)) {

            const op = window.confirm("Confirme a remoção da função da equipe:")
            
            if (op){

                this.context.startLoading();

                const body = new FormData();
                body.append('cod_equipe', cod_equipe);
                body.append('id_papel', id_papel);

                const requestOptions = {
                    method: 'POST',
                    body
                }
                api(apiURL + 'gerirAcesso/manterPapeis/removerPapelDaEquipe', requestOptions)
                .then(async res => await res.json())
                .then(r => alert(r.message))
                .finally(() => {
                    this.context.stopLoading()
                    this.getDados();
                });
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    componentDidUpdate(){
        ReactTooltip.rebuild()
    }

    getFuncionalidadesDoPapelEquipe = async (equipe, papel) => {

        const body = new FormData()
        body.append('papel', papel)
        body.append('equipe', equipe)

        const requestOptions = {
            method: 'post',
            body
        }
        const res = await api(apiURL + 'auxiliar/getFuncionalidesPapelEquipe', requestOptions);
        return await res.json();
    }

    handleModalFuncionalidades = (equipe, papel) => {

        const modal = document.getElementById('launchModalFuncionalidades');
        this.context.startLoading();

        this.getFuncionalidadesDoPapelEquipe(equipe.COD_EQUIPE, papel.COD_PAPEL)
        .then(res => {
            this.setState({ 
                funcsPapelEquipe: res.map(f => f.COD_FUNCIONALIDADE),
                equipeFunc: equipe.COD_EQUIPE,
                equipeFuncNome: equipe.NOME,
                papelFuncNome: papel.NOME,
                papelFunc: papel.ID_PAPEL,
                codPapelFunc: papel.COD_PAPEL
            });
        })
        .finally(() => {
            this.context.stopLoading();
            modal.click();
        })
    }

    salvarFuncionalidadeDoPapel = (e, id_papel, cod_func) => {
        
        if (this.context.getPermission(permissions.CRIAR_FUNCOES_EQUIPES)) {

            const op = window.confirm("Confirme a alteração:")
            let id = id_papel + '-' + cod_func;
            
            if (op){

                this.context.startLoading();

                const body = new FormData();
                body.append('id_papel', id_papel);
                body.append('cod_func', cod_func);
                body.append('status', e.target.checked ? 'create' : 'delete');

                const requestOptions = {
                    method: 'POST',
                    body
                }
                api(apiURL + 'gerirAcesso/manterPapeis/gerirFuncionalidadeDoPapel', requestOptions)
                .then(async res => await res.json())
                .then(async r => {
                    this.getFuncionalidadesDoPapelEquipe(this.state.equipeFunc, this.state.codPapelFunc)
                    .then(res => {
                        this.setState({ 
                            funcsPapelEquipe: res.map(f => f.COD_FUNCIONALIDADE)
                        });
                    })
                    alert(r.message)
                })
                .finally(f => {
                    this.context.stopLoading();
                });
            }
            else {
                document.getElementById(id).checked = false;
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu idMenuOpen="btnAcesso"></Menu>
                <div className="page-content fundoAreaTrabalho" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid" >
                        <div className="row" >
                            <div className="col p-2" style={{ backgroundColor: '#F2F3F7' }}>
                                <div className="container-fluid">
                                    <br />
                                    <Titulo 
                                        name="Funções" 
                                        className=".mt-3"
                                        tooltip={{ 
                                            texto: `<p>Aqui você pode adicionar ou remover as funções <br />
                                                        e atividades ligadas a cada equipe. Depois, ao gerenciar “Equipes”<br />
                                                        você poderá atribuir essas funções/atividades as pessoas membros da mesma.
                                            </p>`, 
                                            direcao: 'right' 
                                        }}
                                    />
                                    <br />

                                    <div className="row my-4" >
                                        <div className="col">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Filtrar funções por equipe
                                                    </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                        <form>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="equipe_seletor">Equipe</label>
                                                                        <select id='equipe_seletor' className="form-control" onChange={() => this.handlePesquisa(document.event, 2)}>
                                                                            <option>Escolha</option>
                                                                            {this.state.equipes.map((seq, index) => {
                                                                                return <option key={index} value={seq.NOME}>{seq.NOME}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12" style={{ textAlign: 'right' }}>
                                                                    <button type="button" className="btn btn-secondary" onClick={this.limparFiltro}>Limpar/Ver todos</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group my-4" style={{textAlign: 'right'}}>
                                        <button 
                                            className="btn btn-primary"
                                            type="button" 
                                            onClick={this.handleModalSalvar}

                                        >Adicionar função</button>
                                        <button 
                                            className="d-none"
                                            id="modalShowCreate"
                                            type="button" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#modal"
                                            ></button>
                                    </div>


                                    <div className="row my-1">
                                        <div className="col-lg-12">
                                            <table className="table table-striped">
                                                <tbody>
                                                    {this.state.equipesPaginado.map((e, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                <tr key={index} style={{paddingTop: '10px'}}>
                                                                    <td>
                                                                        <i className="fas fa-users text-primary me-2"></i>
                                                                        <strong className="text-primary">
                                                                            {e.equipe.NOME.toUpperCase()}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="left" style={{padding: '20px 0 0 0'}}>
                                                                        {
                                                                            Object.keys(e.papeis).map((key, i) => {
                                                                                return (
                                                                                    <ul key={i} style={ { listStyle: 'none' } }>
                                                                                        <li>
                                                                                            <i className="fas fa-bookmark text-success me-2"></i>
                                                                                            <strong>{e.papeis[key].papel.NOME.toUpperCase()}</strong>
                                                                                            <button 
                                                                                                className="btn btn-link" style={{float: 'right'}} 
                                                                                                onClick={() => this.removerPapelDaEquipe(e.equipe.COD_EQUIPE, e.papeis[key].papel.ID_PAPEL)}
                                                                                                data-tip="Remover função"
                                                                                                data-place="bottom"
                                                                                                data-effect="solid"
                                                                                            >
                                                                                                <i className="fas fa-trash text-danger"></i>
                                                                                            </button>
                                                                                            {
                                                                                                this.context.getPermission(permissions.GERENCIAR_PERMISSOES) &&
                                                                                                <button 
                                                                                                    className="btn btn-link" style={{float: 'right'}} 
                                                                                                    data-tip="Gerenciar permissões"
                                                                                                    data-place="bottom"
                                                                                                    data-effect="solid"
                                                                                                    onClick={() => this.handleModalFuncionalidades(e.equipe, e.papeis[key].papel, e.papeis[key].papel)}
                                                                                                >
                                                                                                    <i className="fas fa-lock"></i>
                                                                                                </button>
                                                                                            }
                                                                                            <button 
                                                                                                className="d-none"
                                                                                                id="launchModalFuncionalidades" 
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target="#modalFuncionalidades"
                                                                                            >
                                                                                            </button>
                                                                                        </li>
                                                                                    </ul>
                                                                                )
                                                                            })
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={"Anterior"}
                                            nextLabel={"Próximo"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}

                                            marginPagesDisplayed={this.state.isMobile ? 1 : 5}
                                            pageRangeDisplayed={this.state.isMobile ? 1 : 2}
                                            onPageChange={this.paginate}

                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />

                                        <div className="modal fade" id="modal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="modalLabel">Adicione funções e atividades às equipes</h5>
                                                        <button 
                                                            type="button" 
                                                            id="btnCloseModal" 
                                                            className="btn-close" 
                                                            data-bs-dismiss="modal" 
                                                            aria-label="Close"
                                                            onClick={this.limparForm}
                                                        ></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row my-3">
                                                            <div className="alert alert-info">
                                                                Para adicionar uma função a uma equipe em específico, selecione a equipe, em seguida a função e por fim, quais atividades aquela determinada função pode exercer dentro da equipe escolhida.
                                                            </div>
                                                            <div className="col-md-12 mb-3">
                                                                <label htmlFor="equipe_salvar" >Equipe:</label>
                                                                <select 
                                                                    className="form-control" 
                                                                    id="equipe_salvar" 
                                                                    onChange={e => this.setState({ equipe: e.target.value })}
                                                                    defaultValue='-- Selecione uma equipe --'    
                                                                    defaultChecked
                                                                >
                                                                        <option disabled>-- Selecione uma equipe --</option>
                                                                        {this.state.equipes.map((e, index) => {
                                                                            return <option key={index} value={e.COD_EQUIPE}>{e.NOME}</option>
                                                                        })}
                                                                </select>
                                                            </div>
                                                            <div className="col-md-12 mb-3 p-0">
                                                                <fieldset>
                                                                    <legend style={{fontSize:'12pt'}}>Função</legend>
                                                                    <div className="col-md-12 mb-3">
                                                                        <label htmlFor="papel_salvar">Nome:</label>
                                                                        <input 
                                                                            className="form-control" 
                                                                            id="papel_salvar" 
                                                                            onChange={e => this.setState({ papel: e.target.value })}
                                                                            disabled={!this.state.equipe}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="papeldesc_salvar">Descrição:</label>
                                                                        <input 
                                                                            className="form-control" 
                                                                            id="papeldesc_salvar" 
                                                                            onChange={e => this.setState({ papeldesc: e.target.value })}
                                                                            disabled={!this.state.equipe}
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={this.salvarDados}
                                                            disabled={!this.state.equipe || !this.state.papel || this.context.loading}
                                                        >
                                                            Salvar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal fade" id="modalFuncionalidades" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="modalLabel">Gerencie as atividades das funções</h5>
                                                        <button 
                                                            id="closeModalFuncionalidades"
                                                            type="button" 
                                                            className="btn-close" 
                                                            data-bs-dismiss="modal" 
                                                            aria-label="Close"
                                                        ></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-me-12 text-left px-4">
                                                                <p className="alert alert-primary">
                                                                    <strong className="me-1">Equipe:</strong>
                                                                    {this.state.equipeFuncNome}
                                                                    <br />
                                                                    <strong className="me-1">Função:</strong>
                                                                    {this.state.papelFuncNome}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                        {Object.keys(this.state.funcionalidades).map(key => (
                                                            <div className="col-md-12" key={key}>
                                                                <fieldset style={{ display: 'flex', flexWrap: 'wrap' }} disabled={this.context.loading}>
                                                                    <legend>{key}</legend>
                                                                    {this.state.funcionalidades[key].map((value, i) => (
                                                                    <div className="form-group m-2 mr-5" key={i}>
                                                                        <input 
                                                                            id={this.state.papelFunc + '-' + value.COD_FUNCIONALIDADE}
                                                                            type='checkbox'
                                                                            className="form-check-input me-1"
                                                                            onChange={e => this.salvarFuncionalidadeDoPapel(e, this.state.papelFunc, value.COD_FUNCIONALIDADE)}
                                                                            checked={this.state.funcsPapelEquipe.includes(value.COD_FUNCIONALIDADE)}
                                                                        />
                                                                        <label 
                                                                            className="form-check-label"
                                                                            style={{ fontSize:'11pt' }}
                                                                            htmlFor={this.state.papelFunc + '-' + value.COD_FUNCIONALIDADE}
                                                                        >
                                                                            {value.DESCRICAO}
                                                                        </label>
                                                                    </div>
                                                                    ))}
                                                                </fieldset>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-bs-dismiss="modal" 
                                                            aria-label="Close"
                                                        >
                                                            Fechar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}