import React from 'react';
import LogoParceria from '../../Components/LogoParceria';
import { Redirect }  from 'react-router-dom';
import '../../css/style.css';
import { apiURL } from '../../Services/env';
import background from "../../Resources/img/fundoLogin.png";

import { GlobalContext } from "../../Context/GlobalContext/context";

class RecuperarSenha extends React.Component {

    static contextType = GlobalContext;

    constructor(props){
        super(props);

        this.mostrarSenha = this.mostrarSenha.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeSenhaConfirmar = this.handleChangeSenhaConfirmar.bind(this);
        this.handleChangeSenhaRenovar = this.handleChangeSenhaRenovar.bind(this);
        this.atualizarSenha = this.atualizarSenha.bind(this);

        var url = props.location.search;
        url = url.substring(1, url.length);

        const access_token = url.split('access_token=')[1];

        this.state = {
            codigo: access_token,
            redirecionar: false,
            senhaRenovar:'',
            senhaConfirmar:''
        };
    }

    async atualizarSenha(){
        
        try{
                
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    senha: this.state.senhaRenovar,
                    confirmar_senha: this.state.senhaConfirmar,
                    access_token: this.state.codigo,
                })
            };

            const response = await fetch(apiURL + "atualizarSenha", requestOptions);
            const data = await response.json();
                        
            return data["mensagem"];
            
        }
        catch(error){
            console.log(error);
            return "Não foi possível atualizar a senha!";
        }


    }

    handleSubmit(e) {
    
        e.preventDefault();

        if(
            (this.state.senhaConfirmar === null || this.state.senhaConfirmar === undefined || this.state.senhaConfirmar === '') ||
            (this.state.senhaRenovar === null || this.state.senhaRenovar === undefined || this.state.senhaRenovar === '')
        ){
            alert("Gentileza preencher os campos para alterar a senha!");
        }
        else{

            if(this.state.senhaConfirmar !== this.state.senhaRenovar){
                alert("A confirmação da senha está diferente da senha informada!");
            }
            else{

                this.context.startLoading();
                
                this.atualizarSenha().then(data => {

                    if(data !== 'sucesso'){                        
                        alert(data);
                    }
                    else{
                        alert('Senha alterada com sucesso! Por gentileza realize login no sistema!'); 
                        this.setState({redirecionar: true});                        
                    }
                })
                .catch()
                .finally(() => this.context.stopLoading());
        
            }

        }

    }

    mostrarSenha(e){

        e.preventDefault();
        
        let senhaAtual = document.getElementById("senhaRenovar");
        let senhaNova = document.getElementById("senhaConfirmar");
        let mostra = document.getElementById("mostra");

        if(senhaAtual.type === 'text'){
            senhaAtual.type = 'password';
            senhaNova.type = 'password';
            mostra.innerText = 'Mostrar senha';
        }
        else{
            senhaAtual.type = 'text';
            senhaNova.type = 'text';
            mostra.innerText = 'Ocultar senha';
        }
    }

    handleChangeSenhaConfirmar(e){
        this.setState({senhaConfirmar: e.target.value});
    }

    handleChangeSenhaRenovar(e){
        this.setState({senhaRenovar: e.target.value});
    }

    render(){

        if(this.state.redirecionar === true){
            return ( <Redirect to="/"></Redirect>);
        }
        else{
                return (
                    <div className="containerLogin" style={{background: `transparent url(${background})`}}>         
                        <LogoParceria></LogoParceria>                       
                        <div className="formularioLogin">
                            <div className="alert alert-danger erroLogin my-3" role="alert" id="erro">
                                    Não foi possível atualizar a senha!
                                </div>                                 
                            <form onSubmit={this.handleSubmit} >   
                                                              
                                <div className="mb-3">
                                    <label htmlFor="senhaRenovar" className="form-label primeiro">Digite a nova senha:</label>
                                    <input type="password" className="form-control" id="senhaRenovar" onChange={this.handleChangeSenhaRenovar}/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="senharConfirmar" className="form-label">Confirme a nova senha:</label>
                                    <input type="password" className="form-control" id="senhaConfirmar" onChange={this.handleChangeSenhaConfirmar}/>
                                </div>
                                <div className="row mb-3 d-flex align-items-center">
                                    <div className="col my-3">
                                        <button id="mostra" type="button" onClick={this.mostrarSenha} className="btn btn-secondary" >
                                            Mostrar senha
                                        </button>  
                                    </div>
                                    <div className="col my-3 d-flex align-items-center">                                
                                        <button type="submit" className="botaoLogin" disabled={this.context.loading}>Atualizar</button>
                                    </div>
                                </div>
                            </form>              
                        </div>
                    </div>
                );            
        }
    }

}
export default RecuperarSenha;