function ComboEstados(props) {

    const list = ["AC","AL","AM","AP","BA","CE","DF","ES","GO","MA","MG","MS","MT","PA","PB",
    "PE","PI","PR","RJ","RN","RS","RO","RR","SC","SE","SP","TO"];

    return(  
        <div className="col-xs-12 col-sm-12 col-md-2">                                        
            <label htmlFor={props.id}>Estado</label>
            <select id={props.id} className="form-select" onChange={props.onChange} defaultValue={'0'}>
                <option value="0"> -- </option>
                {
                    list.map(item => (
                        <option key={item} value={item}>{item}</option>
                    ))
                }
                
            </select>
        </div>
    );
}

export default ComboEstados;