import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import LogoParceriaInterna from "../../../../Components/LogoParceria/LogoParceriaInterna";
import Menu from "../../../../Components/Menu/Menu";
import Titulo from "../../../../Components/Titulo";
import { GlobalContext } from "../../../../Context/GlobalContext/context";
import { apiURL } from "../../../../Services/env";
import { api } from '../../../../Services/Api';
import * as permissions from "../../../../Context/GlobalContext/permissions";

import ReactTooltip from "react-tooltip";

export default class ManterEquipeEquipe extends Component {


    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.referencia = React.createRef();

        this.state = {
            isMobile: false,
            Equipes: [],
            equipes: [],
            page: 0,
            offset: 0,
            limit: 10,
            pageCount: 0,
            pesquisa: '',

            id: null,
            nome: '',
            email: '',
            cpf: '',
            descricao: '',
        }
    }

    componentDidMount() {
        if (!this.context.getPermission(permissions.VISUALIZAR_EQUIPES)) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
        this.getDados(this.state.limit, this.state.offset);
        this.setState({ isMobile: window.screen.width <= 400 })
    }

    getDados = (limit, offset, pesquisa = "") => {

        this.context.startLoading();

        const body = new FormData();
        body.append('limit', limit);
        body.append('offset', offset);

        if (pesquisa !== "") {
            body.append('pesquisa', pesquisa);
        }

        const requestOptions = {
            method: 'POST',
            body
        }

        api(apiURL + 'gerirAcesso/manterEquipes/equipes', requestOptions)
            .then(async res => await res.json())
            .then(res => {

                const { data, total } = res;

                this.setState({
                    equipes: Object.values(data),
                    pageCount: total,
                })
            })
            .finally(() => {
                this.context.stopLoading()
            })

    }

    paginate = (e) => {

        let page = e.selected;

        this.setState({ offset: page + 1, page })

        if (this.state.pesquisa !== '') {
            this.getDados(this.state.limit, page + 1, this.state.pesquisa);
        }
        else {
            this.getDados(this.state.limit, page + 1);
        }
    }

    limparFiltro = () => {
        document.getElementById('pesquisar').value = '';
        this.setState({
            limit: 10,
            offset: 0,
            page: 0,
            pesquisa: ''
        })
        this.getDados(10, 0);
    }

    limpartForm = () => {
        this.setState({
            id: null,
            nome: '',
            sigla: '',
            email: '',
            descricao: '',
        })
        document.getElementById('nome_salvar').value = '';
        document.getElementById('sigla_salvar').value = '';
        document.getElementById('email_salvar').value = '';
        document.getElementById('descricao_salvar').value = '';
        this.limparFiltro()
    }

    handlePesquisa = (e) => {
        e.preventDefault();
        this.setState({ page: 0 })
        this.getDados(10, 0, this.state.pesquisa)
    }

    salvarEquipe = () => {

        if (this.context.getPermission(permissions.CRIAR_EQUIPES)) {
            const nome = this.state.nome ?? '';
            const sigla = this.state.sigla ?? '';
            const email = this.state.email ?? '';
            const descricao = this.state.descricao ?? '';

            if (nome !== '' && nome !== null && descricao !== '' && descricao !== null && sigla !== '' && sigla !== null) {

                this.context.startLoading();
                document.getElementById('btnCloseModal').click();

                const body = new FormData();
                body.append('nome', nome);
                body.append('email', email);
                body.append('sigla', sigla);
                body.append('descricao', descricao);

                const requestOptions = {
                    method: 'POST',
                    body
                }

                api(apiURL + 'gerirAcesso/manterEquipes/equipes/salvar', requestOptions)
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.limpartForm();
                        this.context.stopLoading();
                        this.getDados(this.state.limit, this.state.offset);
                    })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    removerEquipe = (id_equipe) => {

        if (this.context.getPermission(permissions.EXCLUIR_EQUIPES)) {
            const op = window.confirm("Confirme a exlusão do registro: ");

            if (op) {

                this.context.startLoading();

                const requestOptions = {
                    method: 'POST'
                }

                api(apiURL + 'gerirAcesso/manterEquipes/equipes/deletar/' + id_equipe, requestOptions)
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.context.stopLoading();
                        this.getDados(this.state.limit, this.state.offset);
                    })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    editarEquipe = (equipe) => {
        if (this.context.getPermission(permissions.EDITAR_EQUIPES)) {
            this.setState({
                id: equipe.COD_EQUIPE,
                nome: equipe.NOME ?? '',
                sigla: equipe.SIGLA ?? '',
                email: equipe.EMAIL ?? '',
                descricao: equipe.DESCRICAO ?? '',
            });
            document.getElementById('btnLaunchModalUpdate').click();
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    atualizarEquipe = () => {

        if (this.context.getPermission(permissions.EDITAR_EQUIPES)) {
            const id = this.state.id ?? '';
            const nome = this.state.nome ?? '';
            const email = this.state.email ?? '';
            const descricao = this.state.descricao ?? '';

            if (id !== '' && id !== null && nome !== '' && nome !== null !== null && descricao !== '' && descricao !== null !== null) {

                this.context.startLoading();
                document.getElementById('btnCloseModalUpdate').click();

                const body = new FormData();
                body.append('nome', nome);
                body.append('email', email);
                body.append('descricao', descricao);

                const requestOptions = {
                    method: 'POST',
                    body
                }

                api(apiURL + 'gerirAcesso/manterEquipes/equipes/atualizar/' + id, requestOptions)
                    .then(async res => await res.json())
                    .then(res => alert(res.message))
                    .finally(() => {
                        this.limpartForm();
                        this.context.stopLoading();
                        this.getDados(this.state.limit, this.state.offset);
                    })
            }
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    handleSalvarEquipe = () => {
        if (this.context.getPermission(permissions.CRIAR_EQUIPES)) {
            document.getElementById('btnLaunchModalCreate').click();
        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    render() {
        return (
            <div className="container-xl-fluid">
                <Menu idMenuOpen="btnAcesso"></Menu>
                <div className="page-content fundoAreaTrabalho" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid" >
                        <div className="row" >
                            <div className="col p-2" style={{ backgroundColor: '#F2F3F7' }}>
                                <div className="container-fluid">
                                    <br />
                                    <Titulo
                                        name="Gerenciar equipes"
                                        className="mt-3"
                                        tooltip={{
                                            texto: `<p>Utilize os campos e botões abaixo para pesquisar, criar e editar equipes ligadas ao Projeto</p>`,
                                            direcao: 'right'
                                        }}
                                    />
                                    <br />

                                    <div className="row my-4" >
                                        <div className="col">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Filtrar equipes
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <input className="form-control" id="pesquisar" type='search' placeholder="Pesquisar (Nome, Sigla, E-mail)" onChange={e => this.setState({ pesquisa: e.target.value })} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12" style={{ textAlign: 'right' }}>
                                                                        <button type="button" className="btn btn-secondary" onClick={this.limparFiltro}>Limpar</button>
                                                                        <button type="submit" className="btn btn-primary mx-2" onClick={this.handlePesquisa} disabled={!this.state.pesquisa}>Pesquisar</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1 justify-content-end" style={{ alignItems: 'center' }}>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group my-4" style={{ textAlign: 'right' }}>
                                                <button
                                                    className="btn btn-primary btn-block mb-2"
                                                    type="button"
                                                    style={{ marginRight: '15px' }}
                                                    onClick={this.handleSalvarEquipe}
                                                >
                                                    Adicionar equipe
                                                </button>
                                                <button
                                                    className="d-none"
                                                    id="btnLaunchModalCreate"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalCreate"
                                                    style={{ marginRight: '15px' }}
                                                    onClick={() => ReactTooltip.rebuild()}
                                                >
                                                </button>

                                                <button
                                                    id="btnLaunchModalUpdate"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalUpdate"
                                                    className="d-none"
                                                ></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-1">
                                        <div className="col-lg-12">

                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">SIGLA</th>
                                                            <th scope="col">NOME</th>
                                                            <th scope="col">E-MAIL</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.equipes.map((e, index) => {
                                                            return (
                                                                <tr key={index}>

                                                                    <td>
                                                                        {e.SIGLA.toUpperCase()}
                                                                    </td>

                                                                    <td>
                                                                        {e.NOME.toUpperCase()}
                                                                    </td>

                                                                    <td>
                                                                        {e.EMAIL ?? ' - '}
                                                                    </td>

                                                                    <td>
                                                                        <button
                                                                            className="btn"
                                                                            onClick={() => this.editarEquipe(e)}
                                                                            data-tip="Editar equipe"
                                                                            data-place="bottom"
                                                                            data-effect="solid"
                                                                        >
                                                                            <i className="fas fa-edit text-primary"></i>
                                                                        </button>
                                                                        <button
                                                                            className="btn"
                                                                            onClick={() => this.removerEquipe(e.COD_EQUIPE, 2)}
                                                                            data-tip="Remover equipe"
                                                                            data-place="bottom"
                                                                            data-effect="solid"
                                                                        >
                                                                            <i className="fas fa-trash text-danger"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={"Anterior"}
                                            nextLabel={"Próximo"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}

                                            marginPagesDisplayed={this.state.isMobile ? 1 : 5}
                                            pageRangeDisplayed={this.state.isMobile ? 1 : 2}
                                            onPageChange={this.paginate}

                                            forcePage={this.state.page}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                    </div>

                                    <div className="modal fade" id="modalCreate" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="modalLabel">Adicionar Nova Equipe</h5>
                                                    <button
                                                        type="button"
                                                        id="btnCloseModal"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row my-3">
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="sigla_salvar">Sigla:</label>
                                                            <input
                                                                className="form-control"
                                                                id="sigla_salvar"
                                                                onChange={e => this.setState({ sigla: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="nome_salvar">Nome:</label>
                                                            <input
                                                                className="form-control"
                                                                id="nome_salvar"
                                                                onChange={e => this.setState({ nome: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <label htmlFor="email_salvar">E-mail (opcional):</label>
                                                            <input
                                                                type='email'
                                                                className="form-control"
                                                                id="email_salvar"
                                                                onChange={e => this.setState({ email: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <label htmlFor="descricao_salvar">Descrição:</label>
                                                            <textarea
                                                                rows={5}
                                                                className="form-control"
                                                                id="descricao_salvar"
                                                                onChange={e => this.setState({ descricao: e.target.value })}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                        disabled={this.context.loading}
                                                    >
                                                        Fechar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={this.salvarEquipe}
                                                        disabled={
                                                            !this.state.nome ||
                                                            !this.state.sigla ||
                                                            !this.state.descricao ||
                                                            this.context.loading
                                                        }
                                                    >
                                                        Salvar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="modalUpdate" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="modalLabel">Atualizar dados da equipe</h5>
                                                    <button
                                                        type="button"
                                                        id="btnCloseModalUpdate"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row my-3">

                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="sigla_salvar">Sigla:</label>
                                                            <input
                                                                className="form-control"
                                                                id="sigla_salvar"
                                                                defaultValue={this.state.sigla}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                                            <label htmlFor="nome_salvar">Nome:</label>
                                                            <input
                                                                className="form-control"
                                                                id="nome_salvar"
                                                                onChange={e => this.setState({ nome: e.target.value })}
                                                                value={this.state.nome}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <label htmlFor="email_salvar">E-mail (opcional):</label>
                                                            <input
                                                                type='email'
                                                                className="form-control"
                                                                id="email_salvar"
                                                                onChange={e => this.setState({ email: e.target.value })}
                                                                value={this.state.email}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <label htmlFor="descricao_salvar">Descrição:</label>
                                                            <textarea
                                                                rows={5}
                                                                className="form-control"
                                                                id="descricao_salvar"
                                                                onChange={e => this.setState({ descricao: e.target.value })}
                                                                value={this.state.descricao}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={this.limpartForm}
                                                        disabled={this.context.loading}
                                                    >
                                                        Fechar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={this.atualizarEquipe}
                                                        disabled={
                                                            !this.state.nome ||
                                                            !this.state.descricao ||
                                                            this.context.loading}
                                                    >
                                                        Atualizar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}