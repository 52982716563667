import React from 'react';
import Menu from '../../../Components/Menu/Menu';
import Titulo from '../../../Components/Titulo';
import LogoParceriaInterna from '../../../Components/LogoParceria/LogoParceriaInterna';
import 'react-tabs/style/react-tabs.css';
import '../../../css/style.css';
import { apiURL } from '../../../Services/env';
import { api } from "../../../Services/Api";
import { GlobalContext } from '../../../Context/GlobalContext/context';
import * as permissions from '../../../Context/GlobalContext/permissions';

class ExportarPessoaFisica extends React.Component {

    static contextType = GlobalContext;

	constructor(props){
        super(props);

        this.referencia = React.createRef();

        this.handleSubmitExportar = this.handleSubmitExportar.bind(this);
        this.handleSelecionarTodos = this.handleSelecionarTodos.bind(this);
        this.handleDeselecionarTodos = this.handleDeselecionarTodos.bind(this);

        this.state = {
            token: localStorage.getItem("projartToken"),
            cpf: localStorage.getItem("projartCod"),
            nome: localStorage.getItem('projartUsuario')
        };

    }

    componentDidMount(){
        if (!this.context.getPermission(permissions.EXPORTAR_PROJETO_PF)) {
            alert('Permissão negada!');
            window.location.href = '/painel'
        }
    }

    handleSelecionarTodos(e){
        
        let valor = false;
        if(document.getElementById('checkTodos').checked === true){
            valor = true;
        }

        document.getElementById('checkTempoAtuacao').checked = valor;
        document.getElementById('checkAtuaSomenteSetorArtesanal').checked = valor;
        document.getElementById('checkFormaAtuacao').checked = valor;
        document.getElementById('checkPrincipaisDesafios').checked = valor;
        document.getElementById('checkNome').checked = valor;
        document.getElementById('checkCPF').checked = valor;
        document.getElementById('checkGenero').checked = valor;
        document.getElementById('checkFaixaEtaria').checked = valor;
        document.getElementById('checkInscricaoSICAB').checked = valor;
        document.getElementById('checkInteresseSICAB').checked = valor;
        document.getElementById('checkCidade').checked = valor;
        document.getElementById('checkEstado').checked = valor;
        document.getElementById('checkTwitter').checked = valor;
        document.getElementById('checkFacebook').checked = valor;
        document.getElementById('checkInstagram').checked = valor;
        document.getElementById('checkLinkedIn').checked = valor;
        
    }

    handleDeselecionarTodos(e){
        
        let contador = 0;
        
        if(
            (document.getElementById('checkTempoAtuacao').checked === false) ||
            (document.getElementById('checkAtuaSomenteSetorArtesanal').checked === false) ||
            (document.getElementById('checkFormaAtuacao').checked === false) ||
            (document.getElementById('checkPrincipaisDesafios').checked === false) ||
            (document.getElementById('checkNome').checked === false) ||
            (document.getElementById('checkCPF').checked === false) ||
            (document.getElementById('checkGenero').checked === false) ||
            (document.getElementById('checkFaixaEtaria').checked === false) ||
            (document.getElementById('checkInscricaoSICAB').checked === false) ||
            (document.getElementById('checkInteresseSICAB').checked === false) ||
            (document.getElementById('checkCidade').checked === false) ||
            (document.getElementById('checkEstado').checked === false) ||
            (document.getElementById('checkTwitter').checked === false) ||
            (document.getElementById('checkFacebook').checked === false) ||
            (document.getElementById('checkInstagram').checked === false) ||
            (document.getElementById('checkLinkedIn').checked === false) 
        ){
            contador++;
        }

        if(contador > 0 ){
            document.getElementById('checkTodos').checked = false;
        }
       
    }

    async extrairPessoaFisica(){

        let spinner = document.getElementById("spinner");
        spinner.style.display = 'block';

        let formData = new FormData();
        formData.append('token', this.state.token);

        if(document.getElementById('checkTempoAtuacao').checked === true){
            formData.append('setor_artesanal_desde','s');
        }

        if(document.getElementById('checkAtuaSomenteSetorArtesanal').checked === true){
            formData.append('exclusivo_setor_artesanal','s');
        }

        if(document.getElementById('checkFormaAtuacao').checked === true){
            formData.append('forma_atuacao_setor_artesanal','s');
        }
/*
        if(document.getElementById('checkLegislacao').checked === true){
            formData.append('principais_desafios','s');
            formData.append('legislacao','s');
        }

        if(document.getElementById('checkComercializacao').checked === true){
            formData.append('principais_desafios','s');
            formData.append('comercializacao','s');
        }

        if(document.getElementById('checkProducao').checked === true){
            formData.append('principais_desafios','s');
            formData.append('producao','s');
        }


        if(document.getElementById('checkConhecimento').checked === true){
            formData.append('principais_desafios','s');
            formData.append('conhecimento','s');
        }
*/

        if(document.getElementById('checkPrincipaisDesafios').checked === true){
            formData.append('principais_desafios','s');
        }

        if(document.getElementById('checkNome').checked === true){
            formData.append('nome_pessoa','s');
        }

        if(document.getElementById('checkCPF').checked === true){
            formData.append('cpf_pessoa','s');
        }

        if(document.getElementById('checkGenero').checked === true){
            formData.append('genero','s');
        }

        if(document.getElementById('checkFaixaEtaria').checked === true){
            formData.append('faixa_etaria','s');
        }

        if(document.getElementById('checkInscricaoSICAB').checked === true){
            formData.append('numero_sicab','s');
        }

        if(document.getElementById('checkInteresseSICAB').checked === true){
            formData.append('interesse_sicab','s');
        }

        if(document.getElementById('checkCidade').checked === true){
            formData.append('endereco_cidade','s');
        }

        if(document.getElementById('checkEstado').checked === true){
            formData.append('endereco_uf','s');
        }

        if(document.getElementById('checkTwitter').checked === true){
            formData.append('twitter','s');
        }

        if(document.getElementById('checkFacebook').checked === true){
            formData.append('facebook','s');
        }

        if(document.getElementById('checkInstagram').checked === true){
            formData.append('instagram','s');
        }

        if(document.getElementById('checkLinkedIn').checked === true){
            formData.append('linkedin','s');
        }

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        const response = await api(apiURL+"pessoa/exportar/PessoaFisica", requestOptions);
        return response;

    }

    handleSubmitExportar(e){

        e.preventDefault();
        
        if (this.context.getPermission(permissions.EXPORTAR_PROJETO_PF)) {
            
            this.context.startLoading();

            document.getElementById("erro").style.display = 'none';
            document.getElementById("erro").innerHTML = '';
            document.getElementById("sucesso").style.display = 'none';
            document.getElementById("sucesso").innerHTML = '';
        
        
            this.extrairPessoaFisica()
            .then(response => { 
                //return response.text();
                return response.blob();       
            })
            .then(blob => {     
    //            const url = URL.createObjectURL(new Blob([data],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'}));
                const url = URL.createObjectURL(blob,{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exportacao_pessoa_fisica.xlsx');
                document.body.appendChild(link);
                link.click();       
                document.body.removeChild(link);
            })
            .catch(erra => {console.log(erra);})
            .finally(() => this.context.stopLoading())   

        }
        else {
            alert('Você não tem permissão para realizar esta operação, consulte o administrador do sistema!')
        }
    }

	render(){
	    return (		
			
            <div className="container-xl-fluid">         
                <Menu idMenuOpen = "btnPesquisa"></Menu>             
                <div className="page-content fundoAreaTrabalho" id="content">
                    <div className="container-fluid cabecalhoPainel" >
                        <LogoParceriaInterna referencia={this.referencia}></LogoParceriaInterna>
                    </div>
                    <div className="container-fluid" >
                        <div className="row" >
                            <div className="col p-2" style={{backgroundColor: '#F2F3F7'}}>
                                <div className="container-fluid">  
                                    <br/>
                                    <Titulo name="Exportar dados da pessoa física" className=".mt-3"/>        
                                    <br/>                               
                                    <div className="row my-1">    
                                        <div className = "col">                                            
                                            <form className="container" encType="multipart/form-data">
                                                <div className="alert alert-danger" role="alert" id="erro"  style={{display:'none'}}>
                                                </div>
                                                <div className="alert alert-success" role="alert" id="sucesso" style={{display:'none'}}>
                                                </div>
                                                <div className="row my-3">
                                                    <div className = "col-xs-12 col-sm-12 col-md-12">
                                                        Selecione os campos que deseja exportar para a planilha:<br/>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className = "col d-flex flex-row-reverse  align-items-center">               
                                                        <label htmlFor="checkTodos"> &nbsp; Selecionar todos os campos </label>
                                                        <input type="checkbox" id='checkTodos' onChange={this.handleSelecionarTodos}/>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row mt-3" >
                                                    <div className="col">
                                                        <label><b>ARTESANATO</b> </label>
                                                    </div>
                                                </div>                                                
                                                <div className="row my-3">
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkTempoAtuacao' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkTempoAtuacao"> &nbsp; Tempo de atuação</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkAtuaSomenteSetorArtesanal' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkAtuaSomenteSetorArtesanal"> &nbsp; Atua somente no setor</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkFormaAtuacao' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkFormaAtuacao"> &nbsp; Forma de atuação</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkPrincipaisDesafios' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkPrincipaisDesafios"> &nbsp; Principais desafios</label>
                                                    </div>                                                   
                                                </div>
                                                <hr style={{display: 'none'}}/>
                                                <div className="row mt-3" style={{display: 'none'}} >
                                                    <div className="col">
                                                        <label><b>PRINCIPAIS DESAFIOS</b> </label>
                                                    </div>
                                                </div>
                                                <div className="row my-3" style={{display: 'none'}}>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkLegislacao' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkLegislacao"> &nbsp; Legislação</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkComercializacao' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkComercializacao"> &nbsp; Comercialização</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkProducao' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkProducao"> &nbsp; Produção</label>
                                                    </div>
                                                </div>
                                                <div className="row mt-3"  style={{display: 'none'}}>
                                                    <div className="col">
                                                        <input type="checkbox" id='checkConhecimento' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkConhecimento"> &nbsp; Registro do saber / Práticas e técnicasTempo de atuação no setor</label>
                                                    </div>                                                    
                                                </div>
                                                <hr/>
                                                <div className="row mt-3" >
                                                    <div className="col">
                                                        <label><b>IDENTIFICAÇÃO</b> </label>
                                                    </div>
                                                </div>
                                                <div className="row my-3">                                                    
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkNome' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkNome"> &nbsp; Nome</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkCPF' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkCPF"> &nbsp; CPF</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkGenero' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkGenero"> &nbsp; Gênero</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkFaixaEtaria' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkFaixaEtaria"> &nbsp; Faixa etária</label>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row mt-3" >
                                                    <div className="col">
                                                        <label><b>LOCALIZAÇÃO</b> </label>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkCidade' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkCidade"> &nbsp; Cidade</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkEstado' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkEstado"> &nbsp; Estado</label>
                                                    </div>
                                                </div>
                                                <hr/>                                                
                                                <div className="row mt-3" >
                                                    <div className="col">
                                                        <label><b>SICAB</b> </label>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className = "col-xs-12 col-sm-6 col-md-4">                                                        
                                                        <input type="checkbox" id='checkInscricaoSICAB' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkInscricaoSICAB"> &nbsp; Possui inscrição no SICAB</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-6">                                                        
                                                        <input type="checkbox" id='checkInteresseSICAB' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkInteresseSICAB"> &nbsp; Possui interesse em se cadastrar no SICAB</label>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row mt-3" >
                                                    <div className="col">
                                                        <label><b>REDES SOCIAIS</b> </label>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkLinkedIn' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkLinkedIn"> &nbsp; Possui LinkedIn</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkFacebook' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkFacebook"> &nbsp; Possui Facebook</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkInstagram' onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkInstagram"> &nbsp; Possui Instagram</label>
                                                    </div>
                                                    <div className = "col-xs-12 col-sm-6 col-md-3">                                                        
                                                        <input type="checkbox" id='checkTwitter'  onChange={this.handleDeselecionarTodos}/>
                                                        <label htmlFor="checkTwitter"> &nbsp; Possui Twitter</label>
                                                    </div>
                                                </div>
                                                <hr/>      
                                                <div className="row my-3">
                                                    <div className="col d-flex flex-row-reverse  align-items-center">
                                                        <button type="submit" className="btn btn-primary my-3"
                                                            onClick={this.handleSubmitExportar} id="botaoExportar">
                                                                EXPORTAR
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>  
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>  
            </div>              
        );
    }


}

export default ExportarPessoaFisica;