import { Link }  from 'react-router-dom';

function MenuDocumento() {
    return(        
        <div className = "col-lg-2">
            <ul className="list-group">
                <li className="list-group-item list-group-item-primary my-0">
                    <Link to="/painel/GestaoDocumento/DocumentoEdit">Novo</Link> 
                </li>
                <li className="list-group-item list-group-item-primary">
                    <Link to="/painel/GestaoDocumento/DocumentoPesquisa">Pesquisar</Link> 
                </li>
            </ul>
        </div>
    );
    
}
export default MenuDocumento;