import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../Context/GlobalContext/context';
import acaoMenu from './acaoMenu';
import imagem from './simbolotr-quadrado.png';
import "./style.css";
import * as permissions from "../../Context/GlobalContext/permissions"

class Menu extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.atualizarFoto = this.atualizarFoto.bind(this);
        this.simularClick = this.simularClick.bind(this);

        this.state = {
            nome: localStorage.getItem('projartUsuario'),
            papeis: localStorage.getItem('projartPapeis'),
            foto: (localStorage.getItem('projartFoto') !== null &&
                localStorage.getItem('projartFoto') !== undefined &&
                localStorage.getItem('projartFoto') !== '') ? localStorage.getItem('projartFoto') : imagem,
            idOpen: props.idMenuOpen
        };

    }

    componentDidMount() {

        if ((this.state.idOpen !== null && this.state.idOpen !== undefined)) {
            let button = document.getElementById(this.state.idOpen);
            button.click();
        }
    }


    simularClick(id) {
    }

    atualizarFoto() {
        let profile = (localStorage.getItem('projartFoto') !== null && localStorage.getItem('projartFoto') !== undefined) ? localStorage.getItem('projartFoto') : imagem;
        this.setState({ foto: profile });
    }

    render() {
        return (
            <aside className="vertical-nav" id="sidebar">
                <button
                    className='btn text-white mr-2'
                    id='btnCloseMenu'
                    style={{ float: 'right' }}
                    onClick={acaoMenu}
                >
                    <i className='fas fa-times text-with'></i>
                </button>
                {/* { this.context.getPermission('VISUALIZAR_MINHA_CONTA') && <div className="py-4 mb-2 w-100"> */}
                {<div className="py-4 mb-2 w-100">
                    <div className="d-flex flex-column align-items-center my-4">

                        <div
                            id="profile"
                            style={{
                                backgroundImage: `url(${this.state.foto})`,
                                backgroundPosition: 'top',
                                backgroundSize: 'cover',
                                height: '115px',
                                width: '115px',
                                backgroundColor: '#fff',
                                border: '5px solid #fff',
                                maxWidth: '100%',
                                borderRadius: '50%'
                            }}
                            className="mr-3 shadow-sm mb-4"
                        ></div>

                        <p className="mb-3 text-center" style={{ color: '#ccc' }}>
                            <span style={{ fontSize: '19px' }}>Olá, {this.state.nome}.</span>
                        </p>
                        <Link to="/painel/minhaConta" className="botaoLink rounded-0 text-center">Minha conta</Link>
                    </div>
                </div>}

                <ul className="nav flex-column mb-0">

                    <li className="mb-1  menuPrincipalAjuste">
                        <Link to="/painel" className="btn btn-toggle btn-open-link-menu rounded" style={{ fontSize: '16px' }}>
                            <i className="fas fa-home" style={{ fontSize: '20px' }}></i>
                            <p>Página inicial</p>
                        </Link>
                    </li>

                    {(
                        this.context.getPermission(permissions.VISUALIZAR_USUARIOS) ||
                        this.context.getPermission(permissions.VISUALIZAR_FUNCOES_EQUIPES) ||
                        this.context.getPermission(permissions.VISUALIZAR_FUNCOES_TODAS_EQUIPES) ||
                        this.context.getPermission(permissions.VISUALIZAR_USUARIOS_FUNCOES_EQUIPES) ||
                        this.context.getPermission(permissions.VISUALIZAR_USUARIOS_FUNCOES_TODAS_EQUIPES) ||
                        this.context.getPermission(permissions.VISUALIZAR_EQUIPES) ||
                        this.context.getPermission(permissions.GERENCIAR_TERMO_PARTICIPACAO)
                    )
                        &&
                        <li className="mb-1  menuPrincipalAjuste">
                            <div id="btnAcesso"
                                className="btn btn-toggle btn-open-link-menu rounded collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#acesso-collapse"
                                aria-expanded="false"
                            >
                                <i className="fas fa-address-card"></i>
                                <p>Gestão de Acesso</p>
                            </div>
                            <div className="collapse" id="acesso-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                                    <Fragment>
                                        {
                                            this.context.getPermission(permissions.VISUALIZAR_USUARIOS) &&
                                            <li>
                                                <Link className="btn w-100 h-100" style={{ fontSize: '16px' }}
                                                      to="/painel/GestaoAcesso/ManterUsuarios">
                                                    Pessoas
                                                </Link>
                                            </li>
                                        }

                                        {(
                                            this.context.getPermission('VISUALIZAR_FUNCOES_EQUIPES') ||
                                            this.context.getPermission('VISUALIZAR_FUNCOES_TODAS_EQUIPES') 
                                        )
                                        &&
                                            <li>
                                                <Link className="btn w-100 h-100" style={{fontSize:'16px'}}
                                                    to="/painel/GestaoAcesso/ManterPapeis"
                                                >
                                                    Funções
                                                </Link>
                                            </li>
                                        }
                                        {(this.context.getPermission('VISUALIZAR_USUARIOS_FUNCOES_EQUIPES') || 
                                            this.context.getPermission('VISUALIZAR_EQUIPES') ||
                                            this.context.getPermission('VISUALIZAR_USUARIOS_FUNCOES_TODAS_EQUIPES') 
                                        ) &&

                                            <li className="btn w-100 h-100 submenu-btn">
                                                <p
                                                    id='btnEquipes'
                                                    className="collapsed m-0 text-white"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#equipes-collapse"
                                                    aria-expanded="true"
                                                    onClick={() => document.getElementById('eqp_seta').getAttribute('class') === 'fas fa-angle-down' ? document.getElementById('eqp_seta').setAttribute('class', 'fas fa-angle-right') : document.getElementById('eqp_seta').setAttribute('class', 'fas fa-angle-down')}

                                                >
                                                    Equipes
                                                    <i className='fas fa-angle-right mt-1' id='eqp_seta' style={{ float: 'right' }}></i>
                                                </p>
                                                <div className="collapse mt-3" id="equipes-collapse">
                                                    <ul className="btn-toggle-nav list-unstyled fw-normal submenu-container">
                                                        {(
                                                            this.context.getPermission('VISUALIZAR_USUARIOS_FUNCOES_EQUIPES') ||
                                                            this.context.getPermission('VISUALIZAR_USUARIOS_FUNCOES_TODAS_EQUIPES') 
                                                        ) &&
                                                            <li style={{ background: 'transparent !importante' }}>
                                                                <Link className="btn w-100 h-100 submenu" to="/painel/GestaoAcesso/ManterEquipes/usuarios">
                                                                    Associar Pessoas
                                                                </Link>
                                                            </li>
                                                        }
                                                        {this.context.getPermission('VISUALIZAR_EQUIPES') &&
                                                            <li>
                                                                <Link className="btn w-100 h-100 submenu" to="/painel/GestaoAcesso/ManterEquipes/equipes">
                                                                    Gerenciar equipes
                                                                </Link>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                        }

                                        {
                                            this.context.getPermission(permissions.GERENCIAR_TERMO_PARTICIPACAO)
                            
                                        &&
                                            <li>
                                                <Link className="btn w-100 h-100" style={{fontSize:'16px'}}
                                                    to="/painel/GestaoAcesso/TermoParticipacao"
                                                >
                                                    Termo de Participacao
                                                </Link>
                                            </li>
                                        }

                                    </Fragment>
                                </ul>
                            </div>
                        </li>
                    }

                    {/* <li className="mb-1  menuPrincipalAjuste">
                            <div 
                                id='btnAgenda'
                                className="btn btn-toggle btn-open-link-menu rounded collapsed" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#agenda-collapse" 
                                aria-expanded="true"
                            >
                                <i className="far fa-calendar-alt"></i>
                                <p>Gestão de Agenda</p>
                            </div>
                            <div className="collapse" id="agenda-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                                    <li><Link className="btn w-100 h-100" to="#">Manter agenda pública</Link></li>                            
                                    <li><Link className="btn w-100 h-100" to="#">Manter agenda interna</Link></li>
                                </ul>
                            </div>
                        </li> */}

                    {(
                        this.context.getPermission('SOLICITAR_DOCUMENTOS') ||
                        this.context.getPermission('SOLICITAR_DOCUMENTOS_TODAS_EQUIPES') ||
                        this.context.getPermission('CRIAR_DOCUMENTOS') ||
                        this.context.getPermission('VISUALIZAR_DOCUMENTOS') ||
                        this.context.getPermission('VISUALIZAR_DOCUMENTOS_EQUIPE') ||
                        this.context.getPermission('VISUALIZAR_DOCUMENTOS_TODAS_EQUIPES')
                    )
                        &&
                        <li className="mb-1  menuPrincipalAjuste">
                            <div id="btnDocumentos" className="btn btn-toggle btn-open-link-menu rounded collapsed" data-bs-toggle="collapse" data-bs-target="#documento-collapse" aria-expanded="true">
                                <i className="fas fa-folder"></i>
                                <p>Gestão de Documento</p>
                            </div>

                            <div className="collapse" id="documento-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">

                                    {(
                                        this.context.getPermission('VISUALIZAR_DOCUMENTOS') ||
                                        this.context.getPermission('SOLICITAR_DOCUMENTOS') ||
                                        this.context.getPermission('SOLICITAR_DOCUMENTOS_TODAS_EQUIPES')
                                    ) &&
                                        <li>
                                            <Link className="btn w-100 h-100" id="solicitacaoDocumento"
                                                to="/painel/GestaoDocumento/SolicitacaoDocumento">
                                                Solicitar
                                            </Link>
                                        </li>
                                    }

                                    {this.context.getPermission('CRIAR_DOCUMENTOS') &&
                                        <li >
                                            <Link className="btn w-100 h-100" id="documentoEdit" to={{
                                                pathname: "/painel/GestaoDocumento/DocumentoEdit",
                                                state: { id: 0 }
                                            }}>
                                                Novo
                                            </Link>
                                        </li>
                                    }

                                    {(
                                        this.context.getPermission('VISUALIZAR_DOCUMENTOS_EQUIPE') ||
                                        this.context.getPermission('VISUALIZAR_DOCUMENTOS_TODAS_EQUIPES')
                                    ) &&
                                        <li>
                                            <Link className="btn w-100 h-100" id="documentoPesquisa"
                                                to="/painel/GestaoDocumento/DocumentoPesquisa">
                                                Pesquisar
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </div>

                        </li>
                    }

                    {(this.context.getPermission('EXPORTAR_PROJETO_PF') || this.context.getPermission('EXPORTAR_PROJETO_PJ')) &&
                        <li className="mb-1  menuPrincipalAjuste">
                            <div id="btnPesquisa" className="btn btn-toggle btn-open-link-menu rounded collapsed" data-bs-toggle="collapse" data-bs-target="#pesquisa-collapse" aria-expanded="true">
                                <i className="fas fa-search"></i>
                                <p>Gestão de Pesquisa</p>
                            </div>

                            <div className="collapse" id="pesquisa-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">

                                    {(this.context.getPermission('EXPORTAR_PROJETO_PF')) &&
                                        <li><Link className="btn w-100 h-100" id="exportarPessoaFisica" to="/painel/GestaoPesquisa/ExportarPessoaFisica">Exportar pessoa física</Link></li>
                                    }

                                    {(this.context.getPermission('EXPORTAR_PROJETO_PJ')) &&
                                        <li><Link className="btn w-100 h-100" id="exportarPessoaJuridica" to="/painel/GestaoPesquisa/ExportarPessoaJuridica">Exportar pessoa jurídica</Link></li>
                                    }

                                </ul>
                            </div>

                        </li>
                    }

                    {/* { (this.context.getPermission('VISUALIZAR') ) && 
                            <li className="mb-1  menuPrincipalAjuste">
                                <div 
                                    id='btnRelatorio'
                                    className="btn btn-toggle btn-open-link-menu rounded collapsed" 
                                    data-bs-toggle="collapse" data-bs-target="#relatorio-collapse" aria-expanded="true"
                                >
                                    <i className="far fa-chart-bar"></i>
                                    <p>Gestão de Relatório</p>
                                </div>
                                <div className="collapse" id="relatorio-collapse">
                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                                        <li><Link className="btn w-100 h-100" to="#">Gerar relatórios</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Pesquisar Relatórios</Link></li>
                                    </ul>
                                </div>
                            </li>
                        } */}
                    {/* { (this.context.getPermission('VISUALIZAR') ) && 
                            <li className="mb-1  menuPrincipalAjuste">
                                <div 
                                    id='btnFormulario'
                                    className="btn btn-toggle btn-open-link-menu rounded collapsed" 
                                    data-bs-toggle="collapse" data-bs-target="#formulario-collapse" aria-expanded="true"
                                >
                                    <i className="fas fa-pencil-alt"></i>
                                    <p>Gestão de Formulário</p>
                                </div>
                                <div className="collapse" id="formulario-collapse">
                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                                        <li><Link className="btn w-100 h-100" to="#">Artesão 1</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Artesão 2</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Organização de Artesões</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Instituições financeiras</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Instituições de apoio</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Gestores (gov. Est.) </Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Sistemas de ensino (escolas)</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Assembléia Legislativa</Link></li>                    
                                    </ul>
                                </div>
                            </li>
                        } */}
                    {/* { (this.context.getPermission('VISUALIZAR') )&& 
                            <li className="mb-1  menuPrincipalAjuste">
                                <div 
                                    id='btnAjuda'
                                    className="btn btn-toggle btn-open-link-menu rounded collapsed" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#ajuda-collapse" aria-expanded="true"
                                >
                                    <i className="far fa-question-circle"></i>
                                    <p>Ajuda</p>
                                </div>
                                <div className="collapse" id="ajuda-collapse">
                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                                        <li><Link className="btn w-100 h-100" to="#">Chamados</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Chat</Link></li>                            
                                        <li><Link className="btn w-100 h-100" to="#">Manuais</Link></li>
                                    </ul>
                                </div>
                            </li>
                        } */}

                    <li className="mt-5 menuPrincipalAjuste logoutMobile">
                        <a href='#/' onClick={this.context.logout}
                            className="btn btn-toggle align-items-center rounded collapsed"
                        >
                            <i className="fas fa-sign-out-alt"></i> Sair
                        </a>
                    </li>

                </ul>

            </aside>

        );

    }

}
export default Menu;