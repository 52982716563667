import React from 'react';
import { Redirect }  from 'react-router-dom';

class DocumentoRedirect extends React.Component {

	constructor(props){
        
        super(props);

        this.state = {
            
        };
            
    }


	componentDidMount(){

    }

    componentWillUnmount(){

    }


	render(){
	    return (		
            <Redirect 
                    to={{
                        pathname:"/painel/GestaoDocumento/DocumentoEdicao",
                        state: {id: 0}
                     }} >
            </Redirect>

        )
    }


}

export default DocumentoRedirect;