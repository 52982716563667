import Painel from '../Painel';
import Login from '../Login';
import Documentos from '../GestaoDocumento/Documentos'
import Templates from '../GestaoDocumento/Templates';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import DocumentoEdit from '../GestaoDocumento/DocumentoEdit';
import DocumentoRedirect from '../GestaoDocumento/DocumentoRedirect';
import DocumentoPesquisa from '../GestaoDocumento/DocumentoPesquisa';
import SolicitacaoDocumento from '../GestaoDocumento/SolicitacaoDocumento';
import MinhaConta from '../Usuario/MinhaConta';
import Termos from '../Usuario/Termos';
import RecuperarSenha from '../RecuperarSenha';
import ExportarPessoaFisica from '../GestaoPesquisa/ExportarPessoaFisica';
import ExportarPessoaJuridica from '../GestaoPesquisa/ExportarPessoaJuridica';

import { SpinnerContainer } from '../../Components/Spinner';
import { GlobalContextProvider } from '../../Context/GlobalContext';

import ManterEquipeUsuario from '../GestaoAcesso/ManterEquipe/usuario';
import ManterEquipeEquipe from '../GestaoAcesso/ManterEquipe/equipe';
import { ManterPapel } from '../GestaoAcesso/ManterPapeis';
// import ManterUsuarioPainel from '../GestaoAcesso/ManterUsuario/Painel';
// import ManterUsuarioSite from '../GestaoAcesso/ManterUsuario/Site';
import ReactTooltip from 'react-tooltip';

import '../../index.css';
import TermoParticipacao from '../GestaoAcesso/TermoParticipacao';
import TermoParticipacaoUsuario from '../Usuario/TermoParticipacao';
import TermoParticipacaoDetalhesUsuario from '../Usuario/TermoParticipacaoDetalhes';
import ManterUsuario from "../GestaoAcesso/ManterUsuario";
import DeclaracaoParticipacaoProjeto from '../Usuario/DeclaracaoParticipacaoProjeto';

function App() {

  return (

    <GlobalContextProvider>
      <SpinnerContainer />
      <ReactTooltip />
      <BrowserRouter>
        <Switch>
          < Route path="/" component={Login} exact />
          < Route path="/autenticacao/redefinirSenha" component={RecuperarSenha} exact />
          < PrivateRoute path='/painel' component={Painel} exact />
          < PrivateRoute path="/painel/GestaoDocumento/Documentos" component={Documentos} exact />
          < PrivateRoute path="/painel/GestaoDocumento/DocumentoEdit" component={DocumentoRedirect} exact />
          < PrivateRoute path="/painel/GestaoDocumento/DocumentoEdicao" component={DocumentoEdit} exact />
          < PrivateRoute path="/painel/GestaoDocumento/DocumentoPesquisa" component={DocumentoPesquisa} exact />
          < PrivateRoute path="/painel/GestaoDocumento/SolicitacaoDocumento" component={SolicitacaoDocumento} exact />
          < PrivateRoute path="/painel/GestaoDocumento/Templates" component={Templates} exact />
          < PrivateRoute path="/painel/GestaoAcesso/ManterUsuarios" component={ManterUsuario} exact />
          {/*< PrivateRoute path="/painel/GestaoAcesso/ManterUsuarios/painel" component={ManterUsuarioPainel} exact />*/}
          {/*< PrivateRoute path="/painel/GestaoAcesso/ManterUsuarios/site" component={ManterUsuarioSite} exact />*/}
          < PrivateRoute path="/painel/GestaoAcesso/ManterEquipes/usuarios" component={ManterEquipeUsuario} exact />
          < PrivateRoute path="/painel/GestaoAcesso/ManterEquipes/equipes" component={ManterEquipeEquipe} exact />
          < PrivateRoute path="/painel/GestaoAcesso/ManterPapeis" component={ManterPapel} exact />
          < PrivateRoute path="/painel/GestaoAcesso/TermoParticipacao" component={TermoParticipacao} exact />
          {/* < PrivateRoute path="/painel/GestaoAcesso/ManterRecursos" component={ManterRecuso} exact /> */}
          < PrivateRoute path="/painel/GestaoPesquisa/ExportarPessoaFisica" component={ExportarPessoaFisica} exact />
          < PrivateRoute path="/painel/GestaoPesquisa/ExportarPessoaJuridica" component={ExportarPessoaJuridica} exact />
          < PrivateRoute path="/painel/minhaConta" component={MinhaConta} exact />
          < PrivateRoute path="/painel/minhaConta/TermoServico" component={Termos} exact />
          < PrivateRoute path="/painel/minhaConta/TermoParticipacao" component={TermoParticipacaoDetalhesUsuario} exact />
          < PrivateRoute path="/painel/minhaConta/DeclaracaoParticipacaoProjeto" component={DeclaracaoParticipacaoProjeto} exact />
          < PrivateRoute path="/painel/TermoParticipacao" component={TermoParticipacaoUsuario} exact />
          < Route path="*">
            <Redirect to='/' />
          </Route>
        </Switch>
      </BrowserRouter>
    </GlobalContextProvider>
  )

}

function PrivateRoute({ component: Component, ...rest }) {

  let url = rest.location.search;
  let path = "/"
  if (url !== "" && url !== undefined && url !== null){
    path = rest.path + url;
    localStorage.setItem('redirect', path)
  }

  return (
    <Route
      {...rest}
      render={(props) => !!localStorage.getItem('projartCod') === true
        ? <Component {...props} />
        : <Redirect to='/' />}
    />
  )
}

export default App;